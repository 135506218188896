import React, { Fragment, useState, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import { Form, InputGroup, Overlay, Row, Col, Tooltip } from 'react-bootstrap'
import ButtonComponent from './Buttons'
import Icon from './Icon'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import MaskedInput from 'react-text-mask'
import InputFiles from 'react-input-files'
import LinkComponent from './Link'
import InputFileButton from './InputFileButton'

const InputFileBatch = ({
  styles,
  labelName,
  className,
  onChange,
  accept,
  multiple,
  files,
  isInvalid,
  isFileBatch,
  isEmptyFile,
  handleDeleteFile,
}) => {
  return isEmptyFile ? (
    <InputFiles accept={accept} multiple={multiple} onChange={onChange}>
      <div
        className={`
          ${isInvalid ? styles.inputs({ input: 'defaultHelper' }) : className}
          ${className ? className : styles.inputs({ input: 'default' })}
          ${labelName && 'form-control d-flex'}
          ${isFileBatch && 'file-batch-input'}
        `}
      >
        <div>
          <Icon iconName='attach_file' />
          <p className='m-0'>
            <span>Selecionar arquivo</span> ou arrastar arquivo
          </p>
          <p className='m-0'>Formato CSV com até 5MB</p>
        </div>
      </div>
    </InputFiles>
  ) : (
    <div className='file-batch-input-filled'>
      <div className='sent-list-area'>
        <div className='sent-list'>
          <p
            className={styles.tipography({
              paragraphy: 'smallBold',
            })}
          >
            Lista enviada:
          </p>
          <div className='d-flex'>
            <div className='file-icon' />
            <ButtonComponent
              className={`
                ${styles.buttonStandardMedium({
                  medium: 'secondary',
                })}
              `}
              hasIcon={true}
              iconName='delete'
              text='Excluir'
              styles={styles}
              onClick={() => handleDeleteFile()}
            />
          </div>
        </div>
        <div className='sent-list-info'>
          {files &&
            files?.map((e, i) => {
              return (
                <div key={i}>
                  <p>{e.name}</p>
                  <p>
                    Tamanho do arquivo:{' '}
                    <strong>{(e.size / (1024 * 1024)).toFixed(2)}mb</strong>
                  </p>
                  <p>
                    Data:{' '}
                    <strong>
                      {new Date(e.lastModified).toLocaleDateString()}
                    </strong>
                  </p>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

const InputFileDefault = ({
  styles,
  labelName,
  isInvalid,
  className,
  onChange,
  accept,
  multiple,
  files,
  filePlaceholderText,
  disabled,
}) => {
  return (
    <InputFiles
      accept={accept}
      multiple={multiple}
      onChange={onChange}
      style={disabled ? { pointerEvents: 'none' } : { pointerEvents: 'all' }}
    >
      <div
        className={`
          ${isInvalid ? styles.inputs({ input: 'defaultHelper' }) : className}
          ${className ? className : styles.inputs({ input: 'default' })}
          ${labelName && 'form-control d-flex'}
          ${disabled && 'disabled'}
        `}
      >
        <Fragment>
          <Icon iconName='file_upload' className='me-3' />
          {files ? (
            files
              .map((e, i) => {
                return e.name
              })
              .join(', ')
          ) : (
            <span className='input-file-placeholder'>
              {filePlaceholderText}
            </span>
          )}
        </Fragment>
      </div>
    </InputFiles>
  )
}

const InputUploadImageChange = ({
  styles,
  className,
  accept,
  multiple,
  onChange,
  defaultImage,
}) => {
  const imageFiller = (event) => {
    var target = event.target || window.event.srcElement,
      files = target.files

    onChange(files[0])

    if (FileReader && files && files.length) {
      var fileRender = new FileReader()
      fileRender.onload = function () {
        document.getElementById('image-filler').src = fileRender.result
        document.getElementById('btn-upload-action').innerHTML =
          'Alterar imagem'
        document.getElementById('lnk-remove-image').classList.remove('d-none')
      }
      fileRender.readAsDataURL(files[0])
    } else {
      document.getElementById('image-filler').src = ''
      document.getElementById('btn-upload-action').innerHTML = 'Inserir imagem'
      document.getElementById('lnk-remove-image').classList.add('d-none')
    }
  }

  const removeImage = () => {
    onChange(null)
    document.getElementById('image-filler').src = ''
    document.getElementById('btn-upload-action').innerHTML = 'Inserir imagem'
    document.getElementById('lnk-remove-image').classList.add('d-none')
  }

  return (
    <Row
      className={`
        ${className ? className : styles.inputs({ input: 'default' })}
        image-button-input
      `}
    >
      <Col>
        <img id='image-filler' src={defaultImage} alt='' />
      </Col>
      <Col xs={6} style={{ display: 'flex' }}>
        <InputFiles
          accept={accept}
          multiple={multiple}
          onChange={(e) => {
            imageFiller(e)
          }}
        >
          <ButtonComponent
            id='btn-upload-action'
            className={`
              ${styles.buttonStandardLarge({
                large: 'primary',
              })}
            `}
            text='Inserir imagem'
            styles={styles}
          />
        </InputFiles>
      </Col>
      <Col xs={2} className='d-flex align-items-center'>
        <LinkComponent
          id='lnk-remove-image'
          className={`
            ${styles.buttonStandardLarge({ large: 'link' })}
            ${styles.tipography({ paragraphs: 'largeLinkRegular' })}
            ${defaultImage ? '' : 'd-none'}
          `}
          to={'javasctip:void(0)'}
          text='Remover logotipo'
          onClick={() => removeImage()}
        />
      </Col>
    </Row>
  )
}

const Input = ({
  styles,
  style,
  id,
  idError,
  inputType,
  inputName,
  labelName,
  placeholder,
  required,
  isInvalid,
  errorText,
  hasIcon,
  iconDescription,
  iconName,
  isTextArea,
  isSearchBox,
  hasSearchButton,
  className,
  value,
  maxLength,
  onKeyDown,
  onChange,
  clearSearchField,
  disabled,
  isDatePicker,
  mask,
  isFileInput,
  selectsRange,
  startDate,
  endDate,
  monthsShown,
  setDateRange,
  hasTooltip,
  tooltipText,
  accept,
  multiple,
  files,
  filePlaceholderText,
  isFileBatch,
  isEmptyFile,
  isFileButton,
  handleDeleteFile,
  deleteFile,
  isFileImageUpload,
  excludeOldDate,
  maxDate,
}) => {
  const defaultState = {
    fieldType: inputType,
    passwordIcon: 'visibility_off',
    showPasswordText: 'Mostrar senha',
  }

  const [ownState, setOwnState] = useState(defaultState)

  const [show, setShow] = useState(false)
  const target = useRef(null)

  const toogleShowPassword = () => {
    ownState.fieldType === 'password'
      ? setOwnState({
          ...ownState,
          fieldType: 'text',
          passwordIcon: 'visibility_on',
          showPasswordText: 'Ocultar senha',
        })
      : setOwnState({
          ...ownState,
          fieldType: 'password',
          passwordIcon: 'visibility_off',
          showPasswordText: 'Mostrar senha',
        })
  }

  return (
    <div
      className={`
        ${!isFileButton && 'position-relative mb-3 w-100'}
        ${isDatePicker && 'date-picker'}
      `}
    >
      {isDatePicker ? (
        <Fragment>
          {labelName && (
            <Form.Label
              htmlFor={id}
              className={[
                styles.tipography({ paragraphs: 'smallBold' }),
                disabled && styles.colors({ colors: 'neutralColorLowLight' }),
              ]}
            >
              {labelName}
              {required && <span>*</span>}
              {hasTooltip && (
                <Fragment>
                  <div
                    className='tooltip-area'
                    ref={target}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                    role='button'
                    tabIndex={0}
                  >
                    <Icon iconName='info' />
                  </div>
                  <Overlay
                    target={target.current}
                    show={show}
                    placement='right'
                  >
                    {(props) => (
                      <Tooltip id='overlay-example' {...props}>
                        {tooltipText}
                      </Tooltip>
                    )}
                  </Overlay>
                </Fragment>
              )}
            </Form.Label>
          )}
          <DatePicker
            id={id}
            selectsRange={selectsRange}
            selected={!selectsRange && startDate}
            startDate={startDate}
            endDate={endDate}
            className={`
              ${
                isInvalid
                  ? styles.inputs({ input: 'defaultHelper' })
                  : styles.inputs({ input: 'default' })
              }
              form-control
            `}
            dateFormat='dd/MM/yyyy'
            onChange={(update) => {
              setDateRange(update)
            }}
            monthsShown={monthsShown}
            locale={ptBR}
            placeholderText={placeholder}
            disabled={disabled}
            minDate={excludeOldDate ? new Date() : null}
            maxDate={maxDate}
          >
            <div className='button-area d-none'>
              <hr />
              <div className='controls'>
                <ButtonComponent
                  className={`${styles.buttonStandardLarge({
                    large: 'primary',
                  })}
                  mt-4 mb-3 cancel-button`}
                  text='Cancelar'
                  styles={styles}
                  onClick={() => null}
                />
                <ButtonComponent
                  className={`${styles.buttonStandardLarge({
                    large: 'primary',
                  })}
                  mt-4 mb-3`}
                  text='Aplicar'
                  styles={styles}
                  onClick={() => null}
                />
              </div>
            </div>
          </DatePicker>
          <Icon iconName='calendar_month' className={disabled && 'disabled'} />
        </Fragment>
      ) : (
        <Fragment>
          {!isSearchBox && (
            <Form.Label
              htmlFor={id}
              className={[
                styles.tipography({ paragraphs: 'smallBold' }),
                disabled && styles.colors({ colors: 'neutralColorLowLight' }),
                !labelName && 'd-none',
              ]}
            >
              {labelName}
              {required && <span>*</span>}
              {hasTooltip && (
                <Fragment>
                  <div
                    className='tooltip-area'
                    ref={target}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                    role='button'
                    tabIndex={0}
                  >
                    <Icon iconName='info' />
                  </div>
                  <Overlay
                    target={target.current}
                    show={show}
                    placement='right'
                  >
                    {(props) => (
                      <Tooltip id='overlay-example' {...props}>
                        {tooltipText}
                      </Tooltip>
                    )}
                  </Overlay>
                </Fragment>
              )}
            </Form.Label>
          )}
          {hasIcon && (
            <InputGroup.Text
              className={
                isSearchBox
                  ? styles.inputs({ searchBoxIcon: 'searchIcon' })
                  : styles.inputs({ inputIcon: 'icon' })
              }
            >
              <Icon iconName={isSearchBox ? 'search' : iconName} />
              {!isSearchBox && (
                <span
                  className={styles.inputs({ inputIcon: 'iconDescription' })}
                >
                  {iconDescription}
                </span>
              )}
            </InputGroup.Text>
          )}
          {mask ? (
            <MaskedInput
              id={id}
              name={inputName}
              mask={mask}
              type={ownState.fieldType}
              aria-describedby={idError}
              placeholder={placeholder}
              required={required}
              isInvalid={isInvalid}
              className={`
                ${
                  isInvalid
                    ? styles.inputs({ input: 'defaultHelper' })
                    : className
                    ? className
                    : styles.inputs({ input: 'default' })
                }
                ${mask && 'form-control'}
                ${mask && hasIcon && 'form-control'}
              `}
              disabled={disabled}
              value={value}
              onKeyDown={onKeyDown}
              onChange={onChange}
            />
          ) : isFileInput ? (
            isFileBatch ? (
              <InputFileBatch
                styles={styles}
                labelName={labelName}
                className={className}
                onChange={onChange}
                accept={accept}
                multiple={multiple}
                files={files}
                isFileBatch={isFileBatch}
                isEmptyFile={isEmptyFile}
                handleDeleteFile={handleDeleteFile}
              />
            ) : isFileButton ? (
              <InputFileButton
                styles={styles}
                className={className}
                onChange={onChange}
                accept={accept}
                multiple={multiple}
                files={files}
                isEmptyFile={isEmptyFile}
                deleteFile={deleteFile}
              />
            ) : isFileImageUpload ? (
              <InputUploadImageChange
                styles={styles}
                className={className}
                labelName={labelName}
                accept={accept}
                multiple={multiple}
                onChange={onChange}
                defaultImage={files?.[0]}
              />
            ) : (
              <InputFileDefault
                styles={styles}
                labelName={labelName}
                isInvalid={isInvalid}
                className={className}
                onChange={onChange}
                accept={accept}
                multiple={multiple}
                files={files}
                filePlaceholderText={filePlaceholderText}
                disabled={disabled}
              />
            )
          ) : (
            <Form.Control
              id={id}
              name={inputName}
              style={style}
              type={ownState.fieldType}
              aria-describedby={idError}
              placeholder={placeholder}
              required={required}
              isInvalid={isInvalid}
              className={[
                isInvalid
                  ? styles.inputs({ input: 'defaultHelper' })
                  : className
                  ? className
                  : styles.inputs({ input: 'default' }),
                isTextArea && styles.inputs({ textArea: 'default' }),
                !isSearchBox &&
                  hasIcon &&
                  styles.inputs({ inputIcon: 'inputPadding' }),
                isSearchBox && styles.inputs({ searchBox: 'searchBoxDefault' }),
                isSearchBox &&
                  hasIcon &&
                  styles.inputs({ searchBox: 'searchBoxIcon' }),
              ]}
              as={isTextArea ? 'textarea' : 'input'}
              disabled={disabled}
              value={value}
              maxLength={maxLength}
              onKeyDown={onKeyDown}
              onChange={onChange}
            />
          )}
          {isSearchBox && (
            <InputGroup.Text
              className={`${!value && 'd-none'} ${styles.inputs({
                searchBoxIcon: 'closeIcon',
              })}`}
            >
              <div
                className='d-flex'
                onClick={clearSearchField}
                onKeyDown={clearSearchField}
                type='button'
                tabIndex={0}
              >
                <Icon iconName='close' />
                <span
                  className={styles.inputs({ inputIcon: 'iconDescription' })}
                >
                  Limpar campo de busca
                </span>
              </div>
            </InputGroup.Text>
          )}
          {isSearchBox && hasSearchButton && (
            <ButtonComponent
              className={`${styles.buttonSquareLarge({
                large: 'primary',
              })} ${styles.inputs({ searchBoxButton: 'buttonPosition' })}`}
              hasIcon={true}
              iconName='search'
            />
          )}
          {inputType === 'password' && (
            <InputGroup.Text
              className={styles.inputs({ inputIcon: 'passwordIcon' })}
              aria-label={ownState.showPasswordText}
              type='button'
              tabIndex={0}
              onClick={() => toogleShowPassword()}
            >
              <Icon iconName={ownState.passwordIcon} />
              <span className={styles.inputs({ inputIcon: 'iconDescription' })}>
                {ownState.showPasswordText}
              </span>
            </InputGroup.Text>
          )}
        </Fragment>
      )}
      {isInvalid && (
        <Form.Control.Feedback
          id={idError}
          type='invalid'
          className={[
            styles.tipography({ paragraphs: 'xSmallRegular' }),
            styles.colors({ colors: 'feedbackColorWarningPure' }),
          ]}
        >
          {errorText}
        </Form.Control.Feedback>
      )}
    </div>
  )
}

Input.propTypes = {
  styles: PropTypes.object,
  style: PropTypes.object,
  id: PropTypes.string,
  idError: PropTypes.string,
  inputName: PropTypes.string,
  labelName: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isInvalid: PropTypes.bool,
  errorText: PropTypes.string,
  hasIcon: PropTypes.bool,
  iconName: PropTypes.string,
  iconDescription: PropTypes.string,
  isTextArea: PropTypes.bool,
  isSearchBox: PropTypes.bool,
  hasSearchButton: PropTypes.bool,
  className: PropTypes.any,
  value: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  clearSearchField: PropTypes.func,
  disabled: PropTypes.bool,
  isDatePicker: PropTypes.bool,
  mask: PropTypes.object,
  isFileInput: PropTypes.bool,
  selectsRange: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  monthsShown: PropTypes.number,
  setDateRange: PropTypes.any,
  hasTooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  files: PropTypes.array,
  filePlaceholderText: PropTypes.string,
  isFileBatch: PropTypes.bool,
  isFileButton: PropTypes.bool,
  isEmptyFile: PropTypes.bool,
  handleDeleteFile: PropTypes.func,
  deleteFile: PropTypes.func,
  isFileImageUpload: PropTypes.bool,
}

export default memo(Input)

import moment from 'moment';
import { badgeTextByStatus } from './CommunicationReportTable';

export const transformCommunicationReportDataForXlxs = (headers, propertyNames, data) => {
  data.map(item => {
    item.sentAt = moment(item.sentAt).format("DD/MM/YYYY [às] HH:mm");
    item.status = badgeTextByStatus[item.status ?? 'scheduled'];
    return item;
  })

  const transformedData = data.map(item => {
    const transformedItem = {};
    propertyNames.forEach((propertyName, index) => {
      transformedItem[headers[index]] = item[propertyName];
    });

    return transformedItem;
  });

  return transformedData;
}

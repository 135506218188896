import { css } from './CreateStitches';
import opacityLayer from './img/opacity-layer.png';
import opacityLayerGradient from './img/opacity-layer-gradient.png';

export const opacityLevels = css({
  variants: {
    layerBG: {
      layerImg: {
        backgroundImage: `url(${opacityLayer})`,
        backgroundRepeat: 'no-repeat',
      },
      layerBlack: {
        backgroundColor: '$neutralColorLowPure',
      },
      layerGradient: {
        backgroundImage: `url(${opacityLayerGradient})`,
        backgroundRepeat: 'no-repeat',
      },
    },
    sizeBG: {
      outerSize: {
        width: 96,
        height: 96,
      },
      layerSize: {
        width: 64,
        height: 64,
      },
    },
    positionBG: {
      layerImg: {
        position: 'absolute',
        marginTop: 0,
        marginLeft: 0,
      },
      layerBlack: {
        position: 'absolute',
        marginTop: 16,
        marginLeft: 16,
        zIndex: 1,
      },
      layerGradient: {
        position: 'absolute',
        marginTop: 32,
        marginLeft: 32,
      },
    },
    opacityLevel: {
      semiopaque: {
        opacity: '0.80',
      },
      intense: {
        opacity: '0.64',
      },
      medium: {
        opacity: '0.32',
      },
      light: {
        opacity: '0.16',
      },
      semitransparent: {
        opacity: '0.08',
      },
    },
  },
});

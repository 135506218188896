import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import { BadgeComponent } from 'cashin-components'
import StepsControls from '../steps-controls'
import Summary from './summary'
import { CampaignContext } from '../../../context/campaign-context'
import { NegotiationUsageTypeAction } from '../../../enums/NegotiationUsageTypeAction'
import { useCompanyUsageBalance } from '../../../API/queries/company/useCompanyUsageBalance'
import api from '../../../API/api'
import axios from 'axios'

function reduzirQuebrasDeLinha(texto) {
  return texto.replace(/\n{2,}/g, (match) => {
    if (match.length >= 4) {
      return '\n\n'
    } else if (match.length === 3) {
      return '\n'
    } else {
      return '\n'
    }
  })
}

const CampaignSummary = ({
  styles,
  state,
  steps,
  handleStepBack,
  handleStepForward,
  handleSaveAndContinue,
  showSendTest,
  showSendTestConfirmation,
  handleCloseSendTest,
  handleCloseSendTestConfirmation,
  handleShotSendTest,
}) => {
  const campaignContext = useContext(CampaignContext)
  const { data: balance } = useCompanyUsageBalance(campaignContext.companyId)
  const [currentUser, setCurrentUser] = useState({})

  const [rule, setRule] = useState({})

  const handleGetTenantId = () => {
    if (!currentUser) return

    const tenantId = currentUser?.attributes?.['custom:tenantId']
    return tenantId
  }

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }

    handleGetTenantId()

    runAsync()
  }, [])

  const verifyFluxDataMeta = () => {
    if (campaignContext.isSegmentation === 'yes') {
      return '_Meta por segmento que o seu participante deve alcançar._'
    } else if (campaignContext.layoutInfos.campaignType === 0) {
      return campaignContext.layoutInfos.campaignBateu.replace('.', ',')
    } else if (campaignContext.layoutInfos.campaignType === 1) {
      const campaignVolumeRuleText =
        campaignContext.layoutInfos.campaignVolumeRuleText
          .replace(/Serviço ou Produto:/g, '')
          .replace(/<b><\/b>\s*/g, '')
          .replace(/<b>/g, '*')
          .replace(/<\/b>/g, '*')
          .replace(/<i>/g, '_')
          .replace(/<\/i>/g, '_')
          .replace(/<u>/g, '')
          .replace(/<\/u>/g, '')
          .replace(/<br\s*\/?>/g, '\n')
      return campaignVolumeRuleText
    } else if (campaignContext.layoutInfos.campaignType === 2) {
      const reachValueDescription =
        campaignContext.campaignState?.rule?.multipleReachValueCondition
          ?.reachValue.description
      const conditionType =
        campaignContext.campaignState?.rule?.multipleReachValueCondition
          ?.conditionType
      const reachValueFromDescription =
        campaignContext.campaignState?.rule?.multipleReachValueCondition
          ?.reachValueFromCondition?.description

      return (
        (reachValueDescription ? reachValueDescription + ' ' : '') +
        (conditionType ? conditionType + ' ' : '') +
        (reachValueFromDescription ? reachValueFromDescription : '')
      )
    }
  }

  const verifyFluxDataLevou = () => {
    if (campaignContext.isSegmentation === 'yes') {
      return '_Meta por segmento que o seu participante deve alcançar._'
    } else if (campaignContext.layoutInfos.campaignType === 0) {
      return campaignContext.layoutInfos.campaignLevou.replace('.', ',')
    } else if (campaignContext.layoutInfos.campaignType === 1) {
      return campaignContext.layoutInfos.campaignVolumePrize
    } else if (campaignContext.layoutInfos.campaignType === 2) {
      const discountDescription =
        campaignContext.campaignState?.prize?.multipleDiscount?.discount
          ?.description
      const conditionType =
        campaignContext.campaignState?.prize?.multipleDiscount?.conditionType
      const discountFromDescription =
        campaignContext.campaignState?.prize?.multipleDiscount
          ?.discountFromCondition?.description

      return (
        (discountDescription ? discountDescription + ' ' : '') +
        (conditionType ? conditionType + ' ' : '') +
        (discountFromDescription ? discountFromDescription : '')
      )
    }
  }

  useEffect(() => {
    const ruleType =
      campaignContext.isSegmentation === 'yes'
        ? campaignContext.campaignState.rules[0].ruleType
        : campaignContext.campaignState.rule.ruleType

    const ruleAndRules =
      campaignContext.isSegmentation === 'yes'
        ? campaignContext.campaignState?.rules[0]
        : campaignContext.campaignState?.rule
    const prizeAndPrizes =
      campaignContext.isSegmentation === 'yes'
        ? campaignContext.campaignState?.prizes[0]
        : campaignContext.campaignState?.prize

    const campaignBateu =
      ruleType === 0
        ? ruleAndRules.reachPurchaseValue?.type === 0
          ? 'R$ ' + ruleAndRules.reachPurchaseValue?.value
          : ruleAndRules.reachPurchaseValue?.value + ' %'
        : ''

    const campaignLevou =
      ruleType === 0
        ? prizeAndPrizes.discount?.type === 0
          ? 'R$ ' + prizeAndPrizes.discount?.value
          : prizeAndPrizes.discount?.value + ' %'
        : ''

    setRule({ campaignBateu, campaignLevou })
  }, [campaignContext.campaignState, campaignContext.isSegmentation])

  console.log(
    campaignContext,
    'campaignContextcampaignContextcampaignContextcampaignContext'
  )

  const onSave = async () => {
    campaignContext?.campaignDetails?.forEach((item) => {
      item.title = `*${item.title}*`
    })

    if (campaignContext?.communicationType === 'whatsapp') {
      campaignContext?.campaignDetails?.forEach((item) => {
        item.introduction = item.introduction.replace(/\*\*/g, '*')
        item.description = item.description.replace(/\*\*/g, '*')
      })
    }

    const addCompanyNegotiationBody = {
      companyId: campaignContext.companyId,
      totalUsage: parseInt(-campaignContext.participantsQuantity),
      action: 'Campanha nova',
      usageLimit: parseInt(balance?.usageLimit),
      negotiationUsageTypeAction: NegotiationUsageTypeAction.CreateCampaing,
    }

    await api.post('/company-negotiation-usage', addCompanyNegotiationBody)
    const res = await campaignContext.createCampaign()

    if (
      campaignContext.communicationType !== 'whatsapp' &&
      campaignContext.communicationType !== 'app'
    ) {
      await axios.post(`${process.env.REACT_APP_API_ENGAJE}create-campaign`, {
        id: res,
        companyName: 'Teste',
        type: state.layoutTitleChoosen,
        layoutInfo: campaignContext.layoutInfos,
      })
    } else {
      if (
        campaignContext?.comunicationLayoutType === 'default' &&
        campaignContext.communicationType === 'whatsapp'
      ) {
        const campaignsParams = campaignContext?.campaignDetailsParams

        campaignsParams.map((item) => {
          const lastParam = reduzirQuebrasDeLinha(item.parameters.at(-1))
          item.parameters[item.parameters.length - 1] = lastParam

          return item
        })

        console.log(campaignContext.sendDates)

        const campaignPayload = campaignsParams.map((item, index) => ({
          ...item,
          sendDate: campaignContext.sendDates[index],
        }))

        axios.post(`${process.env.REACT_APP_API_URL_WHATSAPP}/whatsapp/save`, {
          startDate:
            (campaignContext?.campaignState?.periodDate?.from).toISOString(),
          endDate:
            (campaignContext?.campaignState?.periodDate?.to).toISOString(),
          participants: campaignContext?.participants.map((item) => ({
            name: item.name,
            phone: item.cellphone,
            email: item.email,
            segmentationName: item.SegmentationName || item.segmentation,
          })),
          name: campaignContext?.campaignState?.name,
          campaignId: res,
          campaign: campaignPayload,
          tenantId: handleGetTenantId(),
          journeyType: campaignContext?.engajamentSelected,
          rule: `META: ${verifyFluxDataMeta()}\n\nPRÊMIO: ${verifyFluxDataLevou()}`,
        })
      }
    }

    handleSaveAndContinue()
  }

  return (
    <Col xs={10} className='pt-4 px-5'>
      <Row>
        <Col xs={12}>
          <BadgeComponent
            text={`Etapa ${state.activeStep}/${steps.length}`}
            styles={styles}
            className='badge-steps'
          />
        </Col>
        <Col xs={12}>
          <h2
            className={`
              ${styles.tipography({ headings: 'smallBold' })}
              my-2
            `}
          >
            Resumo
          </h2>
          <hr />
          <Summary styles={styles} state={state} />
        </Col>
        <StepsControls
          styles={styles}
          state={state}
          handleStepBack={handleStepBack}
          handleStepForward={handleStepForward}
          handleSaveAndContinue={onSave}
          showSendTest={showSendTest}
          showSendTestConfirmation={showSendTestConfirmation}
          handleShowSendTest={showSendTest}
          handleShowSendTestConfirmation={showSendTestConfirmation}
          handleCloseSendTest={handleCloseSendTest}
          handleCloseSendTestConfirmation={handleCloseSendTestConfirmation}
          handleShotSendTest={handleShotSendTest}
        />
      </Row>
    </Col>
  )
}

CampaignSummary.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  steps: PropTypes.array,
  handleStepBack: PropTypes.func,
  handleStepForward: PropTypes.func,
  handleSaveAndContinue: PropTypes.func,
  handleUploadChangeBatch: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  showSendTest: PropTypes.any,
  showSendTestConfirmation: PropTypes.any,
  handleCloseSendTest: PropTypes.func,
  handleCloseSendTestConfirmation: PropTypes.func,
  handleShowSendTest: PropTypes.func,
  handleShowSendTestConfirmation: PropTypes.func,
  handleShotSendTest: PropTypes.func,
}

export default CampaignSummary

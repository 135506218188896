import { memo } from "react";
import Pagination from "react-bootstrap/Pagination";
import "./CustomPagination.css";

const CustomPagination = ({ totalPages, onPaginationChange, currentPage, setCurrentPage }) => {
  if (totalPages === 0) return <></>;

  const renderingItems = [...Array(Math.ceil(totalPages))].map(
    (_, idx) => idx + 1
  );

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  };

  const handleOnClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPaginationChange?.(pageNumber);
  };
  const handleNext = () =>
    setCurrentPage((current) => {
      const newValue = ++current;
      onPaginationChange?.(newValue);
      return newValue;
    });
  const handlePrev = () =>
    setCurrentPage((current) => {
      const newValue = --current;
      onPaginationChange?.(newValue);
      return newValue;
    });

  //TODO: ta funcional mas ta complexo
  const rangeOfCurrentItems = renderingItems.slice(
    currentPage - 9 < 0 ? 0 : currentPage - 9,
    currentPage + 1 < 10 ? 10 : currentPage + 1
  );

  return (
    <div style={containerStyle}>
      <Pagination>
        <Pagination.Prev disabled={currentPage === 1} onClick={handlePrev} />
        {rangeOfCurrentItems.map((item) => (
          <Pagination.Item
            key={item}
            active={item === currentPage}
            className={item === currentPage ? "my-active-page" : null}
            onClick={(_) => handleOnClick(item)}
          >
            {item}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={handleNext}
        />
      </Pagination>
    </div>
  );
};

export default memo(CustomPagination);

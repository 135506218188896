import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'react-bootstrap';
import { Input, ButtonComponent, Select } from 'cashin-components';

const CompanyData = ({ styles, state }) => {
  return (
    <Row>
      <Col xs={4}>
        <h2
          className={`
            ${styles.tipography({ headings: 'xsmallBold' })}
            my-3
          `}
        >
          Situação
          <br />
          cadastral
        </h2>
      </Col>
      <Col xs={8}>
        <Row>
          <Col xs={6}>
            <Select
              id="ddlStatusFilter"
              inputName="statusValue"
              idError="fdbStatusError"
              labelName="Status"
              options={state.valueStatusOptions}
              styles={styles}
              // isInvalid={state.isInvalidStatus}
              // errorText={state.statusError}
              // onChange={(e) => handleSelectChange('statusValue', e)}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col
            className={`
            ${styles.border({ borderBottomWidth: 'thin' })}
            mx-3 mt-3 mb-4 page-border
          `}
          />
        </Row>
      </Col>
      <Col xs={4}>
        <h2
          className={`
            ${styles.tipography({ headings: 'xsmallBold' })}
            my-3
          `}
        >
          Dados
          <br />
          da empresa
        </h2>
      </Col>
      <Col xs={8}>
        <Row>
          <Col
            xs={6}
            className={`
              ${styles.inputs({ inputFile: 'imageFileUpload' })}
            `}
          >
            <Input
              styles={styles}
              isFileInput={true}
              isFileImageUpload={true}
              labelName="Logotipo da empresa"
              accept="image/png, image/gif, image/jpeg"
            />
          </Col>
          <Col xs={6} />
          <Col xs={6}>
            <Input
              id="txtCompanyName"
              idError="fdbCompanyName"
              labelName="Nome da empresa"
              errorText={state.companyNameError}
              styles={styles}
              isInvalid={state.isInvalidCompanyName}
              value={state.companyName}
              // onKeyDown={(e) => handleKeyDown(e.key)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col xs={6}>
            <Input
              id="txtCompanyCNPJ"
              idError="fdbCompanyCNPJ"
              labelName="CNPJ"
              errorText={state.companyCnpjError}
              styles={styles}
              isInvalid={state.isInvalidCompanyCNPJ}
              value={state.companyCNPJ}
              // onKeyDown={(e) => handleKeyDown(e.key)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col xs={6}>
            <Input
              id="txtCompanyEmail"
              idError="fdbCompanyEmail"
              labelName="E-mail comercial"
              errorText={state.companyEmailError}
              styles={styles}
              isInvalid={state.isInvalidCompanyEmail}
              value={state.companyEmail}
              // onKeyDown={(e) => handleKeyDown(e.key)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col xs={6}>
            <Input
              id="txtCompanyPhone"
              idError="fdbCompanyPhone"
              labelName="E-mail comercial"
              errorText={state.companyPhoneError}
              styles={styles}
              isInvalid={state.isInvalidCompanyPhone}
              value={state.companyPhone}
              // onKeyDown={(e) => handleKeyDown(e.key)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col
            className={`
            ${styles.border({ borderBottomWidth: 'thin' })}
            mx-3 mt-3 mb-4 page-border
          `}
          />
        </Row>
      </Col>
      <Col xs={4}>
        <h2
          className={`
            ${styles.tipography({ headings: 'xsmallBold' })}
            my-3
          `}
        >
          Dados
          <br />
          do usuário
        </h2>
      </Col>
      <Col xs={8}>
        <Row>
          <Col xs={6}>
            <Input
              id="txtUserName"
              idError="fdbUserName"
              labelName="Nome"
              errorText={state.userNameError}
              styles={styles}
              isInvalid={state.isInvalidUserName}
              value={state.userName}
              // onKeyDown={(e) => handleKeyDown(e.key)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col xs={6}>
            <Input
              id="txtUserSurname"
              idError="fdbUserSurname"
              labelName="Sobrenome"
              errorText={state.userSurnameError}
              styles={styles}
              isInvalid={state.isInvalidUserSurname}
              value={state.userSurname}
              // onKeyDown={(e) => handleKeyDown(e.key)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col xs={6}>
            <Input
              id="txtUserEmail"
              idError="fdbUserEmail"
              labelName="Email"
              errorText={state.userEmailError}
              styles={styles}
              isInvalid={state.isInvalidUserEmail}
              value={state.userEmail}
              // onKeyDown={(e) => handleKeyDown(e.key)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col xs={6}>
            <Input
              id="txtUserPhone"
              idError="fdbUserPhone"
              labelName="Celular"
              errorText={state.userPhoneError}
              styles={styles}
              isInvalid={state.isInvalidUserPhone}
              value={state.userPhone}
              // onKeyDown={(e) => handleKeyDown(e.key)}
              // onChange={(e) => handleChange(e)}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row>
          <Col
            className={`
            ${styles.border({ borderBottomWidth: 'thin' })}
            mx-3 mt-3 mb-4 page-border
          `}
          />
        </Row>
      </Col>
      <Col xs={12} className="d-flex justify-content-end">
        <ButtonComponent
          className={`${styles.buttonStandardLarge({
            large: 'primary',
          })}
          ${
            state.isLoading &&
            styles.buttonStandardLarge({
              large: 'primaryLoading',
            })
          } mb-4`}
          hasIcon={true}
          iconName="save"
          text="Salvar"
          styles={styles}
          // onClick={() => handleCreateCustomer()}
        />
      </Col>
    </Row>
  );
};

CompanyData.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
};

export default CompanyData;

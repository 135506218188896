import React from 'react';
import PropTypes from 'prop-types';
import { Col, Dropdown, Row } from 'react-bootstrap';
import Icon from './Icon';
import Input from './Input';
import ButtonComponent from './Buttons';

const HeadersComponent = ({
  styles,
  state,
  handleChange,
  clearSearchField,
  onClick,
}) => {
  const firstName = () => {
    let firstUserName = state.userName.split(' ');

    return firstUserName[0];
  };

  const sliceUserName = () => {
    let firstUserName = state.userName.split(' ');
    let firstNameWord = firstUserName[0].substring(0, 1);
    let lastNameWord = firstUserName[1]?.substring(0, 1);

    return [firstNameWord, lastNameWord].toString().replace(',', '');
  };

  return (
    <header>
      <Row>
        <Col
          xs={12}
          className={`
            ${styles.headerComponent()}
            w-100 d-flex justify-content-between
          `}
        >
          <div className="d-flex align-items-center">
            <div
              onClick={onClick}
              role="button"
              aria-label={`
                ${state.isMenuOpened ? 'fechar' : 'abrir'} menu lateral
              `}
              tabIndex={0}
            >
              <Icon
                iconName="menu_open"
                className={`${styles.colors({
                  colors: 'neutralColorLowPure',
                })} icon-header pointer`}
              />
            </div>
            {state.sessionTime && (
              <div className="user-greeting-area">
                <h1
                  className={`
                  ${styles.tipography({ headings: 'xsmallRegular' })}
                  ${styles.colors({ colors: 'neutralColorLowPure' })}
                  m-0
                `}
                >
                  Bem vind{state.userGender === 'M' ? 'o' : 'a'}, {firstName()}!
                </h1>
                <p
                  className={`
                  ${styles.tipography({ paragraphs: 'smallRegular' })}
                  ${styles.colors({ colors: 'neutralColorLowMedium' })}
                  m-0
                `}
                >
                  Sua sessão expira em:{' '}
                  <span
                    className={styles.tipography({ paragraphs: 'smallBold' })}
                  >
                    {state.sessionTime}
                  </span>
                </p>
              </div>
            )}
          </div>
          {state.hasSearchInput && (
            <Input
              id="txtSearchBox"
              inputName="txtSearchBox"
              inputType="text"
              isSearchBox={true}
              placeholder="Busque como quiser..."
              styles={styles}
              hasIcon={true}
              iconName="search"
              value={state.searchBoxValue}
              onChange={(e) => handleChange(e)}
              clearSearchField={(e) => clearSearchField(e)}
            />
          )}
          <div className="user-area-menu">
            <div
              className={`
                ${styles.buttonSquareMedium({
                medium: 'primary',
              })}
                user-area-button d-flex align-items-center justify-content-center
              `}
              aria-hidden="true"
            >
              {sliceUserName()}
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="transparent" id="dropdown-user-menu">
                <div>
                  <p
                    className={`
                      ${styles.tipography({ paragraphs: 'largeBold' })}
                      ${styles.colors({ colors: 'neutralColorLowPure' })}
                      m-0
                    `}
                  >
                    {firstName()}!
                  </p>
                  <p
                    className={`
                      ${styles.tipography({ paragraphs: 'xSmallRegular' })}
                      ${styles.colors({ colors: 'neutralColorLowMedium' })}
                      m-0
                    `}
                  >
                    @{state.userCredencial}
                  </p>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className='menu-drop-custom'>
                {state.isAdmin &&
                  state.userMenu.map((e, i) => {
                    return (
                      <Dropdown.Item href={e.url} key={i}>
                        {e.itemName}
                      </Dropdown.Item>
                    );
                  })}
                <Dropdown.Item href="/profile">Meu perfil</Dropdown.Item>
                <Dropdown.Item href="/logoff">Sair</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <ButtonComponent
              className={styles.buttonSquareMedium({ medium: 'secondary' })}
              hasIcon={true}
              iconName="notifications_none"
            />
          </div>
        </Col>
      </Row>
    </header>
  );
};

HeadersComponent.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleChange: PropTypes.func,
  clearSearchField: PropTypes.func,
  onClick: PropTypes.func,
};

export default HeadersComponent;

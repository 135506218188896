import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Form, FormGroup } from 'react-bootstrap'
import { Input, Check } from 'cashin-components'
import { styles } from 'cashin-design-system/Styles/Styles'
import { CampaignContext } from '../../../context/campaign-context'
import { Typeahead } from 'react-bootstrap-typeahead'

const CampaignHitTook = ({ onFieldError }) => {
  const campaignContext = useContext(CampaignContext)

  const [fieldsValid, setFieldsValid] = useState({
    reachValue: true,
    prizeValue: true,
  })
  let [reachValue, setReachValue] = useState(null)
  let [reachValueFloat, setReachValueFloat] = useState(null)
  let [prizeValue, setPrizeValue] = useState(null)
  let [prizeValueFloat, setPrizeValueFloat] = useState(null)
  const [rawHitValue, setRawHitValue] = useState(0.0)
  const [rawTookValue, setRawTookValue] = useState(0.0)
  const [fieldTouched, setFieldTouched] = useState({
    reachValue: false,
    prizeValue: false,
  })
  const [reachValueUnchanged, setReachValueUnchanged] = useState(false)
  const [prizeValueUnchanged, setPrizeValueUnchanged] = useState(false)
  const [reachValueError, setReachValueError] = useState(null)
  const [prizeValueError, setPrizeValueError] = useState(null)

  const [participantReachValues, setParticipantReachValues] = useState([])
  const [participantPrizeValues, setParticipantPrizeValues] = useState([])

  const [participantReachDescription, setParticipantReachDescription] =
    useState([])
  const [participantPrizeDescription, setParticipantPrizeDescription] =
    useState([])

  const handleReachValueChange = (segmentIndex, value, description) => {
    const updatedReachValues = [...participantReachValues]
    updatedReachValues[segmentIndex] = value
    setParticipantReachValues(updatedReachValues)

    const updatedReachDescription = [...participantReachDescription]
    updatedReachDescription[segmentIndex] = description
    setParticipantReachDescription(updatedReachDescription)

    const valid = parseFloat(value) > 0
    setFieldsValid((prevFieldsValid) => ({
      ...prevFieldsValid,
      reachValue: valid,
    }))
  }

  const handlePrizeValueChange = (segmentIndex, value, description) => {
    const updatedPrizeValues = [...participantPrizeValues]
    updatedPrizeValues[segmentIndex] = value
    setParticipantPrizeValues(updatedPrizeValues)

    const updatedPrizeDescription = [...participantPrizeDescription]
    updatedPrizeDescription[segmentIndex] = description
    setParticipantPrizeDescription(updatedPrizeDescription)

    const valid = parseFloat(value) > 0
    setFieldsValid((prevFieldsValid) => ({
      ...prevFieldsValid,
      prizeValue: valid,
    }))
  }

  const validateFields = () => {
    const reachValueValid = parseFloat(reachValue) > 0
    const prizeValueValid = parseFloat(prizeValue) > 0

    setFieldsValid((prevFieldsValid) => ({
      ...prevFieldsValid,
      reachValue: reachValueValid,
      prizeValue: prizeValueValid,
    }))
  }

  const formatToBRL = (value) => {
    const [integerPart, decimalPart] = String(value).split('.')

    let formattedInteger = integerPart.replace(/\D/g, '')
    formattedInteger = formattedInteger === '' ? '0' : formattedInteger

    let formattedDecimal = '00'
    if (decimalPart && decimalPart.length > 0) {
      formattedDecimal = decimalPart
        .replace(/\D/g, '')
        .padEnd(2, '0')
        .substring(0, 2)
    }

    return `${formattedInteger},${formattedDecimal}`
  }

  // const handleReachValueChange = (event) => {
  //   const { value } = event.target;
  //   const numericValue = removeCurrencyMask(value);

  //   if (isNaN(numericValue)) {
  //     return;
  //   }

  //   setReachValueFloat(() => parseFloat(numericValue) / 100);
  //   setReachValue(numericValue);
  //   setFieldsValid((currentValue) => ({
  //     ...currentValue,
  //     reachValue: Number(numericValue) > 0,
  //   }));
  //   setFieldTouched((currentValue) => ({
  //     ...currentValue,
  //     reachValue: true,
  //   }));
  // };

  // const handlePrizeValueChange = (event) => {
  //   const { value } = event.target;
  //   const numericValue = removeCurrencyMask(value);

  //   if (isNaN(numericValue)) {
  //     return;
  //   }

  //   setPrizeValueFloat(() => parseFloat(numericValue) / 100);
  //   setPrizeValue(numericValue);
  //   setFieldsValid((currentValue) => ({
  //     ...currentValue,
  //     prizeValue: parseFloat(numericValue) > 0,
  //   }));
  //   setFieldTouched((currentValue) => ({
  //     ...currentValue,
  //     prizeValue: true,
  //   }));
  // };

  // const handleValueChange = (value, setValue) => {
  //   if (isNaN(value)) {
  //     return;
  //   }
  //   if (value === "") {
  //     setValue(0);
  //     return;
  //   }
  //   setValue(parseFloat(value));
  // };

  // const applyCurrencyMask = (value) => {
  //   const maskedValue = new Intl.NumberFormat('pt-BR', {
  //     style: 'currency',
  //     currency: 'BRL',
  //   }).format(Number(value) / 100);
  //   return maskedValue;
  // };

  // const removeCurrencyMask = (value) => {
  //   return value.replace(/\D/g, '');
  // };

  const handleTypeaheadChange = (field, selected) => {
    if (selected.length > 0) {
      const selectedOption = selected[0]

      if (field === 'reachValue') {
        setReachValue(selectedOption.value)
        setFieldsValid((currentValue) => ({
          ...currentValue,
          reachValue: parseFloat(selectedOption.value) > 0,
        }))
        setFieldTouched((currentValue) => ({
          ...currentValue,
          reachValue: true,
        }))
        setReachValueUnchanged(false)
      } else if (field === 'prizeValue') {
        setPrizeValue(selectedOption.value)
        setFieldsValid((currentValue) => ({
          ...currentValue,
          prizeValue: parseFloat(selectedOption.value) > 0,
        }))
        setFieldTouched((currentValue) => ({
          ...currentValue,
          prizeValue: true,
        }))
        setPrizeValueUnchanged(false)
      }
    }
  }

  useEffect(() => {
    const rules = campaignContext.segmentTags.map((segment, index) => ({
      segmentation:
        campaignContext.isSegmentation === 'yes' ? segment.label : undefined,
      ruleType: 0,
      reachPurchaseValue: {
        value: parseFloat(participantReachValues[index]),
        type: participantReachDescription[index]?.includes('%') ? 1 : 0,
      },
    }))

    const prizes = campaignContext.segmentTags.map((segment, index) => ({
      segmentation:
        campaignContext.isSegmentation === 'yes' ? segment.label : undefined,
      ruleType: 0,
      discount: {
        value: parseFloat(participantPrizeValues[index]),
        type: participantPrizeDescription[index]?.includes('%') ? 1 : 0,
      },
    }))

    campaignContext?.setRuleAndPrize(rules, prizes)
  }, [participantReachValues, participantPrizeValues])

  useEffect(() => {
    const allFieldsValid = Object.values(fieldsValid).every((valid) => valid)

    onFieldError(allFieldsValid)
  }, [fieldsValid])

  useEffect(() => {
    validateFields()
  }, [])

  // useEffect(() => {
  //   campaignContext.setReachValues(participantReachValues);
  //   campaignContext.setPrizeValues(participantPrizeValues);
  // }, [participantReachValues, participantPrizeValues]);

  return (
    <>
      {campaignContext.segmentTags.map((segment, segmentIndex) => (
        <Row key={segmentIndex}>
          <Col xs={campaignContext.isSegmentation === 'yes' ? 4 : 6}>
            <Form.Label
              className={styles.tipography({ paragraphs: 'smallBold' })}
              style={{ paddingTop: 1 }}
            >
              Meta:
            </Form.Label>
            <FormGroup controlId='txtCampaignHit'>
              <Typeahead
                id={`txtCampaignHit${segmentIndex}`}
                inputName={`campaignHit${segmentIndex}`}
                idError={`fdbCampaignHitError${segmentIndex}`}
                errorText={'Campo obrigatório!'}
                isInvalid={reachValueError !== null}
                onBlur={() => {
                  if (parseFloat(participantReachValues[segmentIndex]) > 0) {
                    setReachValueError(null)
                  } else {
                    setReachValueError('Insira um valor.')
                  }
                  setReachValueUnchanged(
                    parseFloat(participantReachValues[segmentIndex]) === null
                  )
                }}
                labelKey={(option) => option.description}
                filterBy={['value']}
                options={[
                  {
                    id: 2,
                    type: 0,
                    value: rawHitValue,
                    description: `R$ ${formatToBRL(rawHitValue)}`,
                  },
                  {
                    id: 1,
                    type: 1,
                    value: rawHitValue,
                    description: `${formatToBRL(rawHitValue)} %`,
                  },
                ]}
                onInputChange={(value) => {
                  const formattedValue = parseFloat(value.replace(',', '.'))
                  if (!isNaN(formattedValue)) {
                    setRawHitValue(formattedValue)
                  }
                }}
                placeholder='0,00'
                style={{ height: 44 }}
                onChange={(selected) =>
                  handleReachValueChange(
                    segmentIndex,
                    selected[0]?.value,
                    selected[0]?.description
                  )
                }
              />
              <div style={{ color: '#6300ff' }}>{reachValueError}</div>
            </FormGroup>
          </Col>
          <Col xs={campaignContext.isSegmentation === 'yes' ? 4 : 6}>
            {/* <Input
                id="txtCampaignTook"
                inputName="campaignTook"
                className={styles.inputs({ input: "default" })}
                idError="fdbCampaignTookError"
                inputType="text"
                labelName="Prêmio:"
                placeholder="R$"
                errorText={"Campo obrigatório!"}
                isInvalid={!fieldsValid.prizeValue}
                styles={styles}
                value={applyCurrencyMask(prizeValue)}
                onChange={handlePrizeValueChange}
              /> */}
            <Form.Label
              className={styles.tipography({ paragraphs: 'smallBold' })}
              style={{ paddingTop: 1 }}
            >
              Prêmio:
            </Form.Label>
            <FormGroup controlId='txtCampaignTook'>
              <Typeahead
                id={`txtCampaignTook${segmentIndex}`}
                inputName={`campaignTook${segmentIndex}`}
                idError={`fdbCampaignTookError${segmentIndex}`}
                errorText={'Campo obrigatório!'}
                isInvalid={prizeValueError !== null}
                onBlur={() => {
                  if (parseFloat(participantPrizeValues[segmentIndex]) > 0) {
                    setPrizeValueError(null)
                  } else {
                    setPrizeValueError('Insira um valor.')
                  }
                  setPrizeValueUnchanged(prizeValue === null)
                }}
                labelKey={(option) => option.description}
                filterBy={['value']}
                isTouched={true}
                options={[
                  {
                    id: 2,
                    type: 0,
                    value: rawTookValue,
                    description: `R$ ${formatToBRL(rawTookValue)}`,
                  },
                  {
                    id: 1,
                    type: 1,
                    value: rawTookValue,
                    description: `${formatToBRL(rawTookValue)} %`,
                  },
                ]}
                onInputChange={(value) => {
                  const formattedValue = parseFloat(value.replace(',', '.'))
                  if (!isNaN(formattedValue)) {
                    setRawTookValue(formattedValue)
                  }
                }}
                placeholder='0,00'
                style={{ height: 44 }}
                onChange={(selected) =>
                  handlePrizeValueChange(
                    segmentIndex,
                    selected[0]?.value,
                    selected[0]?.description
                  )
                }
              />
              <div style={{ color: '#6300ff' }}>{prizeValueError}</div>
            </FormGroup>
          </Col>
          {campaignContext.isSegmentation === 'yes' && (
            <Col xs={4}>
              <Input
                id='txtCampaignSegment'
                inputName='campaignSegment'
                className={styles.inputs({ input: 'default' })}
                idError='fdbCampaignSegmentError'
                inputType='text'
                labelName='Segmento:'
                placeholder='Ex.: Gerente, Coordenador, Vendedor'
                styles={styles}
                value={
                  campaignContext.isSegmentation === 'yes' && segment.label
                }
                disabled
              />
            </Col>
          )}
        </Row>
      ))}
      <Row className='mt-4'>
        <Col xs={3}>
          <Check
            id='addRanking'
            label='Adicionar ranking'
            type='switch'
            onChange={(e) => {
              campaignContext.setIsRanking(e.target.value === 'on')
            }}
            value={campaignContext.isRanking}
            className={`
              ${styles.check({ switch: 'normal' })}
              ps-0
              mb-0
            `}
            hasTooltip={true}
            tooltipText='Com o Ranking você envia para os participantes a posição individual de cada um, ex: 3º Lugar. Para enviar o Ranking basta fazer uma apuração, seja "Parcial" ou no Encerramento da Campanha.'
          />
        </Col>
      </Row>
    </>
  )
}

CampaignHitTook.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
}

export default CampaignHitTook

import React, { Fragment, memo, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { BadgeComponent, ButtonComponent, Input } from 'cashin-components'
import ModalUploadVerificationResponse from '../../campaign-verification/upload-verification/modal-upload-verification-response'
import ModalCloseCampaign from '../../campaign-verification/upload-verification/modal-close-campaign'
import { Col, Row, Spinner } from 'react-bootstrap'
import { styles } from 'cashin-design-system/Styles/Styles'
import whatsappLayoutImage from 'cashin-design-system/Styles/img/ExampleLayout/exampleLayout5.png'
import appLayoutImage from 'cashin-design-system/Styles/img/ExampleLayout/appLayout.png'
import addPartialFileRequest from '../../API/queries/campaign-validate/addPartialFileRequest'
import { useNavigate } from 'react-router'
import './style.css'

const badgesTextByType = {
  0: 'Rascunho',
  1: 'Ativo',
  2: 'Concluída',
  3: 'Removida',
  4: 'Deletada',
  5: 'Agendada',
}

const badgesStylesByType = {
  0: 'warning',
  1: 'success',
  2: 'light',
  3: 'light',
  4: 'light',
  5: 'info',
}

const UploadVerificationTableComponent = ({
  data,
  isLoading,
  research,
  isValidationPage,
  onCampaignDelete,
}) => {
  const navigate = useNavigate()

  const [fileReference, setFileReference] = useState([]) // id, file
  const [
    isLoadingUploadPartialFileFromCampaignId,
    setIsLoadingPartialFileFromCampaignId,
  ] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showFinishCampaignModal, setShowFinishCampaignModal] = useState('')

  const header = isValidationPage
    ? [
        {
          headerTitle: 'Campanha ativa',
        },
        {
          headerTitle: 'Status',
        },
        {
          headerTitle: 'Período',
        },
        {
          headerTitle: 'Apuração parcial',
        },
        {
          headerTitle: 'Ação',
        },
      ]
    : [
        {
          headerTitle: 'Campanha',
        },
        {
          headerTitle: 'Status',
        },
        {
          headerTitle: 'Período',
        },
        {
          headerTitle: 'Ações',
        },
      ]

  const handleUploadPartialChange = (value, index) => {
    setFileReference((current) => [
      ...current.filter((x) => x.id !== index),
      {
        id: index,
        file: value[0],
      },
    ])
  }

  const handleDeletePartial = (id) => {
    setFileReference((current) => current.filter((x) => x.id !== id))
  }

  const showHeader = () => {
    try {
      return header.map((e, i) => {
        return <th key={i}>{e.headerTitle}</th>
      })
    } catch (e) {
      return <th colSpan={header.length}>{e.message}</th>
    }
  }

  const handleUploadPartialFile = async (campaignId) => {
    setIsLoadingPartialFileFromCampaignId(campaignId)

    await addPartialFileRequest(
      campaignId,
      fileReference?.find((x) => x.id === campaignId)?.file
    )

    setIsLoadingPartialFileFromCampaignId('')
    setShowSuccessModal(true)
    handleDeletePartial(campaignId)
  }

  const handleEdit = (id) => {
    navigate(`/edit-campaign/${id}`)
  }

  const handleSelectedLayoutImage = (campaign) => {
    if (campaign.isApp) {
      return appLayoutImage
    } else if (campaign.whatsAppType === '') {
      return campaign?.notificationLayout?.notificationDispatches?.[0]?.imageUrl
    } else {
      return whatsappLayoutImage
    }
  }

  return (
    <Fragment>
      <table
        id='table'
        className={`
          ${styles.table()}
        `}
      >
        <thead>
          <tr>{showHeader()}</tr>
        </thead>
        {!isLoading && (
          <tbody>
            {data?.map((campaign, i) => {
              const campaignFinished = 2
              if (isValidationPage && campaign.status === campaignFinished)
                return null
              return (
                <tr key={i}>
                  <td>
                    <img
                      src={handleSelectedLayoutImage(campaign)}
                      alt='Imagem da campanha'
                    />
                    {campaign.name}
                  </td>
                  <td>
                    <BadgeComponent
                      text={
                        campaign.status === 0
                          ? badgesTextByType[0]
                          : new Date(campaign.periodDate.from) <= new Date() &&
                            new Date() < new Date(campaign.periodDate.to)
                          ? badgesTextByType[1]
                          : campaign.status === 1 &&
                            new Date(campaign.periodDate.to) < new Date()
                          ? 'Apuração pendente'
                          : badgesTextByType[campaign.status]
                      }
                      bgType={
                        campaign.status === 0
                          ? badgesStylesByType[0]
                          : new Date(campaign.periodDate.from) <= new Date() &&
                            new Date() < new Date(campaign.periodDate.to)
                          ? badgesStylesByType[1]
                          : campaign.status === 1 &&
                            new Date(campaign.periodDate.to) < new Date()
                          ? 'danger'
                          : badgesStylesByType[campaign.status]
                      }
                      isBadgePill={true}
                      styles={styles}
                    />
                  </td>
                  <td>
                    {`${moment(campaign.periodDate.from).format(
                      'DD/MM/YYYY'
                    )} - ${moment(campaign.periodDate.to).format(
                      'DD/MM/YYYY'
                    )}`}
                  </td>
                  {isValidationPage && (
                    <>
                      <td
                        className={`
                  ${styles.inputs({ inputFile: 'inputFileButton' })}
                  w-auto
                `}
                      >
                        {campaign.id && (
                          <Input
                            styles={styles}
                            isFileInput={true}
                            isFileButton={true}
                            isEmptyFile={
                              !fileReference?.find((x) => x.id === campaign.id)
                                ?.file
                            }
                            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            onChange={(e) =>
                              handleUploadPartialChange(e, campaign.id)
                            }
                            deleteFile={() => handleDeletePartial(campaign.id)}
                            files={[
                              fileReference?.find((x) => x.id === campaign.id)
                                ?.file,
                            ]}
                          />
                        )}
                      </td>
                      <td className='w-auto text-nowrap'>
                        <ButtonComponent
                          className={`
                    ${styles.buttonStandardSmall({ small: 'secondary' })}
                    me-3 text-nowrap BtnFocus
                  `}
                          id='BtnFocus'
                          text='Enviar'
                          styles={styles}
                          disabled={
                            !fileReference?.find((x) => x.id === campaign.id)
                              ?.file
                          }
                          isLoading={
                            isLoadingUploadPartialFileFromCampaignId ===
                            campaign.id
                          }
                          onClick={() => handleUploadPartialFile(campaign.id)} // Setar disabled/enabled
                        />
                        <ButtonComponent
                          className={`
                    ${styles.buttonStandardSmall({ small: 'secondary' })}
                    text-nowrap
                  `}
                          text='Encerrar campanha'
                          onClick={() =>
                            setShowFinishCampaignModal(campaign.id)
                          }
                          styles={styles}
                        />
                      </td>
                    </>
                  )}
                  {!isValidationPage && (
                    <td>
                      <div className='d-flex justify-content-center'>
                        <ButtonComponent
                          className={styles.buttonSquareLarge({
                            large: 'secondary',
                          })}
                          hasIcon={true}
                          iconName='delete_outline'
                          onClick={() => onCampaignDelete(campaign.id)}
                        />
                        <ButtonComponent
                          className={styles.buttonSquareLarge({
                            large: 'secondary',
                          })}
                          hasIcon={true}
                          iconName='drive_file_rename_outline'
                          onClick={() => handleEdit(campaign.id)}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
      {isLoading && (
        <Row>
          <Col className='offset-5'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}

      {!isLoading && data?.length <= 0 && (
        <Row>
          <Col className='offset-4'>
            <h3>Sem campanhas disponíveis</h3>
          </Col>
        </Row>
      )}

      <ModalUploadVerificationResponse
        styles={styles}
        size='xs'
        show={showSuccessModal}
        onHide={(x) => setShowSuccessModal(false)}
      />
      <ModalCloseCampaign // Deseja encerrar essa campanha?
        styles={styles}
        size='xs'
        show={showFinishCampaignModal}
        campaignId={showFinishCampaignModal}
        onHide={() => setShowFinishCampaignModal(false)}
        onFinish={() => {
          setShowSuccessModal(true)
          research()
        }}
      />
    </Fragment>
  )
}

UploadVerificationTableComponent.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  header: PropTypes.array,
  data: PropTypes.array,
  show: PropTypes.any,
  handleClose: PropTypes.func,
  handleShow: PropTypes.func,
  handleUploadPartialChange: PropTypes.func,
  handleUploadTotalChange: PropTypes.func,
  handleDeletePartial: PropTypes.func,
  handleDeleteTotal: PropTypes.func,
  handleUploadReport: PropTypes.func,
}

export default memo(UploadVerificationTableComponent)

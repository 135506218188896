import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import {
  Input,
  Select,
  ButtonComponent,
  BreadcrumbComponent,
} from 'cashin-components'

const EditInternalUserPage = ({ styles, state, handleSaveInternalUser }) => {
  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12} className='pt-4'>
            <BreadcrumbComponent
              hasIcon={true}
              styles={styles}
              initialIcon='home-color'
              initialNode='Dashboard'
              breadcrumbData={state.breadcrumb}
            />
          </Col>
          <Col xs={6}>
            <h1
              className={`
                ${styles.tipography({ headings: 'smallBold' })}
                my-3
              `}
            >
              Editar usuário
            </h1>
          </Col>
          <Col xs={6} className='d-flex justify-content-end' />
          <Col xs={12}>
            <div
              className={`
                ${styles.border({ borderBottomWidth: 'thick' })}
                mb-4 page-border
              `}
            />
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={4}>
                <h2
                  className={`
                  ${styles.tipography({ headings: 'xsmallBold' })}
                  my-3
                `}
                >
                  Dados
                  <br />
                  pessoais
                </h2>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col
                    xs={6}
                    className={`
                    ${styles.inputs({ inputFile: 'imageFileUpload' })}
                  `}
                  >
                    <Input
                      styles={styles}
                      isFileInput={true}
                      isFileImageUpload={true}
                      labelName='Foto do perfil'
                      accept='image/png, image/gif, image/jpeg'
                    />
                  </Col>
                  <Col xs={6} />
                  <Col xs={6}>
                    <Input
                      id='txtUserName'
                      idError='fdbUserName'
                      labelName='Nome'
                      errorText={state.userNameError}
                      styles={styles}
                      isInvalid={state.isInvalidUserName}
                      // value={state.userName}
                      value={'Jaqueline'}
                      placeholder='Informe o nome do usuário'
                      // onKeyDown={(e) => handleKeyDown(e.key)}
                      // onChange={(e) => handleChange(e)}
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      id='txtUserSurname'
                      idError='fdbUserSurname'
                      labelName='Sobrenome'
                      errorText={state.userSurnameError}
                      styles={styles}
                      isInvalid={state.isInvalidUserSurname}
                      // value={state.userSurname}
                      value={'Emboaba'}
                      placeholder='Informe o sobrenome do usuário'
                      // onKeyDown={(e) => handleKeyDown(e.key)}
                      // onChange={(e) => handleChange(e)}
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      id='txtAccessEmail'
                      idError='fdbAccessEmail'
                      labelName='E-mail de acesso'
                      errorText={state.accessEmailError}
                      styles={styles}
                      isInvalid={state.isInvalidAccessEmail}
                      // value={state.accessEmail}
                      value={'jaqueline.emboaba@cashin.com.br'}
                      placeholder='E-mail para contato comercial'
                      disabled
                      // onKeyDown={(e) => handleKeyDown(e.key)}
                      // onChange={(e) => handleChange(e)}
                    />
                  </Col>
                  <Col xs={6}>
                    <Select
                      id='ddlStatusFilter'
                      labelName='Status'
                      options={state.valueStatusOptions}
                      styles={styles}
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col
                    className={`
                  ${styles.border({ borderBottomWidth: 'thin' })}
                  mx-3 mt-3 mb-4 page-border
                `}
                  />
                </Row>
              </Col>
              <Col xs={4}>
                <h2
                  className={`
                  ${styles.tipography({ headings: 'xsmallBold' })}
                  my-3
                `}
                >
                  Alteração
                  <br />
                  de senha
                </h2>
              </Col>
              <Col xs={8}>
                <Row>
                  <Col xs={6}>
                    <Input
                      id='txtNewPassword'
                      inputName='newPasswordValue'
                      className={styles.inputs({ input: 'default' })}
                      idError='fdbNewPasswordError'
                      inputType='password'
                      labelName='Digite a nova senha'
                      placeholder='Informe sua nova senha'
                      errorText={state.newPasswordError}
                      isInvalid={state.isInvalidNewPassword}
                      styles={styles}
                      value={state.newPasswordValue}
                      // onKeyDown={(e) => handleKeyDown(e.key)}
                      // onChange={(e) => handleChange(e)}
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      id='txtConfirmNewPassword'
                      inputName='confirmNewPasswordValue'
                      className={styles.inputs({ input: 'default' })}
                      idError='fdbConfirmNewPasswordError'
                      inputType='password'
                      labelName='Confirme a senha'
                      placeholder='Confirme sua nova senha'
                      errorText={state.ConfirmNewPasswordError}
                      isInvalid={state.isInvalidConfirmNewPassword}
                      styles={styles}
                      value={state.ConfirmNewPasswordValue}
                      // onKeyDown={(e) => handleKeyDown(e.key)}
                      // onChange={(e) => handleChange(e)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col
                    className={`
                  ${styles.border({ borderBottomWidth: 'thin' })}
                  mx-3 mt-3 mb-4 page-border
                `}
                  />
                </Row>
              </Col>
              <Col xs={12} className='d-flex justify-content-end'>
                <ButtonComponent
                  className={`${styles.buttonStandardLarge({
                    large: 'primary',
                  })}
                ${
                  state.isLoading &&
                  styles.buttonStandardLarge({
                    large: 'primaryLoading',
                  })
                } mb-4`}
                  hasIcon={true}
                  iconName='save'
                  text='Salvar'
                  styles={styles}
                  onClick={() => handleSaveInternalUser()}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

EditInternalUserPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleEdit: PropTypes.func,
  handleSaveInternalUser: PropTypes.func,
}

export default EditInternalUserPage

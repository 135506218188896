import { css } from './CreateStitches';

export const charts = css({
  variants: {
    gantt: {
      default: {
        '> div': {
          width: '84% !important',
          position: 'absolute',

          svg: {
            strokeWidth: '0 !important',

            '.calendar': {
              fontFamily: '$fontFamilyHighBaseBold',
              // fontSize: '$fontSizeSM',
            },

            '.bar': {
              fontFamily: '$fontFamilyHighBaseBold',
              fontSize: '$fontSizeMD',
            },

            '.grid': {
              '.gridBody': {
                '.rows': {
                  rect: {
                    '&:nth-child(2n)': {
                      fill: '$neutralColorHighPure',
                    },
                  },
                },
              },
            },

            '._35nLX': {
              strokeWidth: '0 !important',
            },

            // '._2dZTy': {
            //   '&:nth-child(2n)': {
            //     fill: '$neutralColorHighPure',
            //   },
            // },

            // '.today': {
            //   fill: '$neutralColorHighPure',
            // },

            // '._9w8d5': {
            //   fill: '$neutralColorHighLight',
            // },
          },
        },
      },
    },
  },
});

import api from "../../api";

const addFinalFileRequest = async (campaignId, file) => {
  const formDataRequest = new FormData();

  formDataRequest.append("file", file);

  return await api.post(
    `/campaign-validate/campaign/${campaignId}/final-validation`,
    formDataRequest
  );
};

export default addFinalFileRequest;

import { useQuery } from 'react-query'
import api from '../../api'

const userData = JSON.parse(window.localStorage.getItem('profile'))
const useIdentificationInfo = (cache = false) => {
  return useQuery(
    'companyIdentificationInfo',
    ({ signal }) => {
      if (userData['custom:profile'] !== 'Admin') return []

      return api.get('/company/identification-info', {
        signal,
      })
    },
    {
      cacheTime: 60000 * 5, // Cache por 5 min
    }
  )
}

export default useIdentificationInfo
// {"custom:tenantId":"10101010101010","sub":"131f66cb-d3c0-4181-bde5-6d6d34d9c09f","custom:profile":"Company","email_verified":true,"name":"Testando Teste","email":"testando_cashin@outlook.com"}

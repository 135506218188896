import { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import DatePicker from 'react-datepicker'
import { CampaignContext } from '../context/campaign-context'

const rowStyles = {
  overflowY: 'auto',
  height: '100vh',
  marginRight: '-8px',
  paddingRight: '8px',
}

const ExampleCustomTimeInput = ({ date, value, onChange }) => (
  <input
    value={value}
    onChange={(e) => onChange(e.target.value)}
    onClick={(e) => e.target?.focus()}
    className='input-time'
  />
)

const diasSemana = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
]

const formatDate = (date) => {
  const diaSemana = diasSemana[date.getDay()]
  const dia = date.getDate().toString().padStart(2, '0')
  const mes = (date.getMonth() + 1).toString().padStart(2, '0')
  const ano = date.getFullYear()

  const horas = date.getHours().toString().padStart(2, '0')

  return `${diaSemana}, ${dia}/${mes}/${ano} ${horas}:00`
}

const LayoutTypeEditorSelector = ({
  styles,
  state,
  layoutSelection,
  handleLayoutSelector,
  onEditDate,
}) => {
  const campaignContext = useContext(CampaignContext)
  const [showDatePicker, setShowDatePicker] = useState(null)

  const toggleDatePicker = (layoutId) => {
    setShowDatePicker((prevId) => (prevId === layoutId ? null : layoutId))
  }

  const handleDateChange = (id, date, event) => {
    onEditDate(id, date)

    if (event.type === 'click') {
      toggleDatePicker(id)
    }
  }

  return (
    <div style={rowStyles}>
      {layoutSelection.map((e, i) => {
        function handleSelect() {
          handleLayoutSelector({ i: e.id })
        }

        function handleEditDateClick(event) {
          if (campaignContext.communicationType === 'whatsapp') {
            event.stopPropagation()
            toggleDatePicker(e.id)
          }
        }

        return (
          <div key={i}>
            <div
              className={`w-100 layout-selector mr-3 ${
                state.isLayoutSelectorActive[Number(e.id)] ? 'active' : ''
              }`}
              role='button'
              onClick={handleSelect}
              tabIndex={0}
            >
              <div className='layout-selector-area'>
                <div className='edit-layout-option'>
                  <p
                    className={`${styles.tipography({
                      paragraphs: 'mediumBold',
                    })} m-0`}
                  >
                    {e.layoutTitle}
                    {/* - {e.id} */}
                  </p>
                  <p className={`m-0`} style={{ color: '#666', fontSize: 14 }}>
                    {formatDate(new Date(e.dispatchDate))}
                  </p>
                </div>
                <div
                  className='edit-layout-icon text-center'
                  onClick={handleEditDateClick}
                >
                  <Icon iconName='edit' />
                </div>

                {showDatePicker === e.id && (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: 99,
                      marginTop: '50px',
                      left: 0,
                    }}
                    className='datepicker-container'
                  >
                    <DatePicker
                      style={{ width: '327px' }}
                      class='test'
                      inline
                      selected={new Date(e.dispatchDate)}
                      onChange={(date, $event) =>
                        handleDateChange(e.id, date, $event)
                      }
                      showTimeSelect
                      timeIntervals={60}
                      filterTime={(time) => {
                        const hour = time.getHours()
                        return hour >= 9 && hour < 19
                      }}
                      minTime={new Date().setHours(9, 0)}
                      maxTime={new Date().setHours(18, 0)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

LayoutTypeEditorSelector.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutSelection: PropTypes.array,
  handleLayoutSelector: PropTypes.func,
}

export default LayoutTypeEditorSelector

import React, { memo, useEffect, useState } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import { Input, ButtonComponent, Select } from 'cashin-components'
import UploadVerificationTableComponent from './upload-verification-table-component'
import { Typeahead } from 'react-bootstrap-typeahead'
import useCampaignIdentificationInfo from '../../API/queries/campaign/useCampaignIdentificationInfo'
import { styles } from 'cashin-design-system/Styles/Styles'
import CustomPagination from '../../Components/CustomPagination'
import searchCampaign from '../../API/queries/campaign/useCampaignSearch'

const downloadTemplate = (fileName) => {
  window.location.href = `${process.env.REACT_APP_BLOBSTORAGE_URL}/${fileName}.xlsx`
}

const CampaignPaginationBase = ({ isValidationPage, onCampaignDelete }) => {
  const [campaignIdFilter, setCampaignIdFilter] = useState(null)
  const [currentPagination, setCurrentPagination] = useState(0)
  const [dateRange, setDateRange] = useState([null, null])
  const [data, setData] = useState([])
  const [isSearchLoading, setSearchIsLoading] = useState(false)
  const [status, setStatus] = useState(null)

  const search = async () => {
    setSearchIsLoading(true)

    var response = await searchCampaign(
      dateRange[0] || null,
      dateRange[1] || null,
      campaignIdFilter || null,
      status,
      currentPagination,
      isValidationPage
    )

    setData(response)

    setSearchIsLoading(false)
  }

  // Não deu bom, entender mehor depois
  // const {
  //   data,
  //   fetchNextPage,
  //   hasNextPage,
  //   isFetchingNextPage,
  //   refetch,
  //   isFetching,
  // } = useCompanySearch({
  //   "Filter.AvailabilityInitialDate.From": dateRange[0] || null,
  //   "Filter.AvailabilityInitialDate.To": dateRange[1] || null,
  //   "Filter.CampaignId": campaignIdFilter || null,
  // });

  useEffect(() => {
    search()
  }, [])

  const {
    isLoading,
    error,
    data: campaignIdentificationInfoData,
  } = useCampaignIdentificationInfo()

  const handleCampaignFilterSelected = (item) =>
    setCampaignIdFilter(item?.[0]?.id)

  const handleOnPaginationChange = (newPage) => {
    // fetchNextPage({ pageParam: newPage - 1 });
    setCurrentPagination(newPage - 1)
  }

  useEffect(() => {
    search()
  }, [currentPagination])

  return (
    <Row>
      <Col>
        <Form.Label
          className={styles.tipography({ paragraphs: 'smallBold' })}
          style={{ paddingTop: 1 }}
          onClick={() => console.log(data)}
        >
          Campanha
        </Form.Label>
        <Typeahead
          labelKey={(option) => option.description}
          filterBy={['id']}
          options={campaignIdentificationInfoData}
          placeholder={isLoading && 'Carregando...'}
          label='Campanha'
          disabled={isLoading}
          style={{ height: 44 }}
          onChange={handleCampaignFilterSelected}
        />
      </Col>
      {!isValidationPage && (
        <Col xs={3}>
          <Select
            id='ddlStatusFilter'
            inputName='statusValue'
            idError='fdbStatusError'
            labelName='Status'
            options={[
              {
                value: null,
                label: 'Todos',
              },
              {
                value: 0,
                label: 'Status Rascunho',
              },
              {
                value: 1,
                label: 'Status Ativo',
              },
              {
                value: 2,
                label: 'Status Concluído',
              },
              {
                value: 5,
                label: 'Status Agendado',
              },
            ]}
            styles={styles}
            onChange={(e) => setStatus(e.value)}
          />
        </Col>
      )}
      <Col
        xs
        className={`
          ${styles.inputs({ datePicker: 'datePickerDefault' })}
          d-flex align-items-end
        `}
      >
        <Input
          id='txtDateRangeCampaign'
          idError='fdbDateRangeCampaign'
          labelName='Período'
          styles={styles}
          isDatePicker={true}
          required={true}
          isInvalid={false}
          selectsRange={true}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          monthsShown={2}
          setDateRange={setDateRange}
        />
      </Col>
      <Col xs>
        <ButtonComponent
          className={`${styles.buttonStandardLarge({
            large: 'primary',
          })}
          ${false &&
            styles.buttonStandardLarge({
              large: 'primaryLoading',
            })
            } w-100 mt-4 mb-3`}
          text='Buscar'
          isLoading={false}
          styles={styles}
          onClick={search}
        />
      </Col>
      {isValidationPage && (
        <>
          <Col xs={12}>
            <p
              className={`
            ${styles.tipography({ paragraphs: 'small' })}
            ${styles.colors({ colors: 'neutralColorLowMedium' })}
          `}
            >
              Para fazer upload da sua apuração, você pode fazer o download de
              um dos modelos em Excel abaixo:
            </p>
          </Col>
          <Col xs={12} className='d-flex'>
            <ButtonComponent
              className={`
            ${styles.buttonStandardSmall({ small: 'secondary' })}
            me-3
          `}
              hasIcon={true}
              iconName='download'
              text='Regra Meta financeira'
              onClick={() => downloadTemplate('Padrao-Meta_Financeira')}
            />
            <ButtonComponent
              className={`
            ${styles.buttonStandardSmall({ small: 'secondary' })}
            me-3
          `}
              hasIcon={true}
              iconName='download'
              text='Regra Produto ou Serviço'
              onClick={() => downloadTemplate('Padrao-Produto_ou_serviço')}
            />
            <ButtonComponent
              className={`
            ${styles.buttonStandardSmall({ small: 'secondary' })}
            me-3
          `}
              hasIcon={true}
              iconName='download'
              text='Regra Hibrido'
              onClick={() => downloadTemplate('Padrao-Híbrido')}
            />
          </Col>
        </>
      )}
      <Col xs={12} className='mt-4'>
        <UploadVerificationTableComponent
          isLoading={isSearchLoading}
          research={search}
          data={
            data?.items || []
            // data?.pages?.find((x) => x.pageParam === currentPagination) || []
          }
          isValidationPage={isValidationPage}
          onCampaignDelete={onCampaignDelete}
        />
        <CustomPagination
          totalPages={
            // (data?.pages?.[0]?.totalItems || 0) / 10 ||
            (data?.totalItems || 0) / 10
          }
          onPaginationChange={handleOnPaginationChange}
          currentPage={currentPagination + 1}
          setCurrentPage={setCurrentPagination}
        />
      </Col>
    </Row>
  )
}

export default memo(CampaignPaginationBase)

import React, { useEffect, useState } from 'react'
import { Input, Select } from 'cashin-components'
import { Col, Row } from 'react-bootstrap'
import { styles } from 'cashin-design-system/Styles/Styles'
import { formatDate } from '../../../functions/date'

const EmailSelector = ({ campaign, setIndex }) => {
  const [communications, setCommunications] = useState(
    campaign?.schedule?.campaign.map((item) => {
      return {
        value: item.name,
        label: item.campaignName,
      }
    }) || []
  )
  const [communication, setCommunication] = useState(communications[0])

  useEffect(() => {
    const newCommunications =
      campaign?.schedule?.campaign.map((item) => {
        return {
          value: item.name,
          label: item.campaignName,
        }
      }) || []
    setCommunications(newCommunications)
    setCommunication(newCommunications[0])
  }, [campaign])

  const fullCommunication = campaign?.schedule?.campaign?.find((item) => {
    return item.name === communication?.value
  })

  const schedule = campaign?.schedule

  const handleSelectChange = (e) => {
    setCommunication(e)
  }

  return (
    <>
      <Row className='w-full'>
        <Col xs={12}>
          <Select
            id='ddlChangeStatusFilter'
            inputName='changeStatusValue'
            idError='fdbChangeStatusError'
            labelName='Comunicação'
            options={communications}
            styles={styles}
            value={communication}
            selectedValue={communication || communications[0]}
            onChange={(e) => {
              const getIndex = campaign?.schedule?.campaign.findIndex(
                (item) => item.name === e.value
              )
              setIndex(getIndex)
              handleSelectChange(e)
            }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col xs={12}>
          <Input
            id='txtDateRangeCampaign'
            idError='fdbDateRangeCampaign'
            labelName='Data de envio'
            styles={styles}
            disabled={true}
            value={
              fullCommunication?.sendDate
                ? formatDate(fullCommunication?.sendDate)
                : ''
            }
            selectedValue={
              fullCommunication?.sendDate
                ? formatDate(fullCommunication?.sendDate)
                : ''
            }
          />
        </Col>
      </Row>

      {schedule?.layoutInfo?.logoHeader && (
        <Row className='mt-3'>
          <Col xs={12}>
            <h3
              className={`
                ${styles.tipography({ paragraphs: 'largeBold' })}
                mb-0
              `}
            >
              Logotipo do cabeçalho
            </h3>

            <img
              alt='Logotipo do cabeçalho'
              src={schedule?.layoutInfo?.logoHeader}
              className='rounded mt-2'
              width='140'
            />
          </Col>
        </Row>
      )}

      {schedule?.layoutInfo?.mainBanner1 && (
        <Row className='mt-3'>
          <Col xs={12}>
            <h3
              className={`
                ${styles.tipography({ paragraphs: 'largeBold' })}
                mb-0
              `}
            >
              Banner principal
            </h3>

            <img
              alt='Banner principal'
              src={schedule?.layoutInfo?.mainBanner1}
              width='140'
              className='rounded mt-2'
            />
          </Col>
        </Row>
      )}

      <Row className='mt-3'>
        <Col xs={12}>
          <Input
            id='txtDateRangeCampaign'
            idError='fdbDateRangeCampaign'
            labelName='Título do e-mail'
            styles={styles}
            disabled={true}
            value={fullCommunication?.campaignName}
            selectedValue={fullCommunication?.campaignName}
          />
        </Col>
      </Row>

      {/* <Row className='mt-3'>
        <Col xs={12}>
          <Input
            id='txtDateRangeCampaign'
            idError='fdbDateRangeCampaign'
            labelName='Descrição'
            styles={styles}
            disabled={true}
          />
        </Col>
      </Row> */}
    </>
  )
}

export default EmailSelector

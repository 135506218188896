import { Card } from 'react-bootstrap'
import { Check } from 'cashin-components'
import ParticipantsManualInput from './participants-manual-input'
import ParticipantsBatchInput from './participants-batch-input'

export const ParticipantsCard = ({
  styles,
  state,
  isSegmentation,
  tagsSegment,
  currentAdditionMode,
  handleUploadChangeBatch,
  handleDeleteFile,
  changeCurrentAdditionMode,
}) => {
  return (
    <Card className='tw-p-4 tw-my-4'>
      <p
        className={`
          ${styles.tipography({ paragraphs: 'mediumRegular' })}
            mb-2 pb-1 tw-font-bold
          `}
      >
        Você gostaria de enviar em lote ou manualmente?
      </p>
      <div className='d-flex'>
        <Check
          id='batch-addition-mode'
          label='Lote'
          type='radio'
          value='batch'
          checked={currentAdditionMode === 'batch-input'}
          onChange={(e) => {
            changeCurrentAdditionMode('batch-input')
          }}
          className={`${styles.check({ radio: 'normal' })} me-3`}
        />
        <Check
          id='manual-addition-mode'
          label='Manual'
          type='radio'
          value='manual'
          checked={currentAdditionMode === 'manual-input'}
          onChange={(e) => {
            changeCurrentAdditionMode('manual-input')
          }}
          className={`${styles.check({ radio: 'normal' })} me-3`}
        />
      </div>
      <hr />
      {/* <p className={`
        ${styles.tipography({ paragraphs: "small" })}
        ${styles.colors({ colors: "neutralColorLowMedium" })}
        mb-0
      `}>
        Informe abaixo todos usuários a serem premiados.
      </p> */}
      {currentAdditionMode === 'manual-input' && (
        <ParticipantsManualInput
          styles={styles}
          state={state}
          isSegmentation={isSegmentation}
          tagsSegment={tagsSegment}
        />
      )}
      {currentAdditionMode === 'batch-input' && (
        <ParticipantsBatchInput
          styles={styles}
          state={state}
          tagsSegment={isSegmentation === 'yes' ? tagsSegment : []}
          handleUploadChangeBatch={handleUploadChangeBatch}
          handleDeleteFile={handleDeleteFile}
        />
      )}
    </Card>
  )
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Modal, Row } from "react-bootstrap";
import { ButtonComponent, Input, Select } from "cashin-components";
import api from "../API/api";

const ModalSignUpUser = ({ styles, state, size, show, onHide }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [cellphoneNumber, setCellphoneNumber] = useState("11111111111");
  const [status, setStatus] = useState(1);

  const handleFinish = async () => {
    if (state.id) {
      await api.post(`/admin/${state.id}/change-status/${status}`);
    } else {
      await api.post("/admin", {
        name,
        surname,
        email,
        cellphoneNumber,
        status,
      });
    }

    onHide();
  };

  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={(x) => onHide(false)}
      className={`
        ${styles.modal()}
      `}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body className="px-4 mx-3">
        <h1
          className={`
            ${styles.tipography({ headings: "xsmallBold" })}
            mb-4 text-center
          `}
          style={{
            lineHeight: "var(--lineHeights-lineHeightDefault)",
          }}
        >
          {state.id ? "Atualização status do usuário" : "Novo usuário interno"}
        </h1>
        {!state.id && (
          <p className="text-center m-3">
            Preencha todos os campos abaixo para cadastrar
            <br />
            um novo usuário Cashin.
          </p>
        )}
        <Row>
          {!state.id && (
            <>
              <Col xs={12}>
                <Input
                  id="txtUserName"
                  inputName="userNameValue"
                  className={styles.inputs({ input: "default" })}
                  inputType="text"
                  labelName="Nome"
                  placeholder="Digite o nome"
                  styles={styles}
                  value={name}
                  disabled={state.id}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col xs={12}>
                <Input
                  id="txtUserSurname"
                  inputName="userSurnameValue"
                  className={styles.inputs({ input: "default" })}
                  inputType="text"
                  labelName="Sobrenome"
                  placeholder="Digite o sobrenome"
                  styles={styles}
                  disabled={state.id}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </Col>
              <Col xs={12}>
                <Input
                  id="txtEmailAccess"
                  inputName="emailAccessValue"
                  className={styles.inputs({ input: "default" })}
                  inputType="text"
                  labelName="E-mail de acesso"
                  placeholder="Digite o e-mail"
                  styles={styles}
                  disabled={state.id}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </>
          )}
          <Col xs={12}>
            <Select
              id="ddlStatusFilter"
              labelName="Status"
              options={[
                { value: 0, label: "Criação pendente" },
                { value: 1, label: "Ativo" },
                { value: 2, label: "Desativado" },
                { value: 3, label: "Deletado" },
              ]}
              styles={styles}
              value={status}
              onChange={(x) => setStatus(x.value)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="px-4 mx-3 pb-4">
        <ButtonComponent
          className={`
            ${styles.buttonStandardLarge({
              large: "primary",
            })}
            w-100 mx-0
          `}
          text={state.id ? "Atualizar" : "Cadastrar usuário"}
          styles={styles}
          onClick={handleFinish}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModalSignUpUser.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ModalSignUpUser;

export const isPhoneNumber = (str) => {
  const containsOnlyDigitsSpacesDashesAndParenthesesRegex = /^[\d\s\-()]+$/;
  return containsOnlyDigitsSpacesDashesAndParenthesesRegex.test(str);
};

export const stringTypeValidators = [
  { test: (str) => isPhoneNumber(str), name: 'phone' },
  { test: (str) => str.includes('@'), name: 'email' },
  { test: () => true, name: 'name' },
];

import { css } from './CreateStitches';

export const buttonStandardMedium = css({
  fontFamily: '$fontFamilyHighBaseBold',
  border: 'none',
  borderRadius: '$borderRadiusPill',
  textAlign: 'center',
  borderStyle: 'solid',
  borderWidth: '$borderWidthThin',

  '.icon-button': {
    justifySelf: 'start',
  },

  '.text-button-icon': {
    justifySelf: 'center',
    position: 'absolute',
  },

  '&.btn-layout-customizer': {
    display: 'unset !important',

    span: {
      display: 'block',
    },
  },

  variants: {
    medium: {
      primary: {
        backgroundColor: '$brandColorPrimaryPure',
        borderColor: '$brandColorPrimaryPure',
        padding: '10px $spacingXXS',
        fontSize: '$fontSizeSM',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorPrimaryLight',
          borderColor: '$brandColorPrimaryLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      secondary: {
        backgroundColor: '$neutralColorHighLight',
        borderColor: '$neutralColorHighLight',
        padding: '10px $spacingXXS',
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      link: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: '0',
        fontSize: '$fontSizeSM',
        color: '$highlightColorPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: '0',
          color: '$highlightColorMedium',
        },
        '&:focus': {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: '0',
          color: '$highlightColorDark',
        },
        '&:focus-visible': {
          backgroundColor: 'transparent',
          padding: '0',
          borderRadius: '$borderRadiusNone',
          color: '$highlightColorDark',
        },
      },
      iconLink: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: '0',
        fontSize: '$fontSizeSM',
        color: '$highlightColorPure',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          color: '$highlightColorMedium',
        },
        '&:focus': {
          color: '$highlightColorDark',
        },
        '&:focus-visible': {
          borderRadius: '$borderRadiusNone',
          color: '$highlightColorDark',
        },
      },
      primaryHover: {
        backgroundColor: '$brandColorPrimaryLight',
        borderColor: '$brandColorPrimaryLight',
        padding: '10px $spacingXXS',
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorPrimaryLight',
          borderColor: '$brandColorPrimaryLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      secondaryHover: {
        backgroundColor: '$neutralColorHighLight',
        borderColor: '$neutralColorLowPure',
        padding: '10px $spacingXXS',
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      linkHover: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: '0',
        fontSize: '$fontSizeSM',
        color: '$highlightColorMedium',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          color: '$highlightColorMedium',
        },
        '&:focus': {
          color: '$highlightColorDark',
        },
        '&:focus-visible': {
          borderRadius: '$borderRadiusNone',
          color: '$highlightColorDark',
        },
      },
      linkIconHover: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: '0',
        fontSize: '$fontSizeSM',
        color: '$highlightColorMedium',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          color: '$highlightColorMedium',
        },
        '&:focus': {
          color: '$highlightColorDark',
        },
        '&:focus-visible': {
          borderRadius: '$borderRadiusNone',
          color: '$highlightColorDark',
        },
      },
      primaryFocus: {
        backgroundColor: '$brandColorPrimaryDark',
        borderColor: '$brandColorPrimaryDark',
        padding: '10px $spacingXXS',
        fontSize: '$fontSizeSM',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorPrimaryLight',
          borderColor: '$brandColorPrimaryLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      secondaryFocus: {
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorLowPure',
        padding: '10px $spacingXXS',
        fontSize: '$fontSizeSM',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      linkFocus: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: '0',
        fontSize: '$fontSizeSM',
        color: '$highlightColorDark',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          color: '$highlightColorMedium',
        },
        '&:focus': {
          color: '$highlightColorDark',
        },
        '&:focus-visible': {
          borderRadius: '$borderRadiusNone',
          color: '$highlightColorDark',
        },
      },
      linkIconFocus: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: '0',
        fontSize: '$fontSizeSM',
        color: '$highlightColorDark',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          color: '$highlightColorMedium',
        },
        '&:focus': {
          color: '$highlightColorDark',
        },
        '&:focus-visible': {
          borderRadius: '$borderRadiusNone',
          color: '$highlightColorDark',
        },
      },
      primaryLoading: {
        backgroundColor: '$brandColorPrimaryPure',
        borderColor: '$brandColorPrimaryPure',
        padding: '10px $spacingXXS',
        width: 166,

        '&:hover': {
          backgroundColor: '$brandColorPrimaryLight',
          borderColor: '$brandColorPrimaryLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorLowPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },

        '& div': {
          width: 24,
          height: 24,
        },
      },
      secondaryLoading: {
        backgroundColor: '$neutralColorHighPure',
        borderColor: '$neutralColorHighMedium',
        padding: '10px $spacingXXS',
        width: 190,

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },

        '& div': {
          width: 24,
          height: 24,
        },
      },
    },
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const LinkComponent = ({
  id,
  hasIcon,
  text,
  iconName,
  className,
  to,
  onClick,
  isExternal,
}) => {
  return hasIcon ? (
    <a
      id={id}
      href={to}
      className={`${className} d-flex align-items-center`}
      onClick={onClick}
    >
      {text}
      <Icon iconName={iconName} />
    </a>
  ) : isExternal ? (
    <a
      id={id}
      href={to}
      target="_blank"
      className={className}
      onClick={onClick}
    >
      {text}
    </a>
  ) : (
    <a id={id} href={to} className={className} onClick={onClick}>
      {text}
    </a>
  );
};

LinkComponent.propTypes = {
  id: PropTypes.string,
  hasIcon: PropTypes.bool,
  text: PropTypes.string,
  iconName: PropTypes.string,
  className: PropTypes.any,
  to: PropTypes.string,
  onClick: PropTypes.func,
  isExternal: PropTypes.bool,
};

export default LinkComponent;

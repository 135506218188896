import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ButtonComponent, BadgeComponent } from "cashin-components";
import { Col, Row, Spinner } from "react-bootstrap";
import api from "../API/api";

const badgeDescriptionByStatus = {
  0: "Pendente",
  1: "Ativo",
  2: "Desativado",
  3: "Deletado",
};

const badgeByStatus = {
  0: "disabled",
  1: "success",
  2: "danger",
  3: "danger",
};

const CustomersTableComponent = ({
  styles,
  header,
  data,
  handleEdit,
  isLoading,
}) => {
  const handleDelete = async (id) => {
    await api.post(`/company/${id}/change-status/3`);
    window.location.reload();
  };

  const showHeader = () => {
    try {
      return header.map((e, i) => {
        return <th key={i}>{e.headerTitle}</th>;
      });
    } catch (e) {
      return <th colSpan={header.length}>{e.message}</th>;
    }
  };

  const showData = () => {
    try {
      return data.map((e, i) => {
        return (
          <tr key={i}>
            <td>
              <img src={e.logo} alt="Imagem da campanha" />
              {e.name}
            </td>
            <td>
              <BadgeComponent
                text={badgeDescriptionByStatus[e.status]}
                bgType={badgeByStatus[e.status]}
                isBadgePill={true}
                styles={styles}
              />
            </td>
            <td>{moment(e.createdDate).format("DD/MM/YYYY")}</td>
            <td>
              <div className="d-flex justify-content-center">
                <ButtonComponent
                  className={styles.buttonSquareLarge({
                    large: "secondary",
                  })}
                  hasIcon={true}
                  iconName="delete_outline"
                  onClick={() => handleDelete(e.id)}
                />
                <ButtonComponent
                  className={styles.buttonSquareLarge({
                    large: "secondary",
                  })}
                  hasIcon={true}
                  iconName="drive_file_rename_outline"
                  onClick={() => handleEdit(e.id)}
                />
              </div>
            </td>
          </tr>
        );
      });
    } catch (e) {
      return (
        <tr>
          <td colSpan={data.length}>{e.message}</td>
        </tr>
      );
    }
  };

  return (
    <table
      id="table"
      className={`
        ${styles.table()}
      `}
    >
      <thead>
        <tr>{showHeader()}</tr>
      </thead>
      <tbody>{showData()}</tbody>
      {isLoading && (
        <Row>
          <Col className="offset-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}
    </table>
  );
};

CustomersTableComponent.propTypes = {
  styles: PropTypes.object,
  header: PropTypes.array,
  data: PropTypes.array,
  handleEdit: PropTypes.func,
};

export default CustomersTableComponent;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DashboardPage from "./dashboard-page";

const defaultState = {
  // valueCampaignOptions: [
  //   {
  //     value: 1,
  //     label: 'Todas',
  //   },
  // ],
  valueCampaignOptions: [""],
  dashboardInfoCard: [
    {
      title: "Valor em prêmios",
      value: "R$ 60.000",
      info: "valor total de premiação distribuída",
    },
    {
      title: "Participantes",
      value: "105k+",
      info: "total de participantes",
    },
    {
      title: "Campanhas",
      value: "27",
      info: "total de campanhas ativas e concluídas",
    },
  ],
  view: "week",
  events: [
    {
      event_id: 1,
      title: "Campanha 1",
      start: new Date(new Date("2022/12/24 00:00")),
      end: new Date(new Date("2022/12/25 23:59")),
      editable: false,
      deletable: false,
    },
    {
      event_id: 2,
      title: "Campanha 2",
      start: new Date(new Date("2022/12/25 00:00")),
      end: new Date(new Date("2022/12/26 23:59")),
      editable: false,
      deletable: false,
    },
    {
      event_id: 3,
      title: "Campanha 3",
      start: new Date(new Date("2022/12/25 00:00")),
      end: new Date(new Date("2022/12/27 23:59")),
      editable: false,
      deletable: false,
    },
    {
      event_id: 4,
      title: "Campanha 4",
      start: new Date(new Date("2022/12/27 00:00")),
      end: new Date(new Date("2022/12/28 23:59")),
      editable: false,
      deletable: false,
    },
    {
      event_id: 5,
      title: "Campanha 5",
      start: new Date(new Date("2022/12/27 14:00")),
      end: new Date(new Date("2022/12/27 15:00")),
      editable: false,
      deletable: false,
    },
  ],
  navigation: true,
  loading: false,
};

const Dashboard = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState);

  const getCampaigns = async () => {
    const arr = [];

    // const campaignData = await API.get("/campaign/identification-info");

    // campaignData.map((e) => {
    //   return arr.push({ value: e.id, label: e.description });
    // });

    setOwnState({
      ...ownState,
      valueCampaignOptions: arr,
    });
  };

  const handleCreateCampaign = () => {
    history.push("/create-campaign");
    history.go("/create-campaign");
  };

  useEffect(() => {
    setOwnState({
      ...ownState,
      loading: true,
    });

    getCampaigns();

    setTimeout(() => {
      setOwnState({
        ...ownState,
        loading: false,
      });
    }, 1000);
  }, []);

  return (
    <DashboardPage
      styles={styles}
      state={ownState}
      handleCreateCampaign={handleCreateCampaign}
    />
  );
};

Dashboard.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default Dashboard;

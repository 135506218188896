import { css } from './CreateStitches';

export const breadcrumb = css({
  '& a': {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      marginRight: 10,
    },
  },

  variants: {
    separator: {
      default: {
        '&.breadcrumb-item': {
          '&::before': {
            content: '"/"',
          },
        },
      },
      arrow: {
        '&.breadcrumb-item': {
          '&::before': {
            content: '">"',
          },
        },
      },
    },
  },
});

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import Icon from "./Icon";
import IconCustom from "./IconCustom";

const ButtonComponent = ({
  styles,
  style,
  isSuccessButton,
  id,
  hasIcon,
  hasCustomIcon,
  isLoading,
  text,
  iconName,
  customIconName,
  iconDescription,
  className,
  disabled,
  onClick,
  isFullWidthIconButton,
  isButtonGroup,
  itemsGroup,
}) => {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    if (isSuccessButton) {
      setClicked(true);
    }
    onClick();
  };

  const buttonClassName = `${className} ${isSuccessButton ? "success-save" : ""
    }`;

  return hasIcon ? (
    <>
      {isLoading ? (
        <Button
          id={id}
          className={`${buttonClassName} align-items-center justify-content-center`}
          disabled={true}
          onClick={handleClick}
          style={style}
        >
          <div
            className={styles.loading({ loadingAnimation: "medium" })}
            aria-hidden="true"
          />
        </Button>
      ) : (
        <Button
          id={id}
          className={`${buttonClassName} ${isFullWidthIconButton ? "d-grid align-items-center" : "d-flex"
            } ${!text ? "justify-content-center align-items-center" : ""}`}
          disabled={disabled}
          onClick={handleClick}
          style={style}
        >
          {!isSuccessButton && (
            hasCustomIcon ? (
              <IconCustom iconName={customIconName} />
            ) : (
              <Icon
                iconName={iconName}
                className={`material-icons ${text ? "me-1" : ""} icon-button`}
              />
            )
          )}
          {text ? (
            <span
              className={`${isFullWidthIconButton ? "text-button-icon" : ""}`}
            >
              {text}
            </span>
          ) : (
            <span
              className={`${styles && styles.inputs({ inputIcon: "iconDescription" })
                } ${isFullWidthIconButton ? "text-button-icon" : ""}`}
            >
              {iconDescription}
            </span>
          )}
          {isSuccessButton && <Icon iconName="check" />}
        </Button>
      )}
    </>
  ) : isLoading ? (
    <Button
      id={id}
      className={`${buttonClassName} align-items-center justify-content-center`}
      disabled={disabled}
      onClick={handleClick}
      style={style}
    >
      <div
        className={styles.loading({ loadingAnimation: "medium" })}
        aria-hidden="true"
      />
    </Button>
  ) : isButtonGroup ? (
    <ButtonGroup className={className}>
      <DropdownButton as={ButtonGroup} title="..." id="bg-nested-dropdown">
        {itemsGroup.map((e, i) => {
          return (
            <Dropdown.Item
              eventKey={i}
              className={`d-flex align-items-center ${e.itemIconClass}`}
              onClick={e.onClickItem}
              key={i}
            >
              <Icon iconName={e.itemIcon} className="material-icons me-3" />
              {e.itemName}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </ButtonGroup>
  ) : (
    <Button id={id} className={buttonClassName} disabled={disabled} onClick={handleClick}>
      {text}
    </Button>
  );
};

ButtonComponent.propTypes = {
  styles: PropTypes.object,
  style: PropTypes.object,
  isSuccessButton: PropTypes.bool,
  id: PropTypes.string,
  hasIcon: PropTypes.bool,
  hasCustomIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  text: PropTypes.string,
  iconName: PropTypes.string,
  customIconName: PropTypes.string,
  iconDescription: PropTypes.string,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isFullWidthIconButton: PropTypes.bool,
  isButtonGroup: PropTypes.bool,
  itemsGroup: PropTypes.array,
};

export default ButtonComponent;

import { apiv2 } from '../apiv2'

export enum CommunicationType {
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
  APP = 'app',
}

export type CommunicationRule = {
  templateTitle: string
  templateText: string
  templateName: string
  templateContent: string
  enabled: boolean
  week: number
  day: number
  sendDate: Date
}

export interface Communication {
  _id: string
  communicationType: CommunicationType
  rules: CommunicationRule[]
}

export const getCommunications = async ({
  type,
  enabledOnly,
  weeks,
}: {
  type?: CommunicationType
  enabledOnly?: boolean
  weeks?: number
}) => {
  return await apiv2.get('/communications', {
    params: {
      type,
      enabledOnly,
      weeks,
    },
  })
}

import React, { useState } from 'react'
import { Input, Select } from 'cashin-components'
import { Col, Row } from 'react-bootstrap'
import { styles } from 'cashin-design-system/Styles/Styles'
import { formatDate } from '../../../functions/date'

const WhatsappSelector = ({ campaign, setIndex, index }) => {
  const communications =
    campaign?.schedule?.campaign.map((item) => {
      return {
        value: item.name,
        label: item.campaignName,
      }
    }) || []

  const [communication, setCommunication] = useState(communications[0])

  const fullCommunication = campaign?.schedule?.campaign?.find(
    (item) => item.name === communication?.value
  )

  const campaignSelected = campaign?.campaignDetails[index]

  const handleSelectChange = (e) => {
    setCommunication(e)
  }

  return (
    <>
      <Row className='w-full'>
        <Col xs={12}>
          <Select
            id='ddlChangeStatusFilter'
            inputName='changeStatusValue'
            idError='fdbChangeStatusError'
            labelName='Comunicação'
            options={communications}
            styles={styles}
            value={communication}
            selectedValue={communication}
            onChange={(e) => {
              const getIndex = campaign?.schedule?.campaign.findIndex(
                (item) => item.name === e.value
              )
              setIndex(getIndex)
              handleSelectChange(e)
            }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col xs={12}>
          <Input
            id='txtDateRangeCampaign'
            idError='fdbDateRangeCampaign'
            labelName='Data de envio'
            styles={styles}
            disabled={true}
            value={
              fullCommunication?.sendDate
                ? formatDate(fullCommunication?.sendDate)
                : ''
            }
            selectedValue={
              fullCommunication?.sendDate
                ? formatDate(fullCommunication?.sendDate)
                : ''
            }
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col xs={12}>
          <Input
            id='txtDateRangeCampaign'
            idError='fdbDateRangeCampaign'
            labelName='Descrição'
            styles={styles}
            disabled={true}
            value={campaignSelected.description}
            selectedValue={campaignSelected.description}
          />
        </Col>
      </Row>

      {index === 0 && (
        <Row className='mt-3'>
          <Col xs={12}>
            <Input
              id='txtDateRangeCampaign'
              idError='fdbDateRangeCampaign'
              labelName='Texto do botão'
              styles={styles}
              disabled={true}
              value='Participar'
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default WhatsappSelector

import { Accordion, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { styles } from 'cashin-design-system/Styles/Styles'
import Icon from '../../Components/Icon'
import ButtonComponent from '../../Components/Buttons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import ChevronRight from '@mui/icons-material/KeyboardArrowUp'
import ChevronDown from '@mui/icons-material/KeyboardArrowDown'
import { debounce } from 'lodash'

import '../Reports.css'
import { DrawerFilter } from '../../Components/DrawerFilter'
import CustomPagination from '../../Components/CustomPagination'
import { transformAppReportDataForXlxs } from './export-app-report'
import { exportToXlsx } from '../../functions/export-report-to-xlsx'
import { BadgeComponent, Box } from '../../Components'
import { stringTypeValidators } from '../../functions/string-input-validations'
import { getAppReport } from '../../APIV2/reports/get-app-report'
import { dateMaskWithDash, formatDate } from '../../functions/date'
import Loading from '../../Components/Loading'

const { accessor } = createColumnHelper()

export const badgeTextByStatus = {
  ACCESSED: {
    label: 'Acessou ao App',
    secLabel: 'Acessou',
    color: 'indigo',
  },
  VIEW_CAMPAIGN: {
    label: 'Visualizou a campanha',
    secLabel: 'Visualizou',
    color: 'forest-green',
  },
  ADD_LIKE: { label: 'Curtiu', secLabel: 'Curtiu', color: 'persian-rose' },
  ACCEPTED_TERMS: {
    label: 'Termos e regulamento',
    secLabel: 'Leu e aceitou',
    color: 'eucalyptus',
  },
  ADD_COMMENT: { label: 'Comentário', secLabel: 'Comentou', color: 'orange' },
  DEFAULT: { label: '-', secLabel: '-', color: 'gray' },
  NO_INTERACTION: {
    label: 'Sem interação',
    secLabel: 'Sem interação',
    color: 'gray',
  },
}

export const statusFilter = {
  ...badgeTextByStatus,
}

export const AppReportTable = ({ campaignId, isApp }) => {
  const [communicationStringFilter, setCommunicationStringFilter] = useState('')
  const [debouncedAndDefinedString, setDebouncedAndDefinedString] = useState('')
  const [paramName, setParamName] = useState('')
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [totalPages, setTotalPage] = useState(0)
  const [showDrawerFilter, setShowDrawerFilter] = useState(false)
  const [inputDateRange, setInputDateRange] = useState([null, null])
  const [dateRange, setDateRange] = useState([null, null])
  const [checkBoxStatus, setCheckBoxStatus] = useState({})
  const [types, setTypes] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const debouncedCommunicationStringFilter = debounce((stringFilter) => {
    const matchedValidator = stringTypeValidators.find((type) =>
      type.test(stringFilter)
    )
    if (matchedValidator) {
      setDebouncedAndDefinedString(stringFilter)
      setParamName(matchedValidator.name)
    }
  }, 500)

  const search = useCallback(async () => {
    setLoading(true)
    try {
      const result = await getAppReport({
        campaignId,
        page,
        paramValue: debouncedAndDefinedString,
        paramName,
        from: dateRange[0],
        to: dateRange[1],
        types,
      })
      setData(result.data.data)
      setTotalPage(result.data.totalPages)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }, [campaignId, page, debouncedAndDefinedString, paramName, types, dateRange])

  const exportReport = async () => {
    setLoading(true)
    try {
      const rest = await getAppReport({
        campaignId,
        page,
        paramValue: debouncedAndDefinedString,
        paramName,
        from: dateRange[0],
        to: dateRange[1],
        types,
        toExport: true,
      })
      const xlsxData = transformAppReportDataForXlxs(rest.data.data)
      exportToXlsx(
        xlsxData,
        `rel-app-${formatDate(new Date(), dateMaskWithDash)}`
      )
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    search()
  }, [page, search])

  const columns = useMemo(
    () => [
      accessor('participant', {
        header: 'Participante',
        enableResizing: true,
        cell: (rowMain) => {
          const value = rowMain.getValue().name
          const { row } = rowMain
          return (
            <div>
              {row.getIsExpanded() ? (
                <ChevronRight
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                />
              ) : (
                <ChevronDown
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                />
              )}
              {value}
            </div>
          )
        },
      }),
      accessor('participant', {
        header: 'Telefone',
        enableResizing: true,
        cell: (row) => row.getValue().phone,
      }),
      accessor('participant', {
        header: 'E-mail',
        enableResizing: true,
        cell: (row) => row.getValue().email,
      }),
      accessor('reactedCount', {
        header: 'Qtd de interações',
        enableResizing: true,
        cell: (row) => row.getValue(),
      }),
      accessor('percentage', {
        header: 'Engajamento',
        enableResizing: true,
        cell: (row) => `${row.getValue()}%`,
      }),
    ],
    []
  )

  const table = useReactTable({
    data: data ?? [],
    columns,
    enableHiding: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const [state, setState] = useState(table.initialState)

  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: setState,
    pageCount: totalPages,
  }))

  return (
    <Box>
      <DrawerFilter
        styles={styles}
        show={showDrawerFilter}
        onHide={() => setShowDrawerFilter(false)}
        dateRange={inputDateRange}
        setDateRange={setInputDateRange}
        onCancel={() => setShowDrawerFilter(false)}
        onApply={() => {
          setTypes(checkBoxStatus)
          setDateRange(inputDateRange)
          setShowDrawerFilter(false)
        }}
        onCleanFilter={() => {
          setTypes(null)
          setCheckBoxStatus({})
          setInputDateRange([null, null])
          setDateRange([null, null])
          setParamName('')
          setDebouncedAndDefinedString('')
          setCommunicationStringFilter('')
          setShowDrawerFilter(false)
        }}
      >
        <Accordion defaultActiveKey='0' className='communication-report-table'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header className='d-flex justify-content-start'>
              <span
                className={styles.tipography({ paragraphs: 'mediumRegular' })}
              >
                Status
              </span>
            </Accordion.Header>
            <Accordion.Body style={{ paddingRight: 0, paddingLeft: 0 }}>
              <Form>
                {Object.entries(statusFilter)
                  .filter(([key]) => key !== 'DEFAULT')
                  .map(([key, status], index) => (
                    <Form.Check
                      key={index}
                      type='checkbox'
                      label={status.label}
                      checked={checkBoxStatus[key]}
                      onChange={(event) =>
                        setCheckBoxStatus((prevState) => ({
                          ...prevState,
                          [key]: event.target.checked,
                        }))
                      }
                    />
                  ))}
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </DrawerFilter>
      <Row className='align-items-center mb-4'>
        <Col
          xs={{ span: 4, offset: 8 }}
          className='d-flex justify-content-end align-items-center'
        >
          <ButtonComponent
            className='bg-white text-black border-0 hover:bg-violet-200'
            hasIcon={true}
            iconName='filter_alt'
            onClick={() => setShowDrawerFilter(true)}
          />
          <InputGroup className='my-1 me-2'>
            <Form.Control
              className='bg-white rounded-md border-end-0 border'
              placeholder='Busca por participante, telefone ou email'
              value={communicationStringFilter}
              onChange={(e) => {
                setCommunicationStringFilter(e.target.value)
                debouncedCommunicationStringFilter(e.target.value)
              }}
            />
            <InputGroup.Text className='bg-white border-start-0 rounded-md border'>
              <Icon iconName={'search'} />
            </InputGroup.Text>
          </InputGroup>
          <ButtonComponent
            className={`${styles.buttonStandardSmall({
              small: 'primaryLoading',
            })}`}
            text='Exportar'
            isLoading={isLoading}
            disabled={isLoading}
            styles={styles}
            onClick={exportReport}
          />
        </Col>
      </Row>
      {isApp && (
        <Row>
          <Col>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <table className={`${styles.table()}`}>
                  {table.getHeaderGroups().map((headerGroup) =>
                    headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className='!tw-bg-primary-800 !tw-text-white'
                        >
                          {!header.isPlaceholder ? (
                            <div className='flex items-center gap-2'>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          ) : null}
                        </th>
                      )
                    })
                  )}
                  {!data.length ? (
                    <tr className='table-row'>
                      <td colSpan={3}>
                        Nenhuma informação disponível até o momento.
                      </td>
                    </tr>
                  ) : (
                    table.getSortedRowModel().rows.map((row) => {
                      const original = row.original
                      return (
                        <>
                          <tr
                            key={row.id}
                            onClick={() => row.toggleExpanded()}
                            className='table-row bold'
                          >
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            ))}
                          </tr>
                          {row.getIsExpanded() ? (
                            <>
                              <tr className='table-row bold bg-gray-light'>
                                <td>Período</td>
                                <td>Segmento</td>
                                <td>Data da interação</td>
                                <td>Interação</td>
                                <td>Status</td>
                              </tr>
                              {original.types.map((currentType) => (
                                <tr className='table-row bg-gray-light'>
                                  <td>
                                    {formatDate(original.from)} -{' '}
                                    {formatDate(original.to)}
                                  </td>
                                  <td>
                                    {original.participant.segmentationName ||
                                      '-'}
                                  </td>
                                  <td>
                                    {currentType.createdAt
                                      ? formatDate(currentType.createdAt)
                                      : '-'}
                                  </td>
                                  <td>
                                    {
                                      badgeTextByStatus[
                                        currentType.type ?? 'DEFAULT'
                                      ]?.label
                                    }
                                  </td>
                                  <td>
                                    <BadgeComponent
                                      text={
                                        badgeTextByStatus[
                                          !!currentType.reacted
                                            ? currentType.type
                                            : 'NO_INTERACTION'
                                        ].secLabel
                                      }
                                      bgType={
                                        badgeTextByStatus[
                                          !!currentType.reacted
                                            ? currentType.type
                                            : 'NO_INTERACTION'
                                        ].color
                                      }
                                      isBadgePill={true}
                                      styles={styles}
                                      style={{
                                        cursor:
                                          row?.status === 1 ? 'pointer' : '',
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : null}
                        </>
                      )
                    })
                  )}
                </table>
                <CustomPagination
                  totalPages={totalPages}
                  onPaginationChange={(currentPage) => setPage(currentPage)}
                  currentPage={page}
                  setCurrentPage={setPage}
                />
              </>
            )}
          </Col>
        </Row>
      )}
    </Box>
  )
}

import axios from "axios";

const data = JSON.parse(window.localStorage.getItem("data"));
const keyPrefix = data && data.keyPrefix
const sub = data && data.attributes && data.attributes.sub
const cognitoStructure = `${keyPrefix}.${sub}.accessToken`
const cognitoToken = window.localStorage.getItem(cognitoStructure)

export const apiv2 = axios.create({
  baseURL: process.env.REACT_APP_API_ENGAJE_V2,
  headers: {
    Authorization: `Bearer ${cognitoToken}`,
  },
})

import api from '../../api'

const uploadValidationResultRequest = async (
  validationId,
  validationResultId,
  file,
  type = 'regular'
) => {
  const formDataRequest = new FormData()

  formDataRequest.append('file', file)
  formDataRequest.append('validationId', validationResultId)
  formDataRequest.append('type', type)

  return await api.post(
    `/campaign-validate/${validationId}/upload-result`,
    formDataRequest
  )
}

export default uploadValidationResultRequest

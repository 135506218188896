import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import AppDeviceMuckup from 'cashin-design-system/Styles/img/ExampleLayout/appDefaultCustom.png';
import AppDeviceNoticationHeader from 'cashin-design-system/Styles/img/ExampleLayout/appDefaultCustomHeader.png';

const FolderAppPreview = ({ titleComunication, descriptionComunication, attachmentFilePath, btnAddParticipation, styles, state, layoutInfo }) => {
  const stylesApp = {
    input: {
      display: 'block',
      width: '80%',
      margin: '10px auto',
      padding: '5px',
    },
    textarea: {
      display: 'block',
      width: '80%',
      height: '100px',
      margin: '10px auto',
      padding: '5px',
    },
    button: {
      display: 'block',
      margin: '20px auto',
    },
    device: {
      alignContent: "center",
      paddingLeft: '14px',
      width: '25rem',
      height: '48rem',
      position: 'relative',
      background: `url(${AppDeviceMuckup}) no-repeat center center`,
      backgroundSize: 'cover'
    },
    screen: {
      backgroundColor: '#E189EA',
      width: '19.6rem',
      height: '5rem',
      overflow: 'auto',
      margin: '10px',
      paddingTop: '4px',
      overflowY: 'hidden',
      marginLeft: "29px",
      borderRadius: '10px',
    },
    message: {
      padding: '5px 10px',
      marginBottom: '5px',
      borderRadius: '10px',
      maxWidth: '98%',
      clear: 'both',
    },
    userMessage: {
      marginLeft: 'auto',
      marginRight: '5px',
    },
    messageImage: {
      height: 200
    },
    image: { float: "right", marginBottom: "0.5rem", borderRadius: ".5rem", width: 288, objectFit: "cover" },
    imageHeader: { width: "100%", objectFit: "cover" }
  };

  const handleTextMarkdownToHtml = (text) => {
    if (!text) return '';
    // negrito cpm **
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    // negrito
    text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    // itálico
    text = text.replace(/_(.*?)_/g, '<em>$1</em>');
    // // quebra de linha
    text = text.replace(/\n/g, '<br />');

    return text;
  }

  return (
    <Container>
      <Row>
        <Col xs className="d-flex justify-content-center">
          <div className={styles.whatsAppMessage()}>
            <div style={stylesApp.device}>
              <div style={stylesApp.screen}>
                <img src={AppDeviceNoticationHeader} alt="Publicidade WhatsApp" style={stylesApp.imageHeader} />

                {attachmentFilePath && (
                  <img src={attachmentFilePath} alt="Publicidade WhatsApp" style={stylesApp.image} />
                )}
                <div style={{ ...stylesApp.message, ...stylesApp.userMessage }}>
                  <p style={{ fontWeight: 'bold', marginBottom: -2 }} dangerouslySetInnerHTML={{ __html: handleTextMarkdownToHtml(titleComunication) }} />
                  <p dangerouslySetInnerHTML={{ __html: handleTextMarkdownToHtml(descriptionComunication) }} />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

FolderAppPreview.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutInfo: PropTypes.object,
};

export default FolderAppPreview;

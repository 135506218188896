import { useQuery, useQueryClient, useMutation } from 'react-query'
import api from '../../api'

const useCompanyUsageBalance = (companyId) => {
  return useQuery(
    ['usage-balance', companyId],
    async () =>
      api.get(`/company-negotiation-usage/current-usage/company/${companyId}`),
    {
      cacheTime: 0,
      enabled: !!companyId,
    }
  )
}

const useUpdateCompanyUsage = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (newData) => {
      return api.post('/company-negotiation-usage/update-usage', newData)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('usage-balance')
      },
    }
  )
}

export { useCompanyUsageBalance, useUpdateCompanyUsage }

export default useCompanyUsageBalance

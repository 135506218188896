import { css } from './CreateStitches';

export const sortable = css({
  overflow: 'auto hidden',
  '& > .sortable-area': {
    display: 'flex',
    width: 'fit-content',
    overflow: 'hidden',

    '& div[role]': {
      '& .card-area': {
        width: 176,
        height: 196,
        padding: '$spacingXXXS',
        marginRight: '$spacingSM !important',
        backgroundColor: '$brandColorTertiaryPure',
        border: 'solid 3px $brandColorTertiaryPure',
        borderRadius: '$borderRadiusMD',

        '& .icon-area': {
          width: 40,
          height: 40,
          backgroundColor: '$brandColorTertiaryDark',
          borderRadius: '$borderRadiusSM',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '$neutralColorHighPure',
          marginBottom: 50,
        },

        '& .checkbox-area': {
          position: 'absolute',
          top: 16,
          right: 16,
        },

        '&:last-child': {
          marginRight: 0,
        },

        '&:hover': {
          border: 'solid 3px $brandColorTertiaryDark',
        },

        '&:focus, &:focus-visible': {
          border: 'solid 3px $brandColorTertiaryDark',
          boxShadow: 'none',
          outline: 'none',
        },

        '&.disabled': {
          opacity: '0.48',

          '& .checkbox-area': {
            '& input[type][disabled]': {
              opacity: 1,
            },
          },
        },
      },

      '&:focus, &:focus-visible': {
        boxShadow: 'none',
        outline: 'none',
      },
    },
  },
});

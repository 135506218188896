import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Input, Icon } from 'cashin-components'
import dayjs from 'dayjs'
import { Box } from '../../Components'

const RulesCampaign = ({ styles, state, campaign }) => {
  function formatValue(data) {
    if (!data) return ''

    const { type, value } = data

    if (type === 0) {
      const formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)
      return formattedValue
    } else if (type === 1) {
      return `${value}%`
    }

    return value.toString()
  }

  const ruleTypeDescription = {
    0: 'Estimule uma meta e prêmio financeiro.',
    1: 'Premie com base em produtos ou serviços vendidos.',
    2: 'Monte sua regra! Baseada em metas financeiras e percentual.',
  }

  return (
    <Box>
      <Row>
        <Col xs={6}>
          <Input
            id='txtCampaignName'
            className={styles.inputs({ input: 'default' })}
            inputType='text'
            labelName='Nome da campanha'
            styles={styles}
            value={campaign?.name}
            disabled={true}
            hasTooltip={true}
            tooltipText='Aqui você pode escrever o nome que quer dar a sua campanha ☺️'
          />
        </Col>
        <Col
          xs={6}
          className={`
          ${styles.inputs({ datePicker: 'datePickerDefault' })}
          d-flex align-items-end
        `}
        >
          <Input
            id='txtDateRangeCampaign'
            labelName='Período da campanha'
            styles={styles}
            isDatePicker={true}
            placeholder={`${dayjs(campaign?.periodDate?.from).format(
              'DD/MM/YYYY'
            )} - ${dayjs(campaign?.periodDate?.to).format('DD/MM/YYYY')}`}
            disabled={true}
            hasTooltip={true}
            tooltipText='Escolha o dia para iniciar a sua campanha. Lembre-se que você não poderá voltar para editar esse campo após a campanha já ter iniciado, tudo bem? O período máximo da campanha deve ser de até 3 meses! ☺️'
          />
        </Col>
        <Col xs={6}>
          <div className={styles.inputs({ inputFile: 'inputFileDefault' })}>
            <Input
              id='txtFileInput'
              idError='fdbFileInput'
              labelName='Termos de uso e regulamento (opcional)'
              errorText={state.fileInputError}
              styles={styles}
              isInvalid={state.isInvalidFileInput}
              hasTooltip={true}
              tooltipText='Se você tiver algum Termo ou comunicado adicional para seus participantes, carregue aqui o PDF. Eles receberão durante a comunicação da campanha ☺️'
              isFileInput={true}
              disabled={true}
              filePlaceholderText='Faça upload do arquivo PDF'
            />
          </div>
        </Col>
        <Col xs={6}>
          <Input
            id='txtCampaignName'
            inputName='campaignName'
            className={styles.inputs({ input: 'default' })}
            idError='fdbCampaignNameError'
            inputType='text'
            labelName='Site da campanha (opcional)'
            styles={styles}
            value={campaign?.site || '-Não informado-'}
            disabled={true}
            hasTooltip={true}
            tooltipText='Quer enviar para seus participantes um Site, Vídeo ou alguma informação adicional? Eles receberão durante a comunicação da campanha ☺️'
          />
        </Col>
        <Col xs={12}>
          <hr className='mt-3 mb-4' />
        </Col>
        <Col xs={12}>
          <h2
            className={`
            ${styles.tipography({ headings: 'xsmallBold' })}
            mb-3
          `}
          >
            Tipo de regra:
          </h2>
        </Col>
        <Col xs={12} className='mb-3'>
          <Row>
            <Col xs={'auto'}>
              <div
                className={`
                ${styles.buttonSquareMedium({
                  medium: 'primary',
                })}
                d-flex align-items-center justify-content-center step-badge
              `}
                aria-hidden='true'
              >
                <Icon iconName='rule' />
              </div>
            </Col>
            <Col
              xs={'auto'}
              className={`
              d-flex border-color flex-column
            `}
            >
              <h3
                className={`
                ${styles.tipography({ paragraphs: 'largeBold' })}
                m-0
              `}
              >
                {campaign?.rule?.ruleType === 0 && 'Meta financeira'}
                {campaign?.rule?.ruleType === 1 && 'Produto ou Serviço'}
                {campaign?.rule?.ruleType === 2 && 'Híbrido'}
              </h3>

              <Row>
                <Col
                  xs
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <p
                    className={`
                ${styles.tipography({
                  paragraphs: 'xSmallRegular',
                })}
                ${styles.colors({
                  colors: 'neutralColorLowMedium',
                })}
                m-0
              `}
                  >
                    {ruleTypeDescription[campaign?.rule?.ruleType]}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {campaign?.rule?.ruleType === 0 && (
          <>
            {!campaign?.isSegmentationCampaign && (
              <>
                <Col xs={6}>
                  <Input
                    id='txtCampaignName'
                    inputName='campaignName'
                    className={styles.inputs({ input: 'default' })}
                    idError='fdbCampaignNameError'
                    inputType='text'
                    labelName='Bateu:'
                    styles={styles}
                    value={formatValue(campaign?.rule?.reachPurchaseValue)}
                    disabled={true}
                  />
                </Col>
                <Col xs={6}>
                  <Input
                    id='txtCampaignName'
                    inputName='campaignName'
                    className={styles.inputs({ input: 'default' })}
                    idError='fdbCampaignNameError'
                    inputType='text'
                    labelName='Levou:'
                    styles={styles}
                    value={formatValue(campaign?.prize?.discount)}
                    disabled={true}
                  />
                </Col>
              </>
            )}
            {campaign?.isSegmentationCampaign && (
              <Row key='rule-prize-row'>
                {campaign?.rules?.map((rule, index) => (
                  <React.Fragment key={rule.id}>
                    <Col xs={4}>
                      <Input
                        id={`txtMeta-${rule.id}`}
                        inputName='meta'
                        className={styles.inputs({ input: 'default' })}
                        idError={`fdbMetaError-${rule.id}`}
                        inputType='text'
                        labelName='Bateu:'
                        styles={styles}
                        value={formatValue(rule.reachPurchaseValue)}
                        disabled={true}
                      />
                    </Col>
                    {campaign?.prizes?.[index] && (
                      <Col xs={4} key={campaign.prizes[index].id}>
                        <Input
                          id={`txtPrize-${campaign.prizes[index].id}`}
                          inputName='prizeName'
                          className={styles.inputs({ input: 'default' })}
                          idError={`fdbPrizeError-${campaign.prizes[index].id}`}
                          inputType='text'
                          labelName='Levou:'
                          styles={styles}
                          value={formatValue(
                            campaign?.prizes?.[index]?.discount
                          )}
                          disabled={true}
                        />
                      </Col>
                    )}
                    <Col xs={4}>
                      <Input
                        id={`txtSegmentName-${rule.id}`}
                        inputName='segmentName'
                        className={styles.inputs({ input: 'default' })}
                        idError={`fdbSegmentNameError-${rule.id}`}
                        inputType='text'
                        labelName='Segmento:'
                        styles={styles}
                        value={rule.segmentation}
                        disabled={true}
                      />
                    </Col>
                  </React.Fragment>
                ))}
              </Row>
            )}
          </>
        )}

        {campaign?.rule?.ruleType === 1 && (
          <>
            {!campaign?.isSegmentationCampaign && (
              <>
                {campaign?.rule?.itemQuantity.map((campaing) => {
                  return (
                    <Row>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='Produto ou Serviço:'
                          styles={styles}
                          value={campaing.code}
                          disabled={true}
                        />
                      </Col>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='Quantidade:'
                          styles={styles}
                          value={`${campaing.qtd}`}
                          disabled={true}
                        />
                      </Col>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='&nbsp;'
                          styles={styles}
                          value={
                            campaing.conditionType === 0
                              ? 'e'
                              : campaing.conditionType === 1
                              ? 'ou'
                              : ''
                          }
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  )
                })}
                <Row>
                  <Col xs={4}>
                    <Input
                      id='txtCampaignName'
                      inputName='campaignName'
                      className={styles.inputs({ input: 'default' })}
                      idError='fdbCampaignNameError'
                      inputType='text'
                      labelName='Ganhou:'
                      styles={styles}
                      value={formatValue(campaign?.prize?.discount)}
                      disabled={true}
                    />
                  </Col>
                </Row>
              </>
            )}

            {campaign?.isSegmentationCampaign && (
              <>
                {campaign?.rules.map((item) => (
                  <div className='border p-3 w-full mt-3 mb-2 rounded'>
                    <Row>
                      <Col xs={3}>
                        <Input
                          id='txtSegmentationName'
                          inputName='SegmentationName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbSegmentationNameError'
                          inputType='text'
                          labelName='Segmento:'
                          styles={styles}
                          value={item.segmentation}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    {item?.itemQuantity.map((campaing) => {
                      return (
                        <Row>
                          <Col xs={3}>
                            <Input
                              id='txtCampaignName'
                              inputName='campaignName'
                              className={styles.inputs({ input: 'default' })}
                              idError='fdbCampaignNameError'
                              inputType='text'
                              labelName='Produto ou Serviço:'
                              styles={styles}
                              value={campaing.code}
                              disabled={true}
                            />
                          </Col>
                          <Col xs={2}>
                            <Input
                              id='txtCampaignName'
                              inputName='campaignName'
                              className={styles.inputs({ input: 'default' })}
                              idError='fdbCampaignNameError'
                              inputType='text'
                              labelName='Quantidade:'
                              styles={styles}
                              value={`${campaing.qtd}`}
                              disabled={true}
                            />
                          </Col>

                          {(campaing.conditionType === 0 ||
                            campaing.conditionType === 1) && (
                            <Col xs={1}>
                              <Input
                                id='txtCampaignName'
                                inputName='campaignName'
                                className={styles.inputs({
                                  input: 'default',
                                })}
                                idError='fdbCampaignNameError'
                                inputType='text'
                                labelName='&nbsp;'
                                styles={styles}
                                value={
                                  campaing.conditionType === 0 ? 'e' : 'ou'
                                }
                                disabled={true}
                              />
                            </Col>
                          )}
                        </Row>
                      )
                    })}

                    {
                      <Row>
                        <Col xs={3}>
                          <Input
                            id='txtCampaignName'
                            inputName='campaignName'
                            className={styles.inputs({ input: 'default' })}
                            idError='fdbCampaignNameError'
                            inputType='text'
                            labelName='Ganhou:'
                            styles={styles}
                            value={formatValue(
                              campaign?.prizes?.find(
                                (prize) =>
                                  prize.segmentation === item.segmentation
                              )?.discount
                            )}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    }
                  </div>
                ))}
              </>
            )}
          </>
        )}

        {campaign?.rule?.ruleType === 2 && (
          <>
            {!campaign?.isSegmentationCampaign && (
              <>
                <Col xs={4}>
                  <Input
                    id='txtCampaignName'
                    inputName='campaignName'
                    className={styles.inputs({ input: 'default' })}
                    idError='fdbCampaignNameError'
                    inputType='text'
                    labelName='Bateu:'
                    styles={styles}
                    value={formatValue(
                      campaign?.rule?.multipleReachValueCondition?.reachValue
                    )}
                    disabled={true}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    id='txtCampaignName'
                    inputName='campaignName'
                    className={styles.inputs({ input: 'default' })}
                    idError='fdbCampaignNameError'
                    inputType='text'
                    labelName='&nbsp;'
                    styles={styles}
                    value={
                      campaign?.rule?.multipleReachValueCondition?.condition ===
                        undefined ||
                      campaign?.rule?.multipleReachValueCondition?.condition ===
                        null
                        ? ''
                        : campaign?.rule?.multipleReachValueCondition
                            ?.condition === 0
                        ? 'e'
                        : 'ou'
                    }
                    disabled={true}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    id='txtCampaignName'
                    inputName='campaignName'
                    className={styles.inputs({ input: 'default' })}
                    idError='fdbCampaignNameError'
                    inputType='text'
                    labelName='Bateu:'
                    styles={styles}
                    value={formatValue(
                      campaign?.prize?.multipleDiscount?.discount
                    )}
                    disabled={true}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    id='txtCampaignName'
                    inputName='campaignName'
                    className={styles.inputs({ input: 'default' })}
                    idError='fdbCampaignNameError'
                    inputType='text'
                    labelName='Levou:'
                    styles={styles}
                    value={formatValue(
                      campaign?.rule?.multipleReachValueCondition
                        ?.reachValueFromCondition
                    )}
                    disabled={true}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    id='txtCampaignName'
                    inputName='campaignName'
                    className={styles.inputs({ input: 'default' })}
                    idError='fdbCampaignNameError'
                    inputType='text'
                    labelName='&nbsp;'
                    styles={styles}
                    value={
                      campaign?.prize?.multipleDiscount?.condition ===
                        undefined ||
                      campaign?.prize?.multipleDiscount?.condition === null
                        ? ''
                        : campaign?.prize?.multipleDiscount?.condition === 0
                        ? 'e'
                        : 'ou'
                    }
                    disabled={true}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    id='txtCampaignName'
                    inputName='campaignName'
                    className={styles.inputs({ input: 'default' })}
                    idError='fdbCampaignNameError'
                    inputType='text'
                    labelName='Levou:'
                    styles={styles}
                    value={formatValue(
                      campaign?.prize?.multipleDiscount?.discountFromCondition
                    )}
                    disabled={true}
                  />
                </Col>
              </>
            )}

            {campaign?.isSegmentationCampaign && (
              <>
                {campaign?.rules.map((item) => (
                  <div className='border p-3 w-full mt-3 mb-2 rounded'>
                    <Row>
                      <Col xs={3}>
                        <Input
                          id='txtSegmentationName'
                          inputName='SegmentationName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbSegmentationNameError'
                          inputType='text'
                          labelName='Segmento:'
                          styles={styles}
                          value={item.segmentation}
                          disabled={true}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='Bateu:'
                          styles={styles}
                          value={formatValue(
                            item?.multipleReachValueCondition?.reachValue
                          )}
                          disabled={true}
                        />
                      </Col>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='&nbsp;'
                          styles={styles}
                          value={
                            item?.multipleReachValueCondition?.condition ===
                              undefined ||
                            item?.multipleReachValueCondition?.condition ===
                              null
                              ? ''
                              : item?.multipleReachValueCondition?.condition ===
                                0
                              ? 'e'
                              : 'ou'
                          }
                          disabled={true}
                        />
                      </Col>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='Bateu:'
                          styles={styles}
                          value={formatValue(
                            item?.multipleReachValueCondition
                              ?.reachValueFromCondition
                          )}
                          disabled={true}
                        />
                      </Col>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='Levou:'
                          styles={styles}
                          value={formatValue(
                            campaign?.prizes.find(
                              (prize) =>
                                prize.segmentation === item.segmentation
                            )?.multipleDiscount?.discount
                          )}
                          disabled={true}
                        />
                      </Col>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='&nbsp;'
                          styles={styles}
                          value={
                            campaign?.prizes.find(
                              (prize) =>
                                prize.segmentation === item.segmentation
                            )?.multipleDiscount?.condition === undefined ||
                            campaign?.prizes.find(
                              (prize) =>
                                prize.segmentation === item.segmentation
                            )?.multipleDiscount?.condition === null
                              ? ''
                              : campaign?.prizes.find(
                                  (prize) =>
                                    prize.segmentation === item.segmentation
                                )?.multipleDiscount?.condition === 0
                              ? 'e'
                              : 'ou'
                          }
                          disabled={true}
                        />
                      </Col>
                      <Col xs={4}>
                        <Input
                          id='txtCampaignName'
                          inputName='campaignName'
                          className={styles.inputs({ input: 'default' })}
                          idError='fdbCampaignNameError'
                          inputType='text'
                          labelName='Levou:'
                          styles={styles}
                          value={formatValue(
                            campaign?.prizes.find(
                              (prize) =>
                                prize.segmentation === item.segmentation
                            )?.multipleDiscount?.discountFromCondition
                          )}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </Row>
    </Box>
  )
}

RulesCampaign.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default RulesCampaign

import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import {
  BadgeComponent, Select, Check, ButtonComponent, Input,
} from 'cashin-components'
import { toast } from 'react-toastify'
import Accordion from 'react-bootstrap/Accordion'
import { CampaignContext } from '../../../../context/campaign-context'
import ImageClip from '../../../../design-system/Styles/img/WhatsApp/Icons/clip.png'
import PreviewEbook from '../../../../design-system/Styles/img/App/icon-pdf.png'
import { truncateText } from '../../../../functions/utils'

const style = {
  fileInputTitle: {
    fontWeight: 'bold',
  },
  fileDropArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    padding: '1rem',
    border: '2px dashed #ccc',
    cursor: 'pointer',
    margin: '20px 0',
    position: 'relative',
  },
  fileDropAreaLabel: {
    marginBottom: '10px',
    display: 'contents',
  },
  fileDropAreaFileIcon: {
    fontSize: '30px',
    marginBottom: '10px',
  },
  fileDropAreaP: {
    fontSize: '12px',
    color: '#888',
  },
  labelFile: {
    textDecoration: 'underline',
    color: '#6300FF',
  },
}

const TrainingEbook = ({ styles, loading, setLoading, setShowTrainingEbook, setChoicesSavedConentEbook }) => {

  const campaignContext = useContext(CampaignContext)

  const [thereIsPrize, setThereIsPrize] = useState('no');
  const [cashins, setCashins] = useState('R$ 0,00');
  const [nameEbook, setNameEbook] = useState('');
  const [sizeEbook, setSizeEbook] = useState('');
  const [titleEbook, setTitleEbook] = useState('');
  const [urlEbook, setUrlEbook] = useState('');
  const [isSavedContent, setIsSavedContent] = useState(false);

  const formatMoney = (value) => {
    const onlyNumbers = value.replace(/[^\d]/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(onlyNumbers / 100);
    return formattedValue;
  };

  const formatNumber = (value) => {
    const newValue = parseFloat(value.replace('R$', '').replace('.', '').replace(',', '.').trim())
    return newValue
  }

  const handleCashinsChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatMoney(value);
    setCashins(formattedValue);
  };

  const validateFields = () => {
    if (thereIsPrize === 'yes' && (cashins === 'R$ 0,00' || cashins === '')) {
      toast.error('O campo quantidade de cashins é obrigatório')
      return false
    }

    if (!titleEbook) {
      toast.error('O campo título do ebook é obrigatório')
      return false
    }

    if (!urlEbook) {
      toast.error('O campo ebook é obrigatório')
      return false
    }

    campaignContext.setTrainingEbookObject({
      name: titleEbook,
      url: urlEbook,
      type: 'ebook',
      points: cashins === 'R$ 0,00' ? 0 : formatNumber(cashins),
    })

    setChoicesSavedConentEbook('published')
    return true
  }

  const handleDeleteEbookModule = () => {
    setShowTrainingEbook(false);
    setTitleEbook('');
    setUrlEbook('');
    setThereIsPrize('no');
    setChoicesSavedConentEbook('none');
  };

  const handleMainEbook = async (event) => {
    const target = event.target || window.event.srcElement,
      files = target.files

    // Verificar se o arquivo tem mais de 100MB
    if (files[0].size > 100 * 10 ** 6) {
      toast.error('O arquivo deve ter no máximo 100MB')
      return
    }

    setLoading(true)

    const fileName = files[0].name
    setNameEbook(fileName)

    const fileSize = files[0].size
    setSizeEbook((fileSize * 10 ** -6).toFixed(2) + 'MB')

    const imageUrl = await campaignContext.uploadImage(files[0])

    if (!imageUrl) {
      toast.error('Erro ao enviar o arquivo')
      setLoading(false)
      return
    }

    setLoading(false)

    setUrlEbook(imageUrl)
  }

  const handleLoadMainEbook = () => {
    let mainBanner = document.getElementById('img-main-ebook')

    mainBanner.click()
  }

  return (
    <Accordion className={styles.customAccordion()} defaultActiveKey='0' style={{
      marginBottom: '2rem',
    }}>
      <Accordion.Item
        eventKey={0}
      >
        <Accordion.Header>
          <div className='headerInfos'>
            <div style={{
              alignContent: "center",
            }}>
              <span
                className={styles.tipography({
                  paragraphs: 'mediumBold',
                })}
              >
                Treinamento em ebook
              </span>
            </div>
            <div className='endInfos' style={{
              gap: 0
            }}>
              <div className='texts'>
                <ButtonComponent
                  className={`
                  ${styles.buttonStandardMedium({
                    medium: 'secondary',
                  })}
                    mb-3 me-3
                  `}
                  hasIcon={true}
                  iconName='delete'
                  text='Excluir módulo'
                  styles={styles}
                  onClick={handleDeleteEbookModule}
                />
              </div>

              <div className='texts'>
                <ButtonComponent
                  className={`
                              ${styles.buttonStandardMedium({
                    medium: 'primary',
                  })}
                              mb-3
                            `}
                  hasIcon={true}
                  iconName='save'
                  text='Salvar'
                  isSuccessButton={isSavedContent}
                  styles={styles}
                  onClick={() => {
                    if (!validateFields()) {
                      return
                    }
                    setIsSavedContent(true)
                    toast.success('Ebook salvo com sucesso')
                  }}
                />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{
          padding: '20px',
        }}>
          <div>
            <div className='texts'>
              <span
                className={styles.tipography({
                  paragraphs: 'mediumBold',
                })}
              >
                Terá premiação de Cashins por visualização?
              </span>
            </div>
            <div className=' my-3 d-flex'>
              <Check
                id='yes'
                name='thereIsPrizeEbook'
                label='Sim'
                type='radio'
                value='yes'
                checked={thereIsPrize === 'yes'}
                disabled={isSavedContent}
                onChange={(e) => {
                  setThereIsPrize(e.target.id)
                }}
                className={`${styles.check({ radio: 'normal' })} me-3`}
              />

              <Check
                id='no'
                name='thereIsPrizeEbook'
                label='Não'
                type='radio'
                value='no'
                checked={thereIsPrize === 'no'}
                disabled={isSavedContent}
                onChange={(e) => {
                  setThereIsPrize(e.target.id)
                }}
                className={`${styles.check({ radio: 'normal' })} me-3`}
              />
            </div>
            {thereIsPrize === 'yes' && (
              <div className='my-3'>
                <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                  Quantos Cashins você quer premiar neste quiz?
                </span>
                <Input
                  id='txtPrize'
                  label='Quantidade de Cashins'
                  placeholder='Insira a quantidade de cashins'
                  styles={styles}
                  className='mt-3'
                  disabled={isSavedContent}
                  value={cashins}
                  onChange={handleCashinsChange}
                  style={{ marginLeft: 0 }}
                />
              </div>
            )}
            <Col xs={12}>
              <hr className='mt-3 mb-4' />
            </Col>
            <div className='my-3'>
              <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                Título do ebook
              </span>
              <Input
                id='txtVideoTitle'
                label='Título do ebook'
                placeholder='Insira o título do ebook'
                styles={styles}
                className='mt-3'
                disabled={isSavedContent}
                style={{ marginLeft: 0 }}
                value={titleEbook}
                onChange={(e) => setTitleEbook(e.target.value)}
                maxLength={30}
              />
            </div>

            {!loading && !urlEbook ? (
              <div className='file-input'>
                <p style={style.fileInputTitle}>
                  Adicione aqui a imagem da sua comunicação.
                </p>
                <div style={style.fileDropArea}>
                  <div
                    className='file-icon'
                    onClick={() => handleLoadMainEbook()}
                  >
                    <img src={ImageClip} alt='Upload' />
                  </div>
                  <label htmlFor='img-main-ebook'>
                    <span style={style.labelFile}>Selecionar o arquivo</span>
                  </label>
                  <p>
                    Formato PDF, DOC, DOCX, PPT, PPTX
                  </p>

                  <input
                    id='img-main-ebook'
                    className='d-none'
                    type='file'
                    accept='.pdf, .doc, .docx, .ppt, .pptx'
                    onChange={(e) => handleMainEbook(e)}
                  />
                </div>
              </div>
            ) : (
              <div className='mt-3'>
                <div className='texts'>
                  <span
                    className={styles.tipography({
                      paragraphs: 'mediumBold',
                    })}
                  >
                    Ebook enviado:
                  </span>
                </div>
                <div className='my-3 d-flex'>
                  {loading ? (
                    <div className='d-flex justify-content-center' style={{
                      marginLeft: "3rem"
                    }}>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <img src={PreviewEbook} alt='Ebook' style={{
                      height: "80px",
                      width: "auto",
                      objectFit: "contain",
                      marginRight: "3rem"
                    }} />
                  )}

                  <div style={{ alignContent: "center", marginLeft: "auto", marginRight: "auto", width: 900 }}>
                    <div className='texts' style={{
                      display: 'flex',
                      flexDirection: 'row',

                      gap: "3rem",
                    }}>
                      <div className='texts' style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                        <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                          Nome: {truncateText(nameEbook, 60)}
                        </span>
                        <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                          Tamanho do arquivo: {sizeEbook}
                        </span>
                        <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                          Data de envio: {new Date().toLocaleDateString()}
                        </span>
                      </div>

                      <ButtonComponent
                        className={`
                        ${styles.buttonStandardMedium({
                          medium: 'secondary',
                        })}
                          mb-3 me-3
                        `}
                        hasIcon={true}
                        disabled={isSavedContent}
                        iconName='delete'
                        text='Excluir ebook'
                        styles={styles}
                        style={{ height: 44 }}
                        onClick={() => setUrlEbook('')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

TrainingEbook.propTypes = {
  styles: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setShowTrainingVideo: PropTypes.func,
}

export default TrainingEbook
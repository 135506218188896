import React from 'react'
import EmailSelector from './selector-content/email'
import WhatsappSelector from './selector-content/whatsapp'
import AppSelector from './selector-content/app'

const Selector = ({ campaign, setIndex, index }) => {
  return (
    <div className='bg-white p-3 rounded'>
      {!campaign.whatsAppType && !campaign.isApp && (
        <EmailSelector campaign={campaign} setIndex={setIndex} />
      )}
      {campaign.whatsAppType && (
        <WhatsappSelector
          campaign={campaign}
          setIndex={setIndex}
          index={index}
        />
      )}
      {campaign.isApp && (
        <AppSelector campaign={campaign} setIndex={setIndex} index={index} />
      )}
    </div>
  )
}

export default Selector

import { keyframes } from '@stitches/react';
import { css } from './CreateStitches';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const loading = css({
  variants: {
    loadingAnimation: {
      high: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthThick',
        borderColor: 'rgba(50, 50, 50, 0.25)',
        borderTopStyle: 'solid',
        borderTopWidth: '$borderWidthThick',
        borderTopColor: 'rgba(0, 0, 0, 0.45)',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        animation: `${spin} 2s linear infinite`,
      },
      medium: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthThick',
        borderColor: 'rgba(50, 50, 50, 0.25)',
        borderTopStyle: 'solid',
        borderTopWidth: '$borderWidthThick',
        borderTopColor: 'rgba(0, 0, 0, 0.45)',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        animation: `${spin} 2s linear infinite`,
      },
      low: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthThin',
        borderColor: 'rgba(50, 50, 50, 0.25)',
        borderTopStyle: 'solid',
        borderTopWidth: '$borderWidthThin',
        borderTopColor: 'rgba(0, 0, 0, 0.45)',
        borderRadius: '50%',
        width: '16px',
        height: '16px',
        animation: `${spin} 2s linear infinite`,
      },
    },
  },
});

import { Col, Offcanvas, Row } from "react-bootstrap";
import "./DrawerFilter.css";
import { Input } from 'cashin-components'

export const DrawerFilter = ({
  show,
  onHide,
  styles,
  dateRange,
  setDateRange,
  children,
  onCancel,
  onApply,
  onCleanFilter,
}) => {
  return (
    <div>
      <Offcanvas
        show={show}
        onHide={onHide}
        placement="end"
        keyboard={false}
        className="drawer-filter no-backdrop-shadow"
        backdropClassName="drawer-filter-backdrop"
      >
        <Offcanvas.Header>
          <Offcanvas.Title className={styles.tipography({ headings: 'xsmallBold' })}>
            Filtrar por período
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        {children}
          <Row style={{ padding: 0, margin: 0 }}>
            <Col
              xs={10}
              className={`${styles.inputs({ datePicker: 'datePickerDefault' })}`}
              style={{ padding: 0, margin: 0 }}
            >
              <Input
                id='txtDateRange'
                idError='fdbDateRangeCampaign'
                labelName='Período'
                styles={styles}
                isDatePicker={true}
                required={false}
                isInvalid={false}
                selectsRange={true}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                monthsShown={2}
                setDateRange={setDateRange}
              />
            </Col>
          </Row>
          <div className="offcanvas-footer">
          <Row>
              <Col xs={9} className="d-flex">
                <button className="drawer-filter-cancel-button" onClick={onCancel}>
                  Cancelar
                </button>
                <button className="drawer-filter-apply-button" onClick={onApply}>
                  Aplicar Filtro
                </button>
                <button className="drawer-filter-clean-filter-button" onClick={onCleanFilter}>
                  Limpar Filtro
                </button>
              </Col>
          </Row>
         </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

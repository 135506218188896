import { format } from 'date-fns'

export const dateMaskWithSlash = 'dd/MM/yyyy'
export const dateMaskWithDash = 'dd-MM-yyyy'
export const formatDate = (date, mask = dateMaskWithSlash) => {
  return format(new Date(date), mask)
}

export const formatIntervalString = (to, from) => {
  const formattedTo = format(new Date(to), dateMaskWithSlash)
  const formattedFrom = format(new Date(from), dateMaskWithSlash)
  return `${formattedFrom} - ${formattedTo}`
}

export const formatDateWithHours = (date) => {
  return format(new Date(date), "dd/MM/yyyy 'às' HH:mm")
}

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row } from 'react-bootstrap';
import { ButtonComponent } from 'cashin-components';

const DeleteParticipantModal = ({ styles, size, show, onDelete, onHide }) => {
  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={styles.modal()}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body>
        <h1
          className={`
            ${styles.tipography({ headings: 'xsmallBold' })}
            mb-4 text-center
          `}
        >
          Deseja excluir o participante?
        </h1>
        <p className="text-center m-0">Você não poderá recupera-la depois.</p>
      </Modal.Body>
      <Modal.Footer className={styles.modal({ button: 'deleteButtons' })}>
        <Row>
          <Col xs={6}>
            <ButtonComponent
              className={`
                ${styles.buttonStandardLarge({
                  large: 'primary',
                })}
                w-100 btn-delete
              `}
              text="Sim, excluir"
              styles={styles}
              onClick={onDelete}
            />
          </Col>
          <Col xs={6}>
            <ButtonComponent
              className={`
                ${styles.buttonStandardLarge({
                  large: 'primary',
                })}
                w-100 btn-cancel
              `}
              text="Não"
              styles={styles}
              onClick={onHide}
            />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

DeleteParticipantModal.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default DeleteParticipantModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'cashin-components';
import { Col, Row } from 'react-bootstrap';

const Batch = ({ styles, state }) => {
  return (
    <Row>
      <Col xs={12}>
        <div
          className={`
            ${styles.inputs({ inputFile: 'inputFileBatch' })}
            mt-3
          `}
        >
          <Input
            styles={styles}
            isFileInput={true}
            isFileBatch={true}
            isEmptyFile={true}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            // onChange={(e) => handleUploadFinalChange(e)}
            // deleteFile={() => handleDeleteFinal()}
            // files={state.selectedFileFinal}
          />
        </div>
      </Col>
    </Row>
  );
};

Batch.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
};

export default Batch;

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ForgotPasswordPage from "./forgot-password-page";
import { CognitoContext } from "../context/congnito-context";
import qs from "qs";

const defaultState = {
  emailValue: "",
  obfuscatedEmailValue: "",
  emailError: "",
  hasIcon: true,
  isInvalidEmail: false,
  isLoading: false,
  forgotPasswordForm: true,
  forgotPasswordFormConfirmation: false,
};

const simulateNetworkRequest = () => {
  return new Promise((res) => setTimeout(res, 1000));
};

const ForgotPasswordConfirm = ({ styles, history }) => {
  const cognitoContext = useContext(CognitoContext);
  const [ownState, setOwnState] = useState(defaultState);
  const [username, setUsername] = useState("");

  const handleChange = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    });
  };

  const handleKeyDown = (key) => {
    if (key === "Enter") {
      handleRecoverPassword();
    }
  };

  const obfuscatedEmail = (email) => {
    const [name, domain] = email && email.split("@");

    const obfuscation = `${name && name.substring(0, 3)}${new Array(
      name && name.length
    ).join("*")}@${domain && domain.substring(0, 1)}${new Array(
      domain && domain.length
    ).join("*")}${domain && domain.substring(domain && domain.length - 8)}`;

    window.localStorage.setItem("obfuscatedEmail", obfuscation);

    setOwnState({
      ...ownState,
      obfuscatedEmailValue: obfuscation,
    });
  };

  const handleRecoverPassword = async () => {
    const code = qs.parse(window.location.search.replace("?", ""))?.code;

    await cognitoContext.confirmForgotPassword(
      username,
      code,
      ownState.emailValue
    );
    setOwnState({
      ...ownState,
      isInvalidEmail: false,
      emailError: "",
      hasIcon: false,
      isLoading: true,
    });
  };

  const handleBackToLogin = () => {
    history.push("/login");
    history.go("/login");
  };

  useEffect(() => {
    ownState.isLoading &&
      simulateNetworkRequest().then(() => {
        setOwnState({
          ...ownState,
          hasIcon: true,
          isLoading: false,
          forgotPasswordForm: false,
        });
      });

    obfuscatedEmail(ownState.emailValue);
  }, [ownState.isLoading]);

  return (
    <ForgotPasswordPage
      styles={styles}
      state={ownState}
      handleKeyDown={handleKeyDown}
      handleChange={handleChange}
      handleRecoverPassword={handleRecoverPassword}
      handleBackToLogin={handleBackToLogin}
      setUsername={setUsername}
    />
  );
};

ForgotPasswordConfirm.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default ForgotPasswordConfirm;

import { css } from './CreateStitches';

export const shadow = css({
  variants: {
    sizeBG: {
      layerSize: {
        width: 104,
        height: 104,
        borderRadius: '$borderRadiusSM',
      },
    },
    shadowLevel: {
      level1: {
        boxShadow: '$shadowLevel1',
      },
      level2: {
        boxShadow: '$shadowLevel2',
      },
      level3: {
        boxShadow: '$shadowLevel3',
      },
      level4: {
        boxShadow: '$shadowLevel4',
      },
    },
  },
});

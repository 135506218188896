import { css } from './CreateStitches'

export const progressBar = css({
  height: 16,
  borderRadius: 50,
  backgroundColor: '$neutralColorHighMedium',

  variants: {
    progressBar: {
      default: {
        '.progress-bar': {
          borderRadius: 50,
          backgroundColor: '$brandColorSecondaryMedium',
          fontFamily: '$fontFamilyHighBaseBold',
          fontSize: '$fontSizeXXS',
          textAlign: 'left',
          paddingLeft: '$spacingNano',
          paddingRight: '$spacingNano',
        },
      },
      primary: {
        '.progress-bar': {
          borderRadius: 50,
          backgroundColor: '$brandColorPrimaryPure',
          fontFamily: '$fontFamilyHighBaseBold',
          fontSize: '$fontSizeXXS',
          textAlign: 'left',
          paddingLeft: '$spacingNano',
          paddingRight: '$spacingNano',
        },
      },
    },
  },
})

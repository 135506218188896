import { css } from './CreateStitches';

export const colors = css({
  variants: {
    exampleSpacing: {
      colorArea: {
        width: '100%',
        height: '$xxl',
        marginTop: 16,
        marginBottom: 16,
      },
    },
    bgColors: {
      primaryPure: {
        backgroundColor: '$brandColorPrimaryPure',
      },
      primaryLight: {
        backgroundColor: '$brandColorPrimaryLight',
      },
      primaryMedium: {
        backgroundColor: '$brandColorPrimaryMedium',
      },
      primaryDark: {
        backgroundColor: '$brandColorPrimaryDark',
      },

      secondaryPure: {
        backgroundColor: '$brandColorSecondaryPure',
      },
      secondaryLight: {
        backgroundColor: '$brandColorSecondaryLight',
      },
      secondaryMedium: {
        backgroundColor: '$brandColorSecondaryMedium',
      },
      secondaryDark: {
        backgroundColor: '$brandColorSecondaryDark',
      },

      tertiaryPure: {
        backgroundColor: '$brandColorTertiaryPure',
      },
      tertiaryLight: {
        backgroundColor: '$brandColorTertiaryLight',
      },
      tertiaryMedium: {
        backgroundColor: '$brandColorTertiaryMedium',
      },
      tertiaryDark: {
        backgroundColor: '$brandColorTertiaryDark',
      },

      highlightColorPure: {
        backgroundColor: '$highlightColorPure',
      },
      highlightColorLight: {
        backgroundColor: '$highlightColorLight',
      },
      highlightColorMedium: {
        backgroundColor: '$highlightColorMedium',
      },
      highlightColorDark: {
        backgroundColor: '$highlightColorDark',
      },

      neutralColorLowPure: {
        backgroundColor: '$neutralColorLowPure',
      },
      neutralColorLowLight: {
        backgroundColor: '$neutralColorLowLight',
      },
      neutralColorLowMedium: {
        backgroundColor: '$neutralColorLowMedium',
      },
      neutralColorLowDark: {
        backgroundColor: '$neutralColorLowDark',
      },

      neutralColorHighPure: {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthThick',
        borderColor: '$neutralColorHighMedium',
      },
      neutralColorHighLight: {
        backgroundColor: '$neutralColorHighLight',
      },
      neutralColorHighMedium: {
        backgroundColor: '$neutralColorHighMedium',
      },
      neutralColorHighDark: {
        backgroundColor: '$neutralColorHighDark',
      },

      feedbackColorWarningPure: {
        backgroundColor: 'blue',
      },
      feedbackColorWarningLight: {
        backgroundColor: '$feedbackColorWarningLight',
      },
      feedbackColorWarningMedium: {
        backgroundColor: '$feedbackColorWarningMedium',
      },
      feedbackColorWarningDark: {
        backgroundColor: '$feedbackColorWarningDark',
      },

      feedbackColorHelperPure: {
        backgroundColor: '$feedbackColorHelperPure',
      },
      feedbackColorHelperLight: {
        backgroundColor: '$feedbackColorHelperLight',
      },
      feedbackColorHelperMedium: {
        backgroundColor: '$feedbackColorHelperMedium',
      },
      feedbackColorHelperDark: {
        backgroundColor: '$feedbackColorHelperDark',
      },

      feedbackColorSuccessPure: {
        backgroundColor: '$feedbackColorSuccessPure',
      },
      feedbackColorSuccessLight: {
        backgroundColor: '$feedbackColorSuccessLight',
      },
      feedbackColorSuccessMedium: {
        backgroundColor: '$feedbackColorSuccessMedium',
      },
      feedbackColorSuccessDark: {
        backgroundColor: '$feedbackColorSuccessDark',
      },
    },
    colors: {
      primaryPure: {
        color: '$brandColorPrimaryPure',
      },
      primaryLight: {
        color: '$brandColorPrimaryLight',
      },
      primaryMedium: {
        color: '$brandColorPrimaryMedium',
      },
      primaryDark: {
        color: '$brandColorPrimaryDark',
      },

      secondaryPure: {
        color: '$brandColorSecondaryPure',
      },
      secondaryLight: {
        color: '$brandColorSecondaryLight',
      },
      secondaryMedium: {
        color: '$brandColorSecondaryMedium',
      },
      secondaryDark: {
        color: '$brandColorSecondaryDark',
      },

      tertiaryPure: {
        color: '$brandColorTertiaryPure',
      },
      tertiaryLight: {
        color: '$brandColorTertiaryLight',
      },
      tertiaryMedium: {
        color: '$brandColorTertiaryMedium',
      },
      tertiaryDark: {
        color: '$brandColorTertiaryDark',
      },

      highlightColorPure: {
        color: '$highlightColorPure',
      },
      highlightColorLight: {
        color: '$highlightColorLight',
      },
      highlightColorMedium: {
        color: '$highlightColorMedium',
      },
      highlightColorDark: {
        color: '$highlightColorDark',
      },

      neutralColorLowPure: {
        color: '$neutralColorLowPure',
      },
      neutralColorLowLight: {
        color: '$neutralColorLowLight',
      },
      neutralColorLowMedium: {
        color: '$neutralColorLowMedium',
      },
      neutralColorLowDark: {
        color: '$neutralColorLowDark',
      },

      neutralColorHighPure: {
        color: '$neutralColorHighPure',
      },
      neutralColorHighLight: {
        color: '$neutralColorHighLight',
      },
      neutralColorHighMedium: {
        color: '$neutralColorHighMedium',
      },
      neutralColorHighDark: {
        color: '$neutralColorHighDark',
      },

      feedbackColorWarningPure: {
        color: 'blue',
      },
      feedbackColorWarningLight: {
        color: '$feedbackColorWarningLight',
      },
      feedbackColorWarningMedium: {
        color: '$feedbackColorWarningMedium',
      },
      feedbackColorWarningDark: {
        color: '$feedbackColorWarningDark',
      },

      feedbackColorHelperPure: {
        color: '$feedbackColorHelperPure',
      },
      feedbackColorHelperLight: {
        color: '$feedbackColorHelperLight',
      },
      feedbackColorHelperMedium: {
        color: '$feedbackColorHelperMedium',
      },
      feedbackColorHelperDark: {
        color: '$feedbackColorHelperDark',
      },

      feedbackColorSuccessPure: {
        color: '$feedbackColorSuccessPure',
      },
      feedbackColorSuccessLight: {
        color: '$feedbackColorSuccessLight',
      },
      feedbackColorSuccessMedium: {
        color: '$feedbackColorSuccessMedium',
      },
      feedbackColorSuccessDark: {
        color: '$feedbackColorSuccessDark',
      },
    },
  },
});

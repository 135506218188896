import React from 'react'
import FolderWhatsappPreview from '../../../create-campaign/steps/choose-layout/layout-whatsapp/folder-whatsapp-preview'

import { styles } from 'cashin-design-system/Styles/Styles'

const WhatsappPreview = ({ campaign, index }) => {
  const campaignSelected = campaign?.campaignDetails[index]

  return (
    <div>
      <FolderWhatsappPreview
        titleComunication={campaignSelected.title || ''}
        introductionComunication={campaignSelected.introduction || ''}
        descriptionComunication={campaignSelected.description || ''}
        attachmentFilePath={campaignSelected.attachmentFilePath}
        description
        styles={styles}
      />
    </div>
  )
}

export default WhatsappPreview

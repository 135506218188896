import { apiv2 } from "../apiv2";

export const getGeneralReport = async (tenantId, name, page = 1) => {
  return await apiv2.get(`/general-report/${tenantId}`, {
    params: {
      page: page,
      ...(name && { name }),
    }
  });
}

export const getGeneralReportExport = async (tenantId, name, page = 1) => {
  return await apiv2.get(`/general-report/${tenantId}/export`, {
    params: {
      page: page,
      ...(name && { name }),
    }
  });
}

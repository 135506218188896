import { useQuery } from "react-query";
import api from "../../api";

const useCompanyNegotiationUsageData = (
  companyId, 
) => {
  
  return useQuery(
    ["company-negotiation-data", companyId],
    async () => api.get(`/company-negotiation-usage/company/${companyId}`),
    {
      cacheTime: 60000 * 2,
      enabled: !!companyId, 
    }
  );
};

export default useCompanyNegotiationUsageData;

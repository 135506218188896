import { styles } from "cashin-design-system/Styles/Styles";
import { Card, ProgressBar } from "react-bootstrap";
import "./LabeledProgressBar.css";

export const LabeledProgressBar = ({ label, progress }) => {
  return (
    <Card className="labeled-progress-bar-card">
      <p className={`
        ${styles.tipography({ paragraphs: 'smallBold' })}       
        ${styles.colors({ colors: 'neutralColorLowDark' })}
        mb-1    
      `}
        style={{ fontWeight: 'bold' }}
      >
        {label || 0}
      </p>
      <ProgressBar className={
        `${styles.progressBar({progressBar: 'default'})} labeled-progress-bar`
      } now={progress || 0}/>
    </Card>
  );
};
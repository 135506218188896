import React from 'react'

const EmailPreview = ({ campaign, index }) => {
  const campaignSelected = campaign?.schedule?.campaign[index]

  return (
    <div dangerouslySetInnerHTML={{ __html: campaignSelected?.html }}></div>
  )
}

export default EmailPreview

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import whatsappLayoutImage from "cashin-design-system/Styles/img/ExampleLayout/exampleLayout5.png";
import appLayoutImage from "cashin-design-system/Styles/img/ExampleLayout/appLayout.png"
import { Input, Check } from "cashin-components";
import dayjs from "dayjs";
import {Box} from "../../Components";

const tagStyle = {
  display: 'inline-block',
  padding: '5px 10px',
  alignItems: 'center',
  border: '1px solid #CCCCCC',
  borderRadius: '15px',
  position: 'relative',
  marginRight: '5px',
};

const SummaryCampaign = ({ styles, state, campaign }) => {
  const [layoutSelected, setLayoutSelected] = useState("")

  const handleSelectedLayoutImage = () => {
    if (layoutSelected === "app") {
      return appLayoutImage
    } else if (layoutSelected === "email") {
      return campaign?.notificationLayout?.notificationDispatches?.[0]?.imageUrl
    }
    else {
      return whatsappLayoutImage
    }
  }

  const handleSelectedCheck = () => {
    if (campaign?.isApp) {
      setLayoutSelected("app")
    } else if (campaign?.notificationLayout?.notificationDispatches?.[0]?.imageUrl) {
      setLayoutSelected("email")
    }
    else {
      setLayoutSelected("whatsapp")
    }
  }

  useEffect(() => {
    handleSelectedCheck()
  }, [campaign, layoutSelected, setLayoutSelected])

  return (
    <Box>
      <Row>
        <Col xs={"auto"}>
          <img
            src={handleSelectedLayoutImage()}
            height={appLayoutImage ? "auto" : 150}
            width={150}
            className={styles.shadow({ shadowLevel: "level1" })}
            alt="Layout escolhido para sua campanha: Executivo"
          />
        </Col>
        <Col xs>
          <Row>
            <Col xs={6}>
              <Input
                id="txtCampaignName"
                className={styles.inputs({ input: "default" })}
                inputType="text"
                labelName="Nome da campanha"
                styles={styles}
                value={campaign?.name}
                disabled={true}
              />
            </Col>
            <Col
              xs={6}
              className={`
                ${styles.inputs({ datePicker: "datePickerDefault" })}
                d-flex align-items-end
              `}
            >
              <Input
                id="txtDateRangeCampaign"
                labelName="Período da campanha"
                styles={styles}
                isDatePicker={true}
                placeholder={`${dayjs(campaign?.periodDate?.from).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(campaign?.periodDate?.to).format("DD/MM/YYYY")}`}
                disabled={true}
              />
            </Col>
            <Col xs={6}>
              <Input
                id="txtRule"
                className={styles.inputs({ input: "default" })}
                inputType="text"
                labelName="Regra"
                styles={styles}
                value={campaign?.rule?.ruleType === 0 ? "Meta financeira"
                  : campaign?.rule?.ruleType === 1 ? "Produto ou serviço"
                    : "Híbrido"}
                disabled={true}
              />
            </Col>
            <Col xs={6}>
              <Input
                id="txtParticipants"
                className={styles.inputs({ input: "default" })}
                inputType="text"
                labelName="Participantes"
                styles={styles}
                value={campaign?.participant?.totalParticipants}
                disabled={true}
              />
            </Col>
            <Col xs={12} className="mb-3 pb-3">
              <h3
                className={`
                  ${styles.tipography({ paragraphs: "largeBold" })}
                  ${styles.colors({ colors: "neutralColorLowLight" })}
                `}
              >
                Plataforma de comunicação
              </h3>
              <div className="form-control mt-3 p-4 d-flex">
                <Check
                  id="swtEmailComunication"
                  label="E-mail"
                  type="switch"
                  className={`
                    ${styles.check({ switch: "normal" })}
                    ps-0 me-3
                  `}
                  checked={layoutSelected === "email"}
                  disabled
                />
                <Check
                  id="swtWhatsAppComunication"
                  label="WhatsApp"
                  type="switch"
                  className={`
                    ${styles.check({ switch: "normal" })}
                    ps-0 me-3
                  `}
                  checked={layoutSelected === "whatsapp"}
                  disabled
                />
                <Check
                  id="swtAppComunication"
                  label="App"
                  type="switch"
                  className={`
                    ${styles.check({ switch: "normal" })}
                    ps-0 me-3
                  `}
                  checked={layoutSelected === "app"}
                  disabled
                />
              </div>
            </Col>
          </Row>
          {(campaign?.segmentations?.length > 0) && (
            <Row>
              <Col xs={12} className='mb-3 pb-3'>
                <Row>
                  <h3
                    className={`
                      ${styles.tipography({ paragraphs: 'largeBold' })}
                      ${styles.colors({ colors: 'neutralColorLowLight' })}
                    `}
                  >
                    Segmentos cadastrados
                  </h3>
                  <div className='form-control p-3 d-flex'>
                    {campaign?.segmentations.map((segmentName, index) => (
                      <div key={index} style={tagStyle} className="mr-2 d-inline-flex align-items-center">
                        <span style={{ fontWeight: 'bold', marginLeft: '5px', color: "#666666" }}>{segmentName}</span>
                      </div>
                    ))}
                  </div>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Box>
  );
};

SummaryCampaign.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
};

export default SummaryCampaign;

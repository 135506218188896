import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomersPage from './customers-page';
import API from '../API/api';

const defaultState = {
  valueStatusOptions: [
    {
      value: 1,
      label: 'Todos',
    },
  ],
  breadcrumb: [
    {
      isActive: true,
      itemName: 'Clientes',
    },
  ],
  tableHeader: [
    {
      headerTitle: 'Cliente',
    },
    {
      headerTitle: 'Status',
    },
    {
      headerTitle: 'Data de cadastro',
    },
    {
      headerTitle: 'Ações',
    },
  ],
  dataSet: [],
};

const Customers = ({ styles, history, isAdmin }) => {
  const [ownState, setOwnState] = useState(defaultState);

  const handleEdit = (id) => {
    history.push(`/edit-customer/${id}`);
    history.go(`/edit-customer/${id}`);
  };

  const getPosts = async () => {
    // const url = 'customers.json';
    // const obj = {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    // };
    // await API.get(`/${url}`, obj)
    //   .then((res) => {
    //     setOwnState({
    //       ...ownState,
    //       dataSet: res.data,
    //     });
    //   })
    //   .catch((error) => {
    //     console.warn(error);
    //   });
  };

  const handleCreateCustomer = () => {
    history.push('/create-customer');
    history.go('/create-customer');
  };

  useEffect(() => {
    if (isAdmin) {
      getPosts();
    } else {
      history.push('/not-allowed');
      history.go('/not-allowed');
    }
  }, []);

  return (
    <CustomersPage
      styles={styles}
      state={ownState}
      handleEdit={handleEdit}
      handleCreateCustomer={handleCreateCustomer}
    />
  );
};

Customers.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default Customers;

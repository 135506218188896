import { css } from './CreateStitches';

export const buttonSquareLargeOnColor = css({
  fontFamily: '$fontFamilyHighBaseBold',
  border: 'none',
  borderRadius: '$borderRadiusCircular',
  textAlign: 'center',
  borderStyle: 'solid',
  borderWidth: '$borderWidthThin',

  '.icon-button': {
    justifySelf: 'start',
  },

  '.text-button-icon': {
    justifySelf: 'center',
    position: 'absolute',
  },

  '&.btn-layout-customizer': {
    display: 'unset !important',

    span: {
      display: 'block',
    },
  },

  variants: {
    large: {
      primary: {
        backgroundColor: '$brandColorSecondaryMedium',
        borderColor: '$brandColorSecondaryMedium',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorTertiaryPure',
          borderColor: '$brandColorTertiaryPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowMedium',
          color: '$neutralColorLowLight',
        },
      },
      secondary: {
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorLowMedium',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowMedium',
          color: '$neutralColorLowLight',
        },
      },
      ghost: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG !important',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorLowDark',
          border: '$neutralColorLowDark',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          border: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          border: '$neutralColorLowDark',
          color: '$neutralColorLowLight',
        },
      },
      iconGhost: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          backgroundColor: '$neutralColorLowDark',
          border: '$neutralColorLowDark',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          border: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          border: '$neutralColorLowDark',
          color: '$neutralColorLowLight',
        },
      },
      primaryHover: {
        backgroundColor: '$brandColorTertiaryPure',
        borderColor: '$brandColorTertiaryPure',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorTertiaryPure',
          borderColor: '$brandColorTertiaryPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowMedium',
          color: '$neutralColorLowLight',
        },
      },
      secondaryHover: {
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorHighPure',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowMedium',
          color: '$neutralColorLowLight',
        },
      },
      ghostHover: {
        backgroundColor: '$neutralColorLowDark',
        border: '$neutralColorLowDark',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorLowDark',
          border: '$neutralColorLowDark',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          border: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
      },
      ghostIconHover: {
        backgroundColor: '$neutralColorLowDark',
        border: '$neutralColorLowDark',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          backgroundColor: '$neutralColorLowDark',
          border: '$neutralColorLowDark',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          border: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
      },
      primaryFocus: {
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorHighPure',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorTertiaryPure',
          borderColor: '$brandColorTertiaryPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowMedium',
          color: '$neutralColorLowLight',
        },
      },
      secondaryFocus: {
        backgroundColor: '$neutralColorHighPure',
        borderColor: '$neutralColorHighPure',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowMedium',
          color: '$neutralColorLowLight',
        },
      },
      ghostFocus: {
        backgroundColor: '$neutralColorHighPure',
        border: '$neutralColorHighPure',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorLowDark',
          border: '$neutralColorLowDark',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          border: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
      },
      ghostIconFocus: {
        backgroundColor: '$neutralColorHighPure',
        border: '$neutralColorHighPure',
        padding: 0,
        width: 48,
        height: 48,
        fontSize: '$fontSizeLG',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          backgroundColor: '$neutralColorLowDark',
          border: '$neutralColorLowDark',
          color: '$neutralColorHighPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          border: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
      },
      primaryLoading: {
        backgroundColor: '$brandColorSecondaryMedium',
        borderColor: '$brandColorSecondaryMedium',
        padding: 0,
        width: 48,
        height: 48,

        '&:hover': {
          backgroundColor: '$brandColorTertiaryPure',
          borderColor: '$brandColorTertiaryPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorLowPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowMedium',
          color: '$neutralColorLowLight',
        },

        '& div': {
          width: 24,
          height: 24,
        },
      },
      secondaryLoading: {
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorLowMedium',
        padding: 0,
        width: 48,
        height: 48,

        '&:hover': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorHighPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowMedium',
          color: '$neutralColorLowLight',
        },

        '& div': {
          width: 24,
          height: 24,
          borderColor: 'rgba(255, 255, 255, 0.25)',
          borderTopColor: 'rgba(255, 255, 255, 0.65)',
        },
      },
      ghostLoading: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: 0,
        width: 48,
        height: 48,

        '&:hover': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowDark',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowDark',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorLowDark',
          borderColor: '$neutralColorLowDark',
        },

        '& div': {
          width: 24,
          height: 24,
          borderColor: 'rgba(255, 255, 255, 0.25)',
          borderTopColor: 'rgba(255, 255, 255, 0.65)',
        },
      },
    },
  },
});

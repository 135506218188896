import { Modal, Carousel, Overlay, Tooltip } from 'react-bootstrap'
import { useState, useContext, useRef } from 'react'
import { CampaignContext } from '../../context/campaign-context'
import { ButtonComponent, Icon } from 'cashin-components'
import { toast } from 'react-toastify'

const ModalApp = ({ styles, show, onHide, next, state }) => {
  const [selected, setSelected] = useState('')
  const [imgLogo, setImgLogo] = useState(null)
  const [imgs, setImgs] = useState([])
  const [showToltip, setShow] = useState(false)
  const target = useRef(null)

  const campaignContext = useContext(CampaignContext)

  const handleLogoUpload = async (event) => {
    const target = event.target || window.event.srcElement,
      files = target.files

    const validTypes = ['image/jpeg', 'image/png', 'image/jpg']

    for (let i = 0; i < files.length; i++) {
      if (!validTypes.includes(files[i].type)) {
        toast.error(
          `Tipo de arquivo ${files[i].type} não é permitido. Apenas JPG, PNG, e JPGHE são aceitos.`
        )
        return
      }
    }

    if (files[0].size > 1 * 10 ** 6) {
      toast.error('O arquivo deve ter no máximo 1MB')
      return
    }

    const imageUrl = await campaignContext.uploadImage(files[0])

    setImgLogo(imageUrl)
  }

  const handleImgsUpload = async (event) => {
    const target = event.target || window.event.srcElement,
      files = target.files

    const validTypes = ['image/png', 'image/jpeg', 'video/mp4']
    const videoExtensions = ['mp4', 'avi', 'mov', 'mkv']
    let imageCount = 0
    let videoCount = 0

    for (let i = 0; i < files.length; i++) {
      if (!validTypes.includes(files[i].type)) {
        toast(`Tipo de arquivo ${files[i].type} não é permitido.`, {
          type: 'error',
        })
        return
      }
    }

    imgs.forEach((img) => {
      if (videoExtensions.some((ext) => img.endsWith(ext))) {
        videoCount++
      } else {
        imageCount++
      }
    })

    if (
      imageCount >= 5 &&
      !videoExtensions.some((ext) => files[0].name.endsWith(ext))
    ) {
      toast('Você não pode enviar mais de 5 imagens.', { type: 'error' })
      return
    }

    if (
      videoCount >= 1 &&
      videoExtensions.some((ext) => files[0].name.endsWith(ext))
    ) {
      toast('Você não pode enviar mais de 1 vídeo.', { type: 'error' })
      return
    }

    const imageUrl = await campaignContext.uploadImage(files[0])

    setImgs([...imgs, imageUrl])
  }

  const handleSave = async () => {
    await campaignContext.uploadLayoutFiles(
      state.layoutTitleChoosen || '',
      'Test',
      campaignContext.termsOfService?.fileUrl || '',
      campaignContext.campaignState?.site || '',
      '',
      campaignContext.layoutInfos?.baseColor || '',
      campaignContext.layoutInfos?.footerColor || '',
      campaignContext.layoutInfos?.logoHeader || '',
      campaignContext.layoutInfos?.mainBanner1 || ''
    )

    campaignContext.setCampaignState((currentState) => ({
      ...currentState,
      imgs,
      logo: imgLogo,
      aspectRatio: selected,
    }))

    next()
  }

  return (
    <Modal
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={`
    ${styles.modal()}
    modal-layout-edit
  `}
    >
      <Modal.Header closeButton className='header-layout-edit'>
        <Modal.Title id='contained-modal-title-vcenter' className='w-100'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: 20,
            }}
          >
            <div style={{ display: 'flex' }}>
              <h2
                style={{
                  borderRight: '1px solid #e1e1e1',
                  paddingRight: 10,
                  marginRight: 10,
                }}
              >
                App Engaje
              </h2>
              <b>Configure as mídias da sua campanha</b>
            </div>

            <ButtonComponent
              className={`
                    ${styles.buttonStandardMedium({
                      medium: 'primary',
                    })}
                  `}
              disabled={!imgLogo || !selected || imgs.length === 0}
              hasIcon={true}
              iconName='save'
              text={
                campaignContext.isLoadingLayoutUpload
                  ? 'Carregando...'
                  : 'Salvar e continuar'
              }
              styles={styles}
              onClick={handleSave}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='content-modal-app'>
          <div>
            <div className='item'>
              <span>1</span>
              Logo da campanha
            </div>
            <div className='file-input'>
              <p className='file-input-title'>Adicione aqui a imagem.</p>
              <div className='file-drop-area'>
                <div
                  className='file-icon'
                  onClick={() => {
                    let mainBanner = document.getElementById('img-main-logo')

                    mainBanner.click()
                  }}
                >
                  <svg
                    width='19'
                    height='38'
                    viewBox='0 0 19 38'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.5 10.2499V27.8833C16.5 31.3666 13.95 34.4666 10.4833 34.7999C6.49996 35.1833 3.16663 32.0666 3.16663 28.1666V7.56658C3.16663 5.38325 4.73329 3.39992 6.89996 3.18325C9.39996 2.93325 11.5 4.88325 11.5 7.33325V24.8333C11.5 25.7499 10.75 26.4999 9.83329 26.4999C8.91663 26.4999 8.16663 25.7499 8.16663 24.8333V10.2499C8.16663 9.56658 7.59996 8.99992 6.91663 8.99992C6.23329 8.99992 5.66663 9.56658 5.66663 10.2499V24.5999C5.66663 26.7832 7.23329 28.7666 9.39996 28.9833C11.9 29.2333 14 27.2833 14 24.8333V7.61658C14 4.13325 11.45 1.03325 7.98329 0.699918C4.01663 0.316585 0.666626 3.43325 0.666626 7.33325V27.7833C0.666626 32.5666 4.16663 36.8499 8.93329 37.2999C14.4166 37.7999 19 33.5333 19 28.1666V10.2499C19 9.56658 18.4333 8.99992 17.75 8.99992C17.0666 8.99992 16.5 9.56658 16.5 10.2499Z'
                      fill='#6300FF'
                    />
                  </svg>
                </div>
                <label htmlFor='img-main-logo'>
                  <span className='label-file'>Selecionar o arquivo</span>
                  Formato PNG, JPG com até 1MB
                </label>

                <input
                  id='img-main-logo'
                  className='d-none'
                  type='file'
                  accept='image/png, image/jpeg'
                  onChange={(e) => handleLogoUpload(e)}
                />
              </div>
            </div>

            <div className='item'>
              <span>2</span>
              Qual formato de capa vamos criar?
            </div>

            <ul
              className='capas'
              style={{
                display: imgLogo ? 'flex' : 'none',
              }}
            >
              <li onClick={() => setSelected('1-1')}>
                <svg
                  width='50'
                  height='50'
                  viewBox='0 0 50 50'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M40 48H46C47.1046 48 48 47.1046 48 46V39H50V46C50 48.2091 48.2091 50 46 50H40V48ZM2 39V46C2 47.1046 2.89543 48 4 48H10V50H4C1.79086 50 0 48.2091 0 46V39H2ZM10 2H4C2.89543 2 2 2.89543 2 4V11H0V4C0 1.79086 1.79086 0 4 0H10V2ZM40 2H46C47.1046 2 48 2.89543 48 4V11H50V4C50 1.79086 48.2091 0 46 0H40V2Z'
                    fill={selected === '1-1' ? '#a500b9' : '#A3A3A3'}
                  />
                  <g clip-path='url(#clip0_1516_8456)'>
                    <path
                      d='M34 32V18C34 16.9 33.1 16 32 16H18C16.9 16 16 16.9 16 18V32C16 33.1 16.9 34 18 34H32C33.1 34 34 33.1 34 32ZM21.5 26.5L24 29.51L27.5 25L32 31H18L21.5 26.5Z'
                      fill={selected === '1-1' ? '#a500b9' : '#A3A3A3'}
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1516_8456'>
                      <rect
                        width='24'
                        height='24'
                        fill='white'
                        transform='translate(13 13)'
                      />
                    </clipPath>
                  </defs>
                </svg>

                <div>
                  <b>Feed 1:1</b>
                  <span>1080x1080px</span>
                </div>
                <div className={`select ${selected === '1-1' && 'selected'}`} />
              </li>

              <li onClick={() => setSelected('4-5')}>
                <svg
                  width='50'
                  height='50'
                  viewBox='0 0 50 50'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M34.0001 48H41C42.1046 48 43 47.1046 43 46V39H45V46C45 48.2091 43.2091 50 41 50H34.0001V48ZM7 39V46C7 47.1046 7.89543 48 9 48H16.0001V50H9C6.79086 50 5 48.2091 5 46V39H7ZM16.0001 2H9C7.89543 2 7 2.89543 7 4V11H5V4C5 1.79086 6.79086 0 9 0H16.0001V2ZM34.0001 2H41C42.1046 2 43 2.89543 43 4V11H45V4C45 1.79086 43.2091 0 41 0H34.0001V2Z'
                    fill={selected === '4-5' ? '#a500b9' : '#A3A3A3'}
                  />
                  <g clip-path='url(#clip0_1516_5773)'>
                    <path
                      d='M34 32V18C34 16.9 33.1 16 32 16H18C16.9 16 16 16.9 16 18V32C16 33.1 16.9 34 18 34H32C33.1 34 34 33.1 34 32ZM21.5 26.5L24 29.51L27.5 25L32 31H18L21.5 26.5Z'
                      fill={selected === '4-5' ? '#a500b9' : '#A3A3A3'}
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1516_5773'>
                      <rect
                        width='24'
                        height='24'
                        fill='white'
                        transform='translate(13 13)'
                      />
                    </clipPath>
                  </defs>
                </svg>

                <div>
                  <b>Feed 4:5</b>
                  <span>1080x1035px</span>
                </div>
                <div className={`select ${selected === '4-5' && 'selected'}`} />
              </li>

              <li onClick={() => setSelected('119-100')}>
                <svg
                  width='50'
                  height='50'
                  viewBox='0 0 50 50'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M40.8303 35H46C47.1046 35 48 34.1046 48 33V28.2153H50V33C50 35.2091 48.2091 37 46 37H40.8303V35ZM2 28.2153V33C2 34.1046 2.89543 35 4 35H9.16971V37H4C1.79086 37 0 35.2091 0 33V28.2153H2ZM9.16971 14H4C2.89543 14 2 14.8954 2 16V20.7847H0V16C0 13.7909 1.79086 12 4 12H9.16971V14ZM40.8303 14H46C47.1046 14 48 14.8954 48 16V20.7847H50V16C50 13.7909 48.2091 12 46 12H40.8303V14Z'
                    fill={selected === '119-100' ? '#a500b9' : '#A3A3A3'}
                  />
                  <g clip-path='url(#clip0_1516_8487)'>
                    <path
                      d='M34 32V18C34 16.9 33.1 16 32 16H18C16.9 16 16 16.9 16 18V32C16 33.1 16.9 34 18 34H32C33.1 34 34 33.1 34 32ZM21.5 26.5L24 29.51L27.5 25L32 31H18L21.5 26.5Z'
                      fill={selected === '119-100' ? '#a500b9' : '#A3A3A3'}
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1516_8487'>
                      <rect
                        width='24'
                        height='24'
                        fill='white'
                        transform='translate(13 13)'
                      />
                    </clipPath>
                  </defs>
                </svg>

                <div>
                  <b>Feed 1.19:1</b>
                  <span>1200x607px</span>
                </div>
                <div
                  className={`select ${selected === '119-100' && 'selected'}`}
                />
              </li>
            </ul>

            <div className='item'>
              <span>3</span>
              Selecione o tipo de conteúdo e envie as imagens ou vídeo.
              <div
                className='tooltip-area'
                ref={target}
                onClick={() => setShow(!showToltip)}
                role='button'
                tabIndex={0}
              >
                <Icon
                  iconName='info'
                  styles={{
                    color: 'red',
                    width: 10,
                  }}
                />
              </div>
              <Overlay
                target={target.current}
                show={showToltip}
                placement='right'
              >
                {(props) => (
                  <Tooltip id='overlay-example' {...props}>
                    O limite é de 5 imagens e 1 vídeo de até 100MB para a sua
                    campanha.
                  </Tooltip>
                )}
              </Overlay>
            </div>

            <div
              className='file-input'
              style={{
                display: imgLogo && selected ? 'block' : 'none',
              }}
            >
              <p className='file-input-title'>Capa da campanha</p>
              <p>Adicione aqui a imagem ou video da sua comunicação (max 5).</p>
              <div className='file-drop-area'>
                <div
                  className='file-icon'
                  onClick={() => {
                    let mainBanner = document.getElementById('imgs')

                    mainBanner.click()
                  }}
                >
                  <svg
                    width='19'
                    height='38'
                    viewBox='0 0 19 38'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16.5 10.2499V27.8833C16.5 31.3666 13.95 34.4666 10.4833 34.7999C6.49996 35.1833 3.16663 32.0666 3.16663 28.1666V7.56658C3.16663 5.38325 4.73329 3.39992 6.89996 3.18325C9.39996 2.93325 11.5 4.88325 11.5 7.33325V24.8333C11.5 25.7499 10.75 26.4999 9.83329 26.4999C8.91663 26.4999 8.16663 25.7499 8.16663 24.8333V10.2499C8.16663 9.56658 7.59996 8.99992 6.91663 8.99992C6.23329 8.99992 5.66663 9.56658 5.66663 10.2499V24.5999C5.66663 26.7832 7.23329 28.7666 9.39996 28.9833C11.9 29.2333 14 27.2833 14 24.8333V7.61658C14 4.13325 11.45 1.03325 7.98329 0.699918C4.01663 0.316585 0.666626 3.43325 0.666626 7.33325V27.7833C0.666626 32.5666 4.16663 36.8499 8.93329 37.2999C14.4166 37.7999 19 33.5333 19 28.1666V10.2499C19 9.56658 18.4333 8.99992 17.75 8.99992C17.0666 8.99992 16.5 9.56658 16.5 10.2499Z'
                      fill='#6300FF'
                    />
                  </svg>
                </div>
                <label htmlFor='imgs'>
                  <span className='label-file'>Selecionar o arquivo</span>
                  Formato PNG, JPG, MP4 com até 100MB
                </label>

                <input
                  id='imgs'
                  className='d-none'
                  type='file'
                  accept='image/png, image/jpeg, video/mp4'
                  onChange={(e) => handleImgsUpload(e)}
                />
              </div>
            </div>
          </div>

          <div className='preview-carousel'>
            <div
              className={`preview-carousel__item--${
                selected || '1-1'
              } preview-carousel__item`}
            >
              <div className='profile'>
                <img src={imgLogo} />
                <div>
                  <p>Text</p> <p>Há 0 horas</p>
                </div>
              </div>
              <Carousel controls={imgs.length > 1}>
                {imgs.map((item) => {
                  const isImage = item.match(/\.(jpg|jpeg|png|gif)$/i)
                  const isVideo = item.match(/\.(mp4|avi|mov|wmv)$/i)

                  return (
                    <Carousel.Item>
                      <figure
                        className={`preview-carousel__item--${
                          selected || '1-1'
                        }`}
                      >
                        {isImage && <img src={item} alt='media' />}
                        {isVideo && (
                          <video
                            controls
                            src={item}
                            width='100%'
                            height='100%'
                            style={{ height: '100%' }}
                          ></video>
                        )}
                      </figure>
                    </Carousel.Item>
                  )
                })}
                {imgs.length === 0 && (
                  <Carousel.Item>
                    <figure
                      className={`preview-carousel__item--${selected || '1-1'}`}
                    ></figure>
                  </Carousel.Item>
                )}
              </Carousel>
            </div>

            <ul className='small-preview'>
              {imgs.map((item, index) => {
                const isVideo = /\.(mp4|avi|mov|wmv)$/i.test(item)

                return (
                  <li>
                    {isVideo ? (
                      <video
                        width={60}
                        height={64}
                        src={item}
                        preload='metadata'
                      ></video>
                    ) : (
                      <img src={item} alt={`preview-${index}`} />
                    )}

                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()

                        const arr = [...imgs]
                        arr.splice(index, 1)

                        setImgs(arr)
                      }}
                    >
                      <svg
                        width='13'
                        height='15'
                        viewBox='0 0 13 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1.75004 13.0417C1.75004 13.9125 2.46254 14.625 3.33337 14.625H9.66671C10.5375 14.625 11.25 13.9125 11.25 13.0417V5.125C11.25 4.25417 10.5375 3.54167 9.66671 3.54167H3.33337C2.46254 3.54167 1.75004 4.25417 1.75004 5.125V13.0417ZM11.25 1.16667H9.27087L8.70879 0.604583C8.56629 0.462083 8.36046 0.375 8.15462 0.375H4.84546C4.63962 0.375 4.43379 0.462083 4.29129 0.604583L3.72921 1.16667H1.75004C1.31462 1.16667 0.958374 1.52292 0.958374 1.95833C0.958374 2.39375 1.31462 2.75 1.75004 2.75H11.25C11.6855 2.75 12.0417 2.39375 12.0417 1.95833C12.0417 1.52292 11.6855 1.16667 11.25 1.16667Z'
                          fill='#A3A3A3'
                        />
                      </svg>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalApp

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InternalUsersPage from "./internal-users-page";
import API from "../API/api";

const defaultState = {
  valueStatusOptions: [
    {
      value: 1,
      label: "Todos",
    },
  ],
  breadcrumb: [
    {
      isActive: true,
      itemName: "Usuários internos",
    },
  ],
  tableHeader: [
    {
      headerTitle: "Nome",
    },
    {
      headerTitle: "Status",
    },
    {
      headerTitle: "E-mail de acesso",
    },
    {
      headerTitle: "Data de cadastro",
    },
    {
      headerTitle: "Ações",
    },
  ],
  dataSet: [],
};

const InternalUsers = ({ styles, history, isAdmin }) => {
  const [ownState, setOwnState] = useState(defaultState);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showSignUpConfirmationModal, setShowSignUpConfirmationModal] =
    useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const handleCloseSignUpModal = () => setShowSignUpModal(false);
  const handleShowSignUpModal = () => setShowSignUpModal(true);
  const handleCloseSignUpConfirmationModal = () => {
    setOwnState((current) => ({ ...current, id: "" }));
    setShowSignUpConfirmationModal(false);
  };
  const handleShowSignUpConfirmationModal = () =>
    setShowSignUpConfirmationModal(true);
  const handleCloseDeleteUserModal = () => setShowDeleteUserModal(false);
  const handleShowDeleteUserModal = () => setShowDeleteUserModal(true);

  const getPosts = async () => {
    // const url = 'users.json';
    // const obj = {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    // };
    // await API.get(`/${url}`, obj)
    //   .then((res) => {
    //     setOwnState({
    //       ...ownState,
    //       dataSet: res.data,
    //     });
    //   })
    //   .catch((error) => {
    //     console.warn(error);
    //   });
  };

  const handleCreateNewUser = () => {
    handleShowSignUpModal();
  };

  const handleSaveNewUser = (showModal = true) => {
    handleCloseSignUpModal();

    setOwnState((current) => ({ ...current, id: "" }));

    if (!ownState.id && showModal)
      setTimeout(() => {
        handleShowSignUpConfirmationModal();
      }, 1000);
  };

  const handleEditUser = (id) => {
    setOwnState((current) => ({ ...current, id }));
    handleShowSignUpModal();
  };

  const handleDeleteUser = (id) => {
    setOwnState((current) => ({ ...current, id }));
    handleShowDeleteUserModal();
  };

  useEffect(() => {
    if (isAdmin) {
      getPosts();
    } else {
      history.push("/not-allowed");
      history.go("/not-allowed");
    }
  }, []);

  return (
    <InternalUsersPage
      styles={styles}
      state={ownState}
      showSignUpModal={showSignUpModal}
      showSignUpConfirmationModal={showSignUpConfirmationModal}
      showDeleteUserModal={showDeleteUserModal}
      handleSaveNewUser={handleSaveNewUser}
      handleCreateNewUser={handleCreateNewUser}
      handleCloseSignUpConfirmationModal={handleCloseSignUpConfirmationModal}
      handleEditUser={handleEditUser}
      handleDeleteUser={handleDeleteUser}
      handleCloseDeleteUserModal={handleCloseDeleteUserModal}
    />
  );
};

InternalUsers.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default InternalUsers;

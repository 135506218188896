import { keyframes } from '@stitches/react';
import { css } from './CreateStitches';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const body = css({
  backgroundColor: '$neutralColorHighPure',
  fontFamily: '$fontFamilyHighBaseRegular',
  fontSize: '$fontSizeSM',
  color: '$neutralColorLowPure',

  '.full-height': {
    height: '118vh',
  },

  '.pointer': {
    cursor: 'pointer',
  },

  '.page-border': {
    borderBottomColor: '$neutralColorHighLight',
  },

  '.footer-area': {
    backgroundColor: '$neutralColorHighLight',
  },

  a: {
    color: '$brandColorTertiaryPure',

    '&.link-to-nowhere': {
      cursor: 'default',
      pointerEvents: 'none',
    },
  },

  '.dataTables_processing': {
    '> div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '> div': {
        position: 'absolute !important',
        top: '-40px !important',
        left: '0 !important',
        background: 'none !important',
        animation: `${spin} 2s linear infinite !important`,
        borderStyle: 'solid',
        borderColor:
          'rgba(0, 0, 0, 0.45) rgba(50, 50, 50, 0.25) rgba(50, 50, 50, 0.25)',
        borderTopWidth: '$borderXS',
        borderWidth: '$borderXS',
        borderRadius: '$borderRadiusCircular !important',
        width: '100px !important',
        height: '100px !important',
        zIndex: 1,
      },

      '> div:nth-child(2), > div:nth-child(3), > div:nth-child(4)': {
        display: 'none',
      },
    },
  },

  label: {
    display: 'flex',

    '.tooltip-area': {
      width: 'max-content',
      marginLeft: 10,
      display: 'flex',
      alignItems: 'center',

      span: {
        fontSize: '$fontSizeXS',
        color: '$brandColorTertiaryPure',
        position: 'unset !important',
      },
    },

    span: {
      marginLeft: 2,
      color: '$feedbackColorWarningPure',
      position: 'unset !important',
    },
  },

  '.engagement-chart': {
    position: 'relative',
    width: 200,
    height: 136,

    '.CircularProgressbar': {
      '.CircularProgressbar-trail': {
        strokeWidth: 3,
      },

      '.CircularProgressbar-path': {
        stroke: '$brandColorPrimaryPure',
      },
    },

    'div[data-test-id="CircularProgressbarWithChildren__children"]': {
      height: 'max-content !important',
      marginTop: '-166px !important',
    },
  },

  '.manual-input-empty-cell': {
    width: 72,
    height: 48,
  },

  '.border-color': {
    borderColor: '$neutralColorHighMedium',
  },

  '.bg-delete': {
    backgroundColor: '$feedbackColorWarningPure',
    borderColor: '$feedbackColorWarningPure',
  },
});

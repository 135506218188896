import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input, ButtonComponent } from 'cashin-components';
import UnregistredUserModal from './unregistered-user-modal'

const ForgotPasswordForm = ({
  styles,
  state,
  handleKeyDown,
  handleChange,
  handleRecoverPassword,
  handleBackToLogin,
  showUnregistredUserModal,
  hideUnregistredUserModal
}) => {
  return (
    <Fragment>
      <h1 className={styles.tipography({ headings: 'mediumBold' })}>
        Esqueceu a senha?
      </h1>
      <p
        className={`
          ${styles.tipography({ paragraphs: 'mediumRegular' })}
          ${styles.colors({ colors: 'neutralColorLowMedium' })}
          m-0
        `}
      >
        Nós te ajudamos a recuperar.
      </p>
      <p
        className={`
          ${styles.tipography({ paragraphs: 'mediumRegular' })}
          ${styles.colors({ colors: 'neutralColorLowMedium' })}
        `}
      >
        Confirme seu e-mail de cadastro para continuar.
      </p>
      <Input
        id="txtEmail"
        inputName="emailValue"
        className={styles.inputs({ input: 'default' })}
        idError="fdbEmailError"
        inputType="email"
        labelName="E-mail"
        placeholder="Informe seu e-mail"
        errorText={state.emailError}
        isInvalid={state.isInvalidEmail}
        styles={styles}
        value={state.emailValue}
        onKeyDown={(e) => handleKeyDown(e.key)}
        onChange={(e) => handleChange(e)}
      />
      <ButtonComponent
        className={`${styles.buttonStandardLarge({
          large: 'primary',
        })}
        ${
          state.isLoading &&
          styles.buttonStandardLarge({
            large: 'primaryLoading',
          })
        } w-100 mb-3`}
        hasIcon={state.hasIcon}
        isFullWidthIconButton={true}
        iconName="send"
        text="Recuperar senha"
        isLoading={state.isLoading}
        styles={styles}
        onClick={() => handleRecoverPassword()}
      />
      <ButtonComponent
        className={`${styles.buttonStandardLarge({
          large: 'secondary',
        })} w-100 mb-3`}
        hasIcon={true}
        isFullWidthIconButton={true}
        iconName="west"
        text="Voltar para o login"
        onClick={() => handleBackToLogin()}
      />

      <UnregistredUserModal
        styles={styles}        
        size="xs"
        show={showUnregistredUserModal}
        onHide={hideUnregistredUserModal}
      />
    </Fragment>
  );
};

ForgotPasswordForm.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleRecoverPassword: PropTypes.func,
  handleBackToLogin: PropTypes.func,
  showUnregistredUserModal: PropTypes.bool,
  hideUnregistredUserModal: PropTypes.func,
};

export default ForgotPasswordForm;

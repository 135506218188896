import { useQuery } from "react-query";
import api from "../../api";

const useCampaignIdentificationInfo = (cache = false) => {
  return useQuery(
    "campaignIdentificationInfo",
    ({ signal }) =>
      api.get("/campaign/identification-info", {
        signal,
      }),
    {
      cacheTime: 60000 * 5, // Cache por 5 min
    }
  );
};

export default useCampaignIdentificationInfo;

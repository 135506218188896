import { css } from './CreateStitches';

export const badge = css({
  padding: '4px 12px',
  textTransform: 'uppercase',
  backgroundColor: '$neutralColorLowLight',
  color: '$neutralColorHighPure',
  marginRight: 16,
  height: 'fit-content',

  '&.bg-primary': {
    backgroundColor: '$brandColorTertiaryPure !important',
    color: '$neutralColorHighPure',
  },

  '&.bg-secondary': {
    backgroundColor: '$highlightColorPure !important',
    color: '$neutralColorHighPure',
  },

  '&.bg-success': {
    backgroundColor: '$feedbackColorSuccessPure !important',
    color: '$neutralColorHighPure',
  },

  '&.bg-warning': {
    backgroundColor: '$brandColorTertiaryMedium !important',
    color: '$neutralColorLowPure',
  },

  '&.bg-danger': {
    backgroundColor: '$feedbackColorWarningPure !important',
    color: '$neutralColorHighPure',
  },

  '&.bg-info': {
    backgroundColor: '$brandColorSecondaryMedium !important',
    color: '$neutralColorLowPure',
  },

  '&.bg-dark': {
    backgroundColor: '$neutralColorLowPure !important',
    color: '$neutralColorHighPure',
  },

  '&.bg-light': {
    backgroundColor: '$neutralColorHighLight !important',
    color: '$neutralColorLowLight',
  },

  '&:last-child': {
    margin: 0,
  },
});

import React, { useState } from "react";
import PropTypes from "prop-types";
import EditCampaignPage from "./edit-campaign-page";

const defaultState = {
  breadcrumb: [
    {
      url: '/campaigns',
      itemName: 'Campanhas',
    },
    {
      isActive: true,
      itemName: 'Editar campanha',
    },
  ],
}

const EditCampaign = ({ styles }) => {
  const [ownState] = useState(defaultState);

  return <EditCampaignPage styles={styles} state={ownState} />;
};

EditCampaign.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
}

export default EditCampaign

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row } from 'react-bootstrap';
import { ButtonComponent } from 'cashin-components';

const ExceededUsageLimitModal = ({ styles, size, show, onHide }) => {
  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={styles.modal()}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body>
        <h1
          className={`
            ${styles.tipography({ headings: 'xsmallBold' })}
            mb-4 text-center
          `}
        >
          Oi &#128522;  
        </h1>
        <p className="text-center m-0">Seu limite mensal de participantes foi ultrapassado.</p>
        <p className="text-center m-0">Entre em contato para aumentar o seu saldo</p>
      </Modal.Body>
      <Modal.Footer>
        <Row className={`w-100 d-flex justify-content-center align-items-center`}>
          <Col xs={6}>
            <ButtonComponent
              className={`${styles.buttonStandardLarge({
                large: "primary",
              })}
              ${
                false &&
                styles.buttonStandardLarge({
                  large: "primaryLoading",
                })
              } w-100 mt-4 mb-3`}
              text="Fechar"
              isLoading={false}
              styles={styles}
              onClick={onHide}
            />
          </Col>          
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

ExceededUsageLimitModal.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ExceededUsageLimitModal;

import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import {
  Input,
  Select,
  ButtonComponent,
  BreadcrumbComponent,
} from 'cashin-components'
import InternalUsersTableComponent from './internal-users-table-component'
import ModalSignUpUser from './modal-sign-up-user'
import ModalSignUpUserConfirmation from './modal-sign-up-user-confirmation'
import ModalDeleteUser from './modal-delete-user'
import CustomPagination from '../Components/CustomPagination'
import qs from 'qs'
import api from '../API/api'

const InternalUsersPage = ({
  styles,
  state,
  showSignUpModal,
  showSignUpConfirmationModal,
  showDeleteUserModal,
  handleSaveNewUser,
  handleCreateNewUser,
  handleCloseSignUpConfirmationModal,
  handleEditUser,
  handleDeleteUser,
  handleCloseDeleteUserModal,
}) => {
  const [users, setUsers] = useState()
  const [currentPagination, setCurrentPagination] = useState(0)

  const handleOnPaginationChange = (newPage) => {
    // fetchNextPage({ pageParam: newPage - 1 });
    setCurrentPagination(newPage - 1)
  }

  const search = async () => {
    setUsers([])
    var queryString = qs.stringify(
      {
        'Pagination.Limit': 10,
        'Pagination.Offset': currentPagination * 10,
      },
      { skipNulls: true }
    )

    var response = await api.get(`/admin/search?${queryString}`)
    setUsers(response)
  }

  useEffect(() => {
    search()
  }, [currentPagination])

  const onDeleteUser = () => {
    handleCloseDeleteUserModal()

    search()
  }

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12} className='pt-4'>
              <BreadcrumbComponent
                hasIcon={true}
                styles={styles}
                initialIcon='home-color'
                initialNode='Dashboard'
                breadcrumbData={state.breadcrumb}
              />
            </Col>
            <Col xs={6}>
              <h1
                className={`
                  ${styles.tipography({ headings: 'smallBold' })}
                  my-3
                `}
              >
                Usuários internos
              </h1>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              <ButtonComponent
                className={`${styles.buttonStandardLarge({
                  large: 'primary',
                })}
                my-2`}
                hasIcon={true}
                iconName='add_circle'
                text='Cadastrar novo usuário'
                styles={styles}
                onClick={() => handleCreateNewUser()}
              />
            </Col>
            <Col xs={12}>
              <div
                className={`
                  ${styles.border({ borderBottomWidth: 'thick' })}
                  mb-4 page-border
                `}
              />
            </Col>
            <Col xs>
              <Input
                id='txtSearchCustomers'
                inputName='searchCustomersValue'
                className={styles.inputs({ input: 'default' })}
                inputType='text'
                labelName='Buscar clientes'
                placeholder='Pesquise seus clientes'
                styles={styles}
                // value={state.searchCustomersValue}
                // onKeyDown={(e) => handleKeyDown(e.key)}
                // onChange={(e) => handleChange(e)}
              />
            </Col>
            <Col xs>
              <Select
                id='ddlStatusFilter'
                labelName='Status'
                options={state.valueStatusOptions}
                styles={styles}
              />
            </Col>
            <Col xs={1} className='d-flex align-items-end'>
              <ButtonComponent
                className={`
                  ${styles.buttonStandardLarge({
                    large: 'primary',
                  })}
                  mb-3
                `}
                text='Buscar'
                styles={styles}
                // onClick={() => handleSearch()}
              />
            </Col>
            <Col xs={12} className='mt-4 mb-3'>
              <InternalUsersTableComponent
                styles={styles}
                header={[
                  {
                    headerTitle: 'Nome',
                  },
                  {
                    headerTitle: 'Status',
                  },
                  {
                    headerTitle: 'Ações',
                  },
                ]}
                data={users}
                handleEditUser={handleEditUser}
                handleDeleteUser={handleDeleteUser}
              />
            </Col>
          </Row>
        </Col>

        <CustomPagination
          totalPages={
            // (data?.pages?.[0]?.totalItems || 0) / 10 ||
            (users?.totalItems || 0) / 10
          }
          onPaginationChange={handleOnPaginationChange}
          currentPage={currentPagination + 1}
          setCurrentPage={setCurrentPagination}
        />
      </Row>

      <ModalSignUpUser
        styles={styles}
        state={state}
        size='xs'
        show={showSignUpModal}
        onHide={handleSaveNewUser}
      />

      <ModalSignUpUserConfirmation
        styles={styles}
        state={state}
        size='xs'
        show={showSignUpConfirmationModal}
        onHide={handleCloseSignUpConfirmationModal}
      />

      <ModalDeleteUser
        styles={styles}
        state={state}
        size='xs'
        show={showDeleteUserModal}
        onHide={onDeleteUser}
      />
    </Fragment>
  )
}

InternalUsersPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  showSignUpModal: PropTypes.bool,
  showSignUpConfirmationModal: PropTypes.bool,
  showDeleteUserModal: PropTypes.bool,
  handleSaveNewUser: PropTypes.func,
  handleCreateNewUser: PropTypes.func,
  handleCloseSignUpConfirmationModal: PropTypes.func,
  handleEditUser: PropTypes.func,
  handleDeleteUser: PropTypes.func,
  handleCloseDeleteUserModal: PropTypes.func,
}

export default InternalUsersPage

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from "react-bootstrap";
import { GeneralReport } from "./general-report/GeneralReport";
import { Box, BreadcrumbComponent } from "../Components";

const Reports = ({ styles }) => {
  const userData = JSON.parse(window.localStorage.getItem("profile"));

  return (
    <Row>
      <Col xs={12} style={{ padding: 0 }}>
        <Row>
          <Col xs={12} className="pt-4">
            <Box>
              <BreadcrumbComponent
                hasIcon={true}
                styles={styles}
                initialIcon="home-color"
                initialNode="Dashboard"
                breadcrumbData={[
                  {
                    isActive: true,
                    itemName: 'Relatórios',
                  },
                ]}
              />
            </Box>
          </Col>
          <Col xs={6}>
            <Box>
              <h1 className={`${styles.tipography({ headings: "smallBold" })} my-3`}>Relatórios</h1>
            </Box>
          </Col>
          <Col xs={12}>
            <div className={`${styles.border({ borderBottomWidth: 'thick' })} mb-4 page-border`}/>
          </Col>
          <Col xs={12}>
            <Box>
              <GeneralReport tenantId={userData["custom:tenantId"]}/>
            </Box>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Reports.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default Reports;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent } from 'cashin-components';

const ForgotPasswordConfirmation = ({ styles, state, handleBackToLogin }) => {
  return (
    <Fragment>
      <div
        className={`
          ${styles.login({ loginIcons: 'checkGreen' })}
          w-100 mb-4
        `}
        aria-hidden="true"
      />
      <h1
        className={`
          ${styles.tipography({ headings: 'mediumBold' })}
          text-center mb-4
        `}
      >
        Enviamos um e-mail
        <br />
        com instruções para
        <br />
        alterar sua senha.
      </h1>
      <p
        className={`
          ${styles.tipography({ paragraphs: 'mediumRegular' })}
          ${styles.colors({ colors: 'neutralColorLowMedium' })}
          text-center mb-4 pb-2
        `}
      >
        Verifique seu e-mail:{' '}
        <a href="" className="link-to-nowhere">
          {state.obfuscatedEmailValue}
        </a>
      </p>
      <ButtonComponent
        className={`${styles.buttonStandardLarge({
          large: 'secondary',
        })} w-100 mb-3`}
        hasIcon={true}
        isFullWidthIconButton={true}
        iconName="west"
        text="Voltar para o login"
        onClick={() => handleBackToLogin()}
      />
    </Fragment>
  );
};

ForgotPasswordConfirmation.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleBackToLogin: PropTypes.func,
};

export default ForgotPasswordConfirmation;

import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { ButtonComponent, Input } from 'cashin-components'
import { CampaignContext } from '../../../context/campaign-context'
import { read, utils } from 'xlsx'
import { useCompanyUsageBalance } from '../../../API/queries/company/useCompanyUsageBalance'
import ExceededUsageLimitModal from '../../../Components/ExceededUsageLimitModal'
import api from '../../../API/api'
import { toast } from 'react-toastify'

function clearPhone(phoneNumber) {
  if (!phoneNumber) return ''
  return String(phoneNumber).replace(/\D/g, '')
}
const ParticipantsBatchInput = ({ styles, tagsSegment }) => {
  const [exceededUsageLimitModalIsOpen, SetExceededUsageLimitModalIsOpen] =
    useState(false)
  const campaignContext = useContext(CampaignContext)
  const isEmailRequired = campaignContext.communicationType === 'email'
  const isPhoneRequired = campaignContext.communicationType === 'whatsapp'
  const isContactInfoRequired = campaignContext.communicationType === 'app'

  const { data: balance } = useCompanyUsageBalance(campaignContext.companyId)

  const handleClose = () => SetExceededUsageLimitModalIsOpen(false)
  const handleShow = () => SetExceededUsageLimitModalIsOpen(true)

  const downloadTemplate = (fileName) => {
    window.location.href = `https://multicampaignfileshml.blob.core.windows.net/files/${fileName}`
  }

  async function readFile(file) {
    const reader = new FileReader()
    let errorUpload = false

    if (
      file.type !==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      toast.error('Erro: O arquivo deve ser um arquivo Excel (.xlsx).')
      return
    }

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result)
      const workbook = read(data, { type: 'array' })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const rows = utils.sheet_to_json(worksheet, { header: 1 })

      const celularHeader = 'Celular*'
      const emailHeader = 'E-mail*'
      const segmentationHeader = 'Segmentação*'

      const celularIndex = rows[1].findIndex((cell) =>
        cell.includes(celularHeader)
      )
      const emailIndex = rows[1].findIndex((cell) => cell.includes(emailHeader))
      const segmentationIndex = rows[1].findIndex((cell) =>
        cell.includes(segmentationHeader)
      )

      const nonEmptyRows = rows
        .slice(2)
        .filter((row) => row.some((cell) => cell.trim() !== ''))

      const existingSegmentations = nonEmptyRows
        .map((row) => row[segmentationIndex])
        .filter(Boolean)

      if (
        existingSegmentations.some(
          (segmentation) =>
            !tagsSegment.some((seg) => seg.label === segmentation)
        )
      ) {
        toast.error(`Erro: Segmentação não definida no arquivo.`)
        errorUpload = true
        return
      }

      if (
        tagsSegment.some((seg) => !existingSegmentations.includes(seg.label))
      ) {
        toast.error(`Erro: Segmentação não encontrada no arquivo.`)
        errorUpload = true
        return
      }

      if (errorUpload) return

      const validParticipants = nonEmptyRows.filter((row) => {
        const [name, celular, email, segmentation] = row
        const phoneRegex = /^(\d{2})\d{8,9}$/

        if (
          !name ||
          (isContactInfoRequired && !celular && !email) ||
          (isPhoneRequired && !celular) ||
          (isEmailRequired && !email) ||
          (celular && !phoneRegex.test(celular)) ||
          (segmentationIndex !== -1 && !segmentation)
        ) {
          toast.error('Erro: Dados inválidos ou incompletos.')
          errorUpload = true
          return false
        }
        return true
      })

      if (errorUpload) return

      const participantData = validParticipants.map((row) => ({
        name: row[0],
        cellphone: clearPhone(row[1]),
        email: row[2],
        segmentation: segmentationIndex !== -1 ? row[segmentationIndex] : null,
      }))

      const hasCellphoneDuplicates = participantData.some(
        (item, index, self) =>
          item.cellphone &&
          self.findIndex((p) => p.cellphone === item.cellphone) !== index
      )

      if (hasCellphoneDuplicates) {
        toast.error('Erro: Existem participantes com o mesmo celular.')
        errorUpload = true
        return
      }

      const hasEmailDuplicates = participantData.some(
        (item, index, self) =>
          item.email && self.findIndex((p) => p.email === item.email) !== index
      )

      if (hasEmailDuplicates) {
        toast.error('Erro: Existem participantes com o mesmo e-mail.')
        errorUpload = true
        return
      }

      campaignContext.setParticipants(participantData)

      if (!campaignContext.isSegmentation) {
        campaignContext.setSegmentTags([
          { value: null, label: 'Não segmentado' },
        ])
      }

      const rowCount = participantData.length
      if (rowCount - 1 > balance?.balance) {
        handleShow()
        await api.post(
          `/company-negotiation-usage/send-email/negotiation-denied?companyName=${campaignContext.companyName}`
        )
        return
      }

      campaignContext.uploadParticipantFile(file)
    }

    if (!errorUpload) {
      reader.readAsArrayBuffer(file)
    }
  }

  async function handleUploadFile(file) {
    await readFile(file)
  }

  return (
    <>
      <ExceededUsageLimitModal
        styles={styles}
        size='xs'
        show={exceededUsageLimitModalIsOpen}
        onHide={handleClose}
      />
      <Row>
        <Col xs={12}>
          <ButtonComponent
            text='Download do template'
            hasIcon={true}
            className={`${styles.buttonStandardSmall({
              small: 'primary',
            })}
                my-2`}
            iconName='download'
            styles={styles}
            onClick={() => {
              if (campaignContext.isSegmentation === 'yes') {
                downloadTemplate(
                  'b63a9cbe-b73d-447e-b8a7-962c5bd6dc40Planilha_Padrao-Upload_Participantes_com_Segmentacao.xlsx'
                )
              } else {
                downloadTemplate(
                  'd4d1087a-e9f0-4316-a522-5d084200f5c4Planilha_Padrao-Upload_Participantes_sem_Segmentacao.xlsx'
                )
              }
            }}
          />

          <div
            className={`
              ${styles.inputs({ inputFile: 'inputFileBatch' })}
            `}
          >
            <Input
              id='txtFileInput'
              styles={styles}
              isFileInput={true}
              isFileBatch={true}
              isEmptyFile={
                campaignContext.currentParticipantFileReference?.length <= 0
              }
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              filePlaceholderText='Faça upload do arquivo PDF'
              onChange={(e) => handleUploadFile(e[0])}
              handleDeleteFile={campaignContext.deleteParticipantFile}
              files={campaignContext.currentParticipantFileReference}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

ParticipantsBatchInput.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleUploadChangeBatch: PropTypes.func,
  handleDeleteFile: PropTypes.func,
}

export default ParticipantsBatchInput

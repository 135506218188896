import { formatDateWithHours } from "../../functions/date";
import { formatPrizeString } from "./format-prize-string";

export const transformPrizeReportDataForXlxs = (headers, propertyNames, data) => {
  data.map(item => {
    item.amount = formatPrizeString(item.amount, item.prizeDiscountType);
    item.approvedAt = formatDateWithHours(item.approvedAt);
    return item;
  })

  const transformedData = data.map(item => {
    const transformedItem = {};
    propertyNames.forEach((propertyName, index) => {
      transformedItem[headers[index]] = item[propertyName];
    });

    return transformedItem;
  });

  return transformedData;
} 
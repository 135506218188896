import { css } from './CreateStitches';

export const verification = css({
  variants: {
    verification: {
      upload: {},
      history: {},
    },
  },
});

import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table"
import { Table } from "flowbite-react"
import { Row } from "react-bootstrap"

export const ParticipantsPreviewTable = ({ styles, participants, isSegmentation, communicationType }) => {
  const previewParticipants = participants.slice(0, 10)
  const showCellphoneColumn = communicationType !== 'email'
  const showEmailColumn = communicationType !== 'whatsapp'
  const { accessor } = createColumnHelper()

  const columns = [
    accessor('name', {
      header: 'Nome',
      cell: (props) => <div className="truncate tw-text-lg tw-pl-2">{props.getValue() ?? '-'}</div>,
    }),
    showCellphoneColumn && accessor('cellphone', {
      header: 'Celular',
      cell: (props) => <div className="truncate tw-text-lg">{props.getValue() ?? '-'}</div>,
    }),
    showEmailColumn && accessor('email', {
      header: 'Email',
      cell: (props) => <div className="truncate tw-text-lg">{props.getValue() ?? '-'}</div>,
    }),
    isSegmentation === 'yes' && accessor('segmentation', {
      header: 'Segmentação',
      cell: (props) => <div className="truncate tw-text-lg">{props.getValue() ?? '-'}</div>,
    }),
  ].filter(Boolean);

  const table = useReactTable({
    data: previewParticipants ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });


  return (
    <Row>
    <div className="tw-mx-1 tw-flex tw-flex-col tw-overflow-visible tw-pt-8">
    <p
        className={`
          ${styles.tipography({ paragraphs: 'mediumRegular' })}
            mb-2 pb-1 tw-font-bold`
        }
      >
        Previsão
      </p>
      <p
        className={`
          ${styles.tipography({ paragraphs: 'mediumRegular' })}
            mb-2 pb-1`
        }
      >
        Abaixo está uma previsão das 10 primeiras linhas da planilha importada para que você visualize o formato dos dados inseridos
      </p>
      <Table className="tw-w-full tw-table-fixed tw-overflow-visible tw-rounded-lg tw-bg-white tw-shadow-[1.5px_1.5px_1.5px_1.5px_rgba(0,0,0,0.05),-1.5px_1.5px_1.5px_1.5px_rgba(0,0,0,0.05)]">
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <Table.HeadCell
                key={header.id}
                colSpan={header.colSpan}
                className="tw-text-black-500 tw-border-gray-150 tw-whitespace-nowrap tw-border-b tw-bg-white tw-text-lg"
              >
                {!header.isPlaceholder ? (
                  <div className="tw-flex tw-items-center tw-gap-2 tw-pl-2">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                ) : null}
              </Table.HeadCell>
            ))
          )}
        </Table.Head>
        <Table.Body>
          {table.getCoreRowModel().rows.map((row) => (
            <Table.Row key={row.id} className="tw-border-black-200 tw-border-b">
              {row.getVisibleCells().map((cell) => (
                <Table.Cell className="" key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
    </Row>
  )
}
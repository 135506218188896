import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row, FormGroup } from 'react-bootstrap'
import { Select, Check, Input } from 'cashin-components'
import { styles } from 'cashin-design-system/Styles/Styles'
import { Typeahead } from 'react-bootstrap-typeahead'
import { CampaignContext } from '../../../context/campaign-context'

const styleContainer = {
  border: '#E0E0E0 solid 1px',
  borderRadius: 16,
  padding: '1.5rem',
  marginBottom: '2rem',
}

const CampaignHybrid = ({ onFieldError }) => {
  const campaignContext = useContext(CampaignContext)

  const [reachValueConditionEnabled, setReachValueEnabled] = useState([])
  const [
    multipleDiscountConditionEnabled,
    setMultipleDiscountConditionEnabled,
  ] = useState([])

  const [fieldsValid, setFieldsValid] = useState({
    reachValue: true,
    prizeValue: true,
  })

  const [rawReachValue, setRawReachValue] = useState('')
  const [rawReachValueCondition, setRawReachValueCondition] = useState('')
  const [rawPrizeValue, setRawPrizeValue] = useState('')
  const [rawPrizeValueCondition, setRawPrizeValueCondition] = useState('')

  const [reachValueError, setReachValueError] = useState(null)
  const [reachValueFromConditionError, setReachValueFromConditionError] =
    useState(null)
  const [multipleDiscountError, setMultipleDiscountError] = useState(null)
  const [discountValueFromConditionError, setDiscountValueFromConditionError] =
    useState(null)

  const [reachValueCondition, setReachValueCondition] = useState([])
  const [discountCondition, setDiscountCondition] = useState([])

  const [reachValue, setReachValue] = useState([])
  const [reachValueFromCondition, setReachValueFromCondition] = useState([])
  const [multipleDiscountValue, setMultipleDiscountValue] = useState([])
  const [discountValueFromCondition, setDiscountValueFromCondition] = useState(
    []
  )

  const [rawHitValue, setRawHitValue] = useState(0.0)
  const [rawTookValue, setRawTookValue] = useState(0.0)

  const [reachValueUnchanged, setReachValueUnchanged] = useState(false)
  const [
    reachValueFromConditionUnchanged,
    setReachValueFromConditionUnchanged,
  ] = useState(false)

  const [multipleDiscountUnchanged, setMultipleDiscountUnchanged] =
    useState(false)
  const [
    discountValueFromConditionUnchanged,
    setDiscountValueFromConditionUnchanged,
  ] = useState(false)

  const handleValueChange = (value, setValue, setDescriptionValue) => {
    if (isNaN(value)) {
      return
    }

    setFieldsValid((prevFieldsValid) => ({
      ...prevFieldsValid,
      reachValue: parseFloat(value) > 0,
    }))

    setValue(parseFloat(value))
  }

  const formatToBRL = (value) => {
    const [integerPart, decimalPart] = String(value).split('.')

    let formattedInteger = integerPart.replace(/\D/g, '')
    formattedInteger = formattedInteger === '' ? '0' : formattedInteger

    let formattedDecimal = '00'
    if (decimalPart && decimalPart.length > 0) {
      formattedDecimal = decimalPart
        .replace(/\D/g, '')
        .padEnd(2, '0')
        .substring(0, 2)
    }

    return `${formattedInteger},${formattedDecimal}`
  }

  useEffect(() => {
    setReachValueEnabled(
      new Array(campaignContext.segmentTags.length).fill(true)
    )
    setMultipleDiscountConditionEnabled(
      new Array(campaignContext.segmentTags.length).fill(true)
    )
  }, [campaignContext.segmentTags])

  useEffect(() => {
    let isValid = true

    campaignContext.segmentTags.forEach((segment, segmentIndex) => {
      isValid &&=
        reachValue[segmentIndex]?.value > 0 &&
        reachValue[segmentIndex]?.type !== null &&
        multipleDiscountValue[segmentIndex]?.value > 0 &&
        multipleDiscountValue[segmentIndex]?.type !== null

      if (reachValueConditionEnabled[segmentIndex]) {
        isValid &&= reachValueFromCondition[segmentIndex]?.value > 0
      }

      if (multipleDiscountConditionEnabled[segmentIndex]) {
        isValid &&= discountValueFromCondition[segmentIndex]?.value > 0
      }
    })

    onFieldError?.(isValid)
  }, [
    discountCondition,
    discountValueFromCondition,
    multipleDiscountConditionEnabled,
    multipleDiscountValue,
    onFieldError,
    reachValueCondition,
    reachValue,
    reachValueConditionEnabled,
    reachValueFromCondition,
  ])

  useEffect(() => {
    const updatedRules = []
    const updatedPrizes = []

    campaignContext.segmentTags.forEach((segment, segmentIndex) => {
      updatedRules.push({
        segmentation:
          campaignContext.isSegmentation === 'yes' ? segment.label : undefined,
        ruleType: 2,
        multipleReachValueCondition: {
          condition: reachValueConditionEnabled[segmentIndex]
            ? reachValueCondition[segmentIndex]
            : null,
          conditionType: reachValueConditionEnabled[segmentIndex]
            ? reachValueCondition[segmentIndex] === 1
              ? 'Ou'
              : 'e'
            : '',
          reachValue: {
            type: reachValue[segmentIndex]?.type || 0,
            value: reachValue[segmentIndex]?.value || 0,
            description: reachValue[segmentIndex]?.description || '',
          },
          reachValueFromCondition: reachValueConditionEnabled[segmentIndex]
            ? {
              type: reachValueFromCondition[segmentIndex]?.type || 0,
              value: reachValueFromCondition[segmentIndex]?.value || 0,
              description:
                reachValueFromCondition[segmentIndex]?.description || '',
            }
            : null,
        },
      })

      updatedPrizes.push({
        segmentation:
          campaignContext.isSegmentation === 'yes' ? segment.label : undefined,
        prizeType: 1,
        multipleDiscount: {
          condition: multipleDiscountConditionEnabled[segmentIndex]
            ? discountCondition[segmentIndex]
            : null,
          conditionType: multipleDiscountConditionEnabled[segmentIndex]
            ? discountCondition[segmentIndex] === 1
              ? 'Ou'
              : 'e'
            : '',
          discount: {
            type: multipleDiscountValue[segmentIndex]?.type || 0,
            value: multipleDiscountValue[segmentIndex]?.value || 0,
            description: multipleDiscountValue[segmentIndex]?.description || '',
          },
          discountFromCondition: multipleDiscountConditionEnabled[segmentIndex]
            ? {
              type: discountValueFromCondition[segmentIndex]?.type || 0,
              value: discountValueFromCondition[segmentIndex]?.value || 0,
              description:
                discountValueFromCondition[segmentIndex]?.description || '',
            }
            : null,
        },
      })
    })

    campaignContext.setRuleAndPrize(updatedRules, updatedPrizes)
  }, [
    discountCondition,
    discountValueFromCondition,
    multipleDiscountConditionEnabled,
    multipleDiscountValue,
    onFieldError,
    reachValueCondition,
    reachValue,
    reachValueConditionEnabled,
    reachValueFromCondition,
    rawReachValue,
    rawReachValueCondition,
    rawPrizeValue,
    rawPrizeValueCondition,
  ])

  useEffect(() => {
    const savedRules = campaignContext.campaignState?.rules || []
    const savedPrizes = campaignContext.campaignState?.prizes || []

    setReachValue(
      savedRules.map(
        (rule) => rule?.multipleReachValueCondition?.reachValue || {}
      )
    )
    setReachValueFromCondition(
      savedRules.map(
        (rule) =>
          rule?.multipleReachValueCondition?.reachValueFromCondition || {}
      )
    )
    setMultipleDiscountValue(
      savedPrizes.map((prize) => prize?.multipleDiscount?.discount || {})
    )
    setDiscountValueFromCondition(
      savedPrizes.map(
        (prize) => prize?.multipleDiscount?.discountFromCondition || {}
      )
    )
    setReachValueCondition(
      savedRules.map(
        (rule) => rule?.multipleReachValueCondition?.condition || null
      )
    )
    setDiscountCondition(
      savedPrizes.map((prize) => prize?.multipleDiscount?.condition || null)
    )
  }, [])

  return (
    <>
      {campaignContext.segmentTags?.map((segment, segmentIndex) => (
        <div
          style={
            campaignContext.isSegmentation === 'yes' ? styleContainer : null
          }
        >
          <Row className='mb-3 pb-3'>
            {campaignContext.isSegmentation === 'yes' && (
              <Col xs={4}>
                <Input
                  id='txtCampaignSegment'
                  inputName='campaignSegment'
                  className={styles.inputs({ input: 'default' })}
                  idError='fdbCampaignSegmentError'
                  inputType='text'
                  labelName='Segmento:'
                  placeholder='Ex.: Gerente, Coordenador, Vendedor'
                  styles={styles}
                  value={
                    campaignContext.isSegmentation === 'yes'
                      ? segment.label
                      : undefined
                  }
                  disabled
                />
              </Col>
            )}
          </Row>
          <Row>
            <>
              <Col xs={4}>
                <Form.Label
                  className={styles.tipography({ paragraphs: 'smallBold' })}
                  style={{ paddingTop: 1 }}
                >
                  Meta:
                </Form.Label>
                <FormGroup controlId='txtCampaignHit'>
                  <Typeahead
                    id={`txtCampaignHit${segmentIndex}`}
                    inputName={`campaignHit${segmentIndex}`}
                    idError={`fdbCampaignHitError${segmentIndex}`}
                    errorText={
                      reachValueError !== null ? 'Campo obrigatório!' : null
                    }
                    isInvalid={
                      reachValueError !== null || !fieldsValid.reachValue
                    }
                    onBlur={() => {
                      const selectedValue = reachValue[segmentIndex]?.value || 0
                      if (parseFloat(selectedValue) > 0) {
                        setReachValueError(null)
                      } else {
                        setReachValueError('Insira um valor.')
                      }
                      setReachValueUnchanged(parseFloat(selectedValue) === null)
                    }}
                    labelKey={(option) => option.description}
                    filterBy={['value']}
                    options={[
                      {
                        id: 1,
                        type: 0,
                        value: rawHitValue,
                        description: `R$ ${formatToBRL(rawHitValue)}`,
                      },
                      {
                        id: 2,
                        type: 1,
                        value: rawHitValue,
                        description: `${formatToBRL(rawHitValue)} %`,
                      },
                    ]}
                    onInputChange={(value) => {
                      const formattedValue = parseFloat(value.replace(',', '.'))
                      if (!isNaN(formattedValue)) {
                        setRawHitValue(formattedValue)
                        handleValueChange(value, setRawReachValue)
                      }
                    }}
                    placeholder='0,00'
                    style={{ height: 44 }}
                    onChange={(selected) => {
                      if (selected.length > 0) {
                        const { value, type, description } = selected[0]
                        setReachValue((prevState) => {
                          const updatedArray = [...prevState]
                          updatedArray[segmentIndex] = {
                            value,
                            type,
                            description,
                          }
                          return updatedArray
                        })
                      }
                    }}
                  />
                  <div style={{ color: '#6300ff' }}>{reachValueError}</div>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <Select
                  id='ddlHitConditions'
                  idError='fdbHitConditionsError'
                  options={[
                    {
                      value: 0,
                      label: 'e',
                    },
                    {
                      value: 1,
                      label: 'Ou',
                    },
                  ]}
                  isInvalid={false}
                  errorText='Selecione um item neste campo'
                  styles={styles}
                  className='mt-4'
                  disabled={!reachValueConditionEnabled[segmentIndex]}
                  onChange={(e) => {
                    const updatedConditions = [...reachValueCondition]
                    updatedConditions[segmentIndex] = e.value
                    setReachValueCondition(updatedConditions)
                  }}
                />
                <Check
                  id={`swtDisableHit${segmentIndex}`}
                  label='Desativar campo'
                  type='switch'
                  onChange={(e) => {
                    const updatedEnabled = [...reachValueConditionEnabled]
                    updatedEnabled[segmentIndex] = !updatedEnabled[segmentIndex]
                    setReachValueEnabled(updatedEnabled)
                  }}
                  checked={!reachValueConditionEnabled[segmentIndex]} // Inverte o valor porque o `checked` controla a desativação
                  className={`
                    ${styles.check({ switch: 'normal' })}
                    ps-0 mt-1
                  `}
                />
              </Col>
              <Col xs={4}>
                <Form.Label
                  className={styles.tipography({ paragraphs: 'smallBold' })}
                  style={{ paddingTop: 1 }}
                >
                  Meta:
                </Form.Label>
                <Typeahead
                  id={`txtCampaignHitCondition${segmentIndex}`}
                  labelKey={(option) => option.description}
                  filterBy={['value']}
                  placeholder='0,00'
                  idError={`fdbCampaignHitConditionError${segmentIndex}`}
                  errorText={
                    reachValueFromConditionError !== null
                      ? 'Campo obrigatório!'
                      : null
                  }
                  isInvalid={
                    reachValueFromConditionError !== null ||
                    !fieldsValid.reachValue
                  }
                  onBlur={() => {
                    const selectedValue =
                      reachValueFromCondition[segmentIndex]?.value || 0
                    if (parseFloat(selectedValue) > 0) {
                      setReachValueFromConditionError(null)
                    } else {
                      setReachValueFromConditionError('Insira um valor.')
                    }
                    setReachValueFromConditionUnchanged(
                      parseFloat(selectedValue) === null
                    )
                  }}
                  options={[
                    {
                      type: 0,
                      value: rawReachValueCondition,
                      description: `R$ ${formatToBRL(rawReachValueCondition)}`,
                    },
                    {
                      type: 1,
                      value: rawReachValueCondition,
                      description: `${formatToBRL(rawReachValueCondition)} %`,
                    },
                  ]}
                  onInputChange={(value) => {
                    const formattedValue = parseFloat(value.replace(',', '.'))
                    if (!isNaN(formattedValue)) {
                      setRawReachValueCondition(formattedValue)
                      handleValueChange(value, setRawReachValueCondition)
                    }
                  }}
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      const { value, type, description } = selected[0]
                      setReachValueFromCondition((prevState) => {
                        const updatedArray = [...prevState]
                        updatedArray[segmentIndex] = {
                          value,
                          type,
                          description,
                        }
                        return updatedArray
                      })
                    }
                  }}
                  style={{ height: 44 }}
                  disabled={!reachValueConditionEnabled[segmentIndex]}
                />
                <div style={{ color: '#6300ff' }}>
                  {reachValueFromConditionError}
                </div>
              </Col>
              <Col xs={4}>
                <Form.Label
                  className={styles.tipography({ paragraphs: 'smallBold' })}
                  style={{ paddingTop: 1 }}
                >
                  Prêmio:
                </Form.Label>
                <Typeahead
                  id={`txtCampaignPrize${segmentIndex}`}
                  labelKey={(option) => option.description}
                  filterBy={['value']}
                  placeholder='0,00'
                  idError={`fdbCampaignPrizeError${segmentIndex}`}
                  errorText={
                    multipleDiscountError !== null ? 'Campo obrigatório!' : null
                  }
                  isInvalid={
                    multipleDiscountError !== null || !fieldsValid.prizeValue
                  }
                  onBlur={() => {
                    const selectedValue =
                      multipleDiscountValue[segmentIndex]?.value || 0
                    if (parseFloat(selectedValue) > 0) {
                      setMultipleDiscountError(null)
                    } else {
                      setMultipleDiscountError('Insira um valor.')
                    }
                    setMultipleDiscountUnchanged(
                      parseFloat(selectedValue) === null
                    )
                  }}
                  options={[
                    {
                      type: 0,
                      value: rawPrizeValue,
                      description: `R$ ${formatToBRL(rawPrizeValue)}`,
                    },
                    {
                      type: 1,
                      value: rawPrizeValue,
                      description: `${formatToBRL(rawPrizeValue)} %`,
                    },
                  ]}
                  onInputChange={(value) => {
                    const formattedValue = parseFloat(value.replace(',', '.'))
                    if (!isNaN(formattedValue)) {
                      setRawPrizeValue(formattedValue)
                      handleValueChange(value, setRawPrizeValue)
                    }
                  }}
                  label='prizeValue'
                  style={{ height: 44 }}
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      const { value, type, description } = selected[0]
                      setMultipleDiscountValue((prevState) => {
                        const updatedArray = [...prevState]
                        updatedArray[segmentIndex] = {
                          value,
                          type,
                          description,
                        }
                        return updatedArray
                      })
                    }
                  }}
                />
                <div style={{ color: '#6300ff' }}>{multipleDiscountError}</div>
              </Col>
              <Col xs={4}>
                <Select
                  id='ddlTookConditions'
                  idError='fdbTookConditionsError'
                  options={[
                    {
                      value: 0,
                      label: 'e',
                    },
                    {
                      value: 1,
                      label: 'Ou',
                    },
                  ]}
                  isInvalid={false}
                  errorText='Selecione um item neste campo'
                  styles={styles}
                  className='mt-4'
                  disabled={!multipleDiscountConditionEnabled[segmentIndex]}
                  onChange={(e) => {
                    const updatedConditions = [...discountCondition]
                    updatedConditions[segmentIndex] = e.value
                    setDiscountCondition(updatedConditions)
                  }}
                />
                <Check
                  id='swtDisableTook'
                  label='Desativar campo'
                  type='switch'
                  onChange={(e) => {
                    const updatedEnabled = [...multipleDiscountConditionEnabled]
                    updatedEnabled[segmentIndex] = !updatedEnabled[segmentIndex]
                    setMultipleDiscountConditionEnabled(updatedEnabled)
                  }}
                  className={`
                    ${styles.check({ switch: 'normal' })}
                    ps-0 mt-1
                  `}
                />
              </Col>
              <Col xs={4}>
                <Form.Label
                  className={styles.tipography({ paragraphs: 'smallBold' })}
                  style={{ paddingTop: 1 }}
                >
                  Prêmio:
                </Form.Label>
                <Typeahead
                  id={`txtCampaignPrizeCondition${segmentIndex}`}
                  labelKey={(option) => option.description}
                  filterBy={['value']}
                  placeholder='0,00'
                  idError={`fdbCampaignPrizeConditionError${segmentIndex}`}
                  errorText={
                    discountValueFromConditionError !== null
                      ? 'Campo obrigatório!'
                      : null
                  }
                  isInvalid={
                    discountValueFromConditionError !== null ||
                    !fieldsValid.prizeValue
                  }
                  onBlur={() => {
                    const selectedValue =
                      discountValueFromCondition[segmentIndex]?.value || 0
                    if (parseFloat(selectedValue) > 0) {
                      setDiscountValueFromConditionError(null)
                    } else {
                      setDiscountValueFromConditionError('Insira um valor.')
                    }
                    setDiscountValueFromConditionUnchanged(
                      parseFloat(selectedValue) === null
                    )
                  }}
                  options={[
                    {
                      type: 0,
                      value: rawPrizeValueCondition,
                      description: `R$ ${formatToBRL(rawPrizeValueCondition)}`,
                    },
                    {
                      type: 1,
                      value: rawPrizeValueCondition,
                      description: `${formatToBRL(rawPrizeValueCondition)} %`,
                    },
                  ]}
                  onInputChange={(value) => {
                    const formattedValue = parseFloat(value.replace(',', '.'))
                    if (!isNaN(formattedValue)) {
                      setRawPrizeValueCondition(formattedValue)
                      handleValueChange(value, setRawPrizeValueCondition)
                    }
                  }}
                  label='prizeValue'
                  style={{ height: 44 }}
                  disabled={!multipleDiscountConditionEnabled[segmentIndex]}
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      const { value, type, description } = selected[0]
                      setDiscountValueFromCondition((prevState) => {
                        const updatedArray = [...prevState]
                        updatedArray[segmentIndex] = {
                          value,
                          type,
                          description,
                        }
                        return updatedArray
                      })
                    }
                  }}
                />
                <div style={{ color: '#6300ff' }}>
                  {discountValueFromConditionError}
                </div>
              </Col>
            </>
          </Row>
        </div>
      ))}

      <Row className='mt-4'>
        <Col xs={3}>
          <Check
            id='addRanking'
            label='Adicionar ranking'
            type='switch'
            onChange={(e) => {
              campaignContext.setIsRanking(e.target.value === 'on')
            }}
            value={campaignContext.isRanking}
            className={`
              ${styles.check({ switch: 'normal' })}
              ps-0
              mb-0
            `}
            hasTooltip={true}
            tooltipText='Com o Ranking você envia para os participantes a posição individual de cada um, ex: 3º Lugar. Para enviar o Ranking basta fazer uma apuração, seja "Parcial" ou no Encerramento da Campanha.'
          />
        </Col>
      </Row>
    </>
  )
}

CampaignHybrid.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeSwitch: PropTypes.func,
}

export default CampaignHybrid

import { css } from './CreateStitches';

export const pagination = css({
  marginBottom: 20,

  '& .page-item': {
    '&.active': {
      '& .page-link': {
        backgroundColor: '$brandColorPrimaryPure',
        borderColor: '$brandColorPrimaryPure',
        color: '$neutralColorHighPure',
        opacity: 1,
      },
    },

    '& .page-link': {
      opacity: 0.24,
      borderRadius: 2,
      padding: 0,
      marginLeft: 4,
      width: 24,
      height: 2,
      display: 'block',
    },
  },

  variants: {
    paginationType: {
      whiteBG: {
        '& .page-item': {
          '& .page-link': {
            backgroundColor: '$neutralColorLowMedium',
            borderColor: '$neutralColorLowMedium',
          },
        },
      },
      colorBG: {
        '& .page-item': {
          '& .page-link': {
            backgroundColor: '$neutralColorHighPure',
            borderColor: '$neutralColorHighPure',
          },
        },
      },
      withNumbers: {
        '& .page-item': {
          '&:first-child': {
            marginRight: '$spacingXXS',

            '& .page-link': {
              '&:first-child': {
                '&:after': {
                  content: 'arrow_back',
                  fontFamily: '"Material Icons"',
                  color: '$neutralColorLowPure',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              },
              '& > span': {
                '&:first-child': {
                  display: 'none',
                },
              },
            },
          },

          '& .page-link': {
            backgroundColor: '$neutralColorHighLight',
            borderColor: '$neutralColorHighLight',
            color: '$neutralColorLowPure',
            width: 48,
            height: 48,
            borderRadius: '$borderRadiusCircular',
            marginRight: '$spacingNano',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 1,

            '&[disabled]': {
              pointerEvents: 'none',
            },
          },

          '&:last-child': {
            marginLeft: '$spacingXXS',

            '& .page-link': {
              '&:first-child': {
                '&:after': {
                  content: 'arrow_forward',
                  fontFamily: '"Material Icons"',
                  color: '$neutralColorLowPure',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              },
              '& > span': {
                '&:first-child': {
                  display: 'none',
                },
              },
            },
          },
        },
      },
      withNumbersColor: {},
    },
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { ButtonComponent } from 'cashin-components';

const NotAllowed = ({ styles, history }) => {
  const handleHomePage = () => {
    history.push('/');
    history.go('/');
  };

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={6}>
            <h1
              className={`
                ${styles.tipography({ headings: 'smallBold' })}
                my-3
              `}
            >
              Oops... Permissão negada!
            </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end" />
          <Col xs={12}>
            <div
              className={`
                ${styles.border({ borderBottomWidth: 'thick' })}
                mb-4 page-border
              `}
            />
          </Col>
          <Col xs={12}>
            <h2
              className={`
                ${styles.tipography({ headings: 'smallBold' })}
                mt-5 pt-5 text-center
              `}
            >
              Parece que a página que você tentou acessar
              <br />
              tem acesso restrito.
            </h2>
          </Col>
          <Col xs={12} className="d-flex justify-content-center mb-5 pb-5">
            <ButtonComponent
              className={`
                ${styles.buttonStandardLarge({
                  large: 'primary',
                })}
                my-5
              `}
              text="Voltar para a página inicial"
              styles={styles}
              onClick={() => handleHomePage()}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

NotAllowed.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default NotAllowed;

import React from "react";
import PropTypes from "prop-types";
import { NavComponent } from "cashin-components";
import { Auth } from "aws-amplify";
import useIdentificationInfo from "../API/queries/company/useIdentificationInfo";
import changeTenant from "../API/queries/admin/channge-tenant";
import { useLocation } from "react-router-dom";

const menuItems = [
  {
    iconName: "dashboard",
    linkName: "Início",
    url: "/dashboard",
    isActive: false,
  },
  {
    iconName: "campaign",
    linkName: "Campanhas",
    url: "/campaigns",
    isActive: false,
  },
  {
    iconName: "how_to_reg",
    linkName: "Apuração",
    url: "/campaign-verification",
    isActive: false,
  },
  {
    iconName: "list",
    linkName: "Relatórios",
    url: "/reports",
    isActive: false,
  },
];

const NavMenu = ({ styles, state, isMenuOpened }) => {
  const location = useLocation();

  const activePage = location.pathname;

  const { data } = useIdentificationInfo();

  switch (activePage) {
    case "/dashboard":
    case "/customer-base":
      menuItems[0].isActive = true;
      menuItems[1].isActive = false;
      menuItems[2].isActive = false;
      menuItems[3].isActive = false;
      break;

    case "/campaigns":
    case "/create-campaign":
      menuItems[0].isActive = false;
      menuItems[1].isActive = true;
      menuItems[2].isActive = false;
      menuItems[3].isActive = false;
      break;

    case "/campaign-verification":
      menuItems[0].isActive = false;
      menuItems[1].isActive = false;
      menuItems[2].isActive = true;
      menuItems[3].isActive = false;
      break;

    case "/reports":
      menuItems[0].isActive = false;
      menuItems[1].isActive = false;
      menuItems[2].isActive = false;
      menuItems[3].isActive = true;
      break;

    default:
      menuItems[0].isActive = false;
      menuItems[1].isActive = false;
      menuItems[2].isActive = false;
      menuItems[3].isActive = false;
      break;
  }

  const handleOnChangeCompany = async (companyId) => {
    await changeTenant(companyId);

    await Auth.signOut();

    window.localStorage.clear();

    window.location.reload();
  };

  return (
    <NavComponent
      styles={styles}
      state={state}
      menuItems={menuItems}
      isMenuOpened={isMenuOpened}
      companies={data || []}
      onCompanyChange={handleOnChangeCompany}
    />
  );
};

NavMenu.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  history: PropTypes.object,
  isMenuOpened: PropTypes.bool,
};

export default NavMenu;

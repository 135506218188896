import api from "../../api";

const changeResultValueRequest = async (
  validationId,
  validationResultId,
  value
) => {
  return await api.post(`/campaign-validate/${validationId}/add-value`, {
    value,
    validationId: validationResultId,
  });
};

export default changeResultValueRequest;

import api from "../../api";

const changeValidationResultStatusRequest = async (
  validationId,
  validationResultId,
  status
) => {
  return await api.post(`/campaign-validate/${validationId}/change-status`, {
    status,
    validationId: validationResultId,
  });
};

export default changeValidationResultStatusRequest;

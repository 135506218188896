import { css } from './CreateStitches';
import ImageLogin from './img/login-image.png';
import CheckGreen from './img/Check/check-green.png';

export const login = css({
  variants: {
    login: {
      imgLogin: {
        background: [`url(${ImageLogin})`],
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        paddingTop: '$spacingSM',
        paddingLeft: '$spacingSM',
        borderRadius: 20,
        height: 970,
      },
    },
    loginIcons: {
      checkGreen: {
        background: [`url(${CheckGreen})`],
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: 64,
        height: 64,
      },
    },
    footer: {
      defaultFooter: {
        textAlign: 'center',
        position: 'absolute',
        marginTop: '4rem',
        marginBottom: 24,
        width: 404,
      },
    },
  },
});

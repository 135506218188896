import React from 'react';
import PropTypes from 'prop-types';

const IconCustom = ({ styles, className, iconName, onClick }) => {
  return (
    <span
      className={`${styles} material-icons-custom ${iconName} ${className}`}
      aria-hidden="true"
      onClick={onClick}
    />
  );
};

IconCustom.propTypes = {
  styles: PropTypes.object,
  className: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconCustom;

import { css } from './CreateStitches';

export const table = css({
  borderRadius: '$borderRadiusSM',
  marginBottom: '$spacingXXXS',
  overflow: 'hidden',
  boxShadow: '$shadowLevel1',
  margin: '0 !important',
  border: 'none !important',
  width: '100% !important',

  th: {
    padding: '12px $spacingXXS',
    backgroundColor: '$neutralColorHighLight',
    borderBottom: 'solid 1px $neutralColorHighMedium',
    textTransform: 'uppercase',
    fontFamily: '$fontFamilyHighBaseBold',
    fontSize: '$fontSizeXXS',
    color: '$neutralColorLowMedium',

    '&:last-child': {
      width: 176,
    },
  },

  td: {
    padding: '$spacingXXXS $spacingXXS',
    fontFamily: '$fontFamilyHighBaseRegular',
    fontSize: '$fontSizeXS',
    color: '$neutralColorLowMedium',
    verticalAlign: 'middle',
    height: 56,

    '&:last-child': {
      width: 176,
    },

    img: {
      width: 56,
      borderRadius: '$borderRadiusSM',
      marginRight: '$spacingXXXS',
    },

    div: {
      button: {
        marginRight: 10,

        '&:last-child': {
          marginRight: 0,
        },
      },
    },

    '&.table-edit-text-field': {
      '.edit-area': {
        '> div': {
          marginBottom: '0 !important',

          '.form-control': {
            padding: '0 5px',
          },

          span: {
            color: '$feedbackColorHelperPure',
          },
        },
      },
    },
  },

  '~ .dataTables_paginate': {
    marginTop: '$spacingXXS',
    marginBottom: '$spacingXXS',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    'a.paginate_button': {
      backgroundColor: '$neutralColorHighLight !important',
      borderColor: '$neutralColorHighLight !important',
      color: '$neutralColorLowPure !important',
      width: 48,
      height: 48,
      borderRadius: '$borderRadiusCircular !important',
      marginRight: '$spacingXXS',

      '&:hover': {
        background: '$neutralColorHighLight !important',
        border: 'solid 1px $neutralColorHighLight !important',
        color: '$neutralColorLowPure !important',
      },

      '&.previous': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        textIndent: '-999999999999999999px',

        '&::after': {
          content: 'arrow_back',
          fontFamily: '"Material Icons"',
          color: '$neutralColorLowPure',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textIndent: 0,
        },
      },

      '&.next': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        textIndent: '-999999999999999999px',

        '&::after': {
          content: 'arrow_forward',
          fontFamily: '"Material Icons"',
          color: '$neutralColorLowPure',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textIndent: 0,
        },
      },

      '&.disabled': {
        pointerEvents: 'none',
      },

      '&:last-child': {
        marginLeft: '$spacingXXS',
        marginRight: 0,
      },
    },

    span: {
      '.paginate_button': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '$spacingNano',

        '&.current': {
          background: '$brandColorPrimaryPure !important',
          border: 'solid 1px $brandColorPrimaryPure !important',
          color: '$neutralColorHighPure !important',

          '&:hover': {
            background: '$brandColorPrimaryPure !important',
            border: 'solid 1px $brandColorPrimaryPure !important',
            color: '$neutralColorHighPure !important',
          },
        },

        '&:last-child': {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
  },
});

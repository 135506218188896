import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { Footer } from '../footer';
import RecoveryAccessForm from './recovery-access-form';
import RecoveryAccessConfirmation from './recovery-access-confirmation';

const RecoveryAccessPage = ({
  styles,
  state,
  handleKeyDown,
  handleChange,
  handleRecoverPassword,
  handleBackToLogin,
}) => {
  return (
    <Container fluid className="p-0">
      <Container>
        <Row>
          <Col xs={6} className={styles.login({ login: 'imgLogin' })}>
            <div
              className={styles.logos({
                logo: 'largeSecondary',
              })}
            />
          </Col>
          <Col
            xs={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              {state.recoverPasswordForm ? (
                <RecoveryAccessForm
                  styles={styles}
                  state={state}
                  handleKeyDown={handleKeyDown}
                  handleChange={handleChange}
                  handleRecoverPassword={handleRecoverPassword}
                />
              ) : (
                <RecoveryAccessConfirmation
                  styles={styles}
                  state={state}
                  handleBackToLogin={handleBackToLogin}
                />
              )}
              <Footer styles={styles} />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

RecoveryAccessPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleRecoverPassword: PropTypes.func,
  handleBackToLogin: PropTypes.func,
};

export default RecoveryAccessPage;

import { css } from "./CreateStitches";

export const dashboard = css({
  ".dashboard-info-area": {
    padding: 19,
    height: 121,

    ".title-dashboard": {
      fontFamily: "$fontFamilyHighBaseBold",
      fontSize: "$fontSizeXS",
      color: "$highlightColorPure",
      margin: 0,
    },

    ".value-dashboard": {
      fontFamily: "$fontFamilyHighlightBold",
      fontSize: "$fontSizeHSM",
      color: "$neutralColorLowPure",
      margin: 0,
    },

    ".info-dashboard": {
      fontFamily: "$fontFamilyHighBaseRegular",
      fontSize: "$fontSizeXXS",
      color: "$neutralColorLowMedium",
      margin: 0,
    },
  },

  ".calendar-area": {},
});

import { Accordion } from 'react-bootstrap'
import { styles } from 'cashin-design-system/Styles/Styles'
import { getRandomNumber } from '../../functions/utils'
import { CampaignTable } from './CampaignTable'
import { BadgeComponent } from 'cashin-components'

const calculateTotalPercentage = (engagement) => {
  if (!Array.isArray(engagement)) return '0%'

  const totalPercentage = engagement.reduce((total, item) => {
    return total + item.percentage
  }, 0)

  return `${totalPercentage}%`
}

const badgeTypeByStatus = {
  Active: 'info',
  Deleted: 'danger',
  Draft: 'warning',
  Finished: 'success',
}

export const badgeTextByStatus = {
  Active: 'Ativa',
  Deleted: 'Deletada',
  Draft: 'Rascunho',
  Finished: 'Concluída',
}

export const CampaignAccordion = ({ data }) => {
  const themeColors = [
    '#A500B9',
    '#D778CD',
    '#FF7503',
    '#1B7D2C',
    '#76E388',
    '#666666',
    '#5900E1',
  ]

  return (
    <Accordion className={styles.customAccordion()} defaultActiveKey='0'>
      {data &&
        data.map((item, i) => {
          const randomIndex = getRandomNumber(themeColors.length)
          const randomColor = themeColors[randomIndex]

          return (
            <Accordion.Item
              eventKey={i}
              style={{ borderLeft: `4px solid ${randomColor}` }}
            >
              <Accordion.Header>
                <div className='headerInfos'>
                  <div className='texts'>
                    <span
                      className={styles.tipography({
                        paragraphs: 'mediumBold',
                      })}
                    >
                      {item?.name}
                    </span>
                    <p>
                      Engajamento {calculateTotalPercentage(item.engagement)}
                    </p>
                  </div>

                  <BadgeComponent
                    text={badgeTextByStatus[item.status ?? 'Active']}
                    bgType={badgeTypeByStatus[item.status ?? 'Active']}
                    isBadgePill={true}
                    styles={styles}
                  />
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <CampaignTable item={data[i]} />
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
    </Accordion>
  )
}

import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import useIdentificationInfo from "../API/queries/company/useIdentificationInfo";
import { BreadcrumbComponent, TabsComponent } from "cashin-components";
import CompanyData from "./company-data";
import CreateCustomerPage from "../create-customer/create-customer-page";

const EditCustomerPage = ({ styles, state, history }) => {
  const [currentUser, setCurrentUser] = useState({});  
  const { data: indentificantionData } = useIdentificationInfo();
  const companyName = indentificantionData?.find((x) => x.id === currentUser?.attributes?.["custom:tenantId"])?.description;  

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser());
    }; 
    runAsync();
  }, []);  

  const redirectTabAnotherPage = (textContent) => {
    let location = window.location.pathname.split("/");

    if (textContent === "Negociação comercial") {
      history.push(`/business-negociation/${location[location.length - 1]}`);
      history.go(`/business-negociation/${location[location.length - 1]}`);
    }
  };

  const tabs = [
    {
      eventKey: "customer-data",
      title: "Dados da empresa",
      active: true,
      // content: <CompanyData styles={styles} state={state} />,
      content: <CreateCustomerPage styles={styles} state={state} isEdit />,
    },
    {
      eventKey: "business-negociation",
      title: "Negociação comercial",
      active: false,
      content: <CompanyData styles={styles} state={state} />,
    },
  ];

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12} className="pt-4">
            <BreadcrumbComponent
              hasIcon={true}
              styles={styles}
              initialIcon="home-color"
              initialNode="Dashboard"
              breadcrumbData={state.breadcrumb}
            />
          </Col>
          <Col xs={12}>
            <h1
              className={`
                ${styles.tipography({ headings: "smallBold" })}
                my-3
              `}
            >
              {companyName}
            </h1>
          </Col>
          <Col xs={12}>
            <TabsComponent
              items={tabs}
              tabID="participant-tab-input"
              contentTabID="content-example"
              styles={styles}
              hasBiggerFont={true}
              className={`
                ${styles.tabs({ tabsType: "main" })}
                tab-bg
              `}
              onClick={(e) => redirectTabAnotherPage(e.target.textContent)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

EditCustomerPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  history: PropTypes.object,
};

export default EditCustomerPage;

import React, { createContext, useState } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import { createBrowserHistory } from 'history'
import validateIfUserExist from '../API/queries/admin/validate-user-exists'
import { toast } from 'react-toastify'

export const CognitoContext = createContext()

// HML
Amplify.configure({
  aws_project_region: process.env.REACT_APP_aws_project_region,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_aws_cognito_identity_pool_id,
  aws_cognito_region: process.env.REACT_APP_aws_cognito_region,
  aws_user_pools_id: process.env.REACT_APP_aws_user_pools_id,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_aws_user_pools_web_client_id,
})

const history = createBrowserHistory()

const CognitoProvider = ({ children }) => {
  const [tempUser, setTempUser] = useState()
  const [tempEmail, setTempEmail] = useState()

  const signIn = async (username, password) => {
    const login = await Auth.signIn({ username, password })
      .then((response) => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setTempUser(response)
          setTempEmail(username)
          return 'NEW_PASSWORD_REQUIRED'
        }

        Auth.currentAuthenticatedUser().then((data) => {
          const roles = ['Admin', 'Company']

          const containsAny = roles.some((word) =>
            data.attributes['custom:profile'].includes(word)
          )

          if (!containsAny) {
            toast.error('Você não tem permissão para acessar o sistema.', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })

            return
          }

          window.localStorage.setItem(
            'profile',
            JSON.stringify(data.attributes)
          )

          window.localStorage.setItem('data', JSON.stringify(data))

          history.push('/dashboard')
          history.go('/dashboard')
        })
      })
      .catch((error) => {
        toast.error('E-mail ou senha está incorreto.', { autoClose: 5000, theme: 'light' });
      })

    return login
  }

  const signOut = async () => {
    let logoff = await Auth.signOut()
      .then(() => {
        window.localStorage.clear()

        history.push('/')
        history.go('/')
      })
      .catch((error) => {
        console.log(error)
      })

    return logoff
  }

  const confirmUser = async (password) => {
    await Auth.completeNewPassword(tempUser, password)

    await signIn(tempEmail, password)
  }

  const checkUserExists = async (email) => {
    try {
      const response = await validateIfUserExist(email)
      return response.data
    } catch (error) {
      console.log(error.message)
    }
  }

  const confirmForgotPassword = async (username, code, newPassword) => {
    return await Auth.forgotPasswordSubmit(username, code, newPassword)
  }

  const sendForgotPassword = async (username) => {
    return await Auth.forgotPassword(username)
  }

  const isAdmin = () => {
    const userData = JSON.parse(window.localStorage.getItem('profile'))

    return userData && userData['custom:profile'] === 'Admin' ? true : false
  }

  return (
    <CognitoContext.Provider
      value={{
        signIn,
        signOut,
        confirmUser,
        confirmForgotPassword,
        sendForgotPassword,
        isAdmin,
        checkUserExists,
      }}
    >
      {children}
    </CognitoContext.Provider>
  )
}

export default CognitoProvider

import { css } from './CreateStitches';

export const spacing = css({
  variants: {
    exampleSpacing: {
      layerBorder: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '#FF7101',
        background: 'rgba(255, 113, 1, 0.32)',
      },
      layerBorderInset: {
        borderStyle: 'solid',
        borderColor: '$feedbackColorSuccessLight',
        background: '$neutralColorHighPure',
      },
    },
    sizeSpacingWidth: {
      quarck: {
        width: '$quarck',
      },
      nano: {
        width: '$nano',
      },
      xxxs: {
        width: '$xxxs',
      },
      xxs: {
        width: '$xxs',
      },
      xs: {
        width: '$xs',
      },
      sm: {
        width: '$sm',
      },
      md: {
        width: '$md',
      },
      lg: {
        width: '$lg',
      },
      xl: {
        width: '$xl',
      },
      xxl: {
        width: '$xxl',
      },
      xxxl: {
        width: '$xxxl',
      },
      huge: {
        width: '$huge',
      },
      giant: {
        width: '$giant',
      },
    },
    sizeSpacingHeight: {
      quarck: {
        height: '$quarck',
      },
      nano: {
        height: '$nano',
      },
      xxxs: {
        height: '$xxxs',
      },
      xxs: {
        height: '$xxs',
      },
      xs: {
        height: '$xs',
      },
      sm: {
        height: '$sm',
      },
      md: {
        height: '$md',
      },
      lg: {
        height: '$lg',
      },
      xl: {
        height: '$xl',
      },
      xxl: {
        height: '$xxl',
      },
      xxxl: {
        height: '$xxxl',
      },
      huge: {
        height: '$huge',
      },
      giant: {
        height: '$giant',
      },
    },
    paddingSpace: {
      quarck: {
        padding: '$spacingQuarck',
      },
      nano: {
        padding: '$spacingNano',
      },
      xxxs: {
        padding: '$spacingXXXS',
      },
      xxs: {
        padding: '$spacingXXS',
      },
      xs: {
        padding: '$spacingXS',
      },
      sm: {
        padding: '$spacingSM',
      },
      md: {
        padding: '$spacingMD',
      },
      lg: {
        padding: '$spacingLG',
      },
      xl: {
        padding: '$spacingXL',
      },
      xxl: {
        padding: '$spacingXXL',
      },
      xxxl: {
        padding: '$spacingXXXL',
      },
      huge: {
        padding: '$spacingHuge',
      },
      giant: {
        padding: '$spacingGiant',
      },
    },
    paddingSpaceTop: {
      quarck: {
        paddingTop: '$spacingQuarck',
      },
      nano: {
        paddingTop: '$spacingNano',
      },
      xxxs: {
        paddingTop: '$spacingXXXS',
      },
      xxs: {
        paddingTop: '$spacingXXS',
      },
      xs: {
        paddingTop: '$spacingXS',
      },
      sm: {
        paddingTop: '$spacingSM',
      },
      md: {
        paddingTop: '$spacingMD',
      },
      lg: {
        paddingTop: '$spacingLG',
      },
      xl: {
        paddingTop: '$spacingXL',
      },
      xxl: {
        paddingTop: '$spacingXXL',
      },
      xxxl: {
        paddingTop: '$spacingXXXL',
      },
      huge: {
        paddingTop: '$spacingHuge',
      },
      giant: {
        paddingTop: '$spacingGiant',
      },
    },
    paddingSpaceRight: {
      quarck: {
        paddingRight: '$spacingQuarck',
      },
      nano: {
        paddingRight: '$spacingNano',
      },
      xxxs: {
        paddingRight: '$spacingXXXS',
      },
      xxs: {
        paddingRight: '$spacingXXS',
      },
      xs: {
        paddingRight: '$spacingXS',
      },
      sm: {
        paddingRight: '$spacingSM',
      },
      md: {
        paddingRight: '$spacingMD',
      },
      lg: {
        paddingRight: '$spacingLG',
      },
      xl: {
        paddingRight: '$spacingXL',
      },
      xxl: {
        paddingRight: '$spacingXXL',
      },
      xxxl: {
        paddingRight: '$spacingXXXL',
      },
      huge: {
        paddingRight: '$spacingHuge',
      },
      giant: {
        paddingRight: '$spacingGiant',
      },
    },
    paddingSpaceBottom: {
      quarck: {
        paddingBottom: '$spacingQuarck',
      },
      nano: {
        paddingBottom: '$spacingNano',
      },
      xxxs: {
        paddingBottom: '$spacingXXXS',
      },
      xxs: {
        paddingBottom: '$spacingXXS',
      },
      xs: {
        paddingBottom: '$spacingXS',
      },
      sm: {
        paddingBottom: '$spacingSM',
      },
      md: {
        paddingBottom: '$spacingMD',
      },
      lg: {
        paddingBottom: '$spacingLG',
      },
      xl: {
        paddingBottom: '$spacingXL',
      },
      xxl: {
        paddingBottom: '$spacingXXL',
      },
      xxxl: {
        paddingBottom: '$spacingXXXL',
      },
      huge: {
        paddingBottom: '$spacingHuge',
      },
      giant: {
        paddingBottom: '$spacingGiant',
      },
    },
    paddingSpaceLeft: {
      quarck: {
        paddingLeft: '$spacingQuarck',
      },
      nano: {
        paddingLeft: '$spacingNano',
      },
      xxxs: {
        paddingLeft: '$spacingXXXS',
      },
      xxs: {
        paddingLeft: '$spacingXXS',
      },
      xs: {
        paddingLeft: '$spacingXS',
      },
      sm: {
        paddingLeft: '$spacingSM',
      },
      md: {
        paddingLeft: '$spacingMD',
      },
      lg: {
        paddingLeft: '$spacingLG',
      },
      xl: {
        paddingLeft: '$spacingXL',
      },
      xxl: {
        paddingLeft: '$spacingXXL',
      },
      xxxl: {
        paddingLeft: '$spacingXXXL',
      },
      huge: {
        paddingLeft: '$spacingHuge',
      },
      giant: {
        paddingLeft: '$spacingGiant',
      },
    },
    marginSpace: {
      quarck: {
        margin: '$spacingQuarck',
      },
      nano: {
        margin: '$spacingNano',
      },
      xxxs: {
        margin: '$spacingXXXS',
      },
      xxs: {
        margin: '$spacingXXS',
      },
      xs: {
        margin: '$spacingXS',
      },
      sm: {
        margin: '$spacingSM',
      },
      md: {
        margin: '$spacingMD',
      },
      lg: {
        margin: '$spacingLG',
      },
      xl: {
        margin: '$spacingXL',
      },
      xxl: {
        margin: '$spacingXXL',
      },
      xxxl: {
        margin: '$spacingXXXL',
      },
      huge: {
        margin: '$spacingHuge',
      },
      giant: {
        margin: '$spacingGiant',
      },
    },
    marginSpaceTop: {
      quarck: {
        marginTop: '$spacingQuarck',
      },
      nano: {
        marginTop: '$spacingNano',
      },
      xxxs: {
        marginTop: '$spacingXXXS',
      },
      xxs: {
        marginTop: '$spacingXXS',
      },
      xs: {
        marginTop: '$spacingXS',
      },
      sm: {
        marginTop: '$spacingSM',
      },
      md: {
        marginTop: '$spacingMD',
      },
      lg: {
        marginTop: '$spacingLG',
      },
      xl: {
        marginTop: '$spacingXL',
      },
      xxl: {
        marginTop: '$spacingXXL',
      },
      xxxl: {
        marginTop: '$spacingXXXL',
      },
      huge: {
        marginTop: '$spacingHuge',
      },
      giant: {
        marginTop: '$spacingGiant',
      },
    },
    marginSpaceRight: {
      quarck: {
        marginRight: '$spacingQuarck',
      },
      nano: {
        marginRight: '$spacingNano',
      },
      xxxs: {
        marginRight: '$spacingXXXS',
      },
      xxs: {
        marginRight: '$spacingXXS',
      },
      xs: {
        marginRight: '$spacingXS',
      },
      sm: {
        marginRight: '$spacingSM',
      },
      md: {
        marginRight: '$spacingMD',
      },
      lg: {
        marginRight: '$spacingLG',
      },
      xl: {
        marginRight: '$spacingXL',
      },
      xxl: {
        marginRight: '$spacingXXL',
      },
      xxxl: {
        marginRight: '$spacingXXXL',
      },
      huge: {
        marginRight: '$spacingHuge',
      },
      giant: {
        marginRight: '$spacingGiant',
      },
    },
    marginSpaceBottom: {
      quarck: {
        marginBottom: '$spacingQuarck',
      },
      nano: {
        marginBottom: '$spacingNano',
      },
      xxxs: {
        marginBottom: '$spacingXXXS',
      },
      xxs: {
        marginBottom: '$spacingXXS',
      },
      xs: {
        marginBottom: '$spacingXS',
      },
      sm: {
        marginBottom: '$spacingSM',
      },
      md: {
        marginBottom: '$spacingMD',
      },
      lg: {
        marginBottom: '$spacingLG',
      },
      xl: {
        marginBottom: '$spacingXL',
      },
      xxl: {
        marginBottom: '$spacingXXL',
      },
      xxxl: {
        marginBottom: '$spacingXXXL',
      },
      huge: {
        marginBottom: '$spacingHuge',
      },
      giant: {
        marginBottom: '$spacingGiant',
      },
    },
    marginSpaceLeft: {
      quarck: {
        marginLeft: '$spacingQuarck',
      },
      nano: {
        marginLeft: '$spacingNano',
      },
      xxxs: {
        marginLeft: '$spacingXXXS',
      },
      xxs: {
        marginLeft: '$spacingXXS',
      },
      xs: {
        marginLeft: '$spacingXS',
      },
      sm: {
        marginLeft: '$spacingSM',
      },
      md: {
        marginLeft: '$spacingMD',
      },
      lg: {
        marginLeft: '$spacingLG',
      },
      xl: {
        marginLeft: '$spacingXL',
      },
      xxl: {
        marginLeft: '$spacingXXL',
      },
      xxxl: {
        marginLeft: '$spacingXXXL',
      },
      huge: {
        marginLeft: '$spacingHuge',
      },
      giant: {
        marginLeft: '$spacingGiant',
      },
    },
  },
});

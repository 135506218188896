import { useQuery } from "react-query";
import qs from "qs";
import api from "../../api";

const useCompanyNegotiationUsagePaginatedData = (
  companyId, 
  pageNumber
) => {
  const queryString = qs.stringify(
    {        
      "PageNumber": pageNumber,
      "PageSize": 10,
    },
    { skipNulls: true }
  );

  return useQuery(
    ["company-negotiation-paginated-data", pageNumber],
    async () => api.get(`/company-negotiation-usage/company/paginated/${companyId}?${queryString}`),
    {
      cacheTime: 60000 * 2,
      enabled: !!companyId,    
      refetchOnWindowFocus: false
    }
  );
};

export default useCompanyNegotiationUsagePaginatedData;

import { useQuery } from "react-query";
import api from "../../api";

const useGetCompanyById = (
  companyId  
) => {
  return useQuery(
    ["company-by-id", companyId],
    async () => api.get(`/company/${companyId}`),
    {
      cacheTime: 60000 * 2,
      enabled: !!companyId,    
      refetchOnWindowFocus: false
    }
  );
};

export default useGetCompanyById;

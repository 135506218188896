import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Input } from 'cashin-components'
import FolderObrigado from './layout-generico/folder-obrigado'
import FolderWhatsAppObrigado from './layout-whatsapp/folder-obrigado'
import FolderWhatsappPreview from './layout-whatsapp/folder-whatsapp-preview'

// Executivo
import FolderThanks from './layout-executivo/folder-thanks'

import FolderThanksDivertido from './layout-divertido/folder-thanks'
import FolderThanksModerno from './layout-moderno/folder-thanks'

const LayoutExampleObrigado = ({
  styles,
  state,
  titleComunication,
  setTitleComunication,
  introductionComunication,
  setIntroductionComunication,
  descriptionComunication,
  setDescriptionComunication,
  attachmentFilePath,
  layoutTitleChoosen,
  layoutInfo,
  handleTextOnlyObrigado,
  handleHtmlObrigado,
  render,
}) => {
  return (
    <Row>
      <Col>
        {(layoutTitleChoosen === 'WhatsApp padrão' ||
          layoutTitleChoosen === 'WhatsApp personalizado') &&
          render && (
            <FolderWhatsappPreview
              titleComunication={titleComunication}
              setTitleComunication={setTitleComunication}
              introductionComunication={introductionComunication}
              setIntroductionComunication={setIntroductionComunication}
              descriptionComunication={descriptionComunication}
              setDescriptionComunication={setDescriptionComunication}
              attachmentFilePath={attachmentFilePath}
              styles={styles}
              state={state}
              layoutInfo={layoutInfo}
            />
          )}
        {layoutTitleChoosen === 'Moderno' && (
          <FolderThanksModerno
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}
        {layoutTitleChoosen === 'Executivo' && (
          <FolderThanks
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}

        {layoutTitleChoosen === 'Divertido' && (
          <FolderThanksDivertido
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}
      </Col>
    </Row>
  )
}

LayoutExampleObrigado.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutTitleChoosen: PropTypes.string,
  layoutInfo: PropTypes.object,
  handleTextOnlyObrigado: PropTypes.func,
  handleHtmlObrigado: PropTypes.func,
}

export default LayoutExampleObrigado

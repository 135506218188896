import api from "../../api";

const reproveValidationResultStatusCustomerRequest = async (
  validationId,
  validationResultId,
  status,
  rejectionReason,
  rejectionDescription
) => {
  return await api.post(
    `/campaign-validate/${validationId}/customer/reprove-result`,
    {
      status,
      validationId: validationResultId,
      rejectionReason,
      rejectionDescription,
    }
  );
};

export default reproveValidationResultStatusCustomerRequest;

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'
import TextoWhatsApp from 'cashin-design-system/Styles/img/WhatsApp/mensagem-boas-vindas.png'
import PdfIcon from '../../../../assets/icons/pdf.png'
import WordIcon from '../../../../assets/icons/word.png'
import ExcelIcon from '../../../../assets/icons/excel.png'
import PptIcon from '../../../../assets/icons/ppt.png'
import TxtIcon from '../../../../assets/icons/txt.png'

const FolderWhatsAppPreview = ({
  titleComunication,
  introductionComunication,
  descriptionComunication,
  attachmentFilePath,
  btnAddParticipation,
  styles,
  state,
  layoutInfo,
}) => {
  const stylesWhatsApp = {
    input: {
      display: 'block',
      width: '80%',
      margin: '10px auto',
      padding: '5px',
    },
    textarea: {
      display: 'block',
      width: '80%',
      height: '100px',
      margin: '10px auto',
      padding: '5px',
    },
    button: {
      display: 'block',
      margin: '20px auto',
    },
    device: {
      paddingTop: '70px',
      paddingLeft: '14px',
      width: '25rem',
      height: '50rem',
      position: 'relative',
      background: `url('/iphone14.png') no-repeat center center`,
      backgroundSize: 'cover',
      backgroundColor: 'rgb(229, 221, 213)',
      overflow: 'hidden',
      borderRadius: '80px',
      wordWrap: 'break-word',
    },
    screen: {
      backgroundColor: '#e5ddd5',
      width: '22rem',
      height: '40rem',
      overflow: 'auto',
      margin: '10px',
      padding: '10px',
    },
    message: {
      padding: '5px 10px',
      marginBottom: '5px',
      borderRadius: '10px',
      maxWidth: '98%',
      clear: 'both',
    },
    userMessage: {
      backgroundColor: '#DCF8C6',
      alignSelf: 'flex-end',
      marginLeft: 'auto',
      marginRight: '5px',
    },
    messageImage: {
      height: 200,
    },
    image: {
      float: 'right',
      marginBottom: '0.5rem',
      borderRadius: '.5rem',
      width: 288,
      objectFit: 'cover',
    },
  }

  const handleTextMarkdownToHtml = (text) => {
    if (!text) return ''
    // negrito cpm **
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    // negrito
    text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>')
    // itálico
    text = text.replace(/_(.*?)_/g, '<em>$1</em>')
    // // quebra de linha
    text = text.replace(/\n/g, '<br />')

    return text
  }

  function getFileNameFromUrl(url) {
    const parsedUrl = new URL(url)
    const pathname = parsedUrl.pathname
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1)
    return fileName
  }

  function getFileExtensionFromUrl(url) {
    if (!url) return ''
    const parsedUrl = new URL(url)
    const pathname = parsedUrl.pathname
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1)
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)
    return fileExtension
  }

  const getFileTypeFromUrl = (url) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp']
    const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'webm', 'flv', 'wmv']
    const pdfExtensions = ['pdf']
    const wordExtensions = ['doc', 'docx']
    const excelExtensions = ['xls', 'xlsx']
    const pptExtensions = ['ppt', 'pptx']
    const textExtensions = ['txt', 'odt']

    const fileExtension = getFileExtensionFromUrl(url)

    if (imageExtensions.includes(fileExtension)) {
      return 'image'
    } else if (videoExtensions.includes(fileExtension)) {
      return 'video'
    } else if (pdfExtensions.includes(fileExtension)) {
      return 'pdf'
    } else if (wordExtensions.includes(fileExtension)) {
      return 'word'
    } else if (excelExtensions.includes(fileExtension)) {
      return 'excel'
    } else if (pptExtensions.includes(fileExtension)) {
      return 'ppt'
    } else if (textExtensions.includes(fileExtension)) {
      return 'txt'
    } else {
      return 'unknown'
    }
  }

  function openDocument(doc) {
    window.open(doc, '_blank')
  }

  const iconMapping = {
    pdf: PdfIcon,
    word: WordIcon,
    excel: ExcelIcon,
    ppt: PptIcon,
    txt: TxtIcon,
  }

  const fileType = getFileTypeFromUrl(attachmentFilePath)
  const Icon = iconMapping[fileType] || iconMapping['unknown']

  return (
    <Container>
      <Row>
        <Col xs className='d-flex justify-content-center'>
          <div className={styles.whatsAppMessage()}>
            <div style={stylesWhatsApp.device}>
              <div style={stylesWhatsApp.screen}>
                {attachmentFilePath && (
                  <>
                    {getFileTypeFromUrl(attachmentFilePath) === 'image' && (
                      <img
                        src={attachmentFilePath}
                        alt='Publicidade WhatsApp'
                        style={stylesWhatsApp.image}
                      />
                    )}

                    {(fileType === 'pdf' ||
                      fileType === 'word' ||
                      fileType === 'excel' ||
                      fileType === 'ppt' ||
                      fileType === 'txt') && (
                      <div
                        className='file-container'
                        onClick={() => openDocument(attachmentFilePath)}
                      >
                        <img src={Icon} />
                        {getFileNameFromUrl(attachmentFilePath)}
                      </div>
                    )}
                  </>
                )}
                <div
                  style={{
                    ...stylesWhatsApp.message,
                    ...stylesWhatsApp.userMessage,
                  }}
                >
                  <p
                    style={{ fontWeight: 'bold' }}
                    dangerouslySetInnerHTML={{
                      __html: handleTextMarkdownToHtml(titleComunication),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: handleTextMarkdownToHtml(
                        introductionComunication
                      ),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: handleTextMarkdownToHtml(descriptionComunication),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

FolderWhatsAppPreview.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutInfo: PropTypes.object,
}

export default FolderWhatsAppPreview

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Input } from "cashin-components";
import { styles } from "cashin-design-system/Styles/Styles";
import { CampaignContext } from "../../../context/campaign-context";
import { addMonths } from 'date-fns';

const CampaignRulesForm = ({ onFieldError }) => {
  const campaignContext = useContext(CampaignContext);

  const [canProceedForm, setCanProceedForm] = useState(false);
  const [fieldsValid, setFieldsValid] = useState({
    name: true,
    periodDate: true,
  });
  let [name, setName] = useState("");
  let [site, setSite] = useState("");
  const [[startDate, endDate], setDateRange] = useState([null, null]);

  const handleUploadFileChange = (value) => {
    campaignContext.uploadTermsOfService(value[0]);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    campaignContext.setCampaignState({ name: event.target.value });

    setFieldsValid((currentValue) => ({
      ...currentValue,
      name: event.target.value.length > 0,
    }));
  };

  const handlePeriodChange = (newPeriod) => {
    setDateRange(newPeriod);

    campaignContext.setCampaignState({
      periodDate: { from: newPeriod[0], to: newPeriod[1] },
    });

    setFieldsValid((currentValue) => ({
      ...currentValue,
      periodDate:
        newPeriod.length !== 2 || !!newPeriod?.[0] || !!newPeriod?.[1],
    }));
  };

  useEffect(() => {
    setCanProceedForm(
      fieldsValid.name && fieldsValid.periodDate && startDate && endDate
    );
  }, [fieldsValid]);

  useEffect(() => {
    onFieldError?.(canProceedForm);

    if (canProceedForm)
      campaignContext.setBasicInfoData(
        name,
        { from: startDate, to: endDate },
        site
      );
  }, [canProceedForm, site]);

  useEffect(() => {
    if (campaignContext.campaignState?.name) {
      handleNameChange({
        target: { value: campaignContext.campaignState?.name || "" },
      });
      setSite(campaignContext.campaignState?.site);
      handlePeriodChange([
        campaignContext.campaignState?.periodDate?.from,
        campaignContext.campaignState?.periodDate?.to,
      ]);
    }
  }, []);

  return (
    <Row>
      <Col xs={4}>
        <Input
          id="txtCampaignName"
          inputName="campaignName"
          className={styles.inputs({ input: "default" })}
          idError="fdbCampaignNameError"
          inputType="text"
          labelName="Nome da campanha"
          placeholder="Digite um nome para sua campanha"
          errorText={"Campo obrigatório"}
          isInvalid={!fieldsValid.name}
          styles={styles}
          value={name}
          onChange={handleNameChange}
          hasTooltip={true}
          tooltipText="Aqui você pode escrever o nome que quer dar a sua campanha ☺️"
          maxLength={50}
        />
      </Col>
      <Col
        xs={4}
        className={`
        ${styles.inputs({ datePicker: "datePickerDefault" })}
        d-flex align-items-start
      `}
      >
        <Input
          id="txtDateRangeCampaign"
          idError="fdbDateRangeCampaign"
          labelName="Período"
          errorText={"Campo obrigatório"}
          placeholder={"00/00/0000 - 00/00/0000"}
          styles={styles}
          isDatePicker={true}
          required={true}
          isInvalid={!fieldsValid.periodDate}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          excludeOldDate={false}
          maxDate={addMonths(startDate, 3)}
          monthsShown={2}
          setDateRange={handlePeriodChange}
          hasTooltip={true}
          tooltipText="Escolha o dia para iniciar a sua campanha. Lembre-se que você não poderá voltar para editar esse campo após a campanha já ter iniciado, tudo bem? O período máximo da campanha deve ser de até 3 meses! ☺️"
        />
      </Col>
      <Col xs={4}>
        <div className={styles.inputs({ inputFile: "inputFileDefault" })}>
          <Input
            id="txtFileInput"
            idError="fdbFileInput"
            labelName="Regulamento (opcional)"
            errorText={"Campo obrigatório"}
            filePlaceholderText="Faça upload do arquivo PDF"
            styles={styles}
            hasTooltip={true}
            tooltipText="Se você tiver algum Termo ou comunicado adicional para seus participantes, carregue aqui o PDF. Eles receberão durante a comunicação da campanha ☺️"
            isFileInput={true}
            accept="application/pdf"
            onChange={handleUploadFileChange}
            files={campaignContext.currentTersOfServiceFileReference}
          />
        </div>
      </Col>
      {/* <Col xs={6}>
        <Input
          id="txtCampaignName"
          inputName="campaignName"
          className={styles.inputs({ input: "default" })}
          idError="fdbCampaignNameError"
          inputType="text"
          labelName="Site da campanha (opcional)"
          placeholder="Cole aqui o link da sua campanha"
          errorText={"Campo obrigatório"}
          styles={styles}
          value={site}
          onChange={(e) => setSite(e.target.value)}
          hasTooltip={true}
          tooltipText="Quer enviar para seus participantes um Site, Vídeo ou alguma informação adicional? Eles receberão durante a comunicação da campanha ☺️"
        />
      </Col> */}
    </Row>
  );
};

CampaignRulesForm.propTypes = {
  styles: PropTypes.object,
};

export default CampaignRulesForm;

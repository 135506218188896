import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Input, ButtonComponent, BreadcrumbComponent } from 'cashin-components'
import useGetCompanyById from '../API/queries/company/useGetCompanyById'
import api from '../API/api'

const CreateCustomerPage = ({ styles, state, isEdit }) => {
  const [logoFile, setLogoFile] = useState(null)
  const [companyDataBody, setCompanyDataBody] = useState({
    id: '',
    name: '',
    contact: {
      name: '',
      email: '',
      cellphoneNumber: '',
    },
    logo: null,
    status: null,
    users: [
      {
        name: '',
        surname: '',
        email: '',
        cellphoneNumber: '',
      },
    ],
  })

  const companyId = window.location.pathname.replace('/edit-customer/', '')
  const { data: companyData, refetch } = useGetCompanyById(
    isEdit ? companyId : ''
  )

  const insert = async () => {
    const formData = new FormData()
    formData.append('logo', logoFile)
    formData.append('id', companyDataBody.id)
    formData.append('name', companyDataBody.name)
    formData.append('contact.name', companyDataBody.contact.name)
    formData.append('contact.email', companyDataBody.contact.email)
    formData.append(
      'contact.cellphoneNumber',
      companyDataBody.contact.cellphoneNumber
    )

    companyDataBody.users.forEach((user, index) => {
      formData.append(`user[${index}].name`, user.name)
      formData.append(`user[${index}].surname`, user.surname)
      formData.append(`user[${index}].email`, user.email)
      formData.append(`user[${index}].cellphoneNumber`, user.cellphoneNumber)
    })

    if (isEdit) {
      await api.put(
        '/company/' + window.location.pathname.replace('/edit-customer/', ''),
        companyDataBody
      )
      window.location.href = '/customers'
    } else {
      await api.post('/company', formData)
      window.location.href = '/customers'
    }
  }

  const deleteImage = (logo) => {
    if (!isEdit || logo) return

    api.delete(
      `/company/${window.location.pathname.replace(
        '/edit-customer/',
        ''
      )}/delete-image`
    )
  }

  const insertImage = (logo) => {
    if (!isEdit || !logo) return

    const formData = new FormData()
    formData.append('newLogo', logo)

    api.post(
      `/company/${window.location.pathname.replace(
        '/edit-customer/',
        ''
      )}/change-image`,
      formData
    )
  }

  useEffect(() => {
    if (!companyData) return
    if (isEdit) {
      const users = companyData.users.filter((user) => user.role !== 'app')
      companyData.users = users
      setCompanyDataBody(companyData)
    }
  }, [isEdit, companyData])

  const applyCnpjMask = (cnpj) => {
    if (!cnpj) return
    const maskedCnpj = cnpj
      .replace(/^(\d{2})(\d)/, '$1.$2') // Add dot after the first two digits
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Add dot after the next three digits
      .replace(/\.(\d{3})(\d)/, '.$1/$2') // Add slash after the next three digits
      .replace(/(\d{4})(\d)/, '$1-$2') // Add hyphen after the next four digits
    return maskedCnpj
  }

  const removeCnpjMask = (maskedCnpj) => {
    if (!maskedCnpj) return
    const cnpj = maskedCnpj.replace(/\D/g, '') // Remove non-numeric characters
    return cnpj
  }

  const removeCellphoneMask = (maskedCellphone) => {
    if (!maskedCellphone) return
    const cellphone = maskedCellphone.replace(/\D/g, '')
    return cellphone
  }

  const applyCellphoneMask = (cellphone) => {
    if (!cellphone) return
    const maskedCellphone = cellphone
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
    return maskedCellphone
  }

  const handleChangeCompanyData = (field, value) => {
    setCompanyDataBody((prevData) => ({
      ...prevData,
      [field]: value,
    }))
  }

  const handleChangeContactData = (field, value) => {
    setCompanyDataBody((prevData) => ({
      ...prevData,
      contact: {
        ...prevData.contact,
        [field]: value,
      },
    }))
  }

  const handleChangeUserData = (index, field, value) => {
    setCompanyDataBody((prevData) => {
      const updatedUser = [...prevData.users]
      updatedUser[index] = {
        ...updatedUser[index],
        [field]: value,
      }
      return {
        ...prevData,
        users: updatedUser,
      }
    })
  }

  const handleAddUser = () => {
    setCompanyDataBody((prevData) => ({
      ...prevData,
      users: [
        ...prevData.users,
        {
          name: '',
          surname: '',
          email: '',
          cellphoneNumber: '',
          role: 'admin',
        },
      ],
    }))
  }

  const handleDeleteUser = async (index, userEmail) => {
    if (isEdit && userEmail) {
      await api.post(
        `/company/delete-user?companyId=${companyId}&userEmail=${userEmail}`
      )
      refetch()
      return
    }
    setCompanyDataBody((prevState) => {
      const updatedUsers = [...prevState.users]
      updatedUsers.splice(index, 1)
      return { ...prevState, users: updatedUsers }
    })
  }

  const handleLogoChange = (event) => {
    setLogoFile(event)
  }

  return (
    <Row>
      <Col xs={12}>
        <Row>
          {!isEdit && (
            <Col xs={12} className='pt-4'>
              <BreadcrumbComponent
                hasIcon={true}
                styles={styles}
                initialIcon='home-color'
                initialNode='Dashboard'
                breadcrumbData={state.breadcrumb}
              />
            </Col>
          )}
          <Col xs={6}>
            <h1
              className={`
                ${styles.tipography({ headings: 'smallBold' })}
                my-3
              `}
            >
              Cadastro de clientes
            </h1>
          </Col>
          <Col xs={6} className='d-flex justify-content-end' />
          <Col xs={12}>
            <div
              className={`
                ${styles.border({ borderBottomWidth: 'thick' })}
                mb-4 page-border
              `}
            />
          </Col>
          <Col xs={13}>
            <Row>
              <Col xs={3}>
                <h2
                  className={`
                  ${styles.tipography({ headings: 'xsmallBold' })}
                  my-3
                `}
                >
                  Dados
                  <br />
                  da empresa
                </h2>
              </Col>
              <Col xs={9}>
                <Row>
                  <Col
                    xs={6}
                    className={`
                    ${styles.inputs({ inputFile: 'imageFileUpload' })}
                  `}
                  >
                    <Input
                      styles={styles}
                      isFileInput={true}
                      isFileImageUpload={true}
                      labelName='Logotipo da empresa'
                      accept='image/png, image/gif, image/jpeg'
                      onChange={(logo) => {
                        handleLogoChange(logo)
                        deleteImage(logo)
                        insertImage(logo)
                      }}
                      files={[companyData?.logo]}
                    />
                  </Col>
                  <Col xs={2} />
                  <Col xs={6}>
                    <Input
                      id='txtCompanyName'
                      idError='fdbCompanyName'
                      labelName='Razão Social'
                      errorText={state.companyNameError}
                      styles={styles}
                      isInvalid={state.isInvalidCompanyName}
                      placeholder='Informe a razão social do cliente'
                      value={companyDataBody?.name}
                      onChange={(e) =>
                        handleChangeCompanyData('name', e.target.value)
                      }
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      id='txtCompanyCNPJ'
                      idError='fdbCompanyCNPJ'
                      labelName='CNPJ'
                      errorText={state.companyCnpjError}
                      styles={styles}
                      isInvalid={state.isInvalidCompanyCNPJ}
                      placeholder='00.000.000/0001-00'
                      value={applyCnpjMask(companyDataBody?.id)}
                      onChange={(e) => {
                        if (e.target.value.length > 18) return
                        handleChangeCompanyData(
                          'id',
                          removeCnpjMask(e.target.value)
                        )
                      }}
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      id='txtCompanyEmail'
                      idError='fdbCompanyEmail'
                      labelName='E-mail comercial'
                      errorText={state.companyEmailError}
                      styles={styles}
                      isInvalid={state.isInvalidCompanyEmail}
                      placeholder='E-mail para contato comercial'
                      value={companyDataBody?.contact?.email}
                      onChange={(e) =>
                        handleChangeContactData('email', e.target.value)
                      }
                    />
                  </Col>
                  <Col xs={6}>
                    <Input
                      id='txtCompanyPhone'
                      idError='fdbCompanyPhone'
                      labelName='Telefone comercial'
                      errorText={state.companyPhoneError}
                      styles={styles}
                      isInvalid={state.isInvalidCompanyPhone}
                      placeholder='Informe um telefone comercial'
                      value={applyCellphoneMask(
                        companyDataBody?.contact?.cellphoneNumber
                      )}
                      onChange={(e) => {
                        if (e.target.value.length > 15) return
                        handleChangeContactData(
                          'cellphoneNumber',
                          removeCellphoneMask(e.target.value)
                        )
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col
                    className={`
                  ${styles.border({ borderBottomWidth: 'thin' })}
                  mx-3 mt-3 mb-4 page-border
                `}
                  />
                </Row>
              </Col>
              <Col xs={3}>
                <h2
                  className={`
                  ${styles.tipography({ headings: 'xsmallBold' })}
                  my-3
                `}
                >
                  Dados
                  <br />
                  do usuário
                </h2>
              </Col>
              <Col xs={9}>
                {companyDataBody.users?.map((user, index) => {
                  return (
                    <>
                      <Row>
                        <Col xs={5}>
                          <Input
                            id={`txtUserName${index}`}
                            idError='fdbUserName'
                            labelName='Nome'
                            errorText={state.userNameError}
                            styles={styles}
                            isInvalid={state.isInvalidUserName}
                            placeholder='Nome do usuário'
                            value={user?.name}
                            onChange={(e) =>
                              handleChangeUserData(
                                index,
                                'name',
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col xs={5}>
                          <Input
                            id='txtUserSurname'
                            idError='fdbUserSurname'
                            labelName='Sobrenome'
                            errorText={state.userSurnameError}
                            styles={styles}
                            isInvalid={state.isInvalidUserSurname}
                            placeholder='Sobrenome do usuário'
                            value={user?.surname}
                            onChange={(e) =>
                              handleChangeUserData(
                                index,
                                'surname',
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col xs={5}>
                          <Input
                            id='txtUserEmail'
                            idError='fdbUserEmail'
                            labelName='Email de login'
                            errorText={state.userEmailError}
                            styles={styles}
                            isInvalid={state.isInvalidUserEmail}
                            placeholder='E-mail para acessar a plataforma'
                            value={user?.email}
                            onChange={(e) =>
                              handleChangeUserData(
                                index,
                                'email',
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col xs={5}>
                          <Input
                            id='txtUserPhone'
                            idError='fdbUserPhone'
                            labelName='Celular'
                            errorText={state.userPhoneError}
                            styles={styles}
                            isInvalid={state.isInvalidUserPhone}
                            placeholder='Informe um celular'
                            value={applyCellphoneMask(user?.cellphoneNumber)}
                            onChange={(e) => {
                              if (e.target.value.length > 15) return
                              handleChangeUserData(
                                index,
                                'cellphoneNumber',
                                e.target.value
                              )
                            }}
                          />
                        </Col>
                        <Col
                          style={{
                            display: 'flex',
                            marginTop: '0.25rem',
                            gap: '1rem',
                          }}
                        >
                          {companyDataBody.users.length === index + 1 && (
                            <ButtonComponent
                              className={`
                                ${styles.buttonSquareLarge({
                                  large: 'primary',
                                })}
                                mt-4
                              `}
                              hasIcon={true}
                              iconName='add'
                              onClick={handleAddUser}
                              disabled={companyDataBody.users.length >= 5}
                            />
                          )}

                          {companyDataBody.users.length > 1 && (
                            <ButtonComponent
                              className={`
                                ${styles.buttonSquareLarge({
                                  large: 'secondary',
                                })}
                                mt-4
                              `}
                              hasIcon={true}
                              iconName='delete_outline'
                              onClick={() =>
                                handleDeleteUser(index, user?.email)
                              }
                            />
                          )}
                        </Col>
                      </Row>
                    </>
                  )
                })}
              </Col>
              <Col xs={12}>
                <Row>
                  <Col
                    className={`
                  ${styles.border({ borderBottomWidth: 'thin' })}
                  mx-3 mt-3 mb-4 page-border
                `}
                  />
                </Row>
              </Col>
              <Col xs={12} className='d-flex justify-content-end'>
                <ButtonComponent
                  className={`${styles.buttonStandardLarge({
                    large: 'primary',
                  })}
                ${
                  state.isLoading &&
                  styles.buttonStandardLarge({
                    large: 'primaryLoading',
                  })
                } mb-4`}
                  hasIcon={true}
                  iconName='save'
                  text={isEdit ? 'Editar' : 'Cadastrar empresa'}
                  styles={styles}
                  onClick={insert}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

CreateCustomerPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleEdit: PropTypes.func,
  handleCreateCustomer: PropTypes.func,
}

export default CreateCustomerPage

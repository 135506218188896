import axios from "axios";
import { toast } from "react-toastify";

const data = JSON.parse(window.localStorage.getItem("data"));
const userToken = data && data.signInUserSession.idToken.jwtToken;

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${userToken}`,
  },
});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 422) {
      toast.error(
        error?.response?.data?.messages?.map((x) => x.message).join(", ") ||
          "Aconteceu um problema, tente novamente mais tarde",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else {
      toast.error("Aconteceu um problema, tente novamente mais tarde", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    throw error;
  }
);

export default api;

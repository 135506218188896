import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Icon from './Icon'
import { styles } from 'cashin-design-system/Styles/Styles'

const RuleTypeSelector = ({ onRuleChange }) => {
  const [ruleActive, setRuleActive] = useState(0)

  const campaignRules = [
    {
      ruleTitle: 'Meta financeira',
      ruleDescription: 'Estimule uma meta e prêmio financeiro.',
      ruleIcon: 'monetization_on',
    },
    {
      ruleTitle: 'Produto ou serviço',
      ruleDescription: 'Premie com base em produtos ou serviços vendidos.',
      ruleIcon: 'point_of_sale',
    },
    {
      ruleTitle: "Híbrido",
      ruleDescription:
        'Monte sua regra! Baseada em metas financeiras e percentual.',
      ruleIcon: 'rule',
    },
  ]

  const handleChangeRule = (index) => {
    setRuleActive(index)
    onRuleChange?.(index)
  }

  return (
    <Row>
      {campaignRules.map((e, i) => {
        return (
          <Col xs={4} key={i}>
            <div
              className={`w-100 rule-type ${ruleActive === i ? 'active' : ''}`}
              role='button'
              onClick={() => handleChangeRule(i)}
              tabIndex={0}
            >
              <div className={`rule-title ${ruleActive === i ? 'active' : ''}`}>
                <div className='badge-rule text-center'>
                  <Icon iconName={e.ruleIcon} />
                </div>
                {e.ruleTitle}
                <div className='w-100 d-flex justify-content-center mb-3 pb-3'>
                  <div
                    className={`
                  ${styles.tipography({ fontSizes: 'xs' })}
                  ${styles.colors({ colors: 'neutralColorLowMedium' })}
                `}
                  >
                    <p className='m-0 text-center'>{e.ruleDescription}</p>
                    <p className='m-0 text-center'>{e.ruleExample}</p>
                  </div>
                </div>
              </div>
            </div>

          </Col>
        )
      })}
    </Row>
  )
}

export default RuleTypeSelector

import { apiv2 } from "../apiv2";

export const getAppReport = async ({ campaignId, paramName = '', paramValue = '', types, from, to, page = 1, toExport = false}) => {
  return await apiv2.get(`/app-report/${campaignId}${toExport ? '/export': ''}`, {
    params: {
      page: page,
      ...(paramName && paramValue && {[paramName]: paramValue}),
      ...(types && { types: Object.entries(types).filter(([type, value]) => !!value).map(([key, value]) => key) }),
      ...(from && { from }),
      ...(to && { to }),
    }
  });
}

import React from 'react'
import FolderAppPreview from '../../../create-campaign/steps/choose-layout/layout-whatsapp/folder-app-preview'
import { styles } from 'cashin-design-system/Styles/Styles'

const AppPreview = ({ campaign, index }) => {
  const campaignSelected = campaign?.schedule?.campaign[index]

  return (
    <FolderAppPreview
      titleComunication={campaignSelected.name}
      descriptionComunication={campaignSelected.description}
      description
      styles={styles}
      layoutInfo={campaign?.schedule?.layoutInfo}
    />
  )
}

export default AppPreview

import { css } from './CreateStitches';

export const tabs = css({
  variants: {
    tabsType: {
      main: {
        '&.nav-tabs': {
          borderBottom: 0,

          '& .nav-item': {
            marginRight: 24,

            '&:last-child': {
              marginRight: 0,
            },

            '& .nav-link': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              padding: '12px 0',
              color: '$neutralColorLowPure',

              '&.active': {
                color: '$brandColorPrimaryPure',
                borderLeftColor: 'transparent',
                borderTopColor: 'transparent',
                borderRightColor: 'transparent',
                borderBottomColor: '$brandColorPrimaryPure',
                borderBottomWidth: 3,

                '&.has-bigger-font': {
                  fontFamily: '$fontFamilyHighBaseBold',
                  fontSize: '$fontSizeLG',
                  lineHeight: '$lineHeightMD',
                },
              },

              '&.has-bigger-font': {
                fontFamily: '$fontFamilyHighBaseMedium',
                fontSize: '$fontSizeLG',
                lineHeight: '$lineHeightMD',
              },

              '&:disabled': {
                color: '$neutralColorLowLight',
              },
            },
          },
        },

        '&.tab-bg': {
          backgroundColor: '$neutralColorHighLight',
          borderTop: 'solid 1px $neutralColorHighMedium',
          borderBottom: 'solid 1px $neutralColorHighMedium',

          '.active': {
            backgroundColor: 'transparent',
          },

          li: {
            marginRight: '0 !important',

            button: {
              padding: '14px $spacingXXXS !important',
            },
          },
        },
      },
      alternativeActiveColor: {
        '&.nav-tabs': {
          '& .nav-item': {
            '& .nav-link': {
              '&.active': {
                color: '$neutralColorLowPure',
              },
            },
          },
        },
      },
      verticalTabs: {
        '.nav-item': {
          width: 272,

          '.nav-link': {
            fontFamily: '$fontFamilyHighBaseBold',
            fontSize: '$fontSizeLG',
            fontWeight: 700,
            backgroundColor: 'transparent',
            color: '$neutralColorLowLight',
            borderLeft: 'solid 4px $neutralColorHighLight',
            borderRadius: 0,
            marginBottom: 4,

            '&.active': {
              color: '$neutralColorLowPure',
              borderLeft: 'solid 4px $brandColorPrimaryPure',
            },
          },
        },
      },
    },
  },
});

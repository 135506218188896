import React from 'react'
import EmailPreview from './preview-content/email'
import WhatsappPreview from './preview-content/whatsapp'
import AppPreview from './preview-content/app'

const Preview = ({ campaign, index }) => {
  return (
    <div>
      {!campaign.whatsAppType && !campaign.isApp && (
        <EmailPreview campaign={campaign} index={index} />
      )}
      {campaign.whatsAppType && (
        <WhatsappPreview campaign={campaign} index={index} />
      )}
      {campaign.isApp && <AppPreview campaign={campaign} index={index} />}
    </div>
  )
}

export default Preview

import { css } from './CreateStitches';

export const schedulerDashboard = css({
  '.calendar-container': {
    paddingBottom: '1rem',
  },
  '.calendar-header': {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    margin: '1rem 0 1rem',
    textTransform: 'uppercase',
  },
  '.date-headers': {
    display: 'grid',
    gridAutoColumns: 'minmax(1.75rem, 1fr)', 
    gridAutoFlow: 'column',
    gridGap: '0.25rem',
  },
  '.date-header': {
    height: '4.75rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$neutralColorHighLight',
    padding: '0.625rem 0.75rem',
    borderRadius: '40px',
    width: '36px'
  },
  '.event-bars': {
    display: 'grid',
    gridTemplateColumns: 'repeat(30, 1fr)', 
    gridGap: '0.25rem',
    marginTop: '0.5rem'    
  },
  '.event-bar': {
    height: '3.25rem',
    borderRadius: '40px',
    paddingLeft: '16px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    cursor: 'pointer'
  },
  '.campaign-percent-container': {
    backgroundColor: '$neutralColorHighPure',
    padding: '0.25rem 0.25rem',
    height: '2.5rem',
    borderRadius: 40,
    alignSelf: 'center',
    marginRight: '1.5rem',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
  },
  '.campaign-percent': {
    alignSelf: 'center',
    color: '$neutralColorLowPure',
  },
  '.campaign-name': {
    alignSelf: 'center',
    color: '$neutralColorHighLight',
  },
});

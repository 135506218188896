import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/js/bootstrap";
import Icon from "./Icon";
import BadgeComponent from "./BadgeComponent";

const TabsComponent = ({
  styles,
  items,
  tabID,
  contentTabID,
  className,
  hasBiggerFont,
  onClick,
}) => {
  return (
    <Fragment>
      <ul
        className={`${className} nav nav-tabs mb-3`}
        id={tabID}
        role="tablist"
      >
        {items.map((e, i) => {
          return (
            !e.isHiddenTab && (
              <li className="nav-item" role="presentation" key={i}>
                <button
                  className={`
                    nav-link
                    ${e.active && "active"}
                    ${hasBiggerFont && "has-bigger-font"}
                    d-flex align-items-center`}
                  id={e.eventKey}
                  data-bs-toggle="tab"
                  data-bs-target={`#${e.eventKey}-tab-pane`}
                  type="button"
                  role="tab"
                  aria-controls={`${e.eventKey}-tab-pane`}
                  aria-selected="true"
                  disabled={e.isDisabled}
                  onClick={onClick}
                >
                  {e.hasIcon && <Icon iconName={e.iconName} />}
                  {e.title}
                  {e.hasBadge && (
                    <BadgeComponent
                      text={`${e.badgeValue}`}
                      styles={styles}
                      isBadgePill={true}
                      bgType={e.active ? "primary" : "light"}
                      className="ms-1 px-2"
                    />
                  )}
                </button>
              </li>
            )
          );
        })}
      </ul>
      <div className="tab-content" id={contentTabID}>
        {items.map((e, i) => {
          return typeof e.content === "string" ? (
            <div
              className={`tab-pane fade show ${e.active && "active"}`}
              id={`${e.eventKey}-tab-pane`}
              role="tabpanel"
              aria-labelledby={`${e.eventKey}-tab`}
              tabIndex="0"
              dangerouslySetInnerHTML={{ __html: e.content }}
              key={i}
            />
          ) : (
            <div
              className={`tab-pane fade show ${e.active && "active"}`}
              id={`${e.eventKey}-tab-pane`}
              role="tabpanel"
              aria-labelledby={`${e.eventKey}-tab`}
              tabIndex="0"
              key={i}
            >
              {e.content}
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

TabsComponent.propTypes = {
  styles: PropTypes.object,
  items: PropTypes.array,
  tabID: PropTypes.string,
  contentTabID: PropTypes.string,
  className: PropTypes.string,
  hasBiggerFont: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TabsComponent;

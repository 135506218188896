import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import IconCustom from './IconCustom';

const BreadcrumbComponent = ({
  styles,
  hasIcon,
  isBold,
  hasArrow,
  initialNode,
  initialIcon,
  breadcrumbData,
  changeDefaultSeparator,
}) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li
          className={`
            breadcrumb-item
            ${styles.breadcrumb()}
            ${
              isBold
                ? styles.tipography({ breadcrumb: 'bold' })
                : styles.tipography({ breadcrumb: 'regular' })
            }
          `}
        >
          <a href="/">
            {hasIcon && (
              <span
                className={styles.customIcons({
                  type: 'others',
                })}
              >
                <IconCustom iconName={initialIcon} />
              </span>
            )}
            {initialNode}
            {hasArrow && (
              <span
                className={styles.customIcons({
                  type: 'others',
                })}
              >
                <Icon iconName="keyboard_arrow_down" />
              </span>
            )}
          </a>
        </li>
        {breadcrumbData &&
          breadcrumbData.map((e, i) => {
            return (
              <li
                // href={e.url && e.url}
                className={`
                  breadcrumb-item
                  ${
                    changeDefaultSeparator
                      ? styles.breadcrumb({ separator: 'arrow' })
                      : styles.breadcrumb({ separator: 'default' })
                  }
                  ${
                    e.url
                      ? styles.tipography({ breadcrumb: 'regular' })
                      : styles.tipography({ breadcrumb: 'bold' })
                  }
                  ${e.isActive && 'active'}
                `}
                aria-current="page"
                key={i}
              >
                {e.url ? <a href={e.url}>{e.itemName}</a> : e.itemName}
              </li>
            );
          })}
      </ol>
    </nav>
  );
};

BreadcrumbComponent.propTypes = {
  styles: PropTypes.object,
  hasIcon: PropTypes.bool,
  isBold: PropTypes.bool,
  hasArrow: PropTypes.bool,
  initialNode: PropTypes.string,
  initialIcon: PropTypes.string,
  breadcrumbData: PropTypes.array,
  changeDefaultSeparator: PropTypes.bool,
};

export default BreadcrumbComponent;

import React, { memo } from "react";
import ButtonComponent from "./Buttons";
import InputFiles from "react-input-files";
import { styles } from "cashin-design-system/Styles/Styles";

const InputFileButton = ({
  className,
  onChange,
  accept,
  multiple,
  files,
  isEmptyFile,
  deleteFile,
}) => {
  return isEmptyFile ? (
    <InputFiles accept={accept} multiple={multiple} onChange={onChange}>
      <div
        className={`
          ${className ? className : styles.inputs({ input: "default" })}
          file-button-input
        `}
      >
        <ButtonComponent
          className={`
            ${styles.buttonStandardMedium({
              medium: "secondary",
            })}
          `}
          hasIcon={true}
          iconName="upload"
          text="Upload"
          styles={styles}
        />
      </div>
    </InputFiles>
  ) : (
    <div className="file-button-input-filled">
      <div
        className="sent-list-area"
        onClick={deleteFile}
        role="button"
        tabIndex={0}
      >
        <div className="sent-list">
          <div className="file-icon" />
          {files &&
            files?.map((e, i) => {
              return (
                <div key={i}>
                  <p>{e.name}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default InputFileButton;

import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Input } from 'cashin-components'
import FolderRelembreRegra from './layout-generico/folder-relembre-regra'
import FolderWhatsAppRelembreRegra from './layout-whatsapp/folder-relembre-regra'
import FolderRules from './layout-executivo/folder-rules'
import FolderRulesDivertido from './layout-divertido/folder-rules'
import FolderRulesModerno from './layout-moderno/folder-rules'
import FolderWhatsappPreview from './layout-whatsapp/folder-whatsapp-preview'

const LayoutExampleRelembreRegra = ({
  styles,
  state,
  titleComunication,
  setTitleComunication,
  introductionComunication,
  setIntroductionComunication,
  descriptionComunication,
  setDescriptionComunication,
  attachmentFilePath,
  layoutTitleChoosen,
  layoutInfo,
  handleTextOnlyObrigado,
  handleHtmlObrigado,
  render,
}) => {
  return (
    <Row>
      <Col>
        {(layoutTitleChoosen === 'WhatsApp padrão' || layoutTitleChoosen === 'WhatsApp personalizado') && render && (
          <FolderWhatsappPreview
            titleComunication={titleComunication}
            setTitleComunication={setTitleComunication}
            introductionComunication={introductionComunication}
            setIntroductionComunication={setIntroductionComunication}
            descriptionComunication={descriptionComunication}
            setDescriptionComunication={setDescriptionComunication}
            attachmentFilePath={attachmentFilePath}
            styles={styles}
            state={state}
            layoutInfo={layoutInfo}
          />
        )}
        {layoutTitleChoosen === 'Moderno' && (
          <FolderRulesModerno
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}
        {layoutTitleChoosen === 'Executivo' && (
          <FolderRules
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}
        {layoutTitleChoosen === 'Divertido' && (
          <FolderRulesDivertido
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}
      </Col>
    </Row>
  )
}

LayoutExampleRelembreRegra.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutTitleChoosen: PropTypes.string,
  layoutInfo: PropTypes.object,
  handleTextOnlyObrigado: PropTypes.func,
  handleHtmlObrigado: PropTypes.func,
}

export default LayoutExampleRelembreRegra

import { formatIntervalString } from "../../functions/date";
import { formatIntToMonetaryString } from "../../functions/money";
import { badgeTextByStatus, communicationTypeToString, ruleMap } from "./CampaignTable";

export const transformingGeneralReportDataForXlxs = (headers, propertyNames, data) => {
  const newData = data.slice(0,10).map(item => {
    item.communicationType = communicationTypeToString(item.communicationType);
    item.engagement = `${item.engagement[0]?.percentage ?? 0}%`;
    item.hasSegmentation = item.hasSegmentation? 'Sim' : 'Não';
    item.status = badgeTextByStatus[item.status ?? 'Active'];
    item.rule = ruleMap[item.rule] ?? item.rule;
    item.prize = formatIntToMonetaryString(item.prize);
    item.interval = formatIntervalString(item.to, item.from);
    return item;
  })

  const transformedData = newData.slice(0,10).map(item => {
    const transformedItem = {};
    transformedItem['Nome'] = item.name;
    propertyNames.forEach((propertyName, index) => {
      transformedItem[headers[index]] = item[propertyName];
    });

    return transformedItem;
  });
  return transformedData;
}
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import Icon from './Icon'
import SelectComponent from './Select'
import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router'

const NavComponent = ({
  styles,
  state,
  menuItems,
  isMenuOpened,
  companies,
  onCompanyChange,
}) => {
  let navigate = useNavigate()

  const handleNavigation = (url) => {
    navigate(url)
    // history.go(`${url}`);
  }

  const [currentUser, setCurrentUser] = useState({})

  const handleOnCompanyChange = (company) => onCompanyChange(company.value)

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }

    runAsync()
  }, [])

  return (
    <Col
      xs={'auto'}
      className={`
        ${styles.colors({ bgColors: 'neutralColorHighPure' })}
        ${styles.navComponent()}
        pt-4 px-3
        ${!isMenuOpened ? 'd-none' : ''}
      `}
      style={{
        borderWidth: 1
      }}
    >
      <div
        id='logo'
        className={`
          ${styles.logos({
          logo: 'mediumPrimaryPurple',
        })}
          mb-4 mx-auto
        `}
        aria-label='Logo CashIn'
        onClick={() => handleNavigation('/')}
        tabIndex={0}
        role='link'
      />
      {state.isAdmin && (
        <Fragment>
          <div
            className={`
              ${styles.border({ borderBottomWidth: 'hairline' })}
              menu-area-border
            `}
          />
          <div className='mb-4'>
            <SelectComponent
              id='ddlCompanies'
              inputName='statusValue'
              idError='fdbStatusError'
              selectedValue={{
                value: currentUser?.attributes?.['custom:tenantId'] || '%20',
                label:
                  companies?.find(
                    (x) => x.id === currentUser?.attributes?.['custom:tenantId']
                  )?.description ||
                  currentUser?.attributes?.['custom:tenantId'] ||
                  'Todas as empresas',
              }}
              options={[
                {
                  value: '%20',
                  label: 'Todas as empresas',
                },
                ...companies?.map((x) => ({
                  value: x.id,
                  label: x.description,
                })),
              ]}
              styles={styles}
              onChange={handleOnCompanyChange}
            />
          </div>
        </Fragment>
      )}
      <div
        className={`
        ${styles.border({ borderBottomWidth: 'hairline' })}
        mb-4 menu-area-border
      `}
      />
      <nav className='nav navbar'>
        {menuItems.map((e, i) => {
          return (
            <li
              id={`menu-${e.iconName}`}
              className={e.isActive && 'active'}
              role='link'
              key={i}
              onClick={() => handleNavigation(e.url)}
              tabIndex={0}
            >
              <Icon
                iconName={e.iconName}
                className='material-icons me-2 icon-button'
              />
              {e.linkName}
            </li>
          )
        })}
      </nav>
    </Col>
  )
}

NavComponent.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  history: PropTypes.object,
  menuItems: PropTypes.array,
  isMenuOpened: PropTypes.bool,
}

export default NavComponent

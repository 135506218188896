import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { ButtonComponent, Input } from "cashin-components";
import { CampaignContext } from "../../context/campaign-context";

const ModalSendTest = ({ styles, size, show, onHide, handleShotSendTest }) => {
  const campaignContext = useContext(CampaignContext);

  const [name, setName] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");

  const onSend = async () => {
    campaignContext.sendTestDispatch(name, cellphone, email);
    handleShotSendTest();
  };

  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={`
        ${styles.modal()}
      `}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body className="pb-0">
        <h1
          className={`
            ${styles.tipography({ headings: "xsmallBold" })}
            mb-4 text-center
          `}
          style={{
            lineHeight: "var(--lineHeights-lineHeightDefault)",
          }}
        >
          Fazer disparo teste
        </h1>
        <p className="text-center m-0">
          Preencha todos os campos abaixo para cadastrar
          <br />
          um novo usuário Cashin.
        </p>
        <div
          className={`
            ${styles.inputs({ inputFile: "inputFileBatch" })}
            mt-3
          `}
        >
          <Input
            id="txtUserName2"
            inputName="userName2"
            className={styles.inputs({ input: "default" })}
            idError="fdbUserNameError2"
            inputType="text"
            labelName="Nome"
            placeholder="Digite seu nome"
            // errorText={state.userNameError2}
            // isInvalid={state.isInvalidUserName2}
            styles={styles}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            id="txtUserName2"
            inputName="userName2"
            className={styles.inputs({ input: "default" })}
            idError="fdbUserNameError2"
            inputType="text"
            labelName="Telefone"
            placeholder="Digite seu telefone"
            // errorText={state.userNameError2}
            // isInvalid={state.isInvalidUserName2}
            styles={styles}
            value={cellphone}
            onChange={(e) => setCellphone(e.target.value)}
          />
          <Input
            id="txtUserName2"
            inputName="userName2"
            className={styles.inputs({ input: "default" })}
            idError="fdbUserNameError2"
            inputType="text"
            labelName="E-mail"
            placeholder="Digite seu e-mail"
            // errorText={state.userNameError2}
            // isInvalid={state.isInvalidUserName2}
            styles={styles}
            // value={state.userNameValue2}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-0">
        <ButtonComponent
          className={`
            ${styles.buttonStandardLarge({
            large: "primary",
          })}
            w-100
          `}
          text={
            campaignContext.isLoadingCreateCampaign
              ? "Carregando..."
              : "Fazer disparo teste"
          }
          styles={styles}
          onClick={onSend}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModalSendTest.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  handleShotSendTest: PropTypes.func,
};

export default ModalSendTest;

import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import FolderExecutivoBoasVindas from './layout-executivo/folder-boas-vindas'
import { Input } from 'cashin-components'
import FolderDivertidoBoasVindas from './layout-divertido/folder-boas-vindas'
import FolderModernoBoasVindas from './layout-moderno/folder-boas-vindas'
import FolderWhatsAppBoasVindas from './layout-whatsapp/folder-boas-vindas'
import FolderWhatsappPreview from './layout-whatsapp/folder-whatsapp-preview'
import FolderAppPreview from './layout-whatsapp/folder-app-preview'

const LayoutExampleBoasVindas = ({
  styles,
  titleComunication,
  setTitleComunication,
  introductionComunication,
  setIntroductionComunication,
  descriptionComunication,
  setDescriptionComunication,
  attachmentFilePath,
  state,
  layoutTitleChoosen,
  layoutInfo,
  handleTextOnlyBoasVindas,
  handleHtmlBoasVindas,
  render,
}) => {
  return (
    <Row>
      <Col>
        {(layoutTitleChoosen === 'WhatsApp padrão' || layoutTitleChoosen === 'WhatsApp personalizado') && render && (
          <FolderWhatsappPreview
            titleComunication={titleComunication}
            setTitleComunication={setTitleComunication}
            introductionComunication={introductionComunication}
            setIntroductionComunication={setIntroductionComunication}
            descriptionComunication={descriptionComunication}
            setDescriptionComunication={setDescriptionComunication}
            attachmentFilePath={attachmentFilePath}
            description
            styles={styles}
            state={state}
            layoutInfo={layoutInfo}
          />
        )}
        {(layoutTitleChoosen === 'Notificação padrão' || layoutTitleChoosen === 'Notificação personalizada') && (
          <FolderAppPreview
            titleComunication={titleComunication}
            setTitleComunication={setTitleComunication}
            introductionComunication={introductionComunication}
            setIntroductionComunication={setIntroductionComunication}
            descriptionComunication={descriptionComunication}
            setDescriptionComunication={setDescriptionComunication}
            attachmentFilePath={attachmentFilePath}
            description
            styles={styles}
            state={state}
            layoutInfo={layoutInfo}
          />
        )}
        {layoutTitleChoosen === 'Moderno' && (
          <FolderModernoBoasVindas layoutInfo={layoutInfo} render={render} />
        )}
        {layoutTitleChoosen === 'Divertido' && (
          <FolderDivertidoBoasVindas layoutInfo={layoutInfo} render={render} />
        )}
        {layoutTitleChoosen === 'Executivo' && (
          <FolderExecutivoBoasVindas layoutInfo={layoutInfo} render={render} />
        )}
      </Col>
    </Row>
  )
}

LayoutExampleBoasVindas.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutTitleChoosen: PropTypes.string,
  layoutInfo: PropTypes.object,
  handleTextOnlyBoasVindas: PropTypes.func,
  handleHtmlBoasVindas: PropTypes.func,
}

export default LayoutExampleBoasVindas

import React, { useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, OverlayTrigger, Button, Tooltip } from "react-bootstrap";
import Icon from "./Icon";

const Check = ({
  id,
  name,
  label,
  type,
  disabled,
  hasTooltip,
  tooltipText,
  className,
  checked,
  onChange,
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <div style={{ display: "flex" }}>
      <Form.Check
        id={id}
        name={name}
        label={label}
        type={type}
        disabled={disabled}
        className={className}
        checked={checked}
        onChange={onChange}
      />
      {hasTooltip && (
        <Fragment>
          <div
            className="tooltip-area"
            ref={target}
            onClick={() => setShow(!show)}
            role="button"
            tabIndex={0}
            style={{ marginLeft: "5px", color: "#6300FF" }}
          >
          </div>
          <OverlayTrigger
            placement="right"
            overlay={(props) => (
              <Tooltip {...props}>
                {tooltipText}
              </Tooltip>
            )}
          >
            <button style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <Icon iconName="info" style={{ color: "#6300FF" }} />
            </button>
          </OverlayTrigger>
        </Fragment>
      )
      }
    </div >
  );
};

Check.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.any,
  onChange: PropTypes.func,
};

export default Check;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { ButtonComponent, Select } from "cashin-components";
import changeValidationResultStatusRequest from "../../API/queries/campaign-validate/changeValidationResultStatusRequest";

const ModaAdminChangeStatus = ({
  styles,
  size,
  show,
  onHide,
  validationId,
  validationItemId,
}) => {
  const [status, setStatus] = useState(0);
  const [changeStatusIsLoading, setChangeStatusIsLoading] = useState();

  const handleChangeStatus = async () => {
    await changeValidationResultStatusRequest(
      validationId,
      validationItemId,
      status
    );
    onHide();
  };

  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={`
        ${styles.modal()}
      `}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body>
        <div
          className={`
            ${styles}
            w-100 mb-4
          `}
        >
          <h1>Alterar status</h1>
        </div>
        <Select
          id="ddlReproveFilter"
          inputName="reproveValue"
          idError="fdbReproveError"
          labelName="Status"
          options={[
            {
              value: 0,
              label: "Em processamento",
            },
            {
              value: 1,
              label: "Concluído",
            },
            {
              value: 2,
              label: "Reprovado",
            },
            {
              value: 5,
              label: "Erro na planilha",
            },
          ]}
          styles={styles}
          onChange={(e) => setStatus(e.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          className={`
            ${styles.buttonStandardLarge({
              large: "primary",
            })}
            w-100
          `}
          text="Alterar"
          styles={styles}
          onClick={handleChangeStatus}
          isLoading={changeStatusIsLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModaAdminChangeStatus.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ModaAdminChangeStatus;

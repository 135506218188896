import api from "../../api";

const addPartialFileRequest = async (campaignId, file) => {
  const formDataRequest = new FormData();

  formDataRequest.append("file", file);

  return await api.post(
    `/campaign-validate/campaign/${campaignId}`,
    formDataRequest
  );
};

export default addPartialFileRequest;

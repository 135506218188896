import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { CognitoContext } from '../context/congnito-context';

const Logoff = ({ styles, history }) => {
  const context = useContext(CognitoContext);

  useEffect(() => {
    let hideLayout = document.querySelectorAll('.container-fluid');
    hideLayout[0].classList.add('d-none');

    context.signOut();
  });

  return <div />;
};

Logoff.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default Logoff;

import api from "../../api";
import qs from "qs";

const searchCampaign = async (
  dateFrom,
  dateTo,
  campaignId,
  status,
  currentPagination,
  isValidationPage
) => {
  var queryString = qs.stringify(
    {
      "Filter.AvailabilityInitialDate.From": dateFrom,
      "Filter.AvailabilityInitialDate.To": dateTo,
      "Filter.CampaignId": campaignId,
      "Filter.Status": status,
      "Pagination.Limit": 10,
      "Pagination.Offset": currentPagination * 10,
    },
    { skipNulls: true }
  );

  if (isValidationPage)
    return await api.get(`/campaign-validate/campaign/search?${queryString}`);

  return await api.get(`/campaign/search?${queryString}`);
};

export default searchCampaign;

import api from "../../api";

const changeValidationResultStatusCustomerRequest = async (
  validationId,
  validationResultId,
  status
) => {
  return await api.post(
    `/campaign-validate/${validationId}/customer/change-status`,
    {
      status,
      validationId: validationResultId,
    }
  );
};

export default changeValidationResultStatusCustomerRequest;

import React, { useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Container } from 'react-bootstrap'
import { BreadcrumbComponent, TabsComponent } from 'cashin-components'
import ConsultHistory from './consult-history/consult-history'
import { CognitoContext } from '../context/congnito-context'
import { styles } from 'cashin-design-system/Styles/Styles'
import CampaignPaginationPageBase from '../base-components/campaign-pagination/campaign-pagination-page-base'
import {Box} from "../Components";

const CampaignVerificationPage = () => {
  const cognitoContext = useContext(CognitoContext)
  const isAdmin = cognitoContext.isAdmin()
  const [currentTab, setCurrentTab] = useState('upload-verification')

  const consultHistory = (
    <Box>
      <ConsultHistory
        isManagementAction={
          currentTab === 'upload-verification' ||
          currentTab === 'manage-verification'
        }
        currentTab={currentTab}
      />
    </Box>
  )

  const tabs = [
    {
      eventKey: 'upload-verification',
      title: 'Upload de apuração',
      active: true,
      content: <Box><CampaignPaginationPageBase isValidationPage={true} /></Box>  ,
    },
    {
      eventKey: 'consult-history',
      title: 'Consultar histórico',
      active: false,
      content: consultHistory,
    },
    {
      eventKey: 'manage-verification',
      title: 'Gestão da apuração',
      active: false,
      content: isAdmin && consultHistory,
      isHiddenTab: !isAdmin,
    },
  ]

  return (
    <Row>
      <Col xs={12} style={{ padding: 0 }}>
        <Row>
          <Col xs={12} className='pt-4'>
            <Box>
              <BreadcrumbComponent
                hasIcon={true}
                styles={styles}
                initialIcon='home-color'
                initialNode='Dashboard'
                breadcrumbData={[
                  {
                    isActive: true,
                    itemName: 'Apuração',
                  },
                ]}
              />
            </Box>
          </Col>
          <Col xs={6}>
            <Box>
              <h1 className={`${styles.tipography({ headings: 'smallBold' })}   my-3 `}>Apuração</h1>
            </Box>
          </Col>
          <Col xs={12}>
            <TabsComponent
              items={tabs}
              tabID='participant-tab-input'
              contentTabID='content-example'
              styles={styles}
              hasBiggerFont={true}
              onClick={(e) => setCurrentTab(e.target.id)}
              className={`
                ${styles.tabs({ tabsType: 'main' })}
                tab-bg
              `}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

CampaignVerificationPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setDateRange: PropTypes.any,
  handleUploadPartialChange: PropTypes.func,
  handleUploadTotalChange: PropTypes.func,
  handleDeletePartial: PropTypes.func,
  handleDeleteTotal: PropTypes.func,
  handleUploadReport: PropTypes.func,
  show: PropTypes.any,
  handleClose: PropTypes.func,
  downloadTemplate: PropTypes.func,
  isAdmin: PropTypes.bool,
}

export default CampaignVerificationPage

import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'react-bootstrap'

const BadgeComponent = ({ styles, bgType, text, isBadgePill, className, onClick, style }) => {
  const alternativeColorsClasses = {
    'indigo': '!tw-bg-indigo-800',
    'forest-green': '!tw-bg-forest-green-800',
    'orange': '!tw-bg-orange-800',
    'eucalyptus': '!tw-bg-eucalyptus-800',
    'persian-rose': '!tw-bg-persian-rose-800',
  };

  const additionalClass = alternativeColorsClasses[bgType] || '';

  return isBadgePill ? (
    <Badge
      pill
      bg={bgType}
      onClick={onClick}
      style={style}
      className={`
        ${styles.badge()}
        ${styles.tipography({ captions: 'captionBold' })}
        ${className}
        ${additionalClass}
      `}
    >
      {text}
    </Badge>
  ) : (
    <Badge
      bg={bgType}
      className={`
        ${styles.badge()}
        ${styles.tipography({ captions: 'captionBold' })}
        ${className}
      `}
    >
      {text}
    </Badge>
  )
}

BadgeComponent.propTypes = {
  styles: PropTypes.object,
  bgType: PropTypes.string,
  text: PropTypes.string,
  isBadgePill: PropTypes.bool,
  className: PropTypes.string,
}

export default BadgeComponent

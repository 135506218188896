import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Input, Check } from 'cashin-components'
import { CampaignContext } from '../../../context/campaign-context'
import appLayoutImage from "cashin-design-system/Styles/img/ExampleLayout/appLayout.png"
import dayjs from 'dayjs'

const tagStyle = {
  display: 'inline-block',
  padding: '5px 10px',
  alignItems: 'center',
  border: '1px solid #CCCCCC',
  borderRadius: '15px',
  position: 'relative',
  marginRight: '5px',
};

const Summary = ({ styles, state }) => {
  const campaignContext = useContext(CampaignContext)
  const [textCampaignRuleSelected, setTextCampaignRuleSelected] = useState('')

  const handleShowTypeRole = () => {
    if (campaignContext.campaignRuleSelected === 0) {
      setTextCampaignRuleSelected('Meta financeira')
    }
    else if (campaignContext.campaignRuleSelected === 1) {
      setTextCampaignRuleSelected('Produto ou serviço')
    }
    else if (campaignContext.campaignRuleSelected === 2) {
      setTextCampaignRuleSelected('Híbrido')
    }
    else {
      setTextCampaignRuleSelected('Não definido')
    }
  }

  useEffect(() => {
    handleShowTypeRole()
  }, [campaignContext.campaignRuleSelected])

  return (
    <Row>
      <Col xs={'auto'}>
        <p
          className={`
            ${styles.tipography({ paragraphs: 'smallBold' })}
            mb-2
          `}
          onClick={() =>
            console.log(campaignContext.currentParticipantFileReference)
          }
        >
          Layout: {state.layoutTitleChoosen}
        </p>
        <img
          style={{
            width: campaignContext.communicationType === 'app' ? 200 : 150,
            marginLeft: campaignContext.communicationType === 'app' ? 0 : '2rem'
          }}
          src={campaignContext.communicationType === 'app' ? appLayoutImage : state.layoutExampleImageChoosen}
          alt='Layout escolhido pelo usuário'
        />
      </Col>
      <Col xs>
        <Row>
          <Col xs={6}>
            <Input
              id='txtCampaignName'
              className={styles.inputs({ input: 'default' })}
              inputType='text'
              labelName='Nome da campanha'
              styles={styles}
              value={campaignContext.campaignState?.name}
              disabled={true}
            />
          </Col>
          <Col
            xs={6}
            className={`
              ${styles.inputs({ datePicker: 'datePickerDefault' })}
              d-flex align-items-end
            `}
          >
            <Input
              id='txtDateRangeCampaign'
              labelName='Período da campanha'
              styles={styles}
              isDatePicker={true}
              placeholder={`${dayjs(
                campaignContext.campaignState?.periodDate?.from
              )?.format('DD/MM/YYYY')} - ${dayjs(
                campaignContext.campaignState?.periodDate?.to
              )?.format('DD/MM/YYYY')}`}
              disabled={true}
            />
          </Col>
          {/* <Col xs={6}>
            <Input
              id="txtShootsTotal"
              className={styles.inputs({ input: "default" })}
              inputType="text"
              labelName="Total de disparos"
              styles={styles}
              value={"5000"}
              disabled={true}
            />
          </Col> */}
          <Col xs={6} style={{ marginTop: '7px' }}>
            <Input
              id='txtRoleType'
              className={`${styles.inputs({ input: 'default' })} w-100`}
              inputType='text'
              labelName='Regra'
              styles={styles}
              value={textCampaignRuleSelected}
              disabled={true}
            />
          </Col>
          <Col xs={6} style={{ marginTop: '7px' }}>
            <Input
              id='txtParticipants'
              className={`${styles.inputs({ input: 'default' })} w-100`}
              inputType='text'
              labelName='Participantes'
              styles={styles}
              value={campaignContext.participantsQuantity}
              disabled={true}
            />
          </Col>
          <Col xs={12} className='mb-3 pb-3'>
            <h3
              className={`
                ${styles.tipography({ paragraphs: 'largeBold' })}
                ${styles.colors({ colors: 'neutralColorLowLight' })}
              `}
            >
              Plataforma de comunicação
            </h3>
            <div className='form-control mt-2 p-3 d-flex'>
              <Check
                id='swtEmailComunication'
                label='E-mail'
                type='switch'
                className={`
                  ${styles.check({ switch: 'normal' })}
                  ps-0 me-3
                `}
                checked={campaignContext.communicationType === 'email'}
                disabled
              />
              <Check
                id='swtWhatsAppComunication'
                label='WhatsApp'
                type='switch'
                className={`
                  ${styles.check({ switch: 'normal' })}
                  ps-0 me-3
                `}
                checked={campaignContext.communicationType === 'whatsapp'}
                disabled
              />
              <Check
                id='swtAppComunication'
                label='App'
                type='switch'
                className={`
                  ${styles.check({ switch: 'normal' })}
                  ps-0
                `}
                checked={campaignContext.communicationType === 'app'}
                disabled
              />
            </div>
          </Col>
        </Row>
        {(campaignContext.segmentTags && campaignContext.isSegmentation === 'yes') > 0 && (
          <Row>
            <Col xs={12} className='mb-3 pb-3'>
              <Row>
                <h3
                  className={`
                    ${styles.tipography({ paragraphs: 'largeBold' })}
                    ${styles.colors({ colors: 'neutralColorLowLight' })}
                  `}
                >
                  Segmentos cadastrados
                </h3>
                <div className='form-control p-3 d-flex'>
                  {campaignContext.segmentTags.map((tag, index) => (
                    <div key={index} style={tagStyle} className="mr-2 d-inline-flex align-items-center">
                      <span style={{ fontWeight: 'bold', marginLeft: '5px', color: "#666666" }}>{tag.label}</span>
                    </div>
                  ))}
                </div>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}

Summary.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default Summary

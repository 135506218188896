import { css } from './CreateStitches'

export const select = css({
  '&.select-container': {
    '&:focus, &:focus-visible': {
      outline: 0,
    },
    // '& div[class*="-singleValue"]': {
    //   color: '#fff',
    // },

    '& > div[class*="-control"]': {
      padding: '2px 6px',
      color: '$neutralColorLowPure',
      borderRadius: '$borderRadiusSM',
      height: 45,

      '&:hover': {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorLowPure',
      },
      '&:focus, &:focus-visible': {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthThin',
        borderColor: '$neutralColorLowPure !important',
        outline: '0 !important',
      },
      '&:disabled': {
        backgroundColor: '$neutralColorHighLight',
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorHighMedium',
      },

      '& > div[class*="-ValueContainer"]': {
        '& > div[class*="-placeholder"]:not(:disabled), & > div[class*="-singleValue"]':
        {
          color: '$neutralColorLowPure',
          display: 'flex',

          '& .material-icons': {
            paddingLeft: 8,
            paddingRight: '$spacingXXXS',
          },
        },
      },

      '& > div[class*="-IndicatorsContainer"]': {
        '& span[class*="-indicatorSeparator"]': {
          display: 'none',
        },

        '& div[class*="-indicatorContainer"]': {
          color: '$neutralColorLowPure',
        },
      },
    },

    '& > div[class*="-menu"]': {
      borderRadius: '$borderRadiusSM',
      boxShadow: '$shadowLevel2',
      zIndex: 9,

      '& > div[class*="-MenuList"]': {
        paddingTop: '$spacingNano',
        paddingBottom: '$spacingNano',

        '& > div[class*="-option"]': {
          padding: '12px $spacingXXS',
          color: '$neutralColorLowPure',
          backgroundColor: '$neutralColorHighPure',
          display: 'flex',

          '& .material-icons': {
            paddingRight: 16,
          },

          '&:hover': {
            backgroundColor: '$neutralColorHighLight',
          },
        },
      },
    },

    '&.invalid': {
      '& > div[class*="-control"]': {
        borderStyle: 'solid',
        borderWidth: '$borderWidthThin',
        borderColor: 'blue',
      },
    },

    '&.hover': {
      '& > div[class*="-control"]': {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorLowPure',
      },
    },

    '&.focus': {
      '& > div[class*="-control"]': {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthThin',
        borderColor: '$neutralColorLowPure !important',
        outline: '0 !important',
      },
    },
  },

  '.css-1jqq78o-placeholder': {
    color: '$neutralColorLowLight !important',
  },
})

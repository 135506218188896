import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { ButtonComponent, Input } from "cashin-components";
import addFinalFileRequest from "../../API/queries/campaign-validate/addFinalFileRequest";
import updateStatusCampaing from '../../API/queries/campaign/updateStatusCampaing';

const ModalCloseCampaign = ({
  styles,
  size,
  show,
  onHide,
  campaignId,
  onFinish,
}) => {
  const [fileReference, setFileReference] = useState([]); // id, file
  const [isLoadingUploadFile, setIsLoadingFile] = useState(false);

  const handleUploadFileChange = (value) => {
    setFileReference([value[0]]);
  };

  const handleDeleteFile = () => {
    setFileReference([]);
  };

  const handleOnFinish = async () => {
    try {
      setIsLoadingFile(true);

      await addFinalFileRequest(campaignId, fileReference[0]);

      onHide();
      onFinish();
      handleDeleteFile();
    } catch (error) {}

    setIsLoadingFile(false);
  };

  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={`
        ${styles.modal()}
      `}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body className="pb-0">
        <h1
          className={`
            ${styles.tipography({ headings: "xsmallBold" })}
            mb-4 text-center
          `}
          style={{
            lineHeight: "var(--lineHeights-lineHeightDefault)",
          }}
        >
          Deseja encerrar essa
          <br />
          campanha?
        </h1>
        <p className="text-center m-0">
          Para encerrar essa campanha você precisará enviar
          <br />o arquivo com a apuração final.
        </p>
        <div
          className={`
            ${styles.inputs({ inputFile: "inputFileBatch" })}
            mt-3
          `}
        >
          <Input
            styles={styles}
            isFileInput={true}
            isFileBatch={true}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            isEmptyFile={fileReference.length === 0}
            onChange={handleUploadFileChange}
            handleDeleteFile={handleDeleteFile}
            files={fileReference}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-0">
        <ButtonComponent
          className={`
            ${styles.buttonStandardLarge({
              large: "primary",
            })}
            w-100
          `}
          text="Enviar"
          styles={styles}
          onClick={handleOnFinish}
          isLoading={isLoadingUploadFile}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModalCloseCampaign.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ModalCloseCampaign;

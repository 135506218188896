// import React, { useRef } from 'react';
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
// import Select, { components } from 'react-select';
import Creatable from "react-select/creatable";
// import { components } from 'react-select';
// import Icon from './Icon';

// const { Placeholder, Option, SingleValue } = components;

const SelectComponent = ({
  styles,
  id,
  idError,
  inputName,
  labelName,
  required,
  isInvalid,
  isHover,
  isFocus,
  errorText,
  options,
  onChange,
  placeholder,
  selectedValue,
  disabled,
}) => {
  // const selectInputRef = useRef();

  // const clearSelectField = () => {
  //   selectInputRef.current.select.clearValue();
  // };

  // const IconPlaceholder = (props) => (
  //   <Placeholder {...props}>
  //     {props.options && props.options[0].iconName && (
  //       <Icon iconName={props.options[0].iconName} />
  //     )}
  //     {placeholder || 'Selecione'}
  //   </Placeholder>
  // );

  // const IconOption = (props) => (
  //   <Option {...props}>
  //     {props.data.iconName && <Icon iconName={props.data.iconName} />}
  //     {props.data.label}
  //   </Option>
  // );

  // const IconSingleValue = (props) => (
  //   <SingleValue {...props}>
  //     {props.data.iconName && <Icon iconName={props.data.iconName} />}
  //     {props.data.label}
  //   </SingleValue>
  // );

  return (
    <div className="position-relative">
      {labelName ? (
        <Form.Label
          htmlFor={id}
          className={[
            styles.tipography({ paragraphs: "smallBold" }),
            disabled && styles.colors({ colors: "neutralColorLowLight" }),
          ]}
        >
          {labelName}
          {required && <span>*</span>}
        </Form.Label>
      ) : (
        ""
      )}
      <Creatable
        id={id}
        name={inputName}
        // ref={selectInputRef}
        aria-describedby={idError}
        className={`
          ${styles.select()} select-container
          ${isInvalid && "invalid"}
          ${isHover && "hover"}
          ${isFocus && "focus"}
          ${!labelName && "mt-4 pt-1"}
        `}
        isSearchable={false}
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        // components={{
        //   Placeholder: IconPlaceholder,
        //   Option: IconOption,
        //   SingleValue: IconSingleValue,
        // }}
        placeholder={placeholder || "Selecione"}
        value={selectedValue}
        noOptionsMessage={() => "Não há dados"}
      />
      {isInvalid && errorText && (
        <div
          id={idError}
          type="invalid"
          className={[
            styles.tipography({ paragraphs: "xSmallRegular" }),
            styles.colors({ colors: "feedbackColorWarningPure" }),
          ]}
        >
          {errorText}
        </div>
      )}
    </div>
  );
};

SelectComponent.propTypes = {
  styles: PropTypes.object,
  id: PropTypes.string,
  idError: PropTypes.string,
  inputName: PropTypes.string,
  labelName: PropTypes.string,
  required: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isHover: PropTypes.bool,
  isFocus: PropTypes.bool,
  errorText: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  selectedValue: PropTypes.any,
  disabled: PropTypes.bool,
};

export default SelectComponent;

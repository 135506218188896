import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ButtonComponent } from 'cashin-components';

const ModalSaveProfileConfirmation = ({ styles, size, show, onHide }) => {
  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={`
        ${styles.modal()}
      `}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body>
        <div
          className={`
            ${styles.login({ loginIcons: 'checkGreen' })}
            w-100 mb-4
          `}
          aria-hidden="true"
        />
        <h1
          className={`
            ${styles.tipography({ headings: 'xsmallBold' })}
            mb-4 text-center
          `}
          style={{
            lineHeight: 'var(--lineHeights-lineHeightDefault)',
          }}
        >
          As edições em seu perfil
          <br />
          foram salvas com sucesso
        </h1>
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          className={`
            ${styles.buttonStandardLarge({
              large: 'primary',
            })}
            w-100
          `}
          text="Fechar"
          styles={styles}
          onClick={onHide}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModalSaveProfileConfirmation.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ModalSaveProfileConfirmation;

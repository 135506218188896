import { css } from './CreateStitches';
import LookFront from './img/Emoticons/emoticons_front.gif';
import LookLeft from './img/Emoticons/emoticons_left.gif';
import LookRight from './img/Emoticons/emoticons_right.gif';
import Smile from './img/Emoticons/emoticons_smile.gif';
import LookUp from './img/Emoticons/emoticons_top.gif';
import LookDown from './img/Emoticons/emoticons_botton.gif';
import TiltLeft from './img/Emoticons/emoticons_incline_left.gif';
import TiltRight from './img/Emoticons/emoticons_incline_right.gif';

export const emoticon = css({
  variants: {
    emoticonSize: {
      default: {
        width: 128,
        height: 128,
        backgroundSize: '100%',
      },
    },
    emoticon: {
      front: {
        backgroundImage: `url(${LookFront})`,
        backgroundRepeat: 'no-repeat',
      },
      lookLeft: {
        backgroundImage: `url(${LookLeft})`,
        backgroundRepeat: 'no-repeat',
      },
      lookRight: {
        backgroundImage: `url(${LookRight})`,
        backgroundRepeat: 'no-repeat',
      },
      smile: {
        backgroundImage: `url(${Smile})`,
        backgroundRepeat: 'no-repeat',
      },
      lookUp: {
        backgroundImage: `url(${LookUp})`,
        backgroundRepeat: 'no-repeat',
      },
      lookDown: {
        backgroundImage: `url(${LookDown})`,
        backgroundRepeat: 'no-repeat',
      },
      tiltLeft: {
        backgroundImage: `url(${TiltLeft})`,
        backgroundRepeat: 'no-repeat',
      },
      tiltRight: {
        backgroundImage: `url(${TiltRight})`,
        backgroundRepeat: 'no-repeat',
      },
    },
  },
});

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import ForgotPasswordPage from "./forgot-password-page";
import { CognitoContext } from "../context/congnito-context";
import resendTemporaryPassword from '../API/queries/admin/resend-temporary-password';

const defaultState = {
  emailValue: "",
  obfuscatedEmailValue: "",
  emailError: "",
  hasIcon: true,
  isInvalidEmail: false,
  isLoading: false,
  forgotPasswordForm: true,
  forgotPasswordFormConfirmation: false,
};

const simulateNetworkRequest = () => {
  return new Promise((res) => setTimeout(res, 1000));
};

const ForgotPassword = ({ styles, history }) => {
  const cognitoContext = useContext(CognitoContext);
  const [ownState, setOwnState] = useState(defaultState);
  const [showUnregistredUserModalIsOpen, setShowUnregistredUserModalIsOpen] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    });
  };

  const handleKeyDown = (key) => {
    if (key === "Enter") {
      handleRecoverPassword();
    }
  };

  const obfuscatedEmail = (email) => {
    const [name, domain] = email && email.split("@");

    const obfuscation = `${name && name.substring(0, 3)}${new Array(
      name && name.length
    ).join("*")}@${domain && domain.substring(0, 1)}${new Array(
      domain && domain.length
    ).join("*")}${domain && domain.substring(domain && domain.length - 8)}`;

    window.localStorage.setItem("obfuscatedEmail", obfuscation);

    setOwnState({
      ...ownState,
      obfuscatedEmailValue: obfuscation,
    });
  };

  const handleRecoverPassword = async () => {
    const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);       
    
    if (!isEmail(ownState.emailValue)) {       
      setOwnState({
        ...ownState,
        isInvalidEmail: true,
        emailError: "Digite um e-mail válido",
        isInvalidPassword: false,
        passwordError: "",
      });
      return;
    } 

    const emailExists = await cognitoContext.checkUserExists(ownState.emailValue);

    if(!emailExists.userExists) {
      setShowUnregistredUserModalIsOpen(() => true);
      return;
    }

    if(emailExists.statusVerification === 'FORCE_CHANGE_PASSWORD') {
      await resendTemporaryPassword(ownState.emailValue); 

      setOwnState({
        ...ownState,
        isInvalidEmail: false,
        emailError: "",
        hasIcon: false,
        isLoading: true,
      });

      return;
    }
    
    await cognitoContext.sendForgotPassword(ownState.emailValue); 
  
    setOwnState({
      ...ownState,
      isInvalidEmail: false,
      emailError: "",
      hasIcon: false,
      isLoading: true,
    });
   
  };

  const handleBackToLogin = () => {
    history.push("/login");
    history.go("/login");
  };

  useEffect(() => {
    ownState.isLoading &&
      simulateNetworkRequest().then(() => {
        setOwnState({
          ...ownState,
          hasIcon: true,
          isLoading: false,
          forgotPasswordForm: false,
        });
      });

    obfuscatedEmail(ownState.emailValue);
  }, [ownState.isLoading]);

  function hideUnregistredUserModal() {
    setShowUnregistredUserModalIsOpen(() => false);
  }

  return (
    <ForgotPasswordPage
      styles={styles}
      state={ownState}
      handleKeyDown={handleKeyDown}
      handleChange={handleChange}
      handleRecoverPassword={handleRecoverPassword}
      handleBackToLogin={handleBackToLogin}
      showUnregistredUserModal={showUnregistredUserModalIsOpen}
      hideUnregistredUserModal={hideUnregistredUserModal}
    />
  );
};

ForgotPassword.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default ForgotPassword;

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Form } from 'react-bootstrap'
import { Input, ButtonComponent } from 'cashin-components'
import api from '../API/api'
import useCompanyNegotiationUsageData from '../API/queries/company/useCompanyNegotiationUsageData'
import { NegotiationUsageTypeAction } from '../enums/NegotiationUsageTypeAction'
import useCompanyNegotiationUsagePaginatedData from '../API/queries/company/useCompanyNegotiationUsagePaginatedData'
import { useCompanyUsageBalance } from '../API/queries/company/useCompanyUsageBalance'
import CustomPagination from '../Components/CustomPagination'
import moment from 'moment'
import * as XLSX from 'xlsx'

const BusinessData = ({ styles, state, showHeader, showData }) => {
  const [participantsValue, setParticipantsValue] = useState()
  const [currentPagination, setCurrentPagination] = useState(1)

  const companyId = window.location.pathname.replace(
    '/business-negociation/',
    ''
  )

  const { data: companyNegotiationData } =
    useCompanyNegotiationUsageData(companyId)
  const {
    data: companyNegotiationPaginatedData,
    refetch: refetchCompanyNegotiationUsagePaginatedData,
  } = useCompanyNegotiationUsagePaginatedData(companyId, currentPagination)
  const { data } = useCompanyUsageBalance(companyId)

  const handleOnPaginationChange = (newPage) => {
    setCurrentPagination(newPage)
  }

  const addNegotiation = async () => {
    const isAddCreditAction = companyNegotiationData?.length > 0

    if (isAddCreditAction) {
      await api.post('/company-negotiation-usage', {
        companyId: companyId,
        totalUsage: parseInt(participantsValue),
        action: 'Upgrade',
        usageLimit: parseInt(participantsValue),
        negotiationUsageTypeAction:
          NegotiationUsageTypeAction.AddCreditUsageNegotiation,
      })
      refetchCompanyNegotiationUsagePaginatedData()
      setParticipantsValue('')
    } else {
      await api.post('/company-negotiation-usage', {
        companyId: companyId,
        totalUsage: parseInt(participantsValue),
        action: 'Negociação comercial',
        usageLimit: parseInt(participantsValue),
        negotiationUsageTypeAction:
          NegotiationUsageTypeAction.UsageLimitNegotiation,
      })
      refetchCompanyNegotiationUsagePaginatedData()
      setParticipantsValue('')
    }
  }

  function handleDownloadExtract() {
    const transformedData = companyNegotiationData.map((item) => {
      const transformedItem = {
        'Quantidade de participantes': item.totalUsage,
        Data: moment(item.createdDate).format('DD/MM/YYYY'),
        Ação: item.action,
      }
      return transformedItem
    })

    var workbook = XLSX.utils.book_new()

    var worksheet = XLSX.utils.json_to_sheet(transformedData)

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

    var blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    var downloadLink = document.createElement('a')
    downloadLink.href = window.URL.createObjectURL(blob)
    downloadLink.download = 'data.xlsx'

    downloadLink.click()
  }

  return (
    <Row>
      <Col xs={4}>
        <h2
          className={`
            ${styles.tipography({ headings: 'xsmallBold' })}
            my-3
          `}
        >
          Negociação
          <br />
          comercial
        </h2>
      </Col>
      <Col xs={8}>
        <Row>
          <Col xs={6}>
            <Input
              id='txtShootsQuantity'
              inputName='shootsQuantityValue'
              className={styles.inputs({ input: 'default' })}
              idError='fdbShootsQuantityError'
              inputType='text'
              labelName='Saldo de participantes'
              placeholder='Digite a quantidade de participantes'
              errorText={state.shootsQuantityError}
              isInvalid={state.isInvalidShootsQuantity}
              styles={styles}
              value={participantsValue}
              onChange={(e) => setParticipantsValue(() => e.target.value)}
            />
          </Col>
          <Col xs={1} className='d-flex align-items-end'>
            <ButtonComponent
              className={`
                ${styles.buttonSquareLarge({
                  large: 'primary',
                })}
                mb-3
              `}
              hasIcon={true}
              iconName='add'
              onClick={addNegotiation}
            />
          </Col>
          <Col xs={12}>
            <Form.Label
              className={`
                ${styles.tipography({ paragraphs: 'smallBold' })}
                my-4
              `}
            >
              Extrato da negociação comercial
            </Form.Label>
            <table
              id='table'
              className={`
                ${styles.table()}
              `}
            >
              <thead>
                <tr>{showHeader()}</tr>
              </thead>
              <tbody>
                {companyNegotiationPaginatedData?.items.map((e, i) => (
                  <tr key={i}>
                    <td>{e.totalUsage}</td>
                    <td>{moment(e.createdDate).format('DD/MM/YYYY')}</td>
                    <td style={{ width: '220px' }}>{e.action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Col>
      <Col xs={8} style={{ marginLeft: 'auto' }}>
        <Row>
          <Col>
            <Col
              xs={12}
              className='d-inline-flex p-2 justify-content-start bg-light rounded mt-3'
            >
              <span
                className={`
                  ${styles.tipography({ paragraphs: 'smallBold' })}
                  text-muted
                  pl-2
                `}
                style={{ paddingLeft: '16px' }}
              >
                Saldo: {data?.balance}
              </span>
            </Col>
          </Col>
          <Col xs={12}>
            <CustomPagination
              totalPages={
                (companyNegotiationPaginatedData?.totalItems || 0) / 10
              }
              onPaginationChange={handleOnPaginationChange}
              currentPagination={currentPagination}
              setCurrentPagination={setCurrentPagination}
            />
          </Col>
          <Col xs={12} className='d-flex justify-content-end'>
            <ButtonComponent
              className={`
                ${styles.buttonStandardLarge({
                  large: 'secondary',
                })}
                my-4
              `}
              hasIcon={true}
              iconName='download'
              text='Baixar'
              styles={styles}
              onClick={() => handleDownloadExtract()}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

BusinessData.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  showHeader: PropTypes.func,
  showData: PropTypes.func,
}

export default BusinessData

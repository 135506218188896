import PropTypes from "prop-types";
import { styles } from "cashin-design-system/Styles/Styles";

const CustomLabelProgressBar = ({ balance }) => {
  return (
    <div style={{ position: 'relative', marginTop: -18 }}>
      <div style={{ position: 'absolute', marginLeft: '0.7rem' }}>
        <p className={`
          ${styles.tipography({ paragraphs: 'xSmallRegular' })}       
          ${styles.colors({ colors: 'neutralColorLowDark' })}        
        `}
          style={{ fontWeight: 'bold' }}
        >
          {`Restam ${balance}`}
        </p>
      </div>
    </div>
  );
};

CustomLabelProgressBar.propTypes = {
  balance: PropTypes.number,
};

export default CustomLabelProgressBar;
import { css } from './CreateStitches'

export const navComponent = css({
  width: 240,

  "#ddlCompanies": {
    div: {
      div: {
        '& > div[class*="-singleValue"]': {
          color: "$neutralColorLowPure !important"
        }
      }
    }
  },

  '.menu-area-border': {
    borderBottomColor: '$neutralColorHighLight',
  },

  '.nav': {
    fontFamily: '$fontFamilyHighBaseBold',
    fontSize: '$fontSizeSM',
    color: '$neutralColorLowPure',

    li: {
      display: 'flex',
      justifyContent: 'start',
      padding: '10px 18px',
      marginBottom: '$spacingXXXS',
      cursor: 'pointer',
      width: '100%',
      borderRadius: '$borderRadiusLG',

      span: {
        '&.material-icons': {
          fontSize: '$fontSizeSM !important',
          display: 'flex',
          alignItems: 'center',
        },
      },

      '&.active, &:hover': {
        backgroundColor: '$neutralColorHighLight',
        color: '$neutralColorLowPure',
      },
    },
  },

  '.position-relative': {
    '.select-container': {
      '& > div[class*="-control"]': {
        border: 'solid 1px $neutralColorHighDark',
        backgroundColor: '$neutralColorHighPure',

        '&:hover': {
          border: 'solid 1px $neutralColorHighDark',
          backgroundColor: '$neutralColorHighPure !important',
        },

        '& > div[class*="-ValueContainer"]': {
          '& > div[class*="-placeholder"], & > div[class*="-singleValue"]': {
            color: '$neutralColorLowPure !important',
          },
        },

        '& > div[class*="-IndicatorsContainer"]': {
          '& > div[class*="-indicatorContainer"]': {
            color: '$neutralColorLowPure !important',
          },
        },
      },
    },

    '& > div[class*="-menu"]': {
      border: 'solid 1px $neutralColorHighDark',
      backgroundColor: '$neutralColorLowPure',

      '> div > div': {
        backgroundColor: '$neutralColorLowDark !important',
        color: '$neutralColorLowPure !important',

        '&:hover': {
          backgroundColor: '$neutralColorLowMedium !important',
        },
      },
    },
  },
})
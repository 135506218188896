import { css } from './CreateStitches'
import CsvIcon from './img/csv-icon.png'

export const inputs = css({
  padding: '11px $spacingXXXS $spacingNano $spacingXXXS',
  color: '$neutralColorLowPure',
  borderRadius: '$borderRadiusSM',

  '~ .invalid-feedback': {
    display: 'unset',
  },

  variants: {
    input: {
      default: {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorHighDark',

        '&:hover': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthThin',
          borderColor: '$neutralColorLowPure',
          boxShadow: 'none !important',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowMedium',
        },

        '&::placeholder': {
          color: '$neutralColorLowMedium',
        },
        '&::-webkit-input-placeholder': {
          color: '$neutralColorLowMedium',
        },
        '&::-ms-input-placeholder': {
          color: '$neutralColorLowMedium',
        },
      },
      defaultHover: {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorLowPure',

        '&:hover': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthThin',
          borderColor: '$neutralColorLowPure',
          boxShadow: 'none !important',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorHighMedium',
        },

        '&::placeholder': {
          color: '$neutralColorLowMedium',
        },
        '&::-webkit-input-placeholder': {
          color: '$neutralColorLowMedium',
        },
        '&::-ms-input-placeholder': {
          color: '$neutralColorLowMedium',
        },
      },
      defaultFocus: {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthThin',
        borderColor: '$neutralColorLowPure',
        boxShadow: 'none !important',

        '&:hover': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthThin',
          borderColor: '$neutralColorLowPure',
          boxShadow: 'none !important',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorHighMedium',
        },

        '&::placeholder': {
          color: '$neutralColorLowMedium',
        },
        '&::-webkit-input-placeholder': {
          color: '$neutralColorLowMedium',
        },
        '&::-ms-input-placeholder': {
          color: '$neutralColorLowMedium',
        },
      },
      defaultDisabled: {
        backgroundColor: '$neutralColorHighLight',
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorHighMedium',

        '&:hover': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthThin',
          borderColor: '$neutralColorLowPure',
          boxShadow: 'none !important',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorHighMedium',
        },

        '&::placeholder': {
          color: '$neutralColorLowMedium',
        },
        '&::-webkit-input-placeholder': {
          color: '$neutralColorLowMedium',
        },
        '&::-ms-input-placeholder': {
          color: '$neutralColorLowMedium',
        },
      },
      defaultHelper: {
        backgroundColor: '$neutralColorHighPure',
        borderStyle: 'solid',
        borderWidth: '$borderWidthThin !important',
        borderColor: '$feedbackColorWarningPure !important',
        backgroundImage: 'none !important',
        paddingRight: '$spacingXXXS !important',

        '&:hover': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorHighPure',
          borderStyle: 'solid',
          borderWidth: '$borderWidthThin',
          borderColor: '$neutralColorLowPure',
          boxShadow: 'none !important',
        },
        '&:disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderStyle: 'solid',
          borderWidth: '$borderWidthHairLine',
          borderColor: '$neutralColorHighMedium',
        },
        '&::placeholder': {
          color: '$neutralColorLowLight',
        },
        '&::-webkit-input-placeholder': {
          color: '$neutralColorLowLight',
        },
        '&::-ms-input-placeholder': {
          color: '$neutralColorLowLight',
        },
      },
    },
    textArea: {
      default: {
        padding: '$spacingXXXS',
        height: 155,
      },
    },
    inputIcon: {
      inputPadding: {
        paddingLeft: '$spacingLG',
      },
      icon: {
        backgroundColor: 'transparent',
        border: 'none',
        position: 'absolute',
        top: 30,
        padding: '10px 17px',
      },
      iconDescription: {
        position: 'absolute !important',
        width: '1px !important',
        height: '1px !important',
        padding: '0 !important',
        margin: '-1px !important',
        overflow: 'hidden !important',
        clip: 'rect(0,0,0,0) !important',
        whiteSpace: 'nowrap !important',
        border: '0 !important',
      },
      passwordIcon: {
        backgroundColor: 'transparent',
        border: 'none',
        position: 'absolute',
        top: 30,
        padding: '10px 17px',
        right: 0,
        cursor: 'pointer',
        width: 58,
      },
    },
    searchBox: {
      searchBoxDefault: {
        paddingLeft: '$spacingXXXS',
        borderRadius: '$borderRadiusPill',
      },
      searchBoxIcon: {
        paddingLeft: '$spacingLG',
        borderRadius: '$borderRadiusPill',
      },
    },
    searchBoxIcon: {
      searchIcon: {
        backgroundColor: 'transparent',
        border: 'none',
        position: 'absolute',
        top: 0,
        padding: '11px 17px',
      },
      closeIcon: {
        backgroundColor: 'transparent',
        border: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '11px 17px',
      },
    },
    searchBoxButton: {
      buttonPosition: {
        position: 'absolute',
        top: 0,
        right: '-56px',
      },
    },
    datePicker: {
      datePickerDefault: {
        padding: '0 11px',

        '.date-picker': {
          '.react-datepicker-popper': {
            '.react-datepicker': {
              border: 'none',
              boxShadow: '$shadowLevel2',
              display: 'flex',

              '.react-datepicker__triangle': {
                '&::before': {
                  borderBottomColor: 'transparent',
                  borderTopColor: 'transparent',
                },

                '&::after': {
                  borderBottomColor: '$neutralColorHighPure',
                },
              },

              '.react-datepicker__navigation': {
                position: 'absolute',
                top: 27,
                textIndent: 0,

                '&.react-datepicker__navigation--previous': {
                  left: 28,
                },

                '&.react-datepicker__navigation--next': {
                  right: 28,
                },

                '.react-datepicker__navigation-icon--previous': {
                  '&::before': {
                    top: -42,
                    right: 16,
                    content: 'arrow_back',
                    fontFamily: '"Material Icons"',
                    fontSize: '$fontSizeHXS',
                    transform: 'rotate(0deg)',
                    border: 'none',
                  },
                },

                '.react-datepicker__navigation-icon--next': {
                  '&::before': {
                    top: -42,
                    left: 14,
                    content: 'arrow_forward',
                    fontFamily: '"Material Icons"',
                    fontSize: '$fontSizeHXS',
                    transform: 'rotate(0deg)',
                    border: 'none',
                  },
                },
              },

              '.react-datepicker__month-container': {
                padding: '20px 30px 20px',
                float: 'none',

                '.react-datepicker__header': {
                  backgroundColor: '$neutralColorHighPure',
                  borderBottom: 0,

                  '.react-datepicker__current-month': {
                    textTransform: 'capitalize',
                    fontFamily: '$fontFamilyHighBaseBold',
                    fontSize: '$fontSizeLG',
                    marginBottom: 40,
                  },

                  '.react-datepicker__day-names': {
                    textTransform: 'capitalize',
                    fontFamily: '$fontFamilyHighBaseBold',
                    fontSize: '$fontSizeSM',

                    '.react-datepicker__day-name': {
                      width: 50,
                      height: 27,
                      margin: '0 0 12px 0',
                      justifyContent: 'center',
                    },
                  },
                },

                '.react-datepicker__month': {
                  margin: 0,

                  '.react-datepicker__week': {
                    height: 'fit-content',

                    '.react-datepicker__day': {
                      width: 42,
                      height: 42,
                      margin: 4,
                      fontFamily: '$fontFamilyHighBaseRegular',
                      fontSize: '$fontSizeSM',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '$borderRadiusCircular',

                      '&:hover,\
                       &.react-datepicker__day--in-range,\
                       &.react-datepicker__day--in-selecting-range': {
                        backgroundColor: '$neutralColorHighMedium',
                        color: 'unset',
                      },

                      '&.react-datepicker__day--outside-month': {
                        '&::before': {
                          content: '', // Necessário para não quebrar o layout
                          // textIndent: -99999999,
                        },
                      },

                      '&.react-datepicker__day--today,\
                       &.react-datepicker__day--range-end,\
                       &.react-datepicker__day--outside-month': {
                        backgroundColor: 'transparent',
                        color: 'unset',
                        fontWeight: 'normal',
                      },

                      '&.react-datepicker__day--range-start,\
                       &.react-datepicker__day--selecting-range-start,\
                       &.react-datepicker__day--keyboard-selected,\
                       &.react-datepicker__day--range-end': {
                        backgroundColor: '$brandColorPrimaryPure',
                        color: '$neutralColorHighPure',
                        fontWeight: 'normal',
                      },
                    },
                  },
                },
              },

              '.button-area': {
                float: 'left',
                width: '100%',
                padding: '6px 25px 0',

                hr: {
                  margin: 0,
                },

                '.controls': {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',

                  button: {
                    marginBottom: '25px !important',
                    marginRight: 16,

                    '&:first-child': {
                      backgroundColor: '$neutralColorHighPure',
                      borderColor: '$brandColorPrimaryPure',
                      color: '$brandColorPrimaryPure',
                    },

                    '&:last-child': {
                      marginRight: 0,
                    },
                  },
                },
              },
            },
          },

          span: {
            position: 'absolute',
            top: 40,
            right: 10,
            pointerEvents: 'none',

            '&.disabled': {
              color: '$neutralColorLowLight',
            },
          },
        },
      },
    },
    inputFile: {
      inputFileDefault: {
        padding: 0,

        '[role="button"]': {
          width: '100%',

          '.disabled': {
            backgroundColor: '$neutralColorHighLight',
            borderStyle: 'solid',
            borderWidth: '$borderWidthHairLine',
            borderColor: '$neutralColorHighMedium',

            span: {
              color: '$neutralColorLowLight',
            },
          },
        },

        '.input-file-placeholder': {
          color: '$neutralColorLowMedium',
        },
      },

      inputFileBatch: {
        marginTop: 4,
        padding: 0,

        '[role="button"]': {
          width: '100%',

          '.file-batch-input': {
            border: 'dashed 2px $neutralColorHighDark',
            display: 'flex',
            justifyContent: 'center',
            padding: '$spacingXXS',

            div: {
              width: 'max-content',

              span: {
                color: '$highlightColorPure',
                textDecoration: 'underline',

                '&.material-icons': {
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 36,
                  textDecoration: 'unset',
                  marginBottom: 5,
                },
              },

              p: {
                textAlign: 'center',
              },
            },
          },
        },

        '.file-batch-input-filled': {
          border: 'none',
          padding: 0,

          '.sent-list-area': {
            width: '100%',
            display: 'flex',

            p: {
              textAlign: 'left',
              fontWeight: 700,
            },

            '.sent-list': {
              width: 210,
              borderRight: 'solid 1px $neutralColorHighLight',
              marginRight: '$spacingXXS',

              '.file-icon': {
                backgroundImage: `url(${CsvIcon})`,
                width: 40,
                height: 40,
                marginRight: 20,
              },

              button: {
                span: {
                  display: 'unset',
                  justifyContent: 'unset',
                  fontSize: 'unset',
                  marginBottom: 'unset',
                  color: 'unset',
                  textDecoration: 'unset',
                },
              },
            },

            '.sent-list-info': {
              display: 'flex',
              alignItems: 'center',

              p: {
                fontWeight: 400,
                marginBottom: 0,
              },
            },
          },
        },
      },

      inputFileButton: {
        '.file-button-input': {
          border: 'none',
          padding: 0,
        },

        '.file-button-input-filled': {
          '.sent-list-area': {
            width: '100%',
            height: 69,
            display: 'flex',
            border: 'dashed 1px $highlightColorPure',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '$borderRadiusSM',
            cursor: 'pointer',

            '.sent-list': {
              width: '100%',

              '.file-icon': {
                backgroundImage: `url(${CsvIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                width: '100%',
                height: 32,
              },

              p: {
                margin: 0,
                textAlign: 'center',
                color: '$highlightColorPure',
                textDecoration: 'underline',
              },
            },
          },
        },
      },

      imageFileUpload: {
        '.image-button-input': {
          border: 'none',
          paddingLeft: 0,
          paddingRight: 0,

          '&:hover': {
            border: 'none',
            paddingLeft: 0,
            paddingRight: 0,
          },

          '.col': {
            '&:before': {
              content: 'upload',
              fontFamily: '"Material Icons Round"',
              fontSize: 25,
              color: '$neutralColorLowMedium',
              position: 'absolute',
              top: 50,
              left: 15,
              zIndex: 1,
            },
          },

          img: {
            width: 56,
            height: 56,
            backgroundColor: '$neutralColorHighLight',
            borderRadius: 10,
            border: 'solid 1px $neutralColorHighMedium',
            position: 'relative',
            zIndex: 1,

            '&[src=""]': {
              backgroundColor: '$neutralColorHighMedium',
              borderRadius: 50,
              zIndex: 0,
            },
          },
        },
      },
    },
  },
})
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CreateCustomerPage from './create-customer-page';

const defaultState = {
  valueStatusOptions: [
    {
      value: 1,
      label: 'Todos',
    },
  ],
  breadcrumb: [
    {
      url: '/customers',
      itemName: 'Clientes',
    },
    {
      isActive: true,
      itemName: 'Cadastro de clientes',
    },
  ],
};

const CreateCustomer = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState);

  const handleCreateCustomer = () => {
    history.push(`/customers`);
    history.go(`/customers`);
  };

  return (
    <CreateCustomerPage
      styles={styles}
      state={ownState}
      handleCreateCustomer={handleCreateCustomer}
    />
  );
};

CreateCustomer.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default CreateCustomer;

import React, { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, Row } from 'react-bootstrap'
import {
  ButtonComponent,
  LayoutTypeEditorSelector,
  Input,
  Select,
} from 'cashin-components'
import { ChromePicker } from 'react-color'
import moment from 'moment'
import { Editor } from 'react-draft-wysiwyg'
import {
  EditorState,
  ContentState,
  Modifier,
  convertToRaw,
  convertFromHTML,
  RichUtils,
} from 'draft-js'
import { stateFromMarkdown } from 'draft-js-import-markdown'
import draftToHtml from 'draftjs-to-html'
import { stateFromHTML } from 'draft-js-import-html'
import { marked, use } from 'marked'
import ImageLogin from '../../../design-system/Styles/img/WhatsApp/Icons/clip.png'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Picker from '@emoji-mart/react'
import LayoutExampleBoasVindas from './layout-example-boas-vindas'
import LayoutExampleDicaMeta from './layout-example-dica-meta'
import LayoutExampleMotivacional1 from './layout-example-motivacional-1'
import LayoutExampleLembretePeriodo1 from './layout-example-lembrete-periodo-1'
import LayoutExampleComoConhecerProduto from './layout-example-como-conhecer-produto'
import LayoutExamplePerguntaEngajamento2 from './layout-example-pergunta-engajamento-2'
import LayoutExampleLembretePeriodo2 from './layout-example-lembrete-periodo-2'
import LayoutExampleMotivacional3 from './layout-example-motivacional-3'
import LayoutExampleRelembreRegra from './layout-example-relembre-regra'
import LayoutExampleLembretePeriodo3 from './layout-example-lembrete-periodo-3'
import LayoutExampleMotivacional2 from './layout-example-motivacional-2'
import LayoutExamplePerguntaEngajamento1 from './layout-example-pergunta-engajamento-1'
import LayoutExampleUltimaSemana from './layout-example-ultima-semana'
import LayoutExampleObrigado from './layout-example-obrigado'
import { CampaignContext } from '../../../context/campaign-context'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { formatDate } from '../../../functions/date'

const CHARACTER_LIMIT = 1250

const LogoHeaderTemplateExecutivo = `${process.env.REACT_APP_BLOBSTORAGE_URL}/LogoHeaderExecutivo.png`
const DefaultBannerTemplateExecutivo = `${process.env.REACT_APP_BLOBSTORAGE_URL}/welcome-executive.jpg`
const LogoFooterTemplateExecutivo = `${process.env.REACT_APP_BLOBSTORAGE_URL}/LogoFooterExecutivo.png`
const LogoHeaderTemplateDivertido = `${process.env.REACT_APP_BLOBSTORAGE_URL}/LogoHeaderDivertido.png`
const DefaultBannerTemplateDivertido = `${process.env.REACT_APP_BLOBSTORAGE_URL}/divertido-welcome.png`
const LogoFooterTemplateDivertido = `${process.env.REACT_APP_BLOBSTORAGE_URL}/LogoFooterDivertido.png`
const LogoHeaderTemplateModerno = `${process.env.REACT_APP_BLOBSTORAGE_URL}/LogoHeaderModerno.png`
const DefaultBannerTemplateModerno1 = `${process.env.REACT_APP_BLOBSTORAGE_URL}/moderno-banner.jpg`
const DefaultBannerTemplateModerno2 = `${process.env.REACT_APP_BLOBSTORAGE_URL}/DefaultBanner2Moderno.png`
const DefaultBannerTemplateModerno3 = `${process.env.REACT_APP_BLOBSTORAGE_URL}/DefaultBanner3Moderno.png`
const LogoFooterTemplateModerno = `${process.env.REACT_APP_BLOBSTORAGE_URL}/LogoFooterModerno.png`

const ModalLayoutContent = ({
  styles,
  state,
  size,
  show,
  onHide,
  layoutSelection,
  handleLayoutSelector,
  handleSaveLayout,
  wppMessageAttributes,
  setWppMessageAttributes,
  appMessageAttributes,
  setAppMessageAttributes,
  handleEditDate,
  handleSaveDate,
}) => {
  const campaignContext = useContext(CampaignContext)
  const [isActiveLayoutSelector, setIsActiveLayoutSelector] = useState(false)
  const [recurrenceOptions, setRecurrenceOptions] = useState({
    value: 'no-repeat',
    label: 'Não se repete',
  })

  const userData = JSON.parse(window.localStorage.getItem('profile'))

  const itemQuantityArray =
    campaignContext.campaignState.rule?.itemQuantity || []
  const combinedItems = []
  let itemString = ''

  for (const item of itemQuantityArray) {
    itemString += `${item.code}, ${item.qtd}`
    if (item.conditionType === 0) {
      itemString += ' **e** \n'
    } else if (item.conditionType === 1) {
      itemString += ' **ou** \n'
    } else {
      itemString += ' '
    }
  }

  combinedItems.push(itemString)

  const defaultState = {
    weekCounter: 0,
    displayBaseColorPicker: false,
    displayTitleColorPicker: false,
    displayTextColorPicker: false,
    displayTextButtonColorPicker: false,
    displayFooterColorPicker: false,
    displayFooterTextColorPicker: false,
    baseColor: '',
    titleColor: '',
    textColor: '',
    textButtonColor: '',
    logoHeader: '',
    campaignName: campaignContext.campaignState.name,
    companyName: userData['name'],
    startDate: dayjs(campaignContext.campaignState.periodDate.from).format(
      'MM/DD/YYYY'
    ),
    endDate: dayjs(campaignContext.campaignState.periodDate.to).format(
      'MM/DD/YYYY'
    ),
    linkJoinUs: campaignContext.campaignState.site,
    mainBanner1: '',
    mainBanner2: '',
    mainBanner3: '',
    campaignType:
      campaignContext.isSegmentation === 'yes'
        ? campaignContext.campaignState.rules[0].ruleType
        : campaignContext.campaignState.rule.ruleType, // Se 0 = Meta financeira, Se 1 = Volume, Se 2 = Hibrido
    campaignBateu:
      campaignContext.isSegmentation === 'yes'
        ? 'Meta por segmento que o seu participante deve alcançar.'
        : campaignContext.campaignState.rule.reachPurchaseValue?.type === 0
        ? 'R$ ' + campaignContext.campaignState.rule.reachPurchaseValue?.value
        : campaignContext.campaignState.rule.reachPurchaseValue?.value + ' %',
    campaignLevou:
      campaignContext.isSegmentation === 'yes'
        ? 'Prêmio por segmento que o seu participante ira ganhar alcançando a meta. 🏆'
        : campaignContext.campaignState.prize.discount?.type === 0
        ? 'R$ ' + campaignContext.campaignState.prize.discount?.value
        : campaignContext.campaignState.prize.discount?.value + ' %',
    campaignVolumeRuleText: combinedItems.join(', '),
    campaignVolumePrize:
      campaignContext.isSegmentation === 'yes'
        ? 'Prêmio por segmento que o seu participante ira ganhar alcançando a meta. 🏆'
        : campaignContext.campaignState.prize.discount?.type === 0
        ? 'R$ ' + campaignContext.campaignState.prize.discount?.value
        : campaignContext.campaignState.prize.discount?.value + ' %',
    campaignHibridoBateuPercent:
      campaignContext.isSegmentation === 'yes'
        ? 'Meta por segmento que o seu participante deve alcançar.'
        : campaignContext.campaignState.rule?.multipleReachValueCondition
            ?.reachValue?.type === 0
        ? 'R$ ' +
          campaignContext.campaignState.rule?.multipleReachValueCondition
            ?.reachValue?.value
        : campaignContext.campaignState.rule?.multipleReachValueCondition
            ?.reachValue?.value + ' %',
    campaignHibridoBateuCondicao:
      campaignContext.campaignState.rule?.multipleReachValueCondition
        ?.condition === undefined ||
      campaignContext.campaignState.rule?.multipleReachValueCondition
        ?.condition === null
        ? ''
        : campaignContext.campaignState.rule?.multipleReachValueCondition
            ?.condition === 0
        ? 'E'
        : 'OU',
    campaignHibridoBateuMoney:
      campaignContext.isSegmentation === 'yes'
        ? 'Meta por segmento que o seu participante deve alcançar.'
        : !campaignContext.campaignState.rule?.multipleReachValueCondition
            ?.reachValueFromCondition?.type
        ? ''
        : campaignContext.campaignState.rule?.multipleReachValueCondition
            ?.reachValueFromCondition?.type === 0
        ? 'R$ ' +
          campaignContext.campaignState.rule?.multipleReachValueCondition
            ?.reachValueFromCondition?.value
        : campaignContext.campaignState.rule?.multipleReachValueCondition
            ?.reachValueFromCondition?.value + ' %',
    campaignHibridoLevouPercent:
      campaignContext.isSegmentation === 'yes'
        ? 'Prêmio por segmento que o seu participante ira ganhar alcançando a meta. 🏆'
        : campaignContext.campaignState.prize?.multipleDiscount?.discount
            ?.type === 0
        ? 'R$ ' +
          campaignContext.campaignState.prize?.multipleDiscount?.discount?.value
        : campaignContext.campaignState.prize?.multipleDiscount?.discount
            ?.value + ' %',
    campaignHibridoLevouCondicao: !campaignContext.campaignState.prize
      ?.multipleDiscount?.condition
      ? ''
      : campaignContext.campaignState.prize?.multipleDiscount?.condition === 0
      ? 'E'
      : 'OU',
    campaignHibridoLevouMoney:
      campaignContext.isSegmentation === 'yes'
        ? 'Prêmio por segmento que o seu participante ira ganhar alcançando a meta. 🏆'
        : campaignContext.campaignState.prize?.multipleDiscount
            ?.discountFromCondition?.type === undefined ||
          campaignContext.campaignState.prize?.multipleDiscount
            ?.discountFromCondition?.type === null
        ? ''
        : campaignContext.campaignState.prize?.multipleDiscount
            ?.discountFromCondition?.type === 0
        ? 'R$ ' +
          campaignContext.campaignState.prize?.multipleDiscount
            ?.discountFromCondition?.value
        : campaignContext.campaignState.prize?.multipleDiscount
            ?.discountFromCondition?.value + ' %',
    campaignWebSite: campaignContext.campaignState.site,
    campaignTerms: campaignContext.termsOfService?.fileUr,
    footerColor: '',
    footerTextColor: '',
    logoFooter: '',
    textOnlyBoasVindas: '',
    textOnlyDicaMeta: '',
    textOnlyDicaMetaMaisTempo: '',
    textOnlyObrigado: '',
    customHtmlBoasVindas: '',
    customHtmlDicaMeta: '',
    customHtmlDicaMetaMaisTempo: '',
    customHtmlObrigado: '',
  }

  const [ownState, setOwnState] = useState(defaultState)
  const editorRef = useRef()

  const totalDispatchWeek = campaignContext.getTotalDispatch()

  const [introductionEditorStates, setIntroductionEditorStates] = useState({})
  const [descriptionEditorStates, setDescriptionEditorStates] = useState({})
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [activeEditor, setActiveEditor] = useState(null)

  const isActiveLayoutSelectorCustom =
    isActiveLayoutSelector === 13
      ? 11
      : isActiveLayoutSelector === 11
      ? 10
      : isActiveLayoutSelector === 10
      ? 9
      : isActiveLayoutSelector

  const introduction =
    wppMessageAttributes && wppMessageAttributes[isActiveLayoutSelectorCustom]
      ? wppMessageAttributes[isActiveLayoutSelectorCustom].introduction
      : ''
  const description =
    wppMessageAttributes && wppMessageAttributes[isActiveLayoutSelectorCustom]
      ? wppMessageAttributes[isActiveLayoutSelectorCustom].description
      : ''

  const titleApp =
    appMessageAttributes && appMessageAttributes[isActiveLayoutSelector]
      ? appMessageAttributes[isActiveLayoutSelector].title
      : ''

  const dateSend =
    appMessageAttributes && appMessageAttributes[isActiveLayoutSelector]
      ? appMessageAttributes[isActiveLayoutSelector].dateSend
      : ''

  const descriptionApp =
    appMessageAttributes && appMessageAttributes[isActiveLayoutSelector]
      ? appMessageAttributes[isActiveLayoutSelector].description
      : ''

  const markdownToHtml = (markdown) => {
    return marked(markdown.replace(/\n/g, '<br />'))
  }

  const introductionHtml = markdownToHtml(introduction)
  const descriptionHtml = markdownToHtml(description)

  const contentStateIntroduction = stateFromHTML(introductionHtml)
  const initialStateIntroduction = EditorState.createWithContent(
    contentStateIntroduction
  )

  const contentStateDescription = stateFromHTML(descriptionHtml)
  const initialStateDescription = EditorState.createWithContent(
    contentStateDescription
  )

  const handleOpenBaseColorPicker = () => {
    setOwnState({
      ...ownState,
      displayBaseColorPicker: !ownState.displayBaseColorPicker,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterColorPicker: false,
      displayFooterTextColorPicker: false,
    })
  }

  useEffect(() => {
    if (state.layoutTitleChoosen === 'WhatsApp personalizado') {
      setWppMessageAttributes((prevState) =>
        prevState.map((item) => ({
          ...item,
          description: '',
        }))
      )
      setDescriptionEditorStates((prevStates) =>
        Object.keys(prevStates).reduce((acc, key) => {
          acc[key] = EditorState.createEmpty()
          return acc
        }, {})
      )
    }
  }, [state.layoutTitleChoosen])

  const handleOpenFooterColorPicker = () => {
    setOwnState({
      ...ownState,
      displayFooterColorPicker: !ownState.displayFooterColorPicker,
      displayBaseColorPicker: false,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterTextColorPicker: false,
    })
  }

  const handleBaseColor = (e) => {
    setOwnState({
      ...ownState,
      baseColor: e.hex,
    })
  }

  const handleFooterColor = (e) => {
    setOwnState({
      ...ownState,
      footerColor: e.hex,
    })
  }

  const handleLoadLogoHeader = () => {
    setOwnState({
      ...ownState,
      displayFooterColorPicker: false,
      displayBaseColorPicker: false,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterTextColorPicker: false,
    })

    let logoHeader = document.getElementById('img-logo-header')

    logoHeader.click()
  }

  const handleLogoImageHeaderLoaded = async (event) => {
    const target = event.target || window.event.srcElement,
      files = target.files

    const imageUrl = await campaignContext.uploadImage(files[0])

    setOwnState({
      ...ownState,
      logoHeader: imageUrl,
    })
  }

  const handleLoadMainBanner = () => {
    setOwnState({
      ...ownState,
      displayFooterColorPicker: false,
      displayBaseColorPicker: false,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterTextColorPicker: false,
    })

    let mainBanner = document.getElementById('img-main-banner')

    mainBanner.click()
  }

  const handleMainBannerLoaded = async (event) => {
    const target = event.target || window.event.srcElement,
      files = target.files

    const imageUrl = await campaignContext.uploadImage(files[0])

    setOwnState({
      ...ownState,
      mainBanner1: imageUrl,
    })
  }

  const handleLoadMainBannerWhatsApp = () => {
    // setOwnState({
    //   ...ownState,
    //   displayFooterColorPicker: false,
    //   displayBaseColorPicker: false,
    //   displayTitleColorPicker: false,
    //   displayTextColorPicker: false,
    //   displayTextButtonColorPicker: false,
    //   displayFooterTextColorPicker: false,
    // })

    let mainBanner = document.getElementById('img-main-banner-whatsapp')

    mainBanner.click()
  }

  const handleMainBannerLoadedWhatsApp = async (event) => {
    const target = event.target || window.event.srcElement,
      files = target.files

    if (files[0].size > 5 * 1024 * 1024) {
      toast.error('O arquivo deve ter no máximo 1MB')
      return
    }

    try {
      const imageUrl = await campaignContext.uploadImage(files[0])

      setWppMessageAttributes((prevAttributes) => {
        const updatedAttributes = [...prevAttributes]
        const index = getComponentIndex(isActiveLayoutSelector)

        if (updatedAttributes[index]) {
          updatedAttributes[index] = {
            ...updatedAttributes[index],
            attachmentFilePath: imageUrl,
          }
        }

        console.log('Updated attributes:', updatedAttributes)
        return updatedAttributes
      })

      toast.success('Imagem carregada com sucesso!')
    } catch (error) {
      console.error('Erro ao carregar imagem:', error)
      toast.error('Erro ao carregar imagem. Tente novamente.')
    }
  }

  const handleTextOnlyBoasVindas = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    })
  }

  const handleHtmlBoasVindas = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    })
  }

  const handleTextOnlyDicaMeta = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    })
  }

  const handleHtmlDicaMeta = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    })
  }

  const handleTextOnlyObrigado = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    })
  }

  const handleHtmlObrigado = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    })
  }

  const loadStateTextOnly = () => {
    const body = {
      ...ownState,
      displayBaseColorPicker: false,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterColorPicker: false,
      displayFooterTextColorPicker: false,
      baseColor: '',
      titleColor: '',
      textColor: '',
      textButtonColor: '',
      logoHeader: '',
      // campaignName: '',
      // companyName: '',
      // startDate: '',
      // endDate: '',
      linkJoinUs: '',
      mainBanner1: DefaultBannerTemplateExecutivo,
      mainBanner2: '',
      mainBanner3: '',
      footerColor: '',
      footerTextColor: '',
      logoFooter: LogoFooterTemplateExecutivo,
      textOnlyBoasVindas: '',
      textOnlyDicaMeta: '',
      textOnlyDicaMetaMaisTempo: '',
      textOnlyObrigado: '',
      customHtmlBoasVindas: '',
      customHtmlDicaMeta: '',
      customHtmlDicaMetaMaisTempo: '',
      customHtmlObrigado: '',
    }
    setOwnState(body)
  }

  const loadStateModern = () => {
    const body = {
      ...ownState,
      displayBaseColorPicker: false,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterColorPicker: false,
      displayFooterTextColorPicker: false,
      baseColor: '#6300FF',
      titleColor: '#000000',
      textColor: '#000000',
      textButtonColor: '#000000',
      logoHeader: LogoHeaderTemplateModerno,
      // campaignName: '{{campanha}}',
      // companyName: '{{empresa}}',
      // startDate: '{{inicio}}',
      // endDate: '{{fim}}',
      linkJoinUs: '{{campaignLink}}',
      mainBanner1: DefaultBannerTemplateModerno1,
      mainBanner2: DefaultBannerTemplateModerno2,
      mainBanner3: DefaultBannerTemplateModerno3,
      footerColor: '#000',
      footerTextColor: '#000000',
      logoFooter: LogoFooterTemplateModerno,
      textOnlyBoasVindas: '',
      textOnlyDicaMeta: '',
      textOnlyDicaMetaMaisTempo: '',
      textOnlyObrigado: '',
      customHtmlBoasVindas: '',
      customHtmlDicaMeta: '',
      customHtmlDicaMetaMaisTempo: '',
      customHtmlObrigado: '',
    }
    setOwnState(body)
  }

  const loadStateFunny = () => {
    const body = {
      ...ownState,
      displayBaseColorPicker: false,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterColorPicker: false,
      displayFooterTextColorPicker: false,
      baseColor: '#A500B9',
      titleColor: '#191919',
      textColor: '#000000',
      textButtonColor: '#FFFFFF',
      logoHeader: LogoHeaderTemplateDivertido,
      // campaignName: '{{campanha}}',
      // companyName: '{{empresa}}',
      // startDate: '{{inicio}}',
      // endDate: '{{fim}}',
      linkJoinUs: '{{campaignLink}}',
      mainBanner1: DefaultBannerTemplateDivertido,
      mainBanner2: '',
      mainBanner3: '',
      footerColor: '#000',
      footerTextColor: '#979DAD',
      logoFooter: LogoFooterTemplateDivertido,
      textOnlyBoasVindas: '',
      textOnlyDicaMeta: '',
      textOnlyDicaMetaMaisTempo: '',
      textOnlyObrigado: '',
      customHtmlBoasVindas: '',
      customHtmlDicaMeta: '',
      customHtmlDicaMetaMaisTempo: '',
      customHtmlObrigado: '',
    }
    setOwnState(body)
  }

  const loadStateExecutive = () => {
    const body = {
      ...ownState,
      displayBaseColorPicker: false,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterColorPicker: false,
      displayFooterTextColorPicker: false,
      baseColor: '#EEEEEE',
      titleColor: '#363A57',
      textColor: '#000000',
      textButtonColor: '#FFFFFF',
      logoHeader: LogoHeaderTemplateExecutivo,
      // campaignName: '{{campanha}}',
      // companyName: '{{empresa}}',
      // startDate: '{{inicio}}',
      // endDate: '{{fim}}',
      linkJoinUs: '{{campaignLink}}',
      mainBanner1: DefaultBannerTemplateExecutivo,
      mainBanner2: '',
      mainBanner3: '',
      footerColor: '#191841',
      footerTextColor: '#BFBFD1',
      logoFooter: LogoFooterTemplateExecutivo,
      textOnlyBoasVindas: '',
      textOnlyDicaMeta: '',
      textOnlyDicaMetaMaisTempo: '',
      textOnlyObrigado: '',
      customHtmlBoasVindas: '',
      customHtmlDicaMeta: '',
      customHtmlDicaMetaMaisTempo: '',
      customHtmlObrigado: '',
    }
    setOwnState(body)
  }

  const loadDefaultState = () => {
    const body = {
      ...ownState,
      displayBaseColorPicker: false,
      displayTitleColorPicker: false,
      displayTextColorPicker: false,
      displayTextButtonColorPicker: false,
      displayFooterColorPicker: false,
      displayFooterTextColorPicker: false,
      baseColor: '',
      titleColor: '',
      textColor: '',
      textButtonColor: '',
      logoHeader: '',
      // campaignName: '',
      // companyName: '',
      // startDate: '',
      // endDate: '',
      linkJoinUs: '',
      mainBanner1: '',
      mainBanner2: '',
      mainBanner3: '',
      footerColor: '',
      footerTextColor: '',
      logoFooter: '',
      textOnlyBoasVindas: '',
      textOnlyDicaMeta: '',
      textOnlyDicaMetaMaisTempo: '',
      textOnlyObrigado: '',
      customHtmlBoasVindas: '',
      customHtmlDicaMeta: '',
      customHtmlDicaMetaMaisTempo: '',
      customHtmlObrigado: '',
    }
    setOwnState(body)
  }

  const convertToMarkdownFromHTML = (htmlContent) => {
    const blocksFromHTML = convertFromHTML(htmlContent)
    const contentState = ContentState.createFromBlockArray(blocksFromHTML)
    const editorState = EditorState.createWithContent(contentState)

    let markdownString = ''

    const currentContent = editorState.getCurrentContent()
    const blockMap = currentContent.getBlockMap()

    blockMap.forEach((block) => {
      let text = block.getText()
      let offset = 0

      block.findStyleRanges(
        (characterMetadata) =>
          characterMetadata.hasStyle('BOLD') ||
          characterMetadata.hasStyle('ITALIC'),
        (start, end) => {
          const styles = block.getInlineStyleAt(start)
          let prefix = ''
          let suffix = ''

          if (styles.has('BOLD')) {
            prefix += '*'
            suffix += '*'
          }
          if (styles.has('ITALIC')) {
            prefix += '_'
            suffix += '_'
          }

          text =
            text.substring(0, start + offset) +
            prefix +
            text.substring(start + offset, end + offset) +
            suffix +
            text.substring(end + offset)
          offset += prefix.length + suffix.length
        }
      )

      markdownString += text + '\n'
    })

    return markdownString
  }

  const handleDescriptionEditorChangeWithStyle = (newState) => {
    const currentStyle = newState.getCurrentInlineStyle()

    if (currentStyle.has('BOLD') && currentStyle.has('ITALIC')) {
      if (currentStyle.has('BOLD')) {
        const updatedState = RichUtils.toggleInlineStyle(newState, 'ITALIC')
        handleDescriptionEditorChange(updatedState)
      } else {
        const updatedState = RichUtils.toggleInlineStyle(newState, 'BOLD')
        handleDescriptionEditorChange(updatedState)
      }
    } else {
      handleDescriptionEditorChange(newState)
    }
  }

  const convertToMarkdown = (contentState) => {
    const rawContent = convertToRaw(contentState)
    let markdownString = ''

    let appliedStyles = [] // Array para armazenar os estilos já aplicados

    rawContent.blocks.forEach((block) => {
      let text = block.text

      let offset = 0 // Inicializa o offset para acompanhar as mudanças no texto

      block.inlineStyleRanges.forEach((styleBlock) => {
        const start = styleBlock.offset + offset // Calcula o início com base no offset atual
        const end = start + styleBlock.length // Calcula o fim com base no início e no comprimento

        // Verifica se o estilo atual já foi aplicado a esta parte do texto
        const isStyleApplied = appliedStyles.some(
          (appliedStyle) =>
            appliedStyle.start <= start && appliedStyle.end >= end
        )

        // Se o estilo atual não estiver aplicado, aplica-o
        if (!isStyleApplied) {
          let prefix = ''
          let suffix = ''

          switch (styleBlock.style) {
            case 'BOLD':
              prefix = suffix = '*'
              break
            case 'ITALIC':
              prefix = suffix = '_'
              break
            case 'BOLD_ITALIC':
              prefix = '_*'
              suffix = '*_'
              break
            case 'BREAK':
              prefix = '\n'
              break
            default:
              break
          }

          // Verifica se o estilo atual interfere com estilos já aplicados
          const conflictingStyle = appliedStyles.find(
            (appliedStyle) =>
              (appliedStyle.start <= start && appliedStyle.end > start) ||
              (appliedStyle.start < end && appliedStyle.end >= end)
          )

          if (!conflictingStyle) {
            text =
              text.substring(0, start) +
              prefix +
              text.substring(start, end) +
              suffix +
              text.substring(end)

            // Ajusta o offset de acordo com o número de caracteres adicionados
            offset += prefix.length + suffix.length

            // Registra o estilo aplicado
            appliedStyles.push({ start, end })
          }
        }
      })

      markdownString += text + '\n'
    })

    return markdownString
  }

  const handleSelectEmoji = (emoji) => {
    const custom =
      isActiveLayoutSelector === 13
        ? 11
        : isActiveLayoutSelector === 11
        ? 4
        : isActiveLayoutSelector

    let currentEditorState =
      activeEditor === 'description'
        ? descriptionEditorStates[custom]
        : introductionEditorStates[custom]

    if (!currentEditorState || !currentEditorState.getCurrentContent()) {
      console.error('O estado do editor não está inicializado corretamente.')
      return
    }

    const selection = currentEditorState.getSelection()
    const contentState = Modifier.replaceText(
      currentEditorState.getCurrentContent(),
      selection,
      emoji.native
    )
    const newEditorState = EditorState.push(
      currentEditorState,
      contentState,
      'insert-characters'
    )

    if (activeEditor === 'description') {
      handleDescriptionEditorChange(newEditorState)
    } else {
      handleIntroductionEditorChange(newEditorState)
    }

    setShowEmojiPicker(false) // fecha o seletor após selecionar o emoji

    editorRef.current.focusEditor()
  }

  const weekToIndicesMapping = {
    0: [0, 11], // boas-vindas e campanha encerrada
    1: [0, 11], // boas-vindas e campanha encerrada
    2: [0, 1, 11], // boas-vindas, dica meta e campanha encerrada
    3: [0, 1, 11], // boas-vindas, dica meta e campanha encerrada
    4: [0, 1, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1 e campanha encerrada
    5: [0, 1, 2, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período e campanha encerrada
    6: [0, 1, 2, 4, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período 1, pergunta engajamento 2 e campanha encerrada
    7: [0, 1, 2, 4, 9, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período 1, como conhecer produto, pergunta engajamento 2 e campanha encerrada
    8: [0, 1, 2, 3, 4, 9, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período 1, como conhecer produto, pergunta engajamento 2, lembrete período 2 e campanha encerrada
    9: [0, 1, 2, 3, 4, 8, 9, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período 1, como conhecer produto, pergunta engajamento 2, lembrete período 2, pergunta motivacional 3, relembre regra, pergunta motivacional 2 e campanha encerrada
    10: [0, 1, 2, 3, 4, 5, 8, 9, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período 1, como conhecer produto, pergunta engajamento 2, lembrete período 2, pergunta motivacional 3, relembre regra, pergunta motivacional 2 e campanha encerrada
    11: [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período 1, como conhecer produto, pergunta engajamento 2, lembrete período 2, pergunta motivacional 3, relembre regra, pergunta motivacional 2 e campanha encerrada
    12: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período 1, como conhecer produto, pergunta engajamento 2, lembrete período 2, pergunta motivacional 3, relembre regra, pergunta motivacional 2, pergunta engajamento 1 e campanha encerrada
    13: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // boas-vindas, dica meta, pergunta motivacional 1, lembrete período 1, como conhecer produto, pergunta engajamento 2, lembrete período 2, pergunta motivacional 3, relembre regra, pergunta motivacional 2, pergunta engajamento 1 e campanha encerrada
  }

  const weekToIndicesMappingApp = {
    0: [0, 13],
    1: [0, 13],
    2: [0, 1, 13],
    3: [0, 1, 12, 13],
    4: [0, 1, 6, 12, 13],
    5: [0, 1, 6, 3, 12, 13],
    6: [0, 1, 2, 3, 6, 12, 13],
    7: [0, 1, 2, 3, 6, 5, 12, 13],
    8: [0, 1, 2, 3, 6, 5, 8, 12, 13],
    9: [0, 1, 2, 3, 4, 5, 6, 8, 12, 13],
    10: [0, 1, 2, 3, 4, 5, 6, 7, 8, 12, 13],
    11: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13],
    12: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13],
    13: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  }

  const handleCheckWppMessageAttributes = () => {
    const selectedWeek = campaignContext.getTotalDispatch()
    const indicesToCheck = weekToIndicesMapping[selectedWeek] || []

    const hasEmptyFields = indicesToCheck.some((index) => {
      const item = wppMessageAttributes[index]
      if (!item) return false // Se o índice não corresponder a um item, ignore-o

      const fieldsToCheck = ['title', 'introduction', 'description']
      return fieldsToCheck.some((field) => {
        return !item[field] || item[field].trim() === ''
      })
    })

    return hasEmptyFields // Retorna true se qualquer campo editável estiver vazio nos objetos selecionados
  }

  const handleCheckAppMessageAttributes = () => {
    const selectedWeek = campaignContext.getTotalDispatch()
    const indicesToCheck = weekToIndicesMappingApp[selectedWeek] || []

    const hasEmptyFields = indicesToCheck.some((index) => {
      const item = appMessageAttributes[index]
      if (!item) return false // Se o índice não corresponder a um item, ignore-o

      const fieldsToCheck = ['title', 'description']
      return fieldsToCheck.some((field) => {
        return !item[field] || item[field].trim() === ''
      })
    })

    return hasEmptyFields // Retorna true se qualquer campo editável estiver vazio nos objetos selecionados
  }

  const activateDescriptionEditor = () => {
    setActiveEditor('description')
    toggleEmojiPicker()
  }

  const activateIntroductionEditor = () => {
    setActiveEditor('introduction')
    toggleEmojiPicker()
  }

  function EmojiButton({ onClick }) {
    return (
      <div
        onClick={onClick}
        style={{ display: 'inline-block', cursor: 'pointer' }}
      >
        😀
      </div>
    )
  }

  const handleIntroductionEditorChange = (newEditorState) => {
    const markdownContent = convertToMarkdown(
      newEditorState.getCurrentContent()
    )

    // Verifica se o conteúdo do editor tem mais de 1000 caracteres
    if (markdownContent.length > 1000) {
      toast.error('O campo introdução deve ter no máximo 1000 caracteres')
      return
    }

    setIntroductionEditorStates((prevStates) => ({
      ...prevStates,
      [isActiveLayoutSelector]: newEditorState,
    }))

    handleFieldComunicationWpp(
      { target: { value: markdownContent } },
      'introduction'
    )
  }

  const handleDescriptionEditorChange = (newEditorState) => {
    let adjustedIndex = getComponentIndex(isActiveLayoutSelector)

    const markdownContent = convertToMarkdown(
      newEditorState.getCurrentContent()
    )

    if (markdownContent.length > 1000) {
      toast.error('O campo introdução deve ter no máximo 1000 caracteres')
      return
    }

    const currentIntroductionLength =
      wppMessageAttributes[isActiveLayoutSelector]?.introduction?.length || 0

    const totalLength = markdownContent.length + currentIntroductionLength

    if (totalLength > CHARACTER_LIMIT) {
      toast.error(
        `O total de caracteres de introdução e descrição deve ser no máximo ${CHARACTER_LIMIT} caracteres`
      )
      return
    }

    setDescriptionEditorStates((prevStates) => ({
      ...prevStates,
      [adjustedIndex]: newEditorState,
    }))

    handleFieldComunicationWpp(
      { target: { value: markdownContent } },
      'description'
    )
  }

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker)
  }

  const countWeeks = () => {
    let dateRangeStart
    let dateRangeEnd

    dateRangeStart =
      state.dateRange && state.dateRange[0] && state.dateRange[0].split('/')
    dateRangeEnd =
      state.dateRange && state.dateRange[1] && state.dateRange[1].split('/')

    let startYear = parseFloat(dateRangeStart && dateRangeStart[2])
    let startMonth = parseFloat(dateRangeStart && dateRangeStart[1])
    let startDay = parseFloat(dateRangeStart && dateRangeStart[0])

    let endYear = parseFloat(dateRangeEnd && dateRangeEnd[2])
    let endMonth = parseFloat(dateRangeEnd && dateRangeEnd[1])
    let endDay = parseFloat(dateRangeEnd && dateRangeEnd[0])

    let startDate = moment(new Array(startYear, startMonth, startDay))
    let endDate = moment(new Array(endYear, endMonth, endDay))

    setOwnState({
      ...ownState,
      weekCounter: endDate.diff(startDate, 'week'),
    })
  }

  const getEditorHTML = (field) => {
    const adjustedIndex = getComponentIndex(isActiveLayoutSelector)

    if (field === 'introduction') {
      const rawContentState = convertToRaw(
        introductionEditorStates[adjustedIndex]?.getCurrentContent()
      )
      const html = draftToHtml(rawContentState)
      return html
    } else if (field === 'description') {
      const rawContentState = convertToRaw(
        descriptionEditorStates[adjustedIndex]?.getCurrentContent()
      )
      const html = draftToHtml(rawContentState)
      return html
    }

    return ''
  }

  const handleFieldComunicationWpp = (event, field) => {
    let adjustedIndex = getComponentIndex(isActiveLayoutSelector)

    if (field === 'title') {
      if (
        !event ||
        !event.target ||
        typeof event.target.value === 'undefined'
      ) {
        return
      }

      const { value } = event.target

      setWppMessageAttributes((prevState) => {
        const newState = [...prevState]
        if (newState[adjustedIndex]) {
          newState[adjustedIndex] = {
            ...newState[adjustedIndex],
            [field]: value,
          }
        }
        return newState
      })
    } else {
      const htmlContent = getEditorHTML(field)
      const markdownContent = convertToMarkdownFromHTML(htmlContent)
      setWppMessageAttributes((prevState) => {
        const newState = [...prevState]
        if (newState[adjustedIndex]) {
          newState[adjustedIndex] = {
            ...newState[adjustedIndex],
            [field]: markdownContent,
          }
        }
        return newState
      })
    }
  }

  useEffect(() => {
    const keys = Object.keys(state.isLayoutSelectorActive)
    const activeLayoutKey = keys.find(
      (key) => state.isLayoutSelectorActive[key] === true
    )

    setIsActiveLayoutSelector(Number(activeLayoutKey))
  }, [state.isLayoutSelectorActive])

  useEffect(() => {
    countWeeks()
    switch (state.layoutTitleChoosen) {
      case 'WhatsApp padrão':
        loadStateTextOnly()
        break
      case 'Moderno':
        loadStateModern()
        break
      case 'Divertido':
        loadStateFunny()
        break
      case 'Executivo':
        loadStateExecutive()
        break
      default:
        loadDefaultState()
        break
    }
  }, [state.layoutTitleChoosen])

  const [savedWppMessageAttributes, setSavedWppMessageAttributes] = useState({})
  const [savedDescriptionEditorStates, setSavedDescriptionEditorStates] =
    useState({})

  useEffect(() => {
    if (state.layoutTitleChoosen === 'WhatsApp personalizado') {
      setSavedWppMessageAttributes(wppMessageAttributes)
      setSavedDescriptionEditorStates(descriptionEditorStates)
      setWppMessageAttributes((prevState) =>
        prevState.map((item) => ({
          ...item,
          description: '',
        }))
      )
      setDescriptionEditorStates((prevStates) => ({
        ...prevStates,
        [isActiveLayoutSelector]: EditorState.createEmpty(),
      }))
    } else {
      if (savedWppMessageAttributes) {
        setWppMessageAttributes(savedWppMessageAttributes)
      }
      if (savedDescriptionEditorStates) {
        setDescriptionEditorStates(savedDescriptionEditorStates)
      }
    }
  }, [state.layoutTitleChoosen])

  useEffect(() => {
    campaignContext.setLayoutInfos(() => ownState)
  }, [ownState, campaignContext])

  async function handleSave() {
    await campaignContext.setLayoutInfos(() => ownState)
    const selectedWeek = campaignContext.getTotalDispatch()
    const indicesToInclude =
      campaignContext?.communicationType === 'app'
        ? weekToIndicesMappingApp[selectedWeek] || []
        : weekToIndicesMapping[selectedWeek] || []

    const filteredAttributes =
      campaignContext?.communicationType === 'app'
        ? appMessageAttributes
            .filter((item, index) => indicesToInclude.includes(index))
            .filter(
              (item) => item.title || item.introduction || item.description
            )
        : wppMessageAttributes
            .filter((item, index) => indicesToInclude.includes(index))
            .filter(
              (item) => item.title || item.introduction || item.description
            )

    if (campaignContext?.communicationType === 'app') {
      await campaignContext.setNotificationDetails(filteredAttributes)
    } else {
      await campaignContext.setCampaignDetails(filteredAttributes)
    }

    campaignContext.setCampaignDetailsParams(
      filteredAttributes.map((attribute, index) => ({
        name: attribute.id,
        campaignName: layoutSelection[index]?.layoutTitle,
        parameters: [
          campaignContext.campaign.name,
          campaignContext.companyName,
          moment(campaignContext.campaign.periodDate.from).format('DD/MM/YYYY'),
          moment(campaignContext.campaign.periodDate.to).format('DD/MM/YYYY'),
          attribute.description.replace(/\n/g, '\n\n'),
        ],
        file: attribute.attachmentFilePath,
        actions: attribute.actions || [],
      }))
    )

    handleSaveLayout()
  }

  const verifyFluxDataMeta = () => {
    if (campaignContext.isSegmentation === 'yes') {
      return '_Meta por segmento que o seu participante deve alcançar._'
    } else if (ownState.campaignType === 0) {
      return ownState?.campaignBateu.replace('.', ',')
    } else if (ownState.campaignType === 1) {
      const campaignVolumeRuleText = ownState?.campaignVolumeRuleText
        .replace(/Serviço ou Produto:/g, '')
        .replace(/<b><\/b>\s*/g, '')
        .replace(/<b>/g, '*')
        .replace(/<\/b>/g, '*')
        .replace(/<i>/g, '_')
        .replace(/<\/i>/g, '_')
        .replace(/<u>/g, '')
        .replace(/<\/u>/g, '')
        .replace(/<br\s*\/?>/g, '\n')
      return campaignVolumeRuleText
    } else if (ownState.campaignType === 2) {
      const reachValueDescription =
        campaignContext.campaignState?.rule?.multipleReachValueCondition
          ?.reachValue.description
      const conditionType =
        campaignContext.campaignState?.rule?.multipleReachValueCondition
          ?.conditionType
      const reachValueFromDescription =
        campaignContext.campaignState?.rule?.multipleReachValueCondition
          ?.reachValueFromCondition?.description

      return (
        (reachValueDescription ? reachValueDescription + ' ' : '') +
        (conditionType ? conditionType + ' ' : '') +
        (reachValueFromDescription ? reachValueFromDescription : '')
      )
    }
  }

  const verifyFluxDataLevou = () => {
    if (campaignContext.isSegmentation === 'yes') {
      return '_Prêmio por segmento que o seu participante ira ganhar alcançando a meta._'
    } else if (ownState.campaignType === 0) {
      return ownState?.campaignLevou.replace('.', ',')
    } else if (ownState.campaignType === 1) {
      return ownState?.campaignVolumePrize
    } else if (ownState.campaignType === 2) {
      const discountDescription =
        campaignContext.campaignState?.prize?.multipleDiscount?.discount
          ?.description
      const conditionType =
        campaignContext.campaignState?.prize?.multipleDiscount?.conditionType
      const discountFromDescription =
        campaignContext.campaignState?.prize?.multipleDiscount
          ?.discountFromCondition?.description

      return (
        (discountDescription ? discountDescription + ' ' : '') +
        (conditionType ? conditionType + ' ' : '') +
        (discountFromDescription ? discountFromDescription : '')
      )
    }
  }

  const handleChangeDataVariables = () => {
    setWppMessageAttributes(
      wppMessageAttributes.map((item) => {
        let newDescription = item.description
        if (
          campaignContext.segmentTags.length === 0 &&
          item.description.includes(
            '_Meta por segmento que o seu participante deve alcançar._'
          )
        ) {
          newDescription = item.description.replace(
            /_Meta por segmento que o seu participante deve alcançar._/g,
            verifyFluxDataMeta()
          )
        }
        if (
          campaignContext.segmentTags.length === 0 &&
          item.description.includes(
            '_Prêmio por segmento que o seu participante ira ganhar alcançando a meta._'
          )
        ) {
          newDescription = item.description.replace(
            /_Prêmio por segmento que o seu participante ira ganhar alcançando a meta._/g,
            verifyFluxDataLevou()
          )
        } else {
          newDescription = item.description
            .replace(/{{meta}}/g, verifyFluxDataMeta() || '')
            .replace(/{{prêmio}}/g, verifyFluxDataLevou() || '')
            .replace(/{{site}}/g, ownState?.campaignWebSite || '')
            .replace(
              /{{regulamento}}/g,
              campaignContext?.termsOfService?.fileUrl || ''
            )
        }

        return {
          ...item,
          description: newDescription,
        }
      })
    )
  }

  const getComponentIndex = (componentId) => {
    const indexMap = {
      0: 0, // Boas vindas
      1: 1, // Dica para bater a meta
      2: 2, // Motivacional 1
      3: 3, // Lembrete de Período 1
      4: 4, // Como conhecer o seu Produto
      5: 5, // Pergunta de Engajamento 2
      6: 6, // Lembrete de Período 2
      7: 7, // Motivacional 3
      8: 8, // Relembre suas Regras
      9: 9, // Lembrete de Período 3
      10: 9, // Motivacional 2
      11: 10, // Pergunta de Engajamento 1 (mapeado para o índice 4 no wppMessageAttributes)
      12: 10, // Contagem Regressiva
      13: 11, // Obrigado por participar
    }

    return indexMap[componentId] !== undefined
      ? indexMap[componentId]
      : componentId
  }

  useEffect(() => {
    handleChangeDataVariables()
  }, [ownState, campaignContext, campaignContext.segmentTags])

  return (
    <Modal
      size={size}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={`
          ${styles.modal()}
          modal-layout-edit
        `}
    >
      <Modal.Header closeButton className='header-layout-edit'>
        <Modal.Title id='contained-modal-title-vcenter' className='w-100'>
          <Row>
            <Col xs={10} className='d-flex align-items-center'>
              <Row className='w-100'>
                <Col
                  xs={'auto'}
                  className={`
                      ${
                        state.layoutTitleChoosen !== 'WhatsApp personalizado' &&
                        state.layoutTitleChoosen !== 'WhatsApp padrão' &&
                        styles.border({ borderRightWidth: 'thin' })
                      }
                      ${
                        state.layoutTitleChoosen !== 'WhatsApp personalizado' &&
                        'separator-editor-border-color'
                      }
                      d-flex align-items-center
                    `}
                >
                  {state.layoutTitleChoosen !== 'Notificação padrão' ? (
                    <p
                      className={`
                        ${styles.tipography({ headings: 'xsmallBold' })}
                        m-0
                      `}
                    >
                      Layout{' '}
                      <span className='text-lowercase'>
                        {state.layoutTitleChoosen}
                      </span>
                    </p>
                  ) : (
                    <p
                      className={`
                          ${styles.tipography({ headings: 'xsmallBold' })}
                          m-0
                        `}
                    >
                      Notification Push
                    </p>
                  )}
                </Col>
                <Col xs={'auto'}>
                  {state.layoutTitleChoosen !== 'WhatsApp personalizado' &&
                    campaignContext?.communicationType !== 'app' && (
                      <Row>
                        {state.layoutTitleChoosen !== 'WhatsApp padrão' && (
                          <Col xs={'auto'}>
                            <ButtonComponent
                              className={`
                                ${styles.buttonStandardMedium({
                                  medium: 'link',
                                })}
                                ${styles.customIcons({
                                  type: 'others',
                                })}
                                ${styles.colors({
                                  colors: 'neutralColorLowDark',
                                })}
                                btn-layout-customizer
                              `}
                              hasIcon={true}
                              hasCustomIcon={true}
                              customIconName='button-icon'
                              text={`${
                                !ownState.displayBaseColorPicker
                                  ? 'Cor base'
                                  : 'Fechar'
                              }`}
                              styles={styles}
                              onClick={() => handleOpenBaseColorPicker()}
                            />
                            {ownState.displayBaseColorPicker ? (
                              <div className='color-picker-position'>
                                <ChromePicker
                                  color={ownState.baseColor}
                                  onChangeComplete={(e) => handleBaseColor(e)}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                        )}
                        {/* {state.layoutTitleChoosen !== 'WhatsApp' && (
                          <Col xs={'auto'}>
                            <ButtonComponent
                              className={`
                                ${styles.buttonStandardMedium({
                                  medium: 'link',
                                })}
                                ${styles.customIcons({
                                  type: 'others',
                                })}
                                ${styles.colors({
                                  colors: 'neutralColorLowDark',
                                })}
                                btn-layout-customizer
                              `}
                              hasIcon={true}
                              hasCustomIcon={true}
                              customIconName='title-icon'
                              text={`${
                                !ownState.displayTitleColorPicker
                                  ? 'Títulos'
                                  : 'Fechar'
                              }`}
                              styles={styles}
                              onClick={() => handleOpenTitleColorPicker()}
                            />
                            {ownState.displayTitleColorPicker ? (
                              <div className='color-picker-position'>
                                <ChromePicker
                                  color={ownState.titleColor}
                                  onChangeComplete={(e) => handleTitleColor(e)}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                        )} */}
                        {/* {state.layoutTitleChoosen !== 'WhatsApp' && (
                          <Col xs={'auto'}>
                            <ButtonComponent
                              className={`
                                ${styles.buttonStandardMedium({
                                  medium: 'link',
                                })}
                                ${styles.customIcons({
                                  type: 'others',
                                })}
                                ${styles.colors({
                                  colors: 'neutralColorLowDark',
                                })}
                                btn-layout-customizer
                              `}
                              hasIcon={true}
                              hasCustomIcon={true}
                              customIconName='title-icon'
                              text={`${
                                !ownState.displayTextColorPicker
                                  ? 'Textos'
                                  : 'Fechar'
                              }`}
                              styles={styles}
                              onClick={() => handleOpenTextColorPicker()}
                            />
                            {ownState.displayTextColorPicker ? (
                              <div className='color-picker-position'>
                                <ChromePicker
                                  color={ownState.textColor}
                                  onChangeComplete={(e) => handleTextColor(e)}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                        )} */}
                        {/* {state.layoutTitleChoosen !== 'WhatsApp' && (
                          <Col xs={'auto'}>
                            <ButtonComponent
                              className={`
                                ${styles.buttonStandardMedium({
                                  medium: 'link',
                                })}
                                ${styles.customIcons({
                                  type: 'others',
                                })}
                                ${styles.colors({
                                  colors: 'neutralColorLowDark',
                                })}
                                btn-layout-customizer
                              `}
                              hasIcon={true}
                              hasCustomIcon={true}
                              customIconName='title-icon'
                              text={`${
                                !ownState.displayTextButtonColorPicker
                                  ? 'Texto botão'
                                  : 'Fechar'
                              }`}
                              styles={styles}
                              onClick={() => handleOpenTextButtonColorPicker()}
                            />
                            {ownState.displayTextButtonColorPicker ? (
                              <div className='color-picker-position'>
                                <ChromePicker
                                  color={ownState.textButtonColor}
                                  onChangeComplete={(e) =>
                                    handleTextButtonColor(e)
                                  }
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                        )} */}
                        {state.layoutTitleChoosen !== 'WhatsApp padrão' && (
                          <Col xs={'auto'}>
                            <ButtonComponent
                              className={`
                                ${styles.buttonStandardMedium({
                                  medium: 'link',
                                })}
                                ${styles.customIcons({
                                  type: 'others',
                                })}
                                ${styles.colors({
                                  colors: 'neutralColorLowDark',
                                })}
                                btn-layout-customizer
                              `}
                              hasIcon={true}
                              hasCustomIcon={true}
                              customIconName='title-icon'
                              text={`${
                                !ownState.displayFooterColorPicker
                                  ? 'Cor do titulo'
                                  : 'Fechar'
                              }`}
                              styles={styles}
                              onClick={() => handleOpenFooterColorPicker()}
                            />
                            {ownState.displayFooterColorPicker ? (
                              <div className='color-picker-position'>
                                <ChromePicker
                                  color={ownState.footerColor}
                                  onChangeComplete={(e) => handleFooterColor(e)}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                        )}
                        {/* {state.layoutTitleChoosen !== 'WhatsApp' && (
                          <Col
                            xs={'auto'}
                            className={`
                              ${styles.border({ borderRightWidth: 'thin' })}
                              separator-editor-border-color
                            `}
                          >
                            <ButtonComponent
                              className={`
                                ${styles.buttonStandardMedium({
                                  medium: 'link',
                                })}
                                ${styles.customIcons({
                                  type: 'others',
                                })}
                                ${styles.colors({
                                  colors: 'neutralColorLowDark',
                                })}
                                btn-layout-customizer
                              `}
                              hasIcon={true}
                              hasCustomIcon={true}
                              customIconName='title-icon'
                              text={`${
                                !ownState.displayFooterColorPicker
                                  ? 'Textos rodapé'
                                  : 'Fechar'
                              }`}
                              styles={styles}
                              onClick={() => handleOpenFooterTextColorPicker()}
                            />
                            {ownState.displayFooterTextColorPicker ? (
                              <div className='color-picker-position'>
                                <ChromePicker
                                  color={ownState.footerTextColor}
                                  onChangeComplete={(e) =>
                                    handleFooterTextColor(e)
                                  }
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </Col>
                        )} */}
                        {state.layoutTitleChoosen !== 'WhatsApp padrão' && (
                          <>
                            <Col xs={'auto'}>
                              <ButtonComponent
                                className={`
                                  ${styles.buttonStandardMedium({
                                    medium: 'link',
                                  })}
                                  ${styles.customIcons({
                                    type: 'others',
                                  })}
                                  ${styles.colors({
                                    colors: 'neutralColorLowDark',
                                  })}
                                  btn-layout-customizer
                                `}
                                hasIcon={true}
                                hasCustomIcon={true}
                                customIconName='logo-icon'
                                text='Logo'
                                styles={styles}
                                onClick={() => handleLoadLogoHeader()}
                              />
                              <input
                                id='img-logo-header'
                                className='d-none'
                                type='file'
                                accept='image/png, image/gif, image/jpeg'
                                onChange={(e) => handleLogoImageHeaderLoaded(e)}
                              />
                            </Col>
                            <Col xs={'auto'}>
                              <ButtonComponent
                                className={`
                                  ${styles.buttonStandardMedium({
                                    medium: 'link',
                                  })}
                                  ${styles.customIcons({
                                    type: 'others',
                                  })}
                                  ${styles.colors({
                                    colors: 'neutralColorLowDark',
                                  })}
                                  btn-layout-customizer
                                `}
                                hasIcon={true}
                                hasCustomIcon={true}
                                customIconName='banner1-icon'
                                text='Banner principal'
                                styles={styles}
                                onClick={() => handleLoadMainBanner()}
                              />
                              <input
                                id='img-main-banner'
                                className='d-none'
                                type='file'
                                accept='image/png, image/gif, image/jpeg'
                                onChange={(e) => handleMainBannerLoaded(e)}
                              />
                            </Col>
                          </>
                        )}
                        {/* {state.layoutTitleChoosen !== 'WhatsApp' && (
                          <Col xs={'auto'}>
                            <ButtonComponent
                              className={`
                                ${styles.buttonStandardMedium({
                                  medium: 'link',
                                })}
                                ${styles.customIcons({
                                  type: 'others',
                                })}
                                ${styles.colors({
                                  colors: 'neutralColorLowDark',
                                })}
                                btn-layout-customizer
                              `}
                              hasIcon={true}
                              hasCustomIcon={true}
                              customIconName='banner-icon'
                              text='Logo rodapé'
                              styles={styles}
                              onClick={() => handleLoadLogoFooter()}
                            />
                            <input
                              id='img-logo-footer'
                              className='d-none'
                              type='file'
                              accept='image/png, image/gif, image/jpeg'
                              onChange={(e) => handleLogoImageFooterLoaded(e)}
                            />
                          </Col>
                        )} */}
                      </Row>
                    )}

                  {state.layoutTitleChoosen === 'Notificação padrão' && (
                    <Row>
                      <p
                        className={`
                        ${styles.tipography({ headings: 'xsmallBold' })}
                        m-0
                      `}
                      >
                        Utilize nosso padrão de notificações
                      </p>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={2} className='d-flex justify-content-end pe-4'>
              <ButtonComponent
                className={`
                    ${styles.buttonStandardMedium({
                      medium: 'primary',
                    })}
                  `}
                disabled={
                  campaignContext?.communicationType === 'app'
                    ? handleCheckAppMessageAttributes()
                    : handleCheckWppMessageAttributes()
                }
                hasIcon={true}
                iconName='save'
                text={
                  campaignContext.isLoadingLayoutUpload
                    ? 'Carregando...'
                    : 'Salvar e continuar'
                }
                styles={styles}
                onClick={() => handleSave()}
              />
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='h-100'>
          <div className={`${styles.steps()} selector-area mt-4`}>
            <p className={styles.tipography({ paragraphs: 'mediumBold' })}>
              Confira as comunicações da campanha
            </p>
            <LayoutTypeEditorSelector
              styles={styles}
              state={state}
              layoutSelection={layoutSelection}
              handleLayoutSelector={handleLayoutSelector}
              totalDispatchWeek={totalDispatchWeek}
              onEditDate={handleEditDate}
            />
          </div>
          {(state.layoutTitleChoosen === 'WhatsApp personalizado' ||
            state.layoutTitleChoosen === 'WhatsApp padrão') && (
            <Col className={`selector-area mt-4`}>
              <Input
                id='titleComunication'
                inputName='titleComunication'
                className={styles.inputs({ input: 'default' })}
                idError='titleComunicationError'
                inputType='text'
                labelName='Título da comunicação (obrigatório)'
                placeholder='Insira um título de sua preferência'
                styles={styles}
                value={
                  wppMessageAttributes[
                    getComponentIndex(isActiveLayoutSelector)
                  ]?.title
                }
                disabled={state.layoutTitleChoosen !== 'WhatsApp personalizado'}
                onChange={(e) => handleFieldComunicationWpp(e, 'title')}
                maxLength={100}
              />
              <div className='editor-container'>
                <label
                  htmlFor='introductionEditor'
                  className={`editor-label ${
                    state.layoutTitleChoosen !== 'WhatsApp personalizado' &&
                    'editor-label-disabled'
                  }`}
                >
                  Introdução da comunicação (obrigatório)
                </label>
                <Editor
                  editorState={
                    introductionEditorStates[1] ||
                    initialStateIntroduction ||
                    EditorState.createEmpty()
                  }
                  onEditorStateChange={handleIntroductionEditorChange}
                  wrapperClassName='rt-wrapper'
                  editorClassName={`rt-editor ${
                    state.layoutTitleChoosen !== 'WhatsApp personalizado' &&
                    'editor-disabled'
                  }`}
                  toolbarClassName='rt-toolbar'
                  labelName='Introdução da comunicação'
                  ariaLabel='Introdução da comunicação'
                  readOnly={
                    state.layoutTitleChoosen !== 'WhatsApp personalizado'
                  }
                  placeholder='Insira uma introdução sobre a sua campanha'
                  toolbar={{
                    options: ['inline'],
                    inline: {
                      options: ['bold'],
                    },
                  }}
                  value={
                    wppMessageAttributes[
                      getComponentIndex(isActiveLayoutSelector)
                    ]?.introduction
                  }
                  toolbarCustomButtons={
                    state.layoutTitleChoosen === 'WhatsApp personalizado' && [
                      <EmojiButton onClick={activateIntroductionEditor} />,
                    ]
                  }
                  onChange={(e) =>
                    handleFieldComunicationWpp(e, 'introduction')
                  }
                />
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      bottom: 50,
                      zIndex: 9999,
                    }}
                  >
                    {showEmojiPicker && (
                      <Picker
                        previewPosition={'none'}
                        navPosition={'bottom'}
                        native={true}
                        onEmojiSelect={handleSelectEmoji}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='editor-container'>
                <label htmlFor='descriptionEditor' className='editor-label'>
                  Descrição da comunicação (obrigatório)
                </label>

                <Editor
                  ref={editorRef}
                  editorState={
                    descriptionEditorStates[
                      getComponentIndex(isActiveLayoutSelector)
                    ] ||
                    initialStateDescription ||
                    EditorState.createEmpty()
                  }
                  onEditorStateChange={handleDescriptionEditorChangeWithStyle}
                  wrapperClassName='rt-wrapper'
                  editorClassName='rt-editor'
                  toolbarClassName='rt-toolbar'
                  labelName='Descrição da comunicação'
                  ariaLabel='Descrição da comunicação'
                  placeholder='Aqui você pode escrever uma descrição e uma mensagem personalizada sobre a sua campanha'
                  toolbar={{
                    options: ['inline'],
                    inline: {
                      options: ['bold'],
                      bold: { className: 'custom-bold' },
                    },
                  }}
                  value={
                    wppMessageAttributes[
                      getComponentIndex(isActiveLayoutSelector)
                    ]?.description
                  }
                  toolbarCustomButtons={[
                    <EmojiButton onClick={activateDescriptionEditor} />,
                  ]}
                  onChange={(e) => handleFieldComunicationWpp(e, 'description')}
                  customStyleMap={{
                    BOLD: { fontWeight: 'bold' },
                  }}
                />
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      bottom: 50,
                      zIndex: 9999,
                    }}
                  >
                    {showEmojiPicker && (
                      <Picker
                        previewPosition={'none'}
                        navPosition={'bottom'}
                        native={true}
                        onEmojiSelect={handleSelectEmoji}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className='tw-flex tw-justify-end'
                style={{ marginTop: '-10px', marginBottom: '10px' }}
              >
                {wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.description.length +
                  wppMessageAttributes[
                    getComponentIndex(isActiveLayoutSelector)
                  ]?.introduction.length}
                /{CHARACTER_LIMIT}
              </div>
              <div className='file-input'>
                <p className='file-input-title'>
                  Adicione aqui a imagem da sua comunicação.
                </p>
                <div className='file-drop-area'>
                  <div
                    className='file-icon'
                    onClick={() => handleLoadMainBannerWhatsApp()}
                  >
                    <img src={ImageLogin} alt='Upload' />
                  </div>
                  <label htmlFor='img-main-banner-whatsapp'>
                    <span className='label-file'>Selecionar o arquivo</span>
                    Formato PNG, JPG, GIF, MP4, Doc, PDF com até 5MB
                  </label>

                  <input
                    id='img-main-banner-whatsapp'
                    className='d-none'
                    type='file'
                    accept='image/png, image/gif, image/jpeg, video/mp4, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf'
                    onChange={(e) => handleMainBannerLoadedWhatsApp(e)}
                  />
                </div>
              </div>
              {campaignContext.engajamentSelected === 1 &&
                isActiveLayoutSelector === '0' && (
                  <Input
                    id='add-participation-button'
                    className={styles.inputs({ input: 'default' })}
                    idError='add-participation-button'
                    inputType='text'
                    labelName='Botão de participar'
                    placeholder='Participar'
                    styles={styles}
                    value={'Participar'}
                    disabled
                  />
                )}
              {campaignContext.engajamentSelected === 2 &&
                isActiveLayoutSelector === '0' && (
                  <Input
                    id='add-participation-button'
                    className={styles.inputs({ input: 'default' })}
                    idError='add-participation-button'
                    inputType='text'
                    labelName='Botão de partici;par'
                    placeholder='Participar'
                    styles={styles}
                    value={'Participar'}
                    disabled
                  />
                )}
              {campaignContext.engajamentSelected === 1 &&
                isActiveLayoutSelector === '2' && (
                  <Row>
                    <Col xs={2}>
                      <Input
                        id='motivational1-one-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='motivational1-one-button'
                        inputType='text'
                        labelName='Botão de Interação'
                        placeholder='Estou fazendo acontecer!'
                        styles={styles}
                        value={'Estou fazendo acontecer!'}
                        disabled
                      />
                    </Col>
                    <Col xs={2} style={{ marginTop: '1.8rem' }}>
                      <Input
                        id='motivational1-two-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='motivational1-two-button'
                        inputType='text'
                        labelName=''
                        placeholder='Estou com dúvidas'
                        styles={styles}
                        value={'Estou com dúvidas'}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
              {campaignContext.engajamentSelected === 2 &&
                isActiveLayoutSelector === '2' && (
                  <Row>
                    <Col xs={2}>
                      <Input
                        id='motivational1-one-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='motivational1-one-button'
                        inputType='text'
                        labelName='Botão de Interação'
                        placeholder='Estou prepado'
                        styles={styles}
                        value={'Estou prepado'}
                        disabled
                      />
                    </Col>
                    <Col xs={2} style={{ marginTop: '1.8rem' }}>
                      <Input
                        id='motivational1-two-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='motivational1-two-button'
                        inputType='text'
                        labelName=''
                        placeholder='Estou com dúvidas'
                        styles={styles}
                        value={'Estou com dúvidas'}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
              {campaignContext.engajamentSelected === 1 &&
                isActiveLayoutSelector === '10' && (
                  <Row>
                    <Col xs={2}>
                      <Input
                        id='engajament-one-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='engajament-one-button'
                        inputType='text'
                        labelName='Botão de Interação'
                        placeholder='Estou batendo a meta'
                        styles={styles}
                        value={'Estou batendo a meta'}
                        disabled
                      />
                    </Col>
                    <Col xs={2} style={{ marginTop: '1.8rem' }}>
                      <Input
                        id='engajament-two-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='engajament-two-button'
                        inputType='text'
                        labelName=''
                        placeholder='Estou com dúvidas'
                        styles={styles}
                        value={'Estou com dúvidas'}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
              {campaignContext.engajamentSelected === 2 &&
                isActiveLayoutSelector === '10' && (
                  <Row>
                    <Col xs={2}>
                      <Input
                        id='engajament-one-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='engajament-one-button'
                        inputType='text'
                        labelName='Botão de Interação'
                        placeholder='Sim'
                        styles={styles}
                        value={'Sim'}
                        disabled
                      />
                    </Col>
                    <Col xs={2} style={{ marginTop: '1.8rem' }}>
                      <Input
                        id='engajament-two-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='engajament-two-button'
                        inputType='text'
                        labelName=''
                        placeholder='Mais ou menos'
                        styles={styles}
                        value={'Mais ou menos'}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
              {campaignContext.engajamentSelected === 1 &&
                isActiveLayoutSelector === '3' && (
                  <Row>
                    <Col xs={2}>
                      <Input
                        id='rememberPeriod-one-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='rememberPeriod-one-button'
                        inputType='text'
                        labelName='Botão de Interação'
                        placeholder='Estou sim'
                        styles={styles}
                        value={'Estou sim'}
                        disabled
                      />
                    </Col>
                    <Col xs={2} style={{ marginTop: '1.8rem' }}>
                      <Input
                        id='rememberPeriod-two-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='rememberPeriod-two-button'
                        inputType='text'
                        labelName=''
                        placeholder='Mais ou menos'
                        styles={styles}
                        value={'Mais ou menos'}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
              {campaignContext.engajamentSelected === 1 &&
                isActiveLayoutSelector === '5' && (
                  <Row>
                    <Col xs={2}>
                      <Input
                        id='motivational2-one-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='motivational2-one-button'
                        inputType='text'
                        labelName='Botão de Interação'
                        placeholder='A todo vapor!!!'
                        styles={styles}
                        value={'A todo vapor!!!'}
                        disabled
                      />
                    </Col>
                    <Col xs={2} style={{ marginTop: '1.8rem' }}>
                      <Input
                        id='motivational2-two-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='motivational2-two-button'
                        inputType='text'
                        labelName=''
                        placeholder='Mais ou menos'
                        styles={styles}
                        value={'Mais ou menos'}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
              {campaignContext.engajamentSelected === 2 &&
                isActiveLayoutSelector === '9' && (
                  <Row>
                    <Col xs={2}>
                      <Input
                        id='motivational2-one-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='motivational2-one-button'
                        inputType='text'
                        labelName='Botão de Interação'
                        placeholder='Estou indo bem'
                        styles={styles}
                        value={'Estou indo bem'}
                        disabled
                      />
                    </Col>
                    <Col xs={2} style={{ marginTop: '1.8rem' }}>
                      <Input
                        id='motivational2-two-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='motivational2-two-button'
                        inputType='text'
                        labelName=''
                        placeholder='Estou com dúvidas'
                        styles={styles}
                        value={'Estou com dúvidas'}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
              {campaignContext.engajamentSelected === 2 &&
                isActiveLayoutSelector === '5' && (
                  <Row>
                    <Col xs={2}>
                      <Input
                        id='engajament2-one-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='engajament2-one-button'
                        inputType='text'
                        labelName='Botão de Interação'
                        placeholder='Estou sim'
                        styles={styles}
                        value={'Estou sim'}
                        disabled
                      />
                    </Col>
                    <Col xs={2} style={{ marginTop: '1.8rem' }}>
                      <Input
                        id='engajament2-two-button'
                        className={styles.inputs({ input: 'default' })}
                        idError='engajament2-two-button'
                        inputType='text'
                        labelName=''
                        placeholder='Mais ou menos'
                        styles={styles}
                        value={'Mais ou menos'}
                        disabled
                      />
                    </Col>
                  </Row>
                )}
            </Col>
          )}

          {campaignContext?.communicationType === 'app' && (
            <>
              <Col className={`selector-area mt-4`}>
                <Col
                  className={`
                      ${styles.inputs({ datePicker: 'datePickerDefault' })}
                      d-flex align-items-end
                    `}
                  style={{ padding: '0 0' }}
                >
                  <Input
                    id='txtDateRangeCampaign'
                    idError='fdbDateRangeCampaign'
                    labelName='Data de envio'
                    placeholder='00/00/0000'
                    value={dateSend}
                    styles={styles}
                    isInvalid={false}
                    disabled={true}
                  />
                </Col>
                <Col className={`mb-3`}>
                  <Select
                    id='fdbCampaign'
                    idError='fdbCampaignError'
                    placeholder='Não se repete'
                    options={recurrenceOptions}
                    isInvalid={false}
                    errorText='Selecione um item neste campo'
                    styles={styles}
                    disabled={true}
                    labelName='Criar recorrência'
                    className='mt-1'
                    onChange={(e) => console.log(e)}
                  />
                </Col>
                <Input
                  id='titleComunication'
                  inputName='titleComunication'
                  className={styles.inputs({ input: 'default' })}
                  idError='titleComunicationError'
                  inputType='text'
                  labelName='Título da notificação'
                  placeholder='Insira um titulo para a sua notificação'
                  styles={styles}
                  value={titleApp}
                  disabled={state.layoutTitleChoosen === 'Notificação padrão'}
                  onChange={(e) => handleFieldComunicationWpp(e, 'title')}
                  maxLength={100}
                />
                <div className='editor-container'>
                  {/* <label htmlFor='descriptionEditor' className='editor-label'>
                      Descrição
                    </label> */}
                  {/* <Editor
                      editorState={
                        descriptionEditorStates[isActiveLayoutSelector] ||
                        initialStateDescription ||
                        EditorState.createEmpty()
                      }
                      onEditorStateChange={handleDescriptionEditorChange}
                      wrapperClassName='rt-wrapper'
                      editorClassName={`rt-editor ${state.layoutTitleChoosen === 'Notificação padrão' &&
                        'editor-disabled'
                        }`}
                      toolbarClassName='rt-toolbar'
                      labelName='Descrição'
                      ariaLabel='Descrição'
                      placeholder='Aqui você pode escrever uma mensagem personalizada sobre  sua notificação'
                      toolbar={{
                        options: ['inline'],
                        inline: {
                          options: ['bold', 'italic'],
                        },
                      }}
                      value={
                        descriptionApp
                      }
                      toolbarCustomButtons={[
                        <EmojiButton onClick={activateDescriptionEditor} />,
                      ]}
                      onChange={(e) => handleFieldComunicationWpp(e, 'description')}
                    />
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          right: 0,
                          bottom: 50,
                          zIndex: 9999,
                        }}
                      >
                        {showEmojiPicker && (
                          <Picker
                            previewPosition={'none'}
                            navPosition={'bottom'}
                            native={true}
                            onEmojiSelect={handleSelectEmoji}
                          />
                        )}
                      </div>
                    </div> */}
                  <Input
                    id='descriptionComunication'
                    inputName='descriptionComunication'
                    className={styles.inputs({ input: 'default' })}
                    idError='descriptionComunicationError'
                    inputType='text'
                    isTextArea
                    labelName='Descrição'
                    placeholder='Aqui você pode escrever uma mensagem personalizada sobre  sua notificação'
                    styles={styles}
                    value={descriptionApp}
                    disabled={state.layoutTitleChoosen === 'Notificação padrão'}
                    onChange={(e) => handleFieldComunicationWpp(e, 'title')}
                    maxLength={100}
                  />
                </div>
              </Col>
            </>
          )}

          <Col
            className={`
              ${
                state.layoutTitleChoosen === 'WhatsApp personalizado' ||
                state.layoutTitleChoosen === 'WhatsApp padrão'
                  ? 'd-block'
                  : ''
              }
              layout-editor-area
            `}
          >
            <LayoutExampleBoasVindas
              styles={styles}
              titleComunication={
                campaignContext.communicationType !== 'app'
                  ? wppMessageAttributes[isActiveLayoutSelector]?.title
                  : titleApp
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                campaignContext.communicationType !== 'app'
                  ? wppMessageAttributes[isActiveLayoutSelector]?.description
                  : descriptionApp
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyBoasVindas={handleTextOnlyBoasVindas}
              handleHtmlBoasVindas={handleHtmlBoasVindas}
              render={state.isLayoutSelectorActive[0]} // old logic state.isLayoutSelectorActive[0] && totalDispatchWeek >= 1
            />
            <LayoutExampleDicaMeta
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyDicaMeta={handleTextOnlyDicaMeta}
              handleHtmlDicaMeta={handleHtmlDicaMeta}
              render={state.isLayoutSelectorActive[1]}
            />
            <LayoutExampleMotivacional1
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
              render={state.isLayoutSelectorActive[2] && totalDispatchWeek > 3}
            />
            <LayoutExampleComoConhecerProduto
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
              render={state.isLayoutSelectorActive[4] && totalDispatchWeek >= 3}
            />

            <LayoutExamplePerguntaEngajamento1
              render={state.isLayoutSelectorActive[11] && totalDispatchWeek > 2}
              styles={styles}
              titleComunication={
                wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.title
              }
              introductionComunication={
                wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.description
              }
              attachmentFilePath={
                wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
            />
            <LayoutExampleRelembreRegra
              render={state.isLayoutSelectorActive[8] && totalDispatchWeek >= 7}
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
            />
            <LayoutExampleLembretePeriodo3
              render={state.isLayoutSelectorActive[9] && totalDispatchWeek >= 8}
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
            />
            <LayoutExampleMotivacional2
              render={
                state.isLayoutSelectorActive[10] && totalDispatchWeek >= 6
              }
              styles={styles}
              titleComunication={
                wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.title
              }
              introductionComunication={
                wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.description
              }
              attachmentFilePath={
                wppMessageAttributes[getComponentIndex(isActiveLayoutSelector)]
                  ?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
            />
            <LayoutExampleLembretePeriodo1
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
              render={state.isLayoutSelectorActive[3] && totalDispatchWeek >= 2}
            />
            <LayoutExampleMotivacional3
              render={state.isLayoutSelectorActive[7] && totalDispatchWeek >= 6}
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
            />
            <LayoutExamplePerguntaEngajamento2
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
              render={state.isLayoutSelectorActive[5] && totalDispatchWeek >= 4}
            />
            <LayoutExampleLembretePeriodo2
              render={state.isLayoutSelectorActive[6] && totalDispatchWeek >= 5}
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector]?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
            />
            {campaignContext?.communicationType === 'app' && (
              <LayoutExampleObrigado
                render={state.isLayoutSelectorActive[13]}
                styles={styles}
                titleComunication={
                  wppMessageAttributes[getComponentIndex(13)]?.title
                }
                introductionComunication={
                  wppMessageAttributes[getComponentIndex(13)]?.introduction
                }
                descriptionComunication={
                  wppMessageAttributes[getComponentIndex(13)]?.description
                }
                attachmentFilePath={
                  wppMessageAttributes[getComponentIndex(13)]
                    ?.attachmentFilePath
                }
                state={state}
                layoutInfo={ownState}
                layoutTitleChoosen={state.layoutTitleChoosen}
                handleTextOnlyObrigado={handleTextOnlyObrigado}
                handleHtmlObrigado={handleHtmlObrigado}
              />
            )}
            {campaignContext?.communicationType === 'email' && (
              <>
                <LayoutExampleUltimaSemana
                  render={state.isLayoutSelectorActive[12]}
                  styles={styles}
                  titleComunication={
                    wppMessageAttributes[isActiveLayoutSelector]?.title
                  }
                  introductionComunication={
                    wppMessageAttributes[isActiveLayoutSelector]?.introduction
                  }
                  descriptionComunication={
                    wppMessageAttributes[isActiveLayoutSelector]?.description
                  }
                  attachmentFilePath={
                    wppMessageAttributes[isActiveLayoutSelector]
                      ?.attachmentFilePath
                  }
                  state={state}
                  layoutInfo={ownState}
                  layoutTitleChoosen={state.layoutTitleChoosen}
                  handleTextOnlyObrigado={handleTextOnlyObrigado}
                  handleHtmlObrigado={handleHtmlObrigado}
                />
              </>
            )}
            <LayoutExampleObrigado
              render={state.isLayoutSelectorActive[13]}
              styles={styles}
              titleComunication={
                wppMessageAttributes[isActiveLayoutSelector - 2]?.title
              }
              introductionComunication={
                wppMessageAttributes[isActiveLayoutSelector - 2]?.introduction
              }
              descriptionComunication={
                wppMessageAttributes[isActiveLayoutSelector - 2]?.description
              }
              attachmentFilePath={
                wppMessageAttributes[isActiveLayoutSelector - 2]
                  ?.attachmentFilePath
              }
              state={state}
              layoutInfo={ownState}
              layoutTitleChoosen={state.layoutTitleChoosen}
              handleTextOnlyObrigado={handleTextOnlyObrigado}
              handleHtmlObrigado={handleHtmlObrigado}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

ModalLayoutContent.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  layoutSelection: PropTypes.array,
  handleLayoutSelector: PropTypes.func,
  handleSaveLayout: PropTypes.func,
}

export default ModalLayoutContent

import React, { useState } from "react";
import { styles } from "cashin-design-system/Styles/Styles";
import { ButtonComponent } from "cashin-components";
import { useNavigate } from "react-router";
import moment from "moment";
import 'moment/locale/pt-br';

const SchedulerComponent = ({ dashboardData }) => {
  moment.locale('pt-br');
  const navigate = useNavigate();
  const startOfMonth = moment().startOf("month").startOf("day");
  const [currentMonth, setCurrentMonth] = useState(startOfMonth);
  let colorIndex = 0;

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.clone().add(1, "month"));
  };

  const renderDateHeaders = () => {
    const headers = [];
    const currentDate = currentMonth.clone().startOf("month");

    while (currentDate.isSameOrBefore(currentMonth.clone().endOf("month"))) {
      const dayOfWeek = currentDate.format("ddd");
      const dayOfMonth = currentDate.format("D");

      headers.push(
        <div
          key={currentDate.format("YYYY-MM-DD")}
          id={currentDate.format("YYYY-MM-DD")}
          className="date-header"
        >
          <div className={styles.tipography({ paragraphs: "mediumBold" })}>
            {dayOfMonth}
          </div>
          <div
            className={`
              ${styles.tipography({ paragraphs: "smallRegular" })}
              ${styles.colors({ colors: "neutralColorLowMedium" })}
            `}
          >
            {dayOfWeek}
          </div>
        </div>
      );

      currentDate.add(1, "day");
    }

    return headers;
  };

  const handleEdit = (id) => {
    navigate(`/edit-campaign/${id}`);
  };

  const renderEventBars = () => {
    if (!dashboardData) return null;
    let position = 0;
    const themeColors = [
      '#A500B9', 
      '#D778CD', 
      '#FF7503',
      '#1B7D2C',
      '#76E388',
      '#666666',
      '#5900E1'
    ];
  
    const eventBars = [];
  
    dashboardData.campaigns.forEach((campaign) => {
      const from = moment(campaign.periodDate.from);
      const to = moment(campaign.periodDate.to);
      const currentDate = moment();

      const totalDuration = moment.duration(to.diff(from));
      const elapsedDuration = moment.duration(currentDate.diff(from));

      let percentage = (elapsedDuration.asMilliseconds() / totalDuration.asMilliseconds()) * 100;
      percentage = Math.max(percentage, 0); 
      percentage = Math.min(percentage, 100); 

      const formattedPercentage = Math.ceil(percentage);
  
      const isWithinMonth =
        from.isSameOrBefore(currentMonth.clone().endOf("month")) &&
        to.isSameOrAfter(currentMonth.clone().startOf("month"));
  
      if (isWithinMonth) {
        const startDate = from.isBefore(currentMonth) ? currentMonth : from;
        const endDate = to.isAfter(currentMonth.clone().endOf("month"))
          ? currentMonth.clone().endOf("month")
          : to;
  
        const startDiff = from.isBefore(currentMonth) ? 1 : startDate.date();
        const endDiff = endDate.date() + 1;

        const totalDiff = endDiff - startDiff;
          
        const eventBarStyle = {
          gridColumn: `${startDiff} / ${endDiff}`,
          gridRow: "2 / -1",
          background: themeColors[colorIndex],
          marginTop: `${position * 62}px`,
        };
  
        position++;
  
        if (isWithinMonth) {
          colorIndex = (colorIndex + 1) % themeColors.length; 
        }
   
        eventBars.push(
          <div key={campaign.id} className="event-bar" style={eventBarStyle} onClick={() => handleEdit(campaign.id)}>
            {totalDiff > 5 && (
              <div className='campaign-percent-container'>
                <span className={`${styles.tipography({ paragraphs: "mediumBold" })} campaign-percent`}>
                  {formattedPercentage}%
                  
                </span>
              </div>
            )}            
            <span className={`${styles.tipography({ paragraphs: "mediumBold" })} campaign-name`}>
              {campaign.name}
            </span>
          </div>
        );
      }
    });
  
    return eventBars;
  };
  
  
  return (
    <div className={styles.schedulerDashboard()}>
      <div className={"calendar-container"}>
        <div className={"calendar-header"}> 
          <ButtonComponent
            className={`
              ${styles.buttonStandardLarge({
                large: "secondary",
              })}
            `}
            hasIcon={true}
            iconName="arrow_back"           
            styles={styles}
            onClick={() => handlePrevMonth()}
          />
          <span className={`${styles.tipography({ paragraphs: "mediumBold" })}`} >{currentMonth.format("MMMM [de] YYYY")}</span>
          <ButtonComponent
            className={`
              ${styles.buttonStandardLarge({
                large: "secondary",
              })}
            `}
            hasIcon={true}
            iconName="arrow_forward"           
            styles={styles}
            onClick={() => handleNextMonth()}
          />
        </div>
        <div className="date-headers">{renderDateHeaders()}</div>
        <div className="event-bars">{renderEventBars()}</div>
      </div>      
    </div>
  );
};

export default SchedulerComponent;

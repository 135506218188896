import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { ButtonComponent } from 'cashin-components'
import { styles } from 'cashin-design-system/Styles/Styles'
import CustomPagination from '../../Components/CustomPagination'
import { debounce } from 'lodash'
import Icon from '../../Components/Icon'
import {
  getGeneralReport,
  getGeneralReportExport,
} from '../../APIV2/reports/get-general-report'
import { headers } from './CampaignTable'
import { transformingGeneralReportDataForXlxs } from './export-general-report'
import { CampaignAccordion } from './CampaignAccordion'
import moment from 'moment'
import { exportToXlsx } from '../../functions/export-report-to-xlsx'
import { Loading } from '../../Components'

export const GeneralReport = ({ tenantId }) => {
  const [currentPagination, setCurrentPagination] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const [campaignNameFilter, setCampaignNameFilter] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [debouncedCampaignNameFilter, setDebouncedCampaignNameFilter] =
    useState('')

  const debounceCampaignNameFilter = useCallback(
    debounce((name) => {
      setDebouncedCampaignNameFilter(name)
    }, 500),
    []
  )

  const handleStringFilterChange = (e) => {
    setCampaignNameFilter(e.target.value)
    debounceCampaignNameFilter(e.target.value)
  }

  const handleOnPaginationChange = (newPage) => {
    setCurrentPagination(newPage)
  }

  const exportReport = async () => {
    const res = await getGeneralReportExport(
      tenantId,
      debouncedCampaignNameFilter,
      currentPagination
    )
    const labels = headers.map((header) => header.title)
    const propertyNames = headers.map((header) => header.key)
    const xlsxData = transformingGeneralReportDataForXlxs(
      labels,
      propertyNames,
      res.data.data
    )
    const formattedDate = moment(new Date()).format('DD-MM-YYYY')
    exportToXlsx(xlsxData, `relatório_geral_${formattedDate}`)
  }

  const search = useCallback(async () => {
    setIsLoading(true)
    const result = await getGeneralReport(
      tenantId,
      debouncedCampaignNameFilter,
      currentPagination
    )

    setData(result.data.data)
    setTotalPages(result.data.totalPages)
    setIsLoading(false)
  }, [tenantId, debouncedCampaignNameFilter, currentPagination])

  useEffect(() => {
    search()
  }, [search, currentPagination, debouncedCampaignNameFilter])

  return (
    <div>
      <Row classname='align-items-center mb-4'>
        <Col
          xs={{ span: 3, offset: 9 }}
          className='d-flex justify-content-end align-items-center'
        >
          <InputGroup className='my-1 me-2'>
            <Form.Control
              className='bg-white rounded-md border-end-0 border'
              placeholder='Busca por participante, telefone ou email'
              value={campaignNameFilter}
              onChange={(e) => handleStringFilterChange(e)}
            />
            <InputGroup.Text className='bg-white border-start-0 rounded-md border'>
              <Icon iconName={'search'} />
            </InputGroup.Text>
          </InputGroup>
          <ButtonComponent
            className={`${styles.buttonStandardSmall({
              small: 'primaryLoading',
            })}`}
            text='Exportar'
            isLoading={false}
            styles={styles}
            onClick={exportReport}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className='my-4'>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <CampaignAccordion data={data} />
              <CustomPagination
                totalPages={totalPages}
                onPaginationChange={handleOnPaginationChange}
                currentPage={currentPagination}
                setCurrentPage={setCurrentPagination}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

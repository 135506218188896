import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ButtonComponent } from 'cashin-components';

const UnregistredUserModal = ({ styles, size, show, onHide }) => {
  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={`
        ${styles.modal()}
      `}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body>
        <h1
          className={`
            ${styles.tipography({ headings: 'mediumBold' })}
            mb-4 text-center
          `}
        >
          Ops! Esse e-mail não está cadastrado.
        </h1>
        <p className="text-center m-0">
          Entre em contato através do nosso suporte através do email{' '}
          <a href="mailto:suportecampanha@cashin.com.br">
            suportecampanha@cashin.com.br
          </a>{' '}
          e logo retornamos
          <br />
          assim que possível. ☺️
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          className={`
            ${styles.buttonStandardLarge({
              large: 'primary',
            })}
            w-100
          `}
          text="Fechar"
          styles={styles}
          onClick={onHide}
        />
      </Modal.Footer>
    </Modal>
  );
};

UnregistredUserModal.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default UnregistredUserModal;

import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import {
  BadgeComponent, Select, Check, ButtonComponent, Input,
} from 'cashin-components'
import { toast } from 'react-toastify'
import Accordion from 'react-bootstrap/Accordion'
import { CampaignContext } from '../../../../context/campaign-context'
import ImageLogin from '../../../../design-system/Styles/img/WhatsApp/Icons/clip.png'

const style = {
  fileInputTitle: {
    fontWeight: 'bold',
  },
  fileDropArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    padding: '1rem',
    border: '2px dashed #ccc',
    cursor: 'pointer',
    margin: '20px 0',
    position: 'relative',
  },
  fileDropAreaLabel: {
    marginBottom: '10px',
    display: 'contents',
  },
  fileDropAreaFileIcon: {
    fontSize: '30px',
    marginBottom: '10px',
  },
  fileDropAreaP: {
    fontSize: '12px',
    color: '#888',
  },
  labelFile: {
    textDecoration: 'underline',
    color: '#6300FF',
  },
}

const TrainingVideo = ({ styles, loading, setLoading, setShowTrainingVideo, setChoicesSavedConentVideo }) => {

  const campaignContext = useContext(CampaignContext)

  const [thereIsPrize, setThereIsPrize] = useState('no');
  const [cashins, setCashins] = useState('R$ 0,00');
  const [nameEbook, setNameVideo] = useState('');
  const [sizeEbook, setSizeVideo] = useState('');
  const [titleVideo, setTitleVideo] = useState('');
  const [urlVideo, setUrlVideo] = useState('');
  const [isSavedContent, setIsSavedContent] = useState(false);

  const handleDeleteVideoModule = () => {
    setShowTrainingVideo(false);
    setTitleVideo('');
    setUrlVideo('');
    setThereIsPrize('no');
    setChoicesSavedConentVideo('none')
  };

  const formatMoney = (value) => {
    const onlyNumbers = value.replace(/[^\d]/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(onlyNumbers / 100);
    return formattedValue;
  };

  const formatNumber = (value) => {
    const newValue = parseFloat(value.replace('R$', '').replace('.', '').replace(',', '.').trim())
    return newValue
  }

  const handleCashinsChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatMoney(value);
    setCashins(formattedValue);
  };

  const validateFields = () => {
    if (thereIsPrize === 'yes' && (cashins === 'R$ 0,00' || cashins === '')) {
      toast.error('O campo quantidade de cashins é obrigatório')
      return false
    }

    if (!titleVideo) {
      toast.error('O campo título do vídeo é obrigatório')
      return false
    }

    if (!urlVideo) {
      toast.error('O vídeo é obrigatório')
      return false
    }

    campaignContext.setTrainingVideoObject({
      name: titleVideo,
      url: urlVideo,
      type: 'video',
      points: cashins === 'R$ 0,00' ? 0 : formatNumber(cashins),
    })

    setChoicesSavedConentVideo('published')
    return true
  }

  const handleMainVideo = async (event) => {
    const target = event.target || window.event.srcElement,
      files = target.files

    // Verificar se o arquivo tem mais de 100MB
    if (files[0].size > 100 * 10 ** 6) {
      toast.error('O arquivo deve ter no máximo 100MB')
      return
    }

    setLoading(true)

    const fileName = files[0].name
    setNameVideo(fileName)

    const fileSize = files[0].size
    setSizeVideo((fileSize * 10 ** -6).toFixed(2) + 'MB')

    const imageUrl = await campaignContext.uploadImage(files[0])

    if (!imageUrl) {
      toast.error('Erro ao enviar o arquivo')
      setLoading(false)
      return
    }

    setLoading(false)

    setUrlVideo(imageUrl)
  }

  const handleLoadMainVideo = () => {
    let mainBanner = document.getElementById('img-main-video')

    mainBanner.click()
  }

  return (
    <Accordion className={styles.customAccordion()} defaultActiveKey='0' style={{
      marginBottom: '2rem',
    }}>
      <Accordion.Item
        eventKey={0}
      >
        <Accordion.Header>
          <div className='headerInfos'>
            <div style={{
              alignContent: "center",
            }}>
              <span
                className={styles.tipography({
                  paragraphs: 'mediumBold',
                })}
              >
                Treinamento em video
              </span>
            </div>
            <div className='endInfos' style={{
              gap: 0
            }}>
              <div className='texts'>
                <ButtonComponent
                  className={`
                  ${styles.buttonStandardMedium({
                    medium: 'secondary',
                  })}
                    mb-3 me-3
                  `}
                  hasIcon={true}
                  iconName='delete'
                  text='Excluir módulo'
                  styles={styles}
                  onClick={handleDeleteVideoModule}
                />
              </div>

              <div className='texts'>
                <ButtonComponent
                  className={`
                              ${styles.buttonStandardMedium({
                    medium: 'primary',
                  })}
                              mb-3
                            `}
                  hasIcon={true}
                  iconName='save'
                  text='Salvar'
                  isSuccessButton={isSavedContent}
                  styles={styles}
                  onClick={() => {
                    if (!validateFields()) {
                      return
                    }
                    setIsSavedContent(true)
                    toast.success('Vídeo salvo com sucesso')
                  }}
                />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{
          padding: '20px',
        }}>
          <div>
            <div className='texts'>
              <span
                className={styles.tipography({
                  paragraphs: 'mediumBold',
                })}
              >
                Terá premiação de Cashins por visualização?
              </span>
            </div>
            <div className=' my-3 d-flex'>
              <Check
                id='yes'
                name='thereIsPrizeVideo'
                label='Sim'
                type='radio'
                value='yes'
                checked={thereIsPrize === 'yes'}
                disabled={isSavedContent}
                onChange={(e) => {
                  setThereIsPrize(e.target.id)
                }}
                className={`${styles.check({ radio: 'normal' })} me-3`}
              />

              <Check
                id='no'
                name='thereIsPrizeVideo'
                label='Não'
                type='radio'
                value='no'
                disabled={isSavedContent}
                checked={thereIsPrize === 'no'}
                onChange={(e) => {
                  setThereIsPrize(e.target.id)
                }}
                className={`${styles.check({ radio: 'normal' })} me-3`}
              />
            </div>
            {thereIsPrize === 'yes' && (
              <div className='my-3'>
                <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                  Quantos Cashins você quer premiar neste quiz?
                </span>
                <Input
                  id='txtPrize'
                  label='Quantidade de Cashins'
                  placeholder='Insira a quantidade de cashins'
                  styles={styles}
                  className='mt-3'
                  disabled={isSavedContent}
                  value={cashins}
                  onChange={handleCashinsChange}
                  style={{ marginLeft: 0 }}
                />
              </div>
            )}
            <Col xs={12}>
              <hr className='mt-3 mb-4' />
            </Col>
            <div className='my-3'>
              <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                Título do Vídeo
              </span>
              <Input
                id='txtVideoTitle'
                label='Título do vídeo'
                placeholder='Insira o título do vídeo'
                styles={styles}
                className='mt-3'
                disabled={isSavedContent}
                style={{ marginLeft: 0 }}
                value={titleVideo}
                onChange={(e) => setTitleVideo(e.target.value)}
                maxLength={30}
              />
            </div>

            {!loading && !urlVideo ? (
              <div className='file-input'>
                <p style={style.fileInputTitle}>
                  Adicione aqui a imagem da sua comunicação.
                </p>
                <div style={style.fileDropArea}>
                  <div
                    className='file-icon'
                    onClick={() => handleLoadMainVideo()}
                  >
                    <img src={ImageLogin} alt='Upload' />
                  </div>
                  <label htmlFor='img-main-video'>
                    <span style={style.labelFile}>Selecionar o arquivo</span>
                  </label>
                  <p>
                    Formato MP4, MOV com até 100MB
                  </p>

                  <input
                    id='img-main-video'
                    className='d-none'
                    type='file'
                    accept='video/*'
                    onChange={(e) => handleMainVideo(e)}
                  />
                </div>
              </div>
            ) : (
              <div className='mt-3'>
                <div className='texts'>
                  <span
                    className={styles.tipography({
                      paragraphs: 'mediumBold',
                    })}
                  >
                    Vídeo enviado:
                  </span>
                </div>
                <div className='my-3 d-flex'>
                  {loading ? (
                    <div className='d-flex justify-content-center' style={{
                      marginLeft: "3rem"
                    }}>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <video
                      width='320'
                      height='240'
                      controls
                      style={{ borderRadius: "1rem" }}
                    >
                      <source src={urlVideo} type='video/mp4' />
                      Seu navegador não suporta o elemento video.
                    </video>
                  )}

                  <div style={{ alignContent: "center", marginLeft: "auto", marginRight: "auto" }}>
                    <div className='texts' style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: "2rem",
                    }}>
                      <div className='texts' style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                        <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                          Nome: {nameEbook}
                        </span>
                        <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                          Tamanho do arquivo: {sizeEbook}
                        </span>
                        <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                          Data de envio: {new Date().toLocaleDateString()}
                        </span>
                      </div>

                      <ButtonComponent
                        className={`
                        ${styles.buttonStandardLarge({
                          large: 'secondary',
                        })}
                          mb-3 me-3
                        `}
                        hasIcon={true}
                        disabled={isSavedContent}
                        iconName='delete'
                        text='Excluir vídeo'
                        styles={styles}
                        onClick={() => setUrlVideo('')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

TrainingVideo.propTypes = {
  styles: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setShowTrainingVideo: PropTypes.func,
}

export default TrainingVideo
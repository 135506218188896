import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { ButtonComponent, Input, Select } from "cashin-components";
import reproveValidationResultStatusCustomerRequest from "../../API/queries/campaign-validate/reproveValidationResultStatusCustomerRequest";

const ModalReproveCampaign = ({
  styles,
  size,
  show,
  onHide,
  validationId,
  currentValidationId,
}) => {
  const [reprovalIsLoading, setReprovalIsLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [rejectDescripiton, setRejectDescription] = useState("");

  const handleReproveValidation = async () => {
    setReprovalIsLoading(true);

    await reproveValidationResultStatusCustomerRequest(
      validationId,
      currentValidationId,
      4,
      rejectReason,
      rejectDescripiton
    ).catch((x) => setReprovalIsLoading(false));

    onHide();
  };

  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
      keyboard={false}
      show={show}
      onHide={onHide}
      className={`
        ${styles.modal()}
      `}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" />
      </Modal.Header>
      <Modal.Body className="pb-0">
        <h1
          className={`
            ${styles.tipography({ headings: "xsmallBold" })}
            mb-4 text-center
          `}
          style={{
            lineHeight: "var(--lineHeights-lineHeightDefault)",
          }}
        >
          Deseja reprovar essa
          <br />
          apuração?
        </h1>
        <p className="text-center m-0">
          Informe o motivo da sua reprovação que
          <br />
          cuidaremos de tudo para você.
        </p>
        <div className="my-3">
          <Input
            id="ddlReproveFilter"
            inputName="reproveValue"
            idError="fdbReproveError"
            labelName="Título da reprovação"
            placeholder="Digite aqui..."
            styles={styles}
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </div>
        <div>
          <Input
            labelName="Motivo da reprovação"
            inputName="reproveReason"
            inputType="text"
            placeholder="Digite aqui..."
            styles={styles}
            isTextArea={true}
            value={rejectDescripiton}
            onChange={(e) => setRejectDescription(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-0">
        <ButtonComponent
          className={`
            ${styles.buttonStandardLarge({
              large: "primary",
            })}
            w-100
          `}
          text="Enviar"
          styles={styles}
          onClick={handleReproveValidation}
          isLoading={reprovalIsLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

ModalReproveCampaign.propTypes = {
  styles: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default ModalReproveCampaign;

import { css } from './CreateStitches';

export const otpInput = css({
  variants: {
    styleType: {
      default: {
        width: '48px !important',
        height: 48,
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorHighDark',
        borderRadius: '$borderRadiusSM',
        marginRight: 8,
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeHSM',

        '&:hover': {
          borderColor: '$neutralColorLowPure',
        },

        '&:focus': {
          borderWidth: '$borderWidthThin',
          borderColor: '$neutralColorLowPure',
        },

        '&:focus-visible': {
          outline: 'none',
        },

        '&.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighDark',
        },

        '&.error': {
          borderWidth: '$borderWidthThin',
          borderColor: '$feedbackColorWarningPure',
        },
      },
      hover: {
        width: '48px !important',
        height: 48,
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorLowPure',
        borderRadius: '$borderRadiusSM',
        marginRight: 8,
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeHSM',

        '&:hover': {
          borderColor: '$neutralColorLowPure',
        },

        '&:focus': {
          borderWidth: '$borderWidthThin',
          borderColor: '$neutralColorLowPure',
        },

        '&:focus-visible': {
          outline: 'none',
        },

        '&.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighDark',
        },

        '&.error': {
          borderWidth: '$borderWidthThin',
          borderColor: '$feedbackColorWarningPure',
        },
      },
      focus: {
        width: '48px !important',
        height: 48,
        borderStyle: 'solid',
        borderWidth: '$borderWidthThin',
        borderColor: '$neutralColorLowPure',
        borderRadius: '$borderRadiusSM',
        marginRight: 8,
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeHSM',

        '&:hover': {
          borderColor: '$neutralColorLowPure',
        },

        '&:focus': {
          borderWidth: '$borderWidthThin',
          borderColor: '$neutralColorLowPure',
        },

        '&:focus-visible': {
          outline: 'none',
        },

        '&.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighDark',
        },

        '&.error': {
          borderWidth: '$borderWidthThin',
          borderColor: '$feedbackColorWarningPure',
        },
      },
    },
  },
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditCustomerPage from './edit-customer-page';

const defaultState = {
  valueStatusOptions: [
    {
      value: 1,
      label: 'Ativo',
    },
  ],
  breadcrumb: [
    {
      url: '/customers',
      itemName: 'Clientes',
    },
    {
      url: `${window.location.pathname}`,
      itemName: 'Editar cliente',
    },
    {
      isActive: true,
      itemName: 'Dados da empresa',
    },
  ],
};

const EditCustomer = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState);

  const handleSave = () => {
    history.push('/customers');
    history.go('/customers');
  };

  return (
    <EditCustomerPage
      styles={styles}
      state={ownState}
      history={history}
      // handleSave={handleSave}
    />
  );
};

EditCustomer.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default EditCustomer;

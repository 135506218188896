import { useQuery } from 'react-query'
import qs from 'qs'
import api from '../../api'

const useGetParticipants = (
  participantBaseId,
  searchName,
  pageNumber,
  segmentation
) => {
  const queryString = qs.stringify(
    {
      ParticipantBaseId: participantBaseId,
      SearchName: searchName,
      PageSize: 10,
      PageNumber: pageNumber,
      ...{ Segmentation: segmentation },
    },
    { skipNulls: true }
  )

  return useQuery(
    [
      'participants-list-from-campaing',
      pageNumber,
      searchName || undefined,
      participantBaseId,
      segmentation || undefined,
    ],
    async () => api.get(`/campaign/participants?${queryString}`),
    {
      cacheTime: 60000 * 2,
      enabled: !!participantBaseId,
      refetchOnWindowFocus: true,
    }
  )
}

export default useGetParticipants

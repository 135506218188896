import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { Input } from 'cashin-components'
import FolderUltimaSemana from './layout-generico/folder-ultima-semana'
import FolderWhatsAppUltimaSemana from './layout-whatsapp/folder-ultima-semana'
import FolderLastWeek from './layout-executivo/folder-lastweek'
import FolderLastWeekDivertido from './layout-divertido/folder-lastweek'
import FolderLastWeekModerno from './layout-moderno/folder-lastweek'
import FolderWhatsappPreview from './layout-whatsapp/folder-whatsapp-preview'

const LayoutExampleUltimaSemana = ({
  styles,
  state,
  titleComunication,
  setTitleComunication,
  introductionComunication,
  setIntroductionComunication,
  descriptionComunication,
  setDescriptionComunication,
  attachmentFilePath,
  layoutTitleChoosen,
  layoutInfo,
  handleTextOnlyObrigado,
  handleHtmlObrigado,
  render,
}) => {
  return (
    <Row>
      <Col>
        {(layoutTitleChoosen === 'WhatsApp padrão' || layoutTitleChoosen === 'WhatsApp personalizado') && render && (
          <FolderWhatsappPreview
            titleComunication={titleComunication}
            setTitleComunication={setTitleComunication}
            introductionComunication={introductionComunication}
            setIntroductionComunication={setIntroductionComunication}
            descriptionComunication={descriptionComunication}
            setDescriptionComunication={setDescriptionComunication}
            attachmentFilePath={attachmentFilePath}
            styles={styles}
            state={state}
            layoutInfo={layoutInfo}
          />
        )}
        {layoutTitleChoosen === 'Executivo' && (
          <FolderLastWeek
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}
        {layoutTitleChoosen === 'Moderno' && (
          <FolderLastWeekModerno
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}
        {layoutTitleChoosen === 'Divertido' && (
          <FolderLastWeekDivertido
            layoutInfo={layoutInfo}
            layoutTitleChoosen={layoutTitleChoosen}
            render={render}
          />
        )}
      </Col>
    </Row>
  )
}

LayoutExampleUltimaSemana.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  layoutTitleChoosen: PropTypes.string,
  layoutInfo: PropTypes.object,
  handleTextOnlyObrigado: PropTypes.func,
  handleHtmlObrigado: PropTypes.func,
}

export default LayoutExampleUltimaSemana

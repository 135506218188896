import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { useContext, useEffect } from 'react'
import { CampaignContext } from '../../../../context/campaign-context'
import { Auth } from 'aws-amplify'
import { useState } from 'react'
import useIdentificationInfo from '../../../../API/queries/company/useIdentificationInfo'
import { formatDate } from '../../../../functions/date'

const BannerLembretePeriodo1 = `${process.env.REACT_APP_BLOBSTORAGE_URL}/BannerLembretePeriodo1.png`

const FolderLembretePeriodo1 = ({ layoutInfo, layoutTitleChoosen, render }) => {
  const [currentUser, setCurrentUser] = useState({})
  const { data: indentificantionData } = useIdentificationInfo()
  const companyName = indentificantionData?.find(
    (x) => x.id === currentUser?.attributes?.['custom:tenantId']
  )?.description

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }
    runAsync()
  }, [])

  const html = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  <body topmargin="0" rightmargin="0" bottommargin="0" leftmargin="0" bgcolor="#e1e1e1">
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td align="center">
          <table border="0" cellpadding="0" cellspacing="0" width="600" bgcolor="#ffffff">
            <tr>
              <td align="center" valign="middle" height="88" bgcolor=${
                layoutTitleChoosen !== 'Divertido'
                  ? layoutInfo.baseColor
                  : '#ffffff'
              }>
                <img src=${
                  layoutInfo.logoHeader
                } alt="" style="max-width: 600px">
              </td>
            </tr>
            <tr>
              <td align="left" valign="middle" style="padding: 0 40px">
                <br>
                <br>
                <font face="Arial" style="font-size: 22px; font-weight: 700;" color=${
                  layoutInfo.titleColor
                }>
                  WOW! Mais uma semana de campanha<br>chegando!!!
                </font>
                <br>
                <br>
                <font face="Arial" style="font-size: 14px; font-weight: 400;" color=${
                  layoutInfo.textColor
                }>
                  Você está participando da <b>*Campanha ${
                    layoutInfo.campaignName
                  }*</b> da <i>_${companyName}_</i> no<br>período de ${formatDate(
    layoutInfo.startDate
  )} a ${formatDate(layoutInfo.endDate)}.
                </font>
                <br>
                <br>
              </td>
            </tr>
            <tr>
              <td align="center" valign="middle">
                <img src=${BannerLembretePeriodo1} alt="" width="328">
              </td>
            </tr>
            <tr>
              <td align="left" valign="middle" style="padding: 0 90px;">
                <br>  
                <font face="Arial" style="font-size: 20px; font-weight: 400; line-height: 34px;" color=${
                  layoutInfo.titleColor
                }>
                  Alcance a seguinte meta para ganhar:
                </font>
                <br>
                ${
                  layoutInfo.campaignType === 0
                    ? `<font face="Arial" style="font-size: 20px; font-weight: 400; line-height: 24px; position: relative; top: 8px;" color=${layoutInfo.titleColor}>
                      META: <i>${layoutInfo.campaignBateu}</i>
                      PRÊMIO: <i>${layoutInfo.campaignLevou}</i>
                      &#127942;
                    </font>`
                    : ''
                }
                ${
                  layoutInfo.campaignType === 1
                    ? `<font face="Arial" style="font-size: 15px; font-weight: 400;" color=${layoutInfo.titleColor}>
                        META: <i>${layoutInfo.campaignVolumeRuleText}</i> 
                        <br/>PRÊMIO: <i>${layoutInfo.campaignVolumePrize}</i>
                        &#127942;
                      </font>`
                    : ''
                } 
                ${
                  layoutInfo.campaignType === 2
                    ? `<font face="Arial" style="font-size: 20px; font-weight: 400; line-height: 24px; position: relative; top: 8px;" color=${
                        layoutInfo.titleColor
                      }>
                      META: ${layoutInfo.campaignHibridoBateuPercent} ${
                        layoutInfo.campaignHibridoBateuCondicao &&
                        layoutInfo.campaignHibridoBateuCondicao
                      } ${
                        layoutInfo.campaignHibridoBateuCondicao &&
                        layoutInfo.campaignHibridoBateuMoney
                      }
                      <br>
                      PRÊMIO: ${layoutInfo.campaignHibridoLevouPercent} ${
                        layoutInfo.campaignHibridoLevouCondicao &&
                        layoutInfo.campaignHibridoLevouCondicao
                      } ${
                        layoutInfo.campaignHibridoLevouCondicao &&
                        layoutInfo.campaignHibridoLevouMoney
                      }
                      &#127942;
                    </font>`
                    : ''
                }
                <br>
                <br>
              </td>
            </tr>
            <tr>
              <td align="left" valign="middle" style="padding: 0 90px">
                <br>
                <font face="Arial" style="font-size: 16px; font-weight: 400;" color=${
                  layoutInfo.titleColor
                }>
                  Já estamos quase na metade do caminho, mas muito ainda pode ser feito.
                </font>
                <br>
                <br>
              </td>
            </tr>
            ${
              layoutTitleChoosen !== 'Divertido' &&
              layoutTitleChoosen !== 'Executivo'
                ? `
              <tr>
                <td align="center" valign="middle" style="padding: 0 40px;">
                  <hr style="width: 520px; border: solid 1px #000000; margin: 0;">
                </td>
              </tr>
            `
                : ''
            }
            <tr>
              <td align="center" valign="middle" height="240" bgcolor=${
                layoutInfo.footerColor
              }>
                <table border="0" cellpadding="0" cellspacing="0" width="504">
                  <tr>
                    <td colspan="2" align="center" valign="middle">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="middle">
                      <font face="Arial" style="font-size: 15px; font-weight: 400;" color=${
                        layoutInfo.footerTextColor
                      }>
                        Se você é usuário Cashin e precisa de ajuda o atendimento<br>é feito somente por e-mail:
                      </font>
                      <br>
                      <a href="mailto:suporte.multicampanhas@cashin.com.br" style="color: ${
                        layoutInfo.footerTextColor
                      };">
                        <font face="Arial" style="font-size: 15px; font-weight: 400;" color=${
                          layoutInfo.footerTextColor
                        }>
                          suporte.multicampanhas@cashin.com.br
                        </font>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center" valign="middle" style="border-right: solid 1px ${
                      layoutInfo.footerTextColor
                    }">
                      <a href=${
                        layoutInfo.campaignWebSite
                      } target="_blank" style="color: ${
    layoutInfo.footerTextColor
  };">
                        <font face="Arial" style="font-size: 15px; font-weight: 400;" color=${
                          layoutInfo.footerTextColor
                        }>
                          Acesse o site da<br>campanha clicando aqui
                        </font>
                      </a>
                    </td>
                    <td align="center" valign="middle">
                      <a href=${
                        layoutInfo.campaignTerms
                      } target="_blank" style="color: ${
    layoutInfo.footerTextColor
  };">
                        <font face="Arial" style="font-size: 15px; font-weight: 400;" color=${
                          layoutInfo.footerTextColor
                        }>
                          Acesse o regulamento da<br>campanha clicando aqui
                        </font>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="middle">
                      <img src=${
                        layoutInfo.logoHeader
                      } alt="" style="max-width: 600px">
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="middle">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    </td>
    </tr>
    </table>
  </body>
  </html>
`

  const campaignContext = useContext(CampaignContext)

  useEffect(() => {
    campaignContext.addLayout(html, 3)
  }, [html, campaignContext])

  if (render) return parse(html)
  else return <></>
}

FolderLembretePeriodo1.propTypes = {
  layoutInfo: PropTypes.object,
  layoutTitleChoosen: PropTypes.string,
}

export default FolderLembretePeriodo1

import { Label } from "flowbite-react"

const InfoColumn = ({ label, value }) => (
  <div className="tw-flex tw-w-5/12 tw-flex-col tw-items-start tw-px-6">
    <Label className="tw-text-gray-700 tw-text-xl">{label}</Label>
    <div className="truncate tw-text-xl">{value}</div>
  </div>
);


export const ParticipantRow = ({ name, phone, email, segmentation, onDelete, isApp }) => {

  const columns = [
    { label: "Nome", value: name },
    { label: "Celular", value: isApp ? phone || "-": phone},
    { label: "Email", value: isApp ? email || "-": email },
    { label: "Segmentação", value: segmentation },
  ];
  
  return (
    <div className="tw-flex tw-divide-x tw-divide-primary-600 tw-rounded-md tw-border tw-border-primary-600 tw-py-5">
      <div className="tw-flex tw-justify-center tw-items-center tw-text-primary-600 tw-w-1/12">
        <svg className="tw-h-6 tw-w-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 11 14H9a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 10 19Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      </div>
      {columns.map(
        (column, index) =>
          column.value && <InfoColumn key={index} label={column.label} value={column.value} />
      )}
      <div className="tw-flex tw-grow tw-flex-col tw-items-center tw-self-center tw-text-primary-600 tw-w-1/12">
        <button onClick={onDelete}>
          <svg
            className="tw-h-6 tw-w-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}
import React from 'react'
import PropTypes from 'prop-types'
import Accordion from 'react-bootstrap/Accordion'
import HistoryTable from './history-table'
import moment from 'moment'
import { styles } from 'cashin-design-system/Styles/Styles'
import { getRandomNumber } from '../../functions/utils'

const HistoryData = ({
  data,
  isManagementAction = false,
  research,
  currentTab,
}) => {
  const themeColors = [
    '#A500B9',
    '#D778CD',
    '#FF7503',
    '#1B7D2C',
    '#76E388',
    '#666666',
    '#5900E1',
  ]

  return (
    <Accordion className={styles.customAccordion()} defaultActiveKey='0'>
      {data?.items &&
        data?.items.map((item, i) => {
          const randomIndex = getRandomNumber(themeColors.length)
          const randomColor = themeColors[randomIndex]

          const sortDate = (a, b) =>
            new Date(b.createdDate) - new Date(a.createdDate)

          const sortedList = item.validations.sort(sortDate)

          const totalValidateValue = sortedList.reduce((acc, obj) => {
            if (obj.validateResult && obj.validateResult.totalValue) {
              return acc + obj.validateResult.totalValue
            }
            return acc
          }, 0)

          return (
            <Accordion.Item
              eventKey={i}
              style={{ borderLeft: `4px solid ${randomColor}` }}
              onClick={() => console.log(sortedList)}
            >
              <Accordion.Header>
                <div className='headerInfos'>
                  <div className='texts'>
                    <span
                      className={styles.tipography({
                        paragraphs: 'mediumBold',
                      })}
                    >
                      Campanha: {item?.campaign?.name}
                    </span>
                    <p
                      className={styles.tipography({
                        paragraphs: 'xSmallRegular',
                      })}
                    >
                      {sortedList.length} Arquivos
                    </p>
                  </div>
                  <div className='endInfos'>
                    <div className='texts'>
                      <span
                        className={styles.tipography({
                          paragraphs: 'mediumBold',
                        })}
                      >
                        Data de envio
                      </span>
                      <p
                        className={styles.tipography({
                          paragraphs: 'xSmallRegular',
                        })}
                      >
                        {moment(item.validations[0].createdDate).format(
                          'DD/MM/YYYY'
                        )}
                      </p>
                    </div>
                    <div className='texts'>
                      <span
                        className={styles.tipography({
                          paragraphs: 'mediumBold',
                        })}
                      >
                        Valor Total Apurado
                      </span>
                      <p
                        className={styles.tipography({
                          paragraphs: 'xSmallRegular',
                        })}
                      >
                        {totalValidateValue.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <HistoryTable
                  data={item}
                  isManagementAction={isManagementAction}
                  validationId={item.id}
                  research={research}
                  currentTab={currentTab}
                />
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
    </Accordion>
  )
}

HistoryData.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default HistoryData

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent } from 'cashin-components';
import ModalFooterContent from './modal-footer-content';

const FooterContent = ({
  styles,
  returnYear,
  profile,
  show,
  handleClose,
  handleShow,
}) => {
  return (
    <footer
      className={`${
        profile
          ? 'w-100 text-center'
          : styles.login({ footer: 'defaultFooter' })
      }`}
    >
      <div>
        <p
          className={`${styles.tipography({
            paragraphs: 'xSmallRegular',
          })} m-0`}
        >
          ©{returnYear} CashIn. Todos os direitos reservados.
        </p>
        <p className="m-0">
          <ButtonComponent
            className={`
              ${styles.buttonStandardSmall({
                small: 'link',
              })}
            `}
            text="Preciso de ajuda"
            styles={styles}
            onClick={() => handleShow()}
          />
        </p>
      </div>

      <ModalFooterContent
        styles={styles}
        size="xs"
        show={show}
        onHide={handleClose}
      />
    </footer>
  );
};

FooterContent.propTypes = {
  styles: PropTypes.object,
  returnYear: PropTypes.number,
  profile: PropTypes.any,
  show: PropTypes.any,
  handleClose: PropTypes.func,
  handleShow: PropTypes.func,
};

export default FooterContent;

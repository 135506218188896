import React, { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Switch,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "cashin-design-system/Styles/Fonts.css";
import "@material-design-icons/font";
import { styles } from "cashin-design-system/Styles/Styles";
import { Col, Container, Row } from "react-bootstrap";
import { Login } from "./login";
import { NotFound } from "./404";
import { ForgotPassword } from "./forgot-password";
import { createBrowserHistory } from "history";
import Dashboard from "./Dashboard/dashboard-index";
import Logoff from "./Logoff/logoff-page";
import { NavMenu } from "./nav-menu";
import { HeadersComponent } from "cashin-components";
import { Footer } from "./footer";
import { Campaigns } from "./campaigns";
import { CreateCampaign } from "./create-campaign";
import { RecoveryAccess } from "./recover-access";
import { Reports } from "./reports";
import { Customers } from "./customers";
import { EditCustomer } from "./edit-customer";
import { BusinessNegociation } from "./business-negociation";
import { CreateCustomer } from "./create-customer";
import { InternalUsers } from "./internal-users";
import { EditInternalUser } from "./edit-internal-user";
import { Profile } from "./profile";
import { EditCampaign } from "./edit-campaign";
import { NotAllowed } from "./405";
import CampaignVerificationPage from "./campaign-verification/campaign-verification-page";
import ForgotPasswordConfirm from "./forgot-password-confirm/forgot-password-confirm";

const userData = JSON.parse(window.localStorage.getItem("profile"));

const defaultState = {
  userName: userData && userData.name,
  userCredencial: userData && userData.email.split("@")[0],
  userGender: "",
  isAdmin: userData && userData["custom:profile"] === "Admin" ? true : false,
  userMenu: [
    {
      itemName: "Usuários internos",
      url: "/internal-users",
    },
    {
      itemName: "Clientes",
      url: "/customers",
    },
  ],
  isMenuOpened: true,
};

const App = () => {
  const [ownState, setOwnState] = useState(defaultState);

  const history = createBrowserHistory();
  const profile = window.localStorage.getItem("profile");

  const handleCloseMenu = () => {
    setOwnState({
      ...ownState,
      isMenuOpened: !ownState.isMenuOpened,
    });
  };

  useEffect(() => {
    let body = document.querySelectorAll("body")[0];

    body.setAttribute("class", styles.body());
  });

  return (
    <>
      <Router>
        <Container fluid>
          <Row className={`${profile && "full-height"}`}>
            {profile && (
              <NavMenu
                styles={styles}
                state={ownState}
                isMenuOpened={ownState.isMenuOpened}
              />
            )}
            <Col xs>
              {profile && (
                <HeadersComponent
                  styles={styles}
                  state={ownState}
                  onClick={() => handleCloseMenu()}
                />
              )}
              <section className={styles.section()}>
                <Routes>
                  <Route
                    element={
                      profile ? (
                        <Navigate to="/dashboard" />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/"
                  />
                  <Route
                    element={<Login styles={styles} history={history} />}
                    path="/login"
                  />
                  <Route
                    element={
                      <ForgotPassword styles={styles} history={history} />
                    }
                    path="/forgot-password"
                  />
                  <Route
                    element={
                      <ForgotPasswordConfirm
                        styles={styles}
                        history={history}
                      />
                    }
                    path="/confirm-change-password"
                  />
                  <Route
                    element={
                      <RecoveryAccess styles={styles} history={history} />
                    }
                    path="/recovery-access/:id"
                  />
                  <Route
                    element={
                      profile ? (
                        <Dashboard styles={styles} history={history} />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/dashboard"
                  />
                  <Route
                    element={
                      profile ? (
                        <Customers
                          styles={styles}
                          history={history}
                          isAdmin={ownState.isAdmin}
                        />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/customers"
                  />
                  <Route
                    element={
                      profile ? (
                        <EditCustomer styles={styles} history={history} />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/edit-customer/:id"
                  />
                  <Route
                    element={
                      profile ? (
                        <BusinessNegociation
                          styles={styles}
                          history={history}
                        />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/business-negociation/:id"
                  />
                  <Route
                    element={
                      profile ? (
                        <CreateCustomer styles={styles} history={history} />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/create-customer"
                  />
                  <Route
                    element={
                      profile ? (
                        <InternalUsers
                          styles={styles}
                          history={history}
                          isAdmin={ownState.isAdmin}
                        />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/internal-users"
                  />
                  <Route
                    element={
                      profile ? (
                        <EditInternalUser styles={styles} history={history} />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/edit-internal-user/:id"
                  />
                  <Route
                    element={
                      profile ? (
                        <Profile styles={styles} history={history} />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/profile"
                  />
                  <Route
                    element={
                      profile ? (
                        <Campaigns styles={styles} history={history} />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/campaigns"
                  />
                  <Route
                    element={
                      profile ? (
                        <CreateCampaign styles={styles} history={history} />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/create-campaign"
                  />
                  <Route
                    element={
                      profile ? (
                        <EditCampaign styles={styles} history={history} />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/edit-campaign/:id"
                  />
                  <Route
                    element={
                      profile ? (
                        <CampaignVerificationPage />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                    path="/campaign-verification"
                  />
                  <Route
                    element={
                      profile ? (
                        <Reports styles={styles} history={history} />
                      ) : (
                        // <Navigate to="/campaigns" />
                        <Navigate to="/login" />
                      )
                    }
                    path="/reports"
                  />
                  <Route
                    element={<Logoff styles={styles} history={history} />}
                    path="/logoff"
                  />
                  <Route
                    element={<NotAllowed styles={styles} history={history} />}
                    path="/not-allowed"
                  />
                  <Route
                    element={<NotFound styles={styles} history={history} />}
                    path="*"
                  />
                </Routes>
              </section>
              {profile && <Footer styles={styles} profile={profile} />}
            </Col>
          </Row>
        </Container>
      </Router>
    </>
  );
};

export default App;

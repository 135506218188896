import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
import {
  Input,
  ButtonComponent,
  Select,
  BreadcrumbComponent,
} from "cashin-components";
import CustomersTableComponent from "./customers-table-component";
import useIdentificationInfo from "../API/queries/company/useIdentificationInfo";
import { Typeahead } from "react-bootstrap-typeahead";
import CustomPagination from "../Components/CustomPagination";
import companySearch from "../API/queries/company/companySearch";

const CustomersPage = ({ styles, state, handleEdit, handleCreateCustomer }) => {
  const [companyIdSelected, setCompanyIdSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);
  const [currentPagination, setCurrentPagination] = useState(0);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchResponse, setSearchResponse] = useState({
    totalItems: 0,
    items: [],
  });

  const { isLoading, error, data } = useIdentificationInfo();

  const handleCompanyFilterSelected = (item) =>
    setCompanyIdSelected(item?.[0]?.id);

  const handleOnPaginationChange = (newPage) => {
    // fetchNextPage({ pageParam: newPage - 1 });
    setCurrentPagination(newPage - 1);
  };

  const handleSearch = async () => {
    setIsSearchLoading(true);
    setSearchResponse({
      totalItems: 0,
      items: [],
    });

    var response = await companySearch(
      currentPagination,
      companyIdSelected,
      statusSelected
    );

    setSearchResponse(response);

    setIsSearchLoading(false);
  };

  useEffect(() => {
    handleSearch();
  }, [currentPagination]);

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12} className="pt-4">
            <BreadcrumbComponent
              hasIcon={true}
              styles={styles}
              initialIcon="home-color"
              initialNode="Dashboard"
              breadcrumbData={state.breadcrumb}
            />
          </Col>
          <Col xs={6}>
            <h1
              className={`
                ${styles.tipography({ headings: "smallBold" })}
                my-3
              `}
            >
              Clientes
            </h1>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <ButtonComponent
              className={`${styles.buttonStandardLarge({
                large: "primary",
              })}
              ${state.isLoading &&
                styles.buttonStandardLarge({
                  large: "primaryLoading",
                })
                } my-2`}
              hasIcon={true}
              iconName="add_circle"
              text="Cadastrar empresa"
              styles={styles}
              onClick={() => handleCreateCustomer()}
            />
          </Col>
          <Col xs={12}>
            <div
              className={`
                ${styles.border({ borderBottomWidth: "thick" })}
                mb-4 page-border
              `}
            />
          </Col>
          <Col xs>
            <Col xs>
              <Form.Label
                className={styles.tipography({ paragraphs: "smallBold" })}
                style={{ paddingTop: 1 }}
              >
                Empresa
              </Form.Label>
              <Typeahead
                labelKey={(option) => option.description}
                filterBy={["id"]}
                options={data}
                placeholder={isLoading && "Carregando..."}
                label="Campanha"
                disabled={isLoading}
                style={{ height: 44 }}
                onChange={handleCompanyFilterSelected}
              />
            </Col>
          </Col>
          <Col xs>
            <Select
              id="ddlStatusFilter"
              labelName="Status"
              options={[
                {
                  value: null,
                  label: "Todos",
                },
                {
                  value: 0,
                  label: "Aguardando aprovação",
                },
                {
                  value: 1,
                  label: "Ativado",
                },
                {
                  value: 2,
                  label: "Desativado",
                },
              ]}
              styles={styles}
              onChange={(e) => setStatusSelected(e.value)}
            />
          </Col>
          <Col xs style={{ marginTop: 25 }}>
            <ButtonComponent
              className={`
                ${styles.buttonStandardLarge({
                large: "primary",
              })}
                mb-3
              `}
              text="Buscar"
              styles={styles}
              onClick={handleSearch}
            />
          </Col>
          <Col xs={12} className="mt-4 mb-3">
            <CustomersTableComponent
              styles={styles}
              header={state.tableHeader}
              data={searchResponse?.items || []}
              handleEdit={handleEdit}
              isLoading={isSearchLoading}
            />

            <CustomPagination
              totalPages={(searchResponse?.totalItems || 0) / 10}
              onPaginationChange={handleOnPaginationChange}
              currentPage={currentPagination + 1}
              setCurrentPage={setCurrentPagination}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CustomersPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleEdit: PropTypes.func,
  handleCreateCustomer: PropTypes.func,
};

export default CustomersPage;

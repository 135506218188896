import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { BadgeComponent, ButtonComponent, Input, Icon } from 'cashin-components'
import ModalApproveCampaign from './modal-approve-campaign'
import ModalReproveCampaign from './modal-reprove-campaign'
import { styles } from 'cashin-design-system/Styles/Styles'
import uploadValidationResultRequest from '../../API/queries/campaign-validate/uploadValidationResultRequest'
import changeResultValueRequest from '../../API/queries/campaign-validate/changeResultValue'
import ModaAdminChangeStatus from './modal-admin-change-result-status'
import changeValidationResultStatusCustomerRequest from '../../API/queries/campaign-validate/changeValidationResultStatusCustomerRequest'
import { toast } from 'react-toastify'

const badgeDescriptionByStatus = {
  0: 'Em processamento',
  1: 'Clique para pagar',
  2: 'Reprovado',
  3: 'Finalizado',
  4: 'Finalizado e reprovado',
  5: 'Erro na planilha',
}

const badgeByStatus = {
  0: 'warning',
  1: 'primary',
  2: 'danger',
  3: 'success',
  4: 'danger',
  5: 'dark',
}

const UploadInput = ({
  isManagementAction,
  isLoadingUploadResult,
  item,
  fileReference,
  handleUploadPartialChange,
  type = 'normal',
}) => {
  if (
    !isManagementAction &&
    !item?.validateResult?.fileUploaded &&
    !isLoadingUploadResult
  )
    return '-'

  if (isLoadingUploadResult) return 'Carregando...'

  return (
    <Input
      styles={styles}
      isFileInput={true}
      isFileButton={true}
      isEmptyFile={
        !fileReference?.find((x) => x.id === item.id) || isManagementAction
      }
      accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      onChange={(e) => handleUploadPartialChange(e, item, type)}
      files={
        !isManagementAction
          ? [fileReference?.find((x) => x.id === item.id)]
          : []
      }
    />
  )
}

const HistoryTable = ({
  data,
  validationId,
  isManagementAction = false,
  research,
  currentTab,
}) => {
  const [fileReference, setFileReference] = useState(
    data?.validations
      ?.map((item) =>
        !isManagementAction && item?.validateResult?.fileUploaded
          ? {
              id: item.id,
              name: item?.validateResult?.originalFileName || 'arquivo.xls',
              size: '123456',
              lastModified: '123456',
              type: 'xls',
            }
          : null
      )
      ?.filter(Boolean) || []
  )

  const [fileReferenceApp, setFileReferenceApp] = useState(
    data?.validations
      ?.map((item) =>
        !isManagementAction && item?.validateResultApp?.fileUploaded
          ? {
              id: item.id,
              name: item?.validateResultApp?.originalFileName || 'arquivo.xls',
              size: '123456',
              lastModified: '123456',
              type: 'xls',
            }
          : null
      )
      ?.filter(Boolean) || []
  )

  const [validationResultValueReference, setValidationResultValueReference] =
    useState([])

  const [showApproveCampaign, setShowApproveCampaign] = useState(false)
  const [showReproveCampaign, setShowReproveCampaign] = useState(false)
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(null)
  const [isLoadingUploadResult, setIsLoadingUploadResult] = useState(false)
  const [isLoadingUploadResultApp, setIsLoadingUploadResultApp] =
    useState(false)
  const [currentValidationId, setCurrentValidationId] = useState(null)

  const handleCloseApproveCampaign = () => setShowApproveCampaign(false)
  const handleShowApproveCapmpaign = () => setShowApproveCampaign(true)

  const handleCloseReproveCampaign = () => {
    research()
    setShowReproveCampaign(false)
  }
  const handleShowReproveCapmpaign = () => setShowReproveCampaign(true)

  const handleCloseShowChangeStatusModal = () => {
    setShowChangeStatusModal(null)
    research()
  }
  const handleShowShowChangeStatusModal = (itemId) =>
    setShowChangeStatusModal(itemId)

  const handleValidationFileValue = (value, item) =>
    setValidationResultValueReference((current) => [
      ...current.filter((x) => x.id !== item.id),
      {
        value,
        id: item.id,
      },
    ])

  const handleOnClickChangeValidationValue = async (validationResultId) => {
    const value =
      validationResultValueReference.find((x) => x.id === validationResultId)
        ?.value || 0

    await changeResultValueRequest(validationId, validationResultId, value)

    research()
  }

  const showHeader = () => {
    return (
      <>
        <th>Arquivo</th>
        <th>Data de envio</th>
        <th>Resultado</th>
        {currentTab === 'manage-verification' && <th>Resultado App</th>}
        <th>Valor total apurado</th>
        <th>Data de apuração</th>
        <th>Apuração</th>
        <th>Crédito</th>
        <th>Ações</th>
      </>
    )
  }

  const handleUploadPartialChange = async (value, validationEntity, type) => {
    if (type === 'normal') {
      setIsLoadingUploadResult(validationEntity.id)
    } else {
      setIsLoadingUploadResultApp(validationEntity.id)
    }

    await uploadValidationResultRequest(
      validationId,
      validationEntity.id,
      value[0],
      validationEntity.type
    ).catch(() => setIsLoadingUploadResult(null))

    setIsLoadingUploadResult(null)

    research()
  }

  const handleDownloadFile = (item) =>
    (window.location.href = `${item?.baseUrl}/${item?.formattedFileName}`)

  const handleResultFileDownload = (item) => {
    if (item?.validateResult?.fileUploaded && !isManagementAction)
      window.location.href = `${item?.validateResult?.baseUrl}/${item?.validateResult?.formattedFileName}`
  }

  const handleResultFileDownloadApp = (item) => {
    if (item?.validateResultApp?.fileUploaded && !isManagementAction)
      window.location.href = `${item?.validateResultApp?.baseUrl}/${item?.validateResultApp?.formattedFileName}`
  }

  const handleApproveCampaign = async (currentValidationId) => {
    toast('Solicitação enviada!')

    await changeValidationResultStatusCustomerRequest(
      validationId,
      currentValidationId,
      3
    )

    research()

    handleShowApproveCapmpaign()
  }

  const handleReproveCampaign = (currentValidationId) => {
    setCurrentValidationId(currentValidationId)
    handleShowReproveCapmpaign()
  }

  function formatCurrency(value) {
    const options = {
      style: 'currency',
      currency: 'BRL',
    }
    return value.toLocaleString('pt-BR', options)
  }

  return (
    <>
      <table
        id='table'
        className={`
          ${styles.table()}
        `}
      >
        <thead>
          <tr>{showHeader()}</tr>
        </thead>
        <tbody>
          {data?.validations?.map((item) => (
            <tr key={item.id}>
              <td>{item?.originalFileName}</td>
              <td>{moment(item?.createdDate).format('DD/MM/YYYY')}</td>
              <td
                className={`
              ${styles.inputs({ inputFile: 'inputFileButton' })}
              w-auto
            `}
                onClick={() => handleResultFileDownload(item)}
              >
                <UploadInput
                  fileReference={fileReference}
                  handleUploadPartialChange={handleUploadPartialChange}
                  isLoadingUploadResult={isLoadingUploadResult === item.id}
                  isManagementAction={isManagementAction}
                  item={{ ...item, type: 'regular' }}
                />
              </td>
              {currentTab === 'manage-verification' && (
                <td
                  className={`
              ${styles.inputs({ inputFile: 'inputFileButton' })}
              w-auto
            `}
                  // onClick={() => handleResultFileDownloadApp(item)}
                >
                  <UploadInput
                    fileReference={fileReferenceApp}
                    handleUploadPartialChange={handleUploadPartialChange}
                    isLoadingUploadResult={isLoadingUploadResultApp === item.id}
                    isManagementAction={isManagementAction}
                    item={{ ...item, type: 'app' }}
                    type='app'
                  />
                </td>
              )}
              <td>
                {isManagementAction ? (
                  <div className='d-flex'>
                    <input
                      id='txtCampaign'
                      inputName='campaignValue'
                      className={styles.inputs({ input: 'default' })}
                      idError='fdbCampaignError'
                      inputType='text'
                      style={{ width: 60, padding: 0 }}
                      defaultValue={formatCurrency(
                        item?.validateResult?.totalValue
                      )}
                      onInput={(e) => {
                        const value = e.target.value.replace(/\D/g, '')
                        const number = Number(value) / 100
                        e.target.value = formatCurrency(number)
                        handleValidationFileValue(value / 100, item)
                      }}
                      inputMode='numeric'
                      pattern='[0-9]*'
                    />
                    <Icon
                      iconName='edit'
                      style={{ cursor: 'pointer' }}
                      onClick={(x) =>
                        handleOnClickChangeValidationValue(item.id)
                      }
                    />
                  </div>
                ) : item?.validateResult?.fileUploaded ? (
                  `R$ ${item?.validateResult?.totalValue}`
                ) : (
                  '-'
                )}
              </td>
              <td>
                {item?.validateResult?.fileUploaded
                  ? moment(item?.validateResult?.resultFileDate).format(
                      'DD/MM/YYYY'
                    )
                  : '-'}
              </td>
              <td>{item?.type === 0 ? 'Parcial' : 'Final'}</td>
              <td>
                <BadgeComponent
                  text={badgeDescriptionByStatus[item?.status]}
                  bgType={badgeByStatus[item?.status]}
                  onClick={() => {
                    if (item?.status === 1) {
                      handleApproveCampaign(item.id)
                    }
                  }}
                  isBadgePill={true}
                  styles={styles}
                  style={{ cursor: item?.status === 1 ? 'pointer' : '' }}
                />
              </td>
              <td>
                <ButtonComponent
                  className={`
                ${styles.buttonStandardLarge({
                  large: 'secondary',
                })}
              `}
                  text='...'
                  isButtonGroup={true}
                  itemsGroup={[
                    {
                      itemIcon: 'download',
                      itemIconClass: 'icon-color',
                      itemName: 'Download da apuração',
                      onClickItem: () => handleDownloadFile(item),
                    },
                    item?.validateResult?.fileUploaded && {
                      itemIcon: 'download',
                      itemIconClass: 'icon-color',
                      itemName: 'Download Resultado',
                      onClickItem: () => handleResultFileDownload(item),
                    },
                    isManagementAction && {
                      itemIcon: 'edit',
                      itemIconClass: 'icon-color',
                      itemName: 'Alterar status',
                      onClickItem: () =>
                        handleShowShowChangeStatusModal(item.id),
                    },
                    item?.status === 1 &&
                      !isManagementAction && {
                        itemIcon: 'check',
                        itemIconClass: 'icon-color',
                        itemName: 'Aprovar resultado',
                        onClickItem: () => handleApproveCampaign(item.id),
                      },
                    item?.status === 1 &&
                      !isManagementAction && {
                        itemIcon: 'close',
                        itemIconClass: 'icon-color',
                        itemName: 'Reprovar resultado',
                        onClickItem: () => handleReproveCampaign(item.id),
                      },
                  ].filter(Boolean)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ModalApproveCampaign
        styles={styles}
        size='xs'
        show={showApproveCampaign}
        onHide={handleCloseApproveCampaign}
      />
      <ModalReproveCampaign
        styles={styles}
        size='xs'
        show={showReproveCampaign}
        onHide={handleCloseReproveCampaign}
        validationId={validationId}
        currentValidationId={currentValidationId}
      />
      <ModaAdminChangeStatus
        styles={styles}
        size='xs'
        show={showChangeStatusModal}
        onHide={handleCloseShowChangeStatusModal}
        validationId={validationId}
        validationItemId={showChangeStatusModal}
      />
    </>
  )
}

HistoryTable.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default HistoryTable

import { statusFilter} from './AppReportTable';
import { formatDate } from "../../functions/date";
import {addPhoneMask} from "../../functions/phone";

const xlsxManager = [
  { label: 'Participante', fn: (row) => row.participant.name},
  { label: 'Telefone', fn: (row) => addPhoneMask(row.participant.phone)},
  { label: 'E-mail', fn: (row) => row.participant.email},
  { label: 'Qtd de interações',  fn: (row) => row.reactedCount},
  { label: 'Engajamento',  fn: (row) => `${row.percentage}%`},
  { label: 'Período', fn: (row) => `${formatDate(row.from)} - ${formatDate(row.to)}`},
  { label: 'Segmento', fn: (row) => row.participant.segmentationName || '-'},
  { label: 'Data de Interação', fn: (row) => formatDate(row.type.createdAt)},
  { label: 'Interação', fn: (row) => statusFilter[row.type.type].label},
  { label: 'Status', fn: (row) =>  row.type.reacted ? statusFilter[row.type.type].secLabel : statusFilter.NO_INTERACTION.secLabel},
];

export const transformAppReportDataForXlxs = (data) => {
  let transformedData = []

  data?.forEach(item => {
    item.types.forEach((type) => {
    const transformedItem = {};
      xlsxManager.forEach((propertyName, index) =>
        transformedItem[propertyName.label] = propertyName.fn({ ...item, type}));
      transformedData.push(transformedItem)
    })
  });

  return transformedData;
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CampaignsPage from './campaigns-page'
import { BadgeComponent, ButtonComponent } from 'cashin-components'
import moment from 'moment'
import { useNavigate } from 'react-router'

const defaultState = {
  campaignValue: '',
  campaignError: '',
  statusValue: '',
  statusError: '',
  dateRangeError: '',
  isInvalidCampaign: false,
  isInvalidStatus: false,
  isInvalidDateRange: false,
  isLoading: false,
  breadcrumb: [
    {
      isActive: true,
      itemName: 'Campanhas',
    },
  ],
  valueStatusOptions: [
    {
      value: 0,
      label: 'Todos',
    },
  ],
  monthsShown: 2,
  selectsRange: true,
  tableHeader: [
    {
      headerTitle: 'Campanha',
    },
    {
      headerTitle: 'Status',
    },
    {
      headerTitle: 'Período',
    },
    {
      headerTitle: 'Ações',
    },
  ],
  dataSet: [],
}

const simulateNetworkRequest = () => {
  return new Promise((res) => setTimeout(res, 1000))
}

const Campaigns = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleCreateCampaign = () => {
    navigate('/create-campaign')
  }

  const handleKeyDown = (key) => {
    if (key === 'Enter') {
      handleSearch()
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    })
  }

  const handleSelectChange = (fieldName, e) => {
    setOwnState({
      ...ownState,
      [fieldName]: e.label,
    })
  }

  const handleDateChange = ({ startDate, endDate }) => {
    setOwnState({
      ...ownState,
      startDate: startDate,
      endDate: endDate,
    })
  }

  const handleSearch = () => {
    if (ownState.campaignValue === '') {
      setOwnState({
        ...ownState,
        campaignError: 'Preencha um nome de campanha',
        statusError: '',
        startDateError: '',
        endDateError: '',
        isInvalidCampaign: true,
        isInvalidStatus: false,
        isInvalidStartDate: false,
        isInvalidEndDate: false,
        isLoading: false,
      })
    } else if (ownState.statusValue === '') {
      setOwnState({
        ...ownState,
        campaignError: '',
        statusError: 'Escolha um status',
        startDateError: '',
        endDateError: '',
        isInvalidCampaign: false,
        isInvalidStatus: true,
        isInvalidStartDate: false,
        isInvalidEndDate: false,
        isLoading: false,
      })
    }
    // else if (startDateValue === '') {
    //   setOwnState({
    //     ...ownState,
    //     campaignError: '',
    //     statusError: '',
    //     startDateError: 'Preencha uma data inicial',
    //     endDateError: '',
    //     isInvalidCampaign: false,
    //     isInvalidStatus: false,
    //     isInvalidStartDate: true,
    //     isInvalidEndDate: false,
    //     isLoading: false,
    //   });
    // } else if (endDateValue === '') {
    //   setOwnState({
    //     ...ownState,
    //     campaignError: '',
    //     statusError: '',
    //     startDateError: 'Preencha uma data final',
    //     endDateError: '',
    //     isInvalidCampaign: false,
    //     isInvalidStatus: false,
    //     isInvalidStartDate: false,
    //     isInvalidEndDate: true,
    //     isLoading: false,
    //   });
    // }
    else {
      setOwnState({
        ...ownState,
        campaignError: '',
        statusError: '',
        startDateError: '',
        endDateError: '',
        isInvalidCampaign: false,
        isInvalidStatus: false,
        isInvalidStartDate: false,
        isInvalidEndDate: false,
        isLoading: true,
      })
    }
  }

  const getPosts = async () => {
    const arr = []

    // const campaignData = await API.get(
    //   "/campaign/search?Pagination.Offset=0&Pagination.Limit=10"
    // );

    // campaignData.items.map((e) => {
    //   return arr.push({
    //     id: e.id,
    //     imageUrlBase:
    //       e.notificationLayout.notificationDispatches[0].images[0].urlBase,
    //     imageFile:
    //       e.notificationLayout.notificationDispatches[0].images[0].fileName,
    //     campaignTitle: e.name,
    //     statusType: e.status,
    //     statusContent: e.status,
    //     initialDate: e.periodDate.from,
    //     expirationDate: e.periodDate.to,
    //   });
    // });

    setOwnState({
      ...ownState,
      dataSet: arr,
    })
  }

  const handleEdit = (id) => {
    history.push(`/edit-campaign/${id}`)
    history.go(`/edit-campaign/${id}`)
  }

  const handleDelete = (id) => {
    handleShow()
  }

  useEffect(() => {
    getPosts()

    ownState.isLoading &&
      simulateNetworkRequest().then(() => {
        setOwnState({
          ...ownState,
          isLoading: false,
        })
      })
  }, [ownState.isLoading])

  return (
    <CampaignsPage
      styles={styles}
      state={ownState}
      handleKeyDown={handleKeyDown}
      handleChange={handleChange}
      handleSelectChange={handleSelectChange}
      handleDateChange={handleDateChange}
      handleSearch={handleSearch}
      handleCreateCampaign={handleCreateCampaign}
      startDate={startDate}
      endDate={endDate}
      setDateRange={setDateRange}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      show={show}
      handleClose={handleClose}
      handleShow={handleShow}
    />
  )
}

Campaigns.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
}

export default Campaigns

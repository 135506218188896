import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { ButtonComponent } from "cashin-components";
import ModalSendTest from "./modal-send-test";
import ModalSendTestConfirmation from "./modal-send-test-confirmation";
import { CampaignContext } from "../../context/campaign-context";

const StepsControls = ({
  styles,
  state,
  handleStepBack,
  handleStepForward,
  handleSaveAndContinue,
  showSendTest,
  showSendTestConfirmation,
  handleCloseSendTest,
  handleCloseSendTestConfirmation,
  handleShowSendTest,
  handleShowSendTestConfirmation,
  handleShotSendTest,
  canProceedToNextStep,
  isLoading,
}) => {
  const campaignContext = useContext(CampaignContext);

  return (
    <Col xs={12}>
      <hr className="mt-0" />
      <div className="w-100 d-flex justify-content-end">
        {state.activeStep !== 1 && (
          <ButtonComponent
            className={`
            ${styles.buttonStandardLarge({
              large: "secondary",
            })}
            mb-3 me-3
          `}
            hasIcon={true}
            iconName="arrow_back"
            text="Voltar"
            styles={styles}
            onClick={() => handleStepBack()}
          />)}
        {state.activeStep === 4 ? (
          <ButtonComponent
            className={`
              ${styles.buttonStandardLarge({
              large: "primary",
            })}
              mb-3
            `}
            text={
              campaignContext.isLoadingCreateCampaign
                ? "Carregando..."
                : "Finalizar e concluir"
            }
            styles={styles}
            onClick={() => handleSaveAndContinue()}
          />
        ) : (
          <ButtonComponent
            className={`
              ${styles.buttonStandardLarge({
              large: "primary",
            })}
              mb-3
            `}
            hasIcon={true}
            iconName="arrow_forward"
            text="Continuar"
            styles={styles}
            isLoading={isLoading}
            disabled={
              // state.swtWhatsAppComunication !== true ||
              state.activeStep === 4 || !canProceedToNextStep
            }
            onClick={() => handleStepForward()}
          />
        )}
      </div>

      <ModalSendTest
        styles={styles}
        size="xs"
        show={showSendTest}
        onHide={handleCloseSendTest}
        handleShotSendTest={handleShotSendTest}
      />

      <ModalSendTestConfirmation
        styles={styles}
        size="xs"
        show={showSendTestConfirmation}
        onHide={handleCloseSendTestConfirmation}
      />
    </Col>
  );
};

StepsControls.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleStepBack: PropTypes.func,
  handleStepForward: PropTypes.func,
  handleSaveAndContinue: PropTypes.func,
  showSendTest: PropTypes.any,
  showSendTestConfirmation: PropTypes.any,
  handleCloseSendTest: PropTypes.func,
  handleCloseSendTestConfirmation: PropTypes.func,
  handleShowSendTest: PropTypes.func,
  handleShowSendTestConfirmation: PropTypes.func,
  handleShotSendTest: PropTypes.func,
};

export default StepsControls;

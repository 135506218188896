import { css } from './CreateStitches'
import FacebookBW from './img/OtherIcons/BW/facebook.png'
import FacebookColor from './img/OtherIcons/Color/facebook.png'
import TwitterBW from './img/OtherIcons/BW/twitter.png'
import TwitterColor from './img/OtherIcons/Color/twitter.png'
import InstagramBW from './img/OtherIcons/BW/instagram.png'
import InstagramColor from './img/OtherIcons/Color/instagram.png'
import LinkedInBW from './img/OtherIcons/BW/linkedin.png'
import LinkedInColor from './img/OtherIcons/Color/linkedin.png'
import GoogleBW from './img/OtherIcons/BW/google.png'
import GoogleColor from './img/OtherIcons/Color/google.png'
import YouTubeBW from './img/OtherIcons/BW/youtube.png'
import YouTubeColor from './img/OtherIcons/Color/youtube.png'
import AppleBW from './img/OtherIcons/BW/apple.png'
import AppleColor from './img/OtherIcons/Color/apple.png'
import SnapchatBW from './img/OtherIcons/BW/snapchat.png'
import SnapchatColor from './img/OtherIcons/Color/snapchat.png'
import PinterestBW from './img/OtherIcons/BW/pinterest.png'
import PinterestColor from './img/OtherIcons/Color/pinterest.png'
import MediumBW from './img/OtherIcons/BW/medium.png'
import MediumColor from './img/OtherIcons/Color/medium.png'
import GitHubBW from './img/OtherIcons/BW/github.png'
import GitHubColor from './img/OtherIcons/Color/github.png'
import SkypeBW from './img/OtherIcons/BW/skype.png'
import SkypeColor from './img/OtherIcons/Color/skype.png'
import FigmaBW from './img/OtherIcons/BW/figma.png'
import FigmaColor from './img/OtherIcons/Color/figma.png'
import DribbbleBW from './img/OtherIcons/BW/dribbble.png'
import DribbbleColor from './img/OtherIcons/Color/dribbble.png'
import RedditBW from './img/OtherIcons/BW/reddit.png'
import RedditColor from './img/OtherIcons/Color/reddit.png'
import DiscordBW from './img/OtherIcons/BW/discord.png'
import DiscordColor from './img/OtherIcons/Color/discord.png'
import TikTokBW from './img/OtherIcons/BW/tiktok.png'
import TikTokColor from './img/OtherIcons/Color/tiktok.png'
import TumblrBW from './img/OtherIcons/BW/tumblr.png'
import TumblrColor from './img/OtherIcons/Color/tumblr.png'
import TelegramBW from './img/OtherIcons/BW/telegram.png'
import TelegramColor from './img/OtherIcons/Color/telegram.png'
import ClubHouseBW from './img/OtherIcons/BW/clubhouse.png'
import ClubHouseColor from './img/OtherIcons/Color/clubhouse.png'
import SignalBW from './img/OtherIcons/BW/signal.png'
import SignalColor from './img/OtherIcons/Color/signal.png'
import VKBW from './img/OtherIcons/BW/vk.png'
import VKColor from './img/OtherIcons/Color/vk.png'
import SpotifyBW from './img/OtherIcons/BW/spotify.png'
import SpotifyColor from './img/OtherIcons/Color/spotify.png'
import TwitchBW from './img/OtherIcons/BW/twitch.png'
import TwitchColor from './img/OtherIcons/Color/twitch.png'
import HomeBW from './img/OtherIcons/BW/home.png'
import HomeColor from './img/OtherIcons/Color/home.png'
import BannerIcon from './img/OtherIcons/Other/banner-icon.png'
import ButtonIcon from './img/OtherIcons/Other/button-icon.png'
import PaintIcon from './img/OtherIcons/Other/paint.png'
// import LogoIcon from './img/OtherIcons/Other/logo-icon.png'
// import TitleIcon from './img/OtherIcons/Other/title-icon.png'

import BaseIcon from './img/OtherIcons/Other/base.png'
import TitleIcon from './img/OtherIcons/Other/title.png'
import LogoIcon from './img/OtherIcons/Other/logo.png'
import BannerIcon1 from './img/OtherIcons/Other/banner.png'

export const customIcons = css({
  variants: {
    type: {
      bw: {
        '.material-icons-custom': {
          width: '100%',
          height: 24,
          display: 'block',
          overflow: 'hidden',
          color: 'transparent',
          marginBottom: 10,

          '&.facebook-bw': {
            backgroundImage: `url(${FacebookBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.twitter-bw': {
            backgroundImage: `url(${TwitterBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.instagram-bw': {
            backgroundImage: `url(${InstagramBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.linkedin-bw': {
            backgroundImage: `url(${LinkedInBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.google-bw': {
            backgroundImage: `url(${GoogleBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.youtube-bw': {
            backgroundImage: `url(${YouTubeBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.apple-bw': {
            backgroundImage: `url(${AppleBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.snapchat-bw': {
            backgroundImage: `url(${SnapchatBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.pinterest-bw': {
            backgroundImage: `url(${PinterestBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.medium-bw': {
            backgroundImage: `url(${MediumBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.github-bw': {
            backgroundImage: `url(${GitHubBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.skype-bw': {
            backgroundImage: `url(${SkypeBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.figma-bw': {
            backgroundImage: `url(${FigmaBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.dribbble-bw': {
            backgroundImage: `url(${DribbbleBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.reddit-bw': {
            backgroundImage: `url(${RedditBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.discord-bw': {
            backgroundImage: `url(${DiscordBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.tiktok-bw': {
            backgroundImage: `url(${TikTokBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.tumblr-bw': {
            backgroundImage: `url(${TumblrBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.telegram-bw': {
            backgroundImage: `url(${TelegramBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.clubhouse-bw': {
            backgroundImage: `url(${ClubHouseBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.signal-bw': {
            backgroundImage: `url(${SignalBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.vk-bw': {
            backgroundImage: `url(${VKBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.spotify-bw': {
            backgroundImage: `url(${SpotifyBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.twich-bw': {
            backgroundImage: `url(${TwitchBW})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
        },
      },
      color: {
        '.material-icons-custom': {
          width: '100%',
          height: 24,
          display: 'block',
          overflow: 'hidden',
          color: 'transparent',
          marginBottom: 10,

          '&.facebook-color': {
            backgroundImage: `url(${FacebookColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.twitter-color': {
            backgroundImage: `url(${TwitterColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.instagram-color': {
            backgroundImage: `url(${InstagramColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.linkedin-color': {
            backgroundImage: `url(${LinkedInColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.google-color': {
            backgroundImage: `url(${GoogleColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.youtube-color': {
            backgroundImage: `url(${YouTubeColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.apple-color': {
            backgroundImage: `url(${AppleColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.snapchat-color': {
            backgroundImage: `url(${SnapchatColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.pinterest-color': {
            backgroundImage: `url(${PinterestColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.medium-color': {
            backgroundImage: `url(${MediumColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.github-color': {
            backgroundImage: `url(${GitHubColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.skype-color': {
            backgroundImage: `url(${SkypeColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.figma-color': {
            backgroundImage: `url(${FigmaColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.dribbble-color': {
            backgroundImage: `url(${DribbbleColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.reddit-color': {
            backgroundImage: `url(${RedditColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.discord-color': {
            backgroundImage: `url(${DiscordColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.tiktok-color': {
            backgroundImage: `url(${TikTokColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.tumblr-color': {
            backgroundImage: `url(${TumblrColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.telegram-color': {
            backgroundImage: `url(${TelegramColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.clubhouse-color': {
            backgroundImage: `url(${ClubHouseColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.signal-color': {
            backgroundImage: `url(${SignalColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.vk-color': {
            backgroundImage: `url(${VKColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.spotify-color': {
            backgroundImage: `url(${SpotifyColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
          '&.twich-color': {
            backgroundImage: `url(${TwitchColor})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          },
        },
      },
      others: {
        '.material-icons-custom': {
          width: 11,
          height: 12,
          display: 'block',
          overflow: 'hidden',
          color: 'transparent',

          '&.home-color': {
            backgroundImage: `url(${HomeColor})`,
            backgroundRepeat: 'no-repeat',
          },

          '&.home-bw': {
            backgroundImage: `url(${HomeBW})`,
            backgroundRepeat: 'no-repeat',
          },

          '&.banner-icon': {
            backgroundImage: `url(${BannerIcon})`,
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: 24,
            backgroundPositionX: 'center',
          },

          '&.button-icon': {
            backgroundImage: `url(${BaseIcon})`,
            backgroundRepeat: 'no-repeat',
            width: 60,
            height: 24,
            backgroundSize: 'contain',
            backgroundPositionX: 'center',
          },

          '&.title-icon': {
            backgroundImage: `url(${TitleIcon})`,
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: 25,
            backgroundPositionX: 'center',
            backgroundSize: 'contain',
          },

          '&.banner1-icon': {
            backgroundImage: `url(${BannerIcon1})`,
            backgroundRepeat: 'no-repeat',
            width: 91,
            height: 25,
            backgroundPositionX: 'center',
            backgroundSize: 'contain',
          },

          '&.logo-icon': {
            backgroundImage: `url(${LogoIcon})`,
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: 24,
            backgroundSize: 'contain',
            backgroundPositionX: 'center',
          },

          // '&.title-icon': {
          //   backgroundImage: `url(${TitleIcon})`,
          //   backgroundRepeat: 'no-repeat',
          //   width: '100%',
          //   height: 24,
          //   backgroundPositionX: 'center',
          // },
        },
      },
    },
  },
})

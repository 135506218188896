import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import LoginPage from "./login-page";
import { CognitoContext } from "../context/congnito-context";
import { Input, ButtonComponent } from "cashin-components";
import { Col, Container, Row } from "react-bootstrap";
import { Footer } from "../footer";

const defaultState = {
  emailValue: "",
  emailError: "",
  passwordValue: "",
  passwordError: "",
  isInvalidEmail: false,
  isInvalidPassword: false,
  isLoading: false,
};

const simulateNetworkRequest = () => {
  return new Promise((res) => setTimeout(res, 1000));
};

const Login = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState);
  const [shouldSetNewPassword, setShouldSetNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  const context = useContext(CognitoContext);

  const handleChange = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    });
  };

  const handleKeyDown = (key) => {
    if (key === "Enter") {
      handleAccessAccount();
    }
  };

  const handleAccessAccount = () => {
    const isEmail = (email) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    if (!isEmail(ownState.emailValue)) {
      setOwnState({
        ...ownState,
        isInvalidEmail: true,
        emailError: "Digite um e-mail válido",
        isInvalidPassword: false,
        passwordError: "",
      });
    } else if (ownState.passwordValue === "") {
      setOwnState({
        ...ownState,
        isInvalidEmail: false,
        emailError: "",
        isInvalidPassword: true,
        passwordError: "Digite uma senha",
      });
    } else {
      setOwnState({
        ...ownState,
        isInvalidEmail: false,
        emailError: "",
        isInvalidPassword: false,
        passwordError: "",
        isLoading: true,
      });
    }
  };

  useEffect(() => {
    ownState.isLoading &&
      simulateNetworkRequest().then(async () => {
        setOwnState({
          ...ownState,
          isLoading: false,
        });

        var response = await context.signIn(
          ownState.emailValue,
          ownState.passwordValue
        );

        if (response === "NEW_PASSWORD_REQUIRED") {
          setShouldSetNewPassword(true);
        }
      });
  }, [ownState.isLoading]);

  const handleConfirmpassword = async () => {
    setIsLoadingUser(true);
    await context.confirmUser(newPassword);
    setIsLoadingUser(false);
  };

  return (
    <>
      {shouldSetNewPassword ? (
        <Container fluid className="p-0">
          <Container>
            <Row>
              <Col xs={6} className={styles.login({ login: "imgLogin" })}>
                <div
                  className={styles.logos({
                    logo: "largeSecondary",
                  })}
                />
              </Col>
              <Col
                xs={6}
                className="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h1 className={styles.tipography({ headings: "mediumBold" })}>
                    Bem-vindo a{" "}
                    <span className={styles.colors({ colors: "primaryPure" })}>
                      CashIn
                    </span>{" "}
                    <p className={styles.colors({ colors: "primaryPure" })}>
                      Soma
                    </p>
                  </h1>
                  <p
                    className={`
                    ${styles.tipography({ paragraphs: "mediumRegular" })}
                    ${styles.colors({ colors: "neutralColorLowMedium" })}
                  `}
                  >
                    Vamos criar sua senha? <br />
                    Lembrando que a senha deve ter 8 caracteres com letras
                    maiúsculas, minusculas e números
                  </p>
                  <Input
                    id="txtPassword"
                    inputName="passwordValue"
                    className={styles.inputs({ input: "default" })}
                    idError="fdbEmailError"
                    inputType="password"
                    labelName="Senha"
                    placeholder="Informe sua senha"
                    styles={styles}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <ButtonComponent
                    className={`${styles.buttonStandardLarge({
                      large: "primary",
                    })} w-100 mb-5`}
                    text="Entrar"
                    isLoading={isLoadingUser}
                    styles={styles}
                    onClick={handleConfirmpassword}
                  />
                  <Footer styles={styles} />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      ) : (
        <LoginPage
          styles={styles}
          state={ownState}
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          handleAccessAccount={handleAccessAccount}
        />
      )}
    </>
  );
};

Login.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default Login;

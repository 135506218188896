import { css } from './CreateStitches';

export const headerComponent = css({
  padding: '16px 32px',
  display: 'flex',
  borderBottom: 'solid 1px $neutralColorHighLight',
  width: 'auto',

  '.menu-drop-custom': {
    width: '190px',
    transform: 'none !important',
    inset: 'unset !important',
    marginLeft: '-50px',
    marginTop: 4
  },

  '& .icon-header': {
    marginRight: 16,
  },

  '& .user-greeting-area': {
    width: 300,
    marginRight: 67,
  },

  '& .user-area-menu': {
    display: 'flex',
  },

  '& .dropdown': {
    margin: '0 16px',

    '& button': {
      padding: 0,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      border: 'none',

      '&::after': {
        content: 'keyboard_arrow_down',
        fontFamily: '"Material Icons"',
        border: 'none',
      },
    },

    '& a': {
      color: '$neutralColorLowPure',
    },
  },
});

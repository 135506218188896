import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { Input, Select, ButtonComponent } from 'cashin-components'
import HistoryData from './history-data'
import { styles } from 'cashin-design-system/Styles/Styles'
import CustomPagination from '../../Components/CustomPagination'
import api from '../../API/api'
import qs from 'qs'
import { Typeahead } from 'react-bootstrap-typeahead'
import useCampaignIdentificationInfo from '../../API/queries/campaign/useCampaignIdentificationInfo'

const ConsultHistory = function ({ isManagementAction = false, currentTab }) {
  const [campaignIdFilter, setCampaignIdFilter] = useState(null)
  const [currentPagination, setCurrentPagination] = useState(0)
  const [data, setData] = useState([])
  const [isSearchLoading, setSearchIsLoading] = useState(false)
  const [filename, setFilename] = useState('')
  const [dateRange, setDateRange] = useState(null)
  const [type, setType] = useState(null)
  const [status, setStatus] = useState(null)

  const {
    isLoading,
    error,
    data: campaignIdentificationInfoData,
  } = useCampaignIdentificationInfo()

  useEffect(() => {
    search()
  }, [isManagementAction])

  const search = async () => {
    setData([])
    var queryString = qs.stringify(
      {
        'Pagination.Limit': 10,
        'Pagination.Offset': currentPagination * 10,
        'Filter.Filename': filename,
        'Filter.SendDate': dateRange,
        'Filter.CampaignId': campaignIdFilter,
        'Filter.Status': status,
        'Filter.ValidateStatus': type,
      },
      { skipNulls: true }
    )

    setSearchIsLoading(true)
    var response = await api.get(`/campaign-validate/search?${queryString}`)
    setData(response)
    setSearchIsLoading(false)
  }

  const handleCampaignFilterSelected = (item) =>
    setCampaignIdFilter(item?.[0]?.id)

  const handleOnPaginationChange = (newPage) => {
    // fetchNextPage({ pageParam: newPage - 1 });
    setCurrentPagination(newPage - 1)
  }

  useEffect(() => {
    search()
  }, [currentPagination])

  return (
    <Row>
      <Col xs={4}>
        <Input
          id='txtFile'
          inputName='fileValue'
          className={styles.inputs({ input: 'default' })}
          idError='fdbFileError'
          inputType='text'
          labelName='Arquivo'
          placeholder='Pesquise pelo nome do arquivo'
          isInvalid={false}
          styles={styles}
          onChange={(e) => setFilename(e.target.value)}
        />
      </Col>
      <Col
        xs={4}
        className={`
          ${styles.inputs({ datePicker: 'datePickerDefault' })}
          d-flex align-items-end
        `}
      >
        <Input
          id='txtDateRangeSent'
          idError='fdbDateRangeSent'
          labelName='Data de envio'
          // errorText={state.dateRangeError}
          styles={styles}
          isDatePicker={true}
          required={true}
          // isInvalid={state.isInvalidDateRange}
          selectsRange={false}
          startDate={dateRange}
          monthsShown={1}
          setDateRange={setDateRange}
        />
      </Col>
      <Col xs={4}>
        <Form.Label
          className={styles.tipography({ paragraphs: 'smallBold' })}
          style={{ paddingTop: 1 }}
        >
          Campanha
        </Form.Label>
        <Typeahead
          labelKey={(option) => option.description}
          filterBy={['id']}
          options={campaignIdentificationInfoData}
          placeholder={isLoading && 'Carregando...'}
          label='Campanha'
          disabled={isLoading}
          style={{ height: 44 }}
          onChange={handleCampaignFilterSelected}
        />
      </Col>
      <Col xs={4}>
        <Select
          id='ddlResultApprovalFilter'
          inputName='resultApprovalValue'
          idError='fdbResultApprovalError'
          labelName='Aprovação de resultado'
          options={[
            {
              value: null,
              label: 'Todos',
            },
            {
              value: 0,
              label: 'Parcial',
            },
            {
              value: 1,
              label: 'Total',
            },
          ]}
          styles={styles}
          onChange={(e) => setType(e.value)}
        />
      </Col>
      <Col xs={4}>
        <Select
          id='ddlCreditFilter'
          inputName='creditValue'
          idError='fdbCreditError'
          labelName='Crédito'
          options={[
            {
              value: null,
              label: 'Todos',
            },
            {
              value: 0,
              label: 'Em processamento',
            },
            {
              value: 1,
              label: 'Concluído',
            },
            {
              value: 2,
              label: 'Reprovado',
            },
            {
              value: 3,
              label: 'Finalizado',
            },
            {
              value: 4,
              label: 'Finalizado e reprovado',
            },
            {
              value: 5,
              label: 'Erro na planilha',
            },
          ]}
          styles={styles}
          onChange={(e) => setStatus(e.value)}
        />
      </Col>
      <Col xs>
        <ButtonComponent
          className={`${styles.buttonStandardLarge({
            large: 'primary',
          })}
          ${false &&
            styles.buttonStandardLarge({
              large: 'primaryLoading',
            })
            } w-100 mt-4 mb-3`}
          text='Buscar'
          isLoading={false}
          styles={styles}
          onClick={search}
        />
      </Col>
      <Col xs={12} className='my-4'>
        <HistoryData
          data={data}
          isManagementAction={isManagementAction}
          research={search}
          currentTab={currentTab}
        />
      </Col>

      {isSearchLoading && (
        <Row>
          <Col className='offset-5'>
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </Col>
        </Row>
      )}

      {!isSearchLoading && data?.length <= 0 && (
        <Row>
          <Col className='offset-4'>
            <h3>Sem apurações disponíveis</h3>
          </Col>
        </Row>
      )}

      <CustomPagination
        totalPages={
          // (data?.pages?.[0]?.totalItems || 0) / 10 ||
          (data?.totalItems || 0) / 10
        }
        onPaginationChange={handleOnPaginationChange}
        currentPage={currentPagination + 1}
        setCurrentPage={setCurrentPagination}
      />
    </Row>
  )
}

ConsultHistory.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default ConsultHistory

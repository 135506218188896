import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import { Check } from 'cashin-components'
import { CampaignContext } from '../../../context/campaign-context'
import { styles } from 'cashin-design-system/Styles/Styles'

const ComunicationPlatform = () => {
  const campaignContext = useContext(CampaignContext)
  const handleOnChange = (e) => {
    campaignContext.setParticipants([]);
    campaignContext.setCommunicationType(e.target.id);
  };

  return (
    <Col xs={12}>
      <h3 className={`${styles.tipography({ paragraphs: 'largeBold' })}`}>
        Onde a sua campanha vai acontecer? Escolha a plataforma.
      </h3>
      <div className='d-flex tw-pt-3'>
        <Check
          id='email'
          name='swtEngagementGroup'
          label='E-mail'
          type='switch'
          checked={campaignContext.communicationType === 'email'}
          className={`
            ${styles.check({ switch: 'normal' })}
            ps-0 me-3
          `}
          onChange={handleOnChange}
        />
        <Check
          id='whatsapp'
          name='whatsapp'
          label='WhatsApp'
          type='switch'
          checked={campaignContext.communicationType === 'whatsapp'}
          className={`
            ${styles.check({ switch: 'normal' })}
            ps-0 me-3
          `}
          onChange={handleOnChange}
        />
        <Check
          id='app'
          name='app'
          label='App'
          type='switch'
          checked={campaignContext.communicationType === 'app'}
          className={`
            ${styles.check({ switch: 'normal' })}
            ps-0 me-3
          `}
          onChange={handleOnChange}
        />
      </div>
    </Col>
  )
}

ComunicationPlatform.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handlePlatformComunication: PropTypes.func,
}

export default ComunicationPlatform

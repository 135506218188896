import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Input, ButtonComponent } from "cashin-components";

const ForgotPasswordForm = ({
  styles,
  state,
  handleKeyDown,
  handleChange,
  handleRecoverPassword,
  handleBackToLogin,
  setUsername,
}) => {
  return (
    <Fragment>
      <h1 className={styles.tipography({ headings: "mediumBold" })}>
        Vamos mudar a senha?
      </h1>
      <p>
        <br />
        Lembrando que a senha deve ter 8 caracteres com letras maiúsculas,
        minusculas e números
      </p>
      <Input
        id="txtPassword"
        inputName="passwordValue"
        className={styles.inputs({ input: "default" })}
        idError="fdbEmailError"
        inputType="text"
        labelName="Email"
        placeholder="Informe seu email"
        styles={styles}
        onChange={(e) => setUsername(e.target.value)}
      />
      <Input
        id="txtPassword"
        inputName="emailValue"
        className={styles.inputs({ input: "default" })}
        idError="fdbEmailError"
        inputType="password"
        labelName="Senha"
        placeholder="Informe sua senha"
        styles={styles}
        value={state.emailValue}
        onKeyDown={(e) => handleKeyDown(e.key)}
        onChange={(e) => handleChange(e)}
      />
      <ButtonComponent
        className={`${styles.buttonStandardLarge({
          large: "primary",
        })}
        ${
          state.isLoading &&
          styles.buttonStandardLarge({
            large: "primaryLoading",
          })
        } w-100 mb-3`}
        hasIcon={state.hasIcon}
        isFullWidthIconButton={true}
        iconName="send"
        text="Trocar senha"
        isLoading={state.isLoading}
        styles={styles}
        onClick={() => handleRecoverPassword()}
      />
      <ButtonComponent
        className={`${styles.buttonStandardLarge({
          large: "secondary",
        })} w-100 mb-3`}
        hasIcon={true}
        isFullWidthIconButton={true}
        iconName="west"
        text="Voltar para o login"
        onClick={() => handleBackToLogin()}
      />
    </Fragment>
  );
};

ForgotPasswordForm.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleRecoverPassword: PropTypes.func,
  handleBackToLogin: PropTypes.func,
};

export default ForgotPasswordForm;

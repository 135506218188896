import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Footer } from "../footer";
import ForgotPasswordForm from "./forgot-password-form";
import ForgotPasswordConfirmation from "./forgot-password-confirmation";
import { useParams } from "react-router";
import qs from "qs";

const ForgotPasswordPage = ({
  styles,
  state,
  handleKeyDown,
  handleChange,
  handleRecoverPassword,
  handleBackToLogin,
  setUsername,
}) => {
  return (
    <Container fluid className="p-0">
      <Container>
        <Row>
          <Col xs={6} className={styles.login({ login: "imgLogin" })}>
            <div
              className={styles.logos({
                logo: "largeSecondary",
              })}
            />
          </Col>
          <Col
            xs={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              {state.forgotPasswordForm ? (
                <ForgotPasswordForm
                  styles={styles}
                  state={state}
                  handleKeyDown={handleKeyDown}
                  handleChange={handleChange}
                  handleRecoverPassword={handleRecoverPassword}
                  handleBackToLogin={handleBackToLogin}
                  setUsername={setUsername}
                />
              ) : (
                <ForgotPasswordConfirmation
                  styles={styles}
                  state={state}
                  handleBackToLogin={handleBackToLogin}
                />
              )}
              <Footer styles={styles} />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

ForgotPasswordPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleRecoverPassword: PropTypes.func,
  handleBackToLogin: PropTypes.func,
};

export default ForgotPasswordPage;

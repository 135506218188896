import { css } from './CreateStitches';

export const header = css({
  backgroundColor: '$neutralColorLowPure',
  height: 200,
  marginBottom: 30,
  paddingLeft: 40,
  paddingRight: 40,

  '@media (max-width: 1366px)': {
    height: 'auto',
  },

  img: {
    width: 180,
    height: 90,
  },

  '.navbar': {
    height: 100,

    '.navbar-toggler': {
      zIndex: '999',

      '&:focus': {
        boxShadow: 'none',
        outlineStyle: 'solid',
        outlineWidth: '3px',
        outlineColor: '$feedbackColorHelperPure',
      },
    },

    '.navbar-toggler-icon': {
      fontFamily: '"Material Icons Round"',
      backgroundImage: 'none',
      color: '$neutralColorHighPure',
      fontSize: '$fontSizeHSM',
      width: 'auto',
      height: 'auto',
    },

    '@media (max-width: 1366px)': {
      height: 'auto',
    },

    a: {
      height: 100,

      '@media (max-width: 1366px)': {
        height: 'auto',
      },

      display: 'flex',
      alignItems: 'center',
      color: '$neutralColorHighPure !important',
      textDecoration: 'none',
      fontFamily: '$fontFamilyHighBaseRegular',

      '&:hover, &:focus, &.active': {
        backgroundColor: '$neutralColorLowDark',
        textDecoration: 'underline',
        outline: 0,
      },
    },

    '.dropdown-menu': {
      top: 110,
      left: 0,
      padding: 0,

      '@media (max-width: 1366px)': {
        backgroundColor: 'transparent',
        border: 'none',
      },

      a: {
        height: 'auto',
        color: '$neutralColorLowPure !important',
        padding: '.25rem .5rem',

        '@media (max-width: 1366px)': {
          color: '$neutralColorHighPure !important',
        },

        '&:hover, &:focus': {
          backgroundColor: '$neutralColorLowDark',
          color: '$neutralColorHighPure !important',
        },

        '&.active': {
          backgroundColor: '$neutralColorLowDark',
          textDecoration: 'underline',
          outline: 0,
          color: '$neutralColorHighPure !important',
        },
      },
    },
  },

  '.title-design-system': {
    '@media (max-width: 767px)': {
      justifyContent: 'center !important',
      borderTopStyle: 'solid',
      borderTopWidth: '$borderWidthHairLine',
      borderTopColor: '$neutralColorHighPure',
      marginTop: 20,
      paddingTop: 30,
      marginBottom: 20,
    },

    '.logo': {
      width: 201,
      height: 'auto',
      justifySelf: 'end',
      marginBottom: 15,

      '@media (max-width: 767px)': {
        justifySelf: 'center !important',
      },
    },

    '.design-tokens': {
      width: 200,
      height: 24,

      '@media (max-width: 1366px)': {
        justifySelf: 'center !important',
      },
    },
  },
});

import qs from "qs";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
});

const validateIfUserExist = async (email) => {
  var queryString = qs.stringify(
    {
      "email": email     
    },
    { skipNulls: true }
  );

  return await api.get(`/admin/validate-user-exists?${queryString}`);
};

export default validateIfUserExist;

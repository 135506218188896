import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import FooterContent from './footer-content';

const FooterPage = ({
  styles,
  returnYear,
  profile,
  show,
  handleClose,
  handleShow,
}) => {
  return profile ? (
    <Row>
      <Col xs={12} className="footer-area py-3">
        <FooterContent
          styles={styles}
          returnYear={returnYear()}
          profile={profile}
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      </Col>
    </Row>
  ) : (
    <FooterContent
      styles={styles}
      returnYear={returnYear()}
      profile={profile}
      show={show}
      handleClose={handleClose}
      handleShow={handleShow}
    />
  );
};

FooterPage.propTypes = {
  styles: PropTypes.object,
  returnYear: PropTypes.func,
  profile: PropTypes.any,
  show: PropTypes.any,
  handleClose: PropTypes.func,
  handleShow: PropTypes.func,
};

export default FooterPage;

import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { CampaignContext } from '../context/campaign-context'
import { Col, Row } from 'react-bootstrap'
import { BreadcrumbComponent, StepsComponent } from 'cashin-components'
import CampaignRules from './steps/create-rules/campaign-rules'
import Participants from './steps/participants/participants'
import ChooseLayout from './steps/choose-layout/choose-layout'
import CampaignSummary from './steps/campaign-summary/campaign-summary'
import ModalApp from './steps/modal-app'

const CreateCampaignPage = ({
  styles,
  steps,
  campaignRules,
  state,
  startDateValue,
  endDateValue,
  onChangeStartDate,
  onChangeEndDate,
  handleKeyDown,
  handleChange,
  handleUploadChange,
  handleUploadChangeBatch,
  handleRuleType,
  handleChangeSwitch,
  handlePlatformComunication,
  handleStepBack,
  handleStepForward,
  handleDeleteFile,
  handleSaveAndContinue,
  layoutType,
  handleLayoutType,
  layoutSelection,
  handleLayoutSelector,
  show,
  showSendTest,
  showSendTestConfirmation,
  handleClose,
  handleCloseSendTest,
  handleCloseSendTestConfirmation,
  handleShow,
  handleShowSendTest,
  handleShowSendTestConfirmation,
  handleLayoutChoosen,
  handleSaveLayout,
  handleShotSendTest,
  handleSaveDraft,
  wppMessageAttributes,
  setWppMessageAttributes,
  appMessageAttributes,
  setAppMessageAttributes,
}) => {
  const campaignContext = useContext(CampaignContext)
  const [isAppModal, setIsAppModal] = useState(false)

  return (
    <>
      <ModalApp
        styles={styles}
        state={state}
        show={isAppModal}
        onHide={() => setIsAppModal(false)}
        next={() => {
          setIsAppModal(false)
          handleStepForward()
        }}
      />
      <Row className={styles.steps()}>
        <Col xs={12}>
          <Row>
            <Col xs={12} className='pt-4'>
              <BreadcrumbComponent
                hasIcon={true}
                styles={styles}
                initialIcon='home-color'
                initialNode='Dashboard'
                breadcrumbData={state.breadcrumb}
              />
            </Col>
            <Col xs={6}>
              <h1
                className={`
                ${styles.tipography({ headings: 'smallBold' })}
                my-3
              `}
              >
                Criar campanha
              </h1>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
              {/* {state.activeStep === 5 && ( */}
              {/* <ButtonComponent
                className={`
                  ${styles.buttonStandardLarge({
                  large: 'secondary',
                })}
                  mb-3 me-3
                `}
                hasIcon={true}
                iconName='save'
                text='Salvar rascunho'
                styles={styles}
                disabled={
                  campaignContext.campaignState?.name === '' ||
                  !campaignContext.campaignState?.periodDate?.from ||
                  !campaignContext.campaignState?.periodDate?.to
                }
                onClick={() => handleSaveDraft()}
              /> */}
              {/* )} */}
            </Col>
            <Col xs={12}>
              <div
                className={`
                ${styles.border({ borderBottomWidth: 'thick' })}
                page-border
              `}
              />
            </Col>
            <StepsComponent styles={styles} state={state} steps={steps} />
            {state.activeStep === 1 && (
              <Participants
                styles={styles}
                state={state}
                steps={steps}
                handleStepBack={handleStepBack}
                handleStepForward={handleStepForward}
                handleSaveAndContinue={handleSaveAndContinue}
                handleUploadChangeBatch={handleUploadChangeBatch}
                handleDeleteFile={handleDeleteFile}
              />
            )}
            {state.activeStep === 2 && (
              <CampaignRules
                styles={styles}
                steps={steps}
                campaignRules={campaignRules}
                state={state}
                startDateValue={startDateValue}
                endDateValue={endDateValue}
                onChangeStartDate={onChangeStartDate}
                onChangeEndDate={onChangeEndDate}
                handleKeyDown={handleKeyDown}
                handleChange={handleChange}
                handleUploadChange={handleUploadChange}
                handleRuleType={handleRuleType}
                handleChangeSwitch={handleChangeSwitch}
                handleStepBack={handleStepBack}
                handleStepForward={() => {
                  if (campaignContext.communicationType === 'app') {
                    setIsAppModal(true)
                  } else {
                    handleStepForward()
                  }
                }}
                handleSaveAndContinue={handleSaveAndContinue}
              />
            )}
            {state.activeStep === 3 && (
              <ChooseLayout
                styles={styles}
                state={state}
                steps={steps}
                handleStepBack={handleStepBack}
                handleStepForward={handleStepForward}
                handleSaveAndContinue={handleSaveAndContinue}
                layoutType={layoutType}
                handleLayoutType={handleLayoutType}
                layoutSelection={layoutSelection}
                handleLayoutSelector={handleLayoutSelector}
                show={show}
                handleClose={handleClose}
                handleShow={handleShow}
                handleLayoutChoosen={handleLayoutChoosen}
                handleSaveLayout={() => handleSaveLayout(handleStepForward)}
                wppMessageAttributes={wppMessageAttributes}
                appMessageAttributes={appMessageAttributes}
                setWppMessageAttributes={setWppMessageAttributes}
              />
            )}
            {state.activeStep === 4 && (
              <CampaignSummary
                styles={styles}
                state={state}
                steps={steps}
                handleStepBack={handleStepBack}
                handleStepForward={handleStepForward}
                handleSaveAndContinue={handleSaveAndContinue}
                showSendTest={showSendTest}
                showSendTestConfirmation={showSendTestConfirmation}
                handleCloseSendTest={handleCloseSendTest}
                handleCloseSendTestConfirmation={
                  handleCloseSendTestConfirmation
                }
                handleShowSendTest={handleShowSendTest}
                handleShowSendTestConfirmation={handleShowSendTestConfirmation}
                handleShotSendTest={handleShotSendTest}
              />
            )}
          </Row>
        </Col>
      </Row>
    </>
  )
}

CreateCampaignPage.propTypes = {
  styles: PropTypes.object,
  steps: PropTypes.array,
  campaignRules: PropTypes.array,
  state: PropTypes.object,
  startDateValue: PropTypes.any,
  endDateValue: PropTypes.any,
  onChangeStartDate: PropTypes.any,
  onChangeEndDate: PropTypes.any,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleUploadChange: PropTypes.func,
  handleUploadChangeBatch: PropTypes.func,
  handleRuleType: PropTypes.func,
  handleChangeSwitch: PropTypes.func,
  handlePlatformComunication: PropTypes.func,
  handleStepBack: PropTypes.func,
  handleStepForward: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  handleSaveAndContinue: PropTypes.func,
  layoutType: PropTypes.array,
  handleLayoutType: PropTypes.func,
  layoutSelection: PropTypes.array,
  handleLayoutSelector: PropTypes.func,
  show: PropTypes.any,
  showSendTest: PropTypes.any,
  showSendTestConfirmation: PropTypes.any,
  handleClose: PropTypes.func,
  handleCloseSendTest: PropTypes.func,
  handleCloseSendTestConfirmation: PropTypes.func,
  handleShow: PropTypes.func,
  handleShowSendTest: PropTypes.func,
  handleShowSendTestConfirmation: PropTypes.func,
  handleLayoutChoosen: PropTypes.func,
  handleSaveLayout: PropTypes.func,
  handleShotSendTest: PropTypes.func,
}

export default CreateCampaignPage

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RecoveryAccessPage from './recovery-access-page';

const defaultState = {
  newPasswordValue: '',
  confirmNewPasswordValue: '',
  newPasswordError: '',
  confirmNewPasswordError: '',
  isInvalidNewPassword: false,
  isInvalidConfirmNewPassword: false,
  hasIcon: false,
  isLoading: false,
  recoverPasswordForm: true,
};

const simulateNetworkRequest = () => {
  return new Promise((res) => setTimeout(res, 1000));
};

const RecoveryAccess = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState);

  const handleChange = ({ target: { name, value } }) => {
    setOwnState({
      ...ownState,
      [name]: value,
    });
  };

  const handleKeyDown = (key) => {
    if (key === 'Enter') {
      handleRecoverPassword();
    }
  };

  const handleRecoverPassword = () => {
    const isValidPassword =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(
        ownState.newPasswordValue
      );

    if (!isValidPassword) {
      setOwnState({
        ...ownState,
        newPasswordError: 'Digite uma senha que atenda aos requisitos míminos',
        confirmNewPasswordError: '',
        isInvalidNewPassword: true,
        isInvalidConfirmNewPassword: false,
      });
    } else if (ownState.newPasswordValue !== ownState.confirmNewPasswordValue) {
      setOwnState({
        ...ownState,
        newPasswordError: '',
        confirmNewPasswordError: 'As senhas não conferem',
        isInvalidNewPassword: false,
        isInvalidConfirmNewPassword: true,
      });
    } else {
      setOwnState({
        ...ownState,
        newPasswordError: '',
        confirmNewPasswordError: '',
        isInvalidNewPassword: false,
        isInvalidConfirmNewPassword: false,
        isLoading: true,
      });
    }
  };

  const handleBackToLogin = () => {
    history.push('/login');
    history.go('/login');
  };

  useEffect(() => {
    ownState.isLoading &&
      simulateNetworkRequest().then(() => {
        setOwnState({
          ...ownState,
          hasIcon: true,
          isLoading: false,
          recoverPasswordForm: false,
        });
      });
  }, [ownState.isLoading]);

  return (
    <RecoveryAccessPage
      styles={styles}
      state={ownState}
      handleChange={handleChange}
      handleKeyDown={handleKeyDown}
      handleRecoverPassword={handleRecoverPassword}
      handleBackToLogin={handleBackToLogin}
    />
  );
};

RecoveryAccess.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default RecoveryAccess;

import qs from "qs";
import api from "../../api";

const companySearch = async (currentPagination, companyId, status) => {
  var queryString = qs.stringify(
    {
      "Filter.companyId": companyId || null,
      "Filter.status": status || null,
      "Pagination.Limit": 10,
      "Pagination.Offset": currentPagination * 10,
    },
    { skipNulls: true }
  );

  return await api.get(`/company/search?${queryString}`);
};

export default companySearch;

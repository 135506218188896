import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Tab } from 'react-bootstrap'
import Batch from './batch'
import Manual from './manual'
import { Box } from '../../Components'

const Participants = ({ styles, state, campaign, getCampaignById }) => {
  return (
    <div className={`${styles.tabs({ tabsType: 'verticalTabs' })}`}>
      <Tab.Container id='left-tabs-example' defaultActiveKey='0'>
        <Row>
          <Col xs>
            <Box>
              <Tab.Content>
                <Tab.Pane eventKey='0'>
                  <Manual
                    styles={styles}
                    state={state}
                    campaign={campaign}
                    getCampaignById={getCampaignById}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='1'>
                  <Batch styles={styles} state={state} campaign={campaign} />
                </Tab.Pane>
              </Tab.Content>
            </Box>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
}

Participants.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
}

export default Participants

import { css } from './CreateStitches';

export const card = css({
  border: 'solid 1px $neutralColorHighMedium',
  borderRadius: '$borderRadiusSM',
  backgroundColor: '$neutralColorHighPure',
  marginBottom: 20,
  padding: '$spacingXXXS $spacingXXS',

  '&:nth-child(even)': {
    backgroundColor: '$neutralColorHighLight',
  },

  '& .img-area': {
    borderRadius: '$borderRadiusSM',
    width: 64,
    height: 64,
    backgroundColor: '$neutralColorLowLight',
    overflow: 'hidden',
    marginRight: '$spacingXXS',

    '& img': {
      width: 64,
      height: 64,

      '&[alt]': {
        color: '$neutralColorLowPure',
      },
    },
  },
});

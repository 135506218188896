import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-bootstrap'
import { Input, ButtonComponent, LinkComponent } from 'cashin-components'
import { Footer } from '../footer'

const LoginPage = ({
  styles,
  state,
  handleChange,
  handleKeyDown,
  handleAccessAccount,
}) => {
  return (
    <Container fluid className='p-0'>
      <Container>
        <Row>
          <Col xs={6} className={styles.login({ login: 'imgLogin' })}>
            <div
              className={styles.logos({
                logo: 'largeSecondary',
              })}
            />
          </Col>
          <Col
            xs={6}
            className='d-flex align-items-center justify-content-center'
          >
            <div>
              <h1 className={styles.tipography({ headings: 'mediumBold' })}>
                Bem-vindo à{' '}
                <span className={styles.colors({ colors: 'primaryPure' })}>
                  Cashin
                </span>{' '}
                <p className={styles.colors({ colors: 'primaryPure' })}>
                  Engaje
                </p>
              </h1>
              <p
                className={`
                  ${styles.tipography({ paragraphs: 'mediumRegular' })}
                  ${styles.colors({ colors: 'neutralColorLowMedium' })}
                `}
              >
                Acesse sua conta informando seu e-mail e senha.
              </p>
              <Input
                id='txtEmail'
                inputName='emailValue'
                className={styles.inputs({ input: 'default' })}
                idError='fdbEmailError'
                inputType='email'
                labelName='E-mail'
                placeholder='Informe seu e-mail'
                errorText={state.emailError}
                isInvalid={state.isInvalidEmail}
                styles={styles}
                value={state.emailValue}
                onKeyDown={(e) => handleKeyDown(e.key)}
                onChange={(e) => handleChange(e)}
              />
              <Input
                id='txtPassword'
                inputName='passwordValue'
                className={styles.inputs({ input: 'default' })}
                idError='fdbEmailError'
                inputType='password'
                labelName='Senha'
                placeholder='Informe sua senha'
                errorText={state.passwordError}
                isInvalid={state.isInvalidPassword}
                styles={styles}
                value={state.passwordValue}
                onKeyDown={(e) => handleKeyDown(e.key)}
                onChange={(e) => handleChange(e)}
              />
              <ButtonComponent
                className={`${styles.buttonStandardLarge({
                  large: 'primary',
                })}
                ${
                  state.isLoading &&
                  styles.buttonStandardLarge({
                    large: 'primaryLoading',
                  })
                } w-100 mb-5`}
                text='Entrar'
                isLoading={state.isLoading}
                styles={styles}
                onClick={() => handleAccessAccount()}
              />
              <LinkComponent
                className={`
                  ${styles.tipography({ paragraphs: 'mediumBold' })}
                `}
                to='/forgot-password'
                text='Esqueci minha senha'
              />
              <Footer styles={styles} />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

LoginPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  handleAccessAccount: PropTypes.func,
}

export default LoginPage

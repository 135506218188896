import { css } from './CreateStitches';
import transparentShape from './img/transparent-shape.png';

export const border = css({
  variants: {
    exampleSize: {
      sizeNormal: {
        width: 64,
        height: 64,
      },
      sizePill: {
        width: 94,
        height: 40,
      },
    },
    exampleBG: {
      blackColor: {
        backgroundColor: '$neutralColorLowPure',
      },
      whiteColor: {
        backgroundColor: '$neutralColorHighPure',
      },
      transparentImg: {
        backgroundImage: `url(${transparentShape})`,
        backgroundRepeat: 'no-repeat',
      },
    },
    borderWidth: {
      none: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthNone',
        borderColor: '$neutralColorLowPure',
      },
      hairline: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthHairLine',
        borderColor: '$neutralColorLowPure',
      },
      thin: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthThin',
        borderColor: '$neutralColorLowPure',
      },
      thick: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthThick',
        borderColor: '$neutralColorLowPure',
      },
      heavy: {
        borderStyle: 'solid',
        borderWidth: '$borderWidthHeavy',
        borderColor: '$neutralColorLowPure',
      },
    },
    borderTopWidth: {
      none: {
        borderTopStyle: 'solid',
        borderTopWidth: '$borderWidthNone',
        borderTopColor: '$neutralColorLowPure',
      },
      hairline: {
        borderTopStyle: 'solid',
        borderTopWidth: '$borderWidthHairLine',
        borderTopColor: '$neutralColorLowPure',
      },
      thin: {
        borderTopStyle: 'solid',
        borderTopWidth: '$borderWidthThin',
        borderTopColor: '$neutralColorLowPure',
      },
      thick: {
        borderTopStyle: 'solid',
        borderTopWidth: '$borderWidthThick',
        borderTopColor: '$neutralColorLowPure',
      },
      heavy: {
        borderTopStyle: 'solid',
        borderTopWidth: '$borderWidthHeavy',
        borderTopColor: '$neutralColorLowPure',
      },
    },
    borderRightWidth: {
      none: {
        borderRightStyle: 'solid',
        borderRightWidth: '$borderWidthNone',
        borderRightColor: '$neutralColorLowPure',
      },
      hairline: {
        borderRightStyle: 'solid',
        borderRightWidth: '$borderWidthHairLine',
        borderRightColor: '$neutralColorLowPure',
      },
      thin: {
        borderRightStyle: 'solid',
        borderRightWidth: '$borderWidthThin',
        borderRightColor: '$neutralColorLowPure',
      },
      thick: {
        borderRightStyle: 'solid',
        borderRightWidth: '$borderWidthThick',
        borderRightColor: '$neutralColorLowPure',
      },
      heavy: {
        borderRightStyle: 'solid',
        borderRightWidth: '$borderWidthHeavy',
        borderRightColor: '$neutralColorLowPure',
      },
    },
    borderBottomWidth: {
      none: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '$borderWidthNone',
        borderBottomColor: '$neutralColorLowPure',
      },
      hairline: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '$borderWidthHairLine',
        borderBottomColor: '$neutralColorLowPure',
      },
      thin: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '$borderWidthThin',
        borderBottomColor: '$neutralColorLowPure',
      },
      thick: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '$borderWidthThick',
        borderBottomColor: '$neutralColorLowPure',
      },
      heavy: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '$borderWidthHeavy',
        borderBottomColor: '$neutralColorLowPure',
      },
    },
    borderLeftWidth: {
      none: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '$borderWidthNone',
        borderLeftColor: '$neutralColorLowPure',
      },
      hairline: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '$borderWidthHairLine',
        borderLeftColor: '$neutralColorLowPure',
      },
      thin: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '$borderWidthThin',
        borderLeftColor: '$neutralColorLowPure',
      },
      thick: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '$borderWidthThick',
        borderLeftColor: '$neutralColorLowPure',
      },
      heavy: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '$borderWidthHeavy',
        borderLeftColor: '$neutralColorLowPure',
      },
    },
    borderRadius: {
      none: {
        borderRadius: '$borderRadiusNone',
      },
      sm: {
        borderRadius: '$borderRadiusSM',
      },
      md: {
        borderRadius: '$borderRadiusMD',
      },
      lg: {
        borderRadius: '$borderRadiusLG',
      },
      pill: {
        borderRadius: '$borderRadiusPill',
      },
      circular: {
        borderRadius: '$borderRadiusCircular',
      },
    },
    borderTopRadius: {
      none: {
        borderTopRadius: '$borderRadiusNone',
      },
      sm: {
        borderTopRadius: '$borderRadiusSM',
      },
      md: {
        borderTopRadius: '$borderRadiusMD',
      },
      lg: {
        borderTopRadius: '$borderRadiusLG',
      },
      pill: {
        borderTopRadius: '$borderRadiusPill',
      },
      circular: {
        borderTopRadius: '$borderRadiusCircular',
      },
    },
    borderRightRadius: {
      none: {
        borderRightRadius: '$borderRadiusNone',
      },
      sm: {
        borderRightRadius: '$borderRadiusSM',
      },
      md: {
        borderRightRadius: '$borderRadiusMD',
      },
      lg: {
        borderRightRadius: '$borderRadiusLG',
      },
      pill: {
        borderRightRadius: '$borderRadiusPill',
      },
      circular: {
        borderRightRadius: '$borderRadiusCircular',
      },
    },
    borderBottomRadius: {
      none: {
        borderBottomRadius: '$borderRadiusNone',
      },
      sm: {
        borderBottomRadius: '$borderRadiusSM',
      },
      md: {
        borderBottomRadius: '$borderRadiusMD',
      },
      lg: {
        borderBottomRadius: '$borderRadiusLG',
      },
      pill: {
        borderBottomRadius: '$borderRadiusPill',
      },
      circular: {
        borderBottomRadius: '$borderRadiusCircular',
      },
    },
    borderLeftRadius: {
      none: {
        borderLeftRadius: '$borderRadiusNone',
      },
      sm: {
        borderLeftRadius: '$borderRadiusSM',
      },
      md: {
        borderLeftRadius: '$borderRadiusMD',
      },
      lg: {
        borderLeftRadius: '$borderRadiusLG',
      },
      pill: {
        borderLeftRadius: '$borderRadiusPill',
      },
      circular: {
        borderLeftRadius: '$borderRadiusCircular',
      },
    },
    borderSpacing: {
      quarck: {
        borderWidth: '$borderQuarck',
      },
      nano: {
        borderWidth: '$borderNano',
      },
      xs: {
        borderWidth: '$borderXS',
      },
      sm: {
        borderWidth: '$borderSM',
      },
      md: {
        borderWidth: '$borderMD',
      },
      lg: {
        borderWidth: '$borderLG',
      },
    },
  },
});

import { css } from './CreateStitches';
import XLargePrimary from './img/Logos/xlarge-primary.png';
import LargePrimary from './img/Logos/large-primary.png';
import MediumPrimaryPurple from './img/Logos/medium-primary-purple.png';
import MediumPrimary from './img/Logos/medium-primary.png';
import SmallPrimary from './img/Logos/small-primary.png';
import XSmallPrimary from './img/Logos/xsmall-primary.png';
import XLargeSecondary from './img/Logos/xlarge-secondary.png';
import LargeSecondary from './img/Logos/large-secondary.png';
import MediumSecondary from './img/Logos/medium-secondary.png';
import SmallSecondary from './img/Logos/small-secondary.png';
import XSmallSecondary from './img/Logos/xsmall-secondary.png';
import XLargeTertiary from './img/Logos/xlarge-tertiary.png';
import LargeTertiary from './img/Logos/large-tertiary.png';
import MediumTertiary from './img/Logos/medium-tertiary.png';
import SmallTertiary from './img/Logos/small-tertiary.png';
import XSmallTertiary from './img/Logos/xsmall-tertiary.png';

export const logos = css({
  variants: {
    logo: {
      xLargePrimary: {
        backgroundImage: `url(${XLargePrimary})`,
        backgroundRepeat: 'no-repeat',
        width: 267,
        height: 64,
      },
      largePrimary: {
        backgroundImage: `url(${LargePrimary})`,
        backgroundRepeat: 'no-repeat',
        width: 200,
        height: 48,
      },
      mediumPrimary: {
        backgroundImage: `url(${MediumPrimary})`,
        backgroundRepeat: 'no-repeat',
        width: 134,
        height: 32,
      },
      mediumPrimaryPurple: {
        backgroundImage: `url(${MediumPrimaryPurple})`,
        backgroundRepeat: 'no-repeat',
        width: 168,
        height: 48,
      },
      smallPrimary: {
        backgroundImage: `url(${SmallPrimary})`,
        backgroundRepeat: 'no-repeat',
        width: 100,
        height: 24,
      },
      xsmallPrimary: {
        backgroundImage: `url(${XSmallPrimary})`,
        backgroundRepeat: 'no-repeat',
        width: 64,
        height: 64,
      },
      xLargeSecondary: {
        backgroundImage: `url(${XLargeSecondary})`,
        backgroundRepeat: 'no-repeat',
        width: 267,
        height: 64,
      },
      largeSecondary: {
        backgroundImage: `url(${LargeSecondary})`,
        backgroundRepeat: 'no-repeat',
        width: 200,
        height: 48,
      },
      mediumSecondary: {
        backgroundImage: `url(${MediumSecondary})`,
        backgroundRepeat: 'no-repeat',
        width: 134,
        height: 32,
      },
      smallSecondary: {
        backgroundImage: `url(${SmallSecondary})`,
        backgroundRepeat: 'no-repeat',
        width: 100,
        height: 24,
      },
      xsmallSecondary: {
        backgroundImage: `url(${XSmallSecondary})`,
        backgroundRepeat: 'no-repeat',
        width: 64,
        height: 64,
      },
      xLargeTertiary: {
        backgroundImage: `url(${XLargeTertiary})`,
        backgroundRepeat: 'no-repeat',
        width: 267,
        height: 64,
      },
      largeTertiary: {
        backgroundImage: `url(${LargeTertiary})`,
        backgroundRepeat: 'no-repeat',
        width: 200,
        height: 48,
      },
      mediumTertiary: {
        backgroundImage: `url(${MediumTertiary})`,
        backgroundRepeat: 'no-repeat',
        width: 134,
        height: 32,
      },
      smallTertiary: {
        backgroundImage: `url(${SmallTertiary})`,
        backgroundRepeat: 'no-repeat',
        width: 100,
        height: 24,
      },
      xsmallTertiary: {
        backgroundImage: `url(${XSmallTertiary})`,
        backgroundRepeat: 'no-repeat',
        width: 64,
        height: 64,
      },
    },
  },
});

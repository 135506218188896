import { css } from './CreateStitches';

export const buttonSquareMedium = css({
  fontFamily: '$fontFamilyHighBaseBold',
  border: 'none',
  borderRadius: '$borderRadiusCircular',
  textAlign: 'center',
  borderStyle: 'solid',
  borderWidth: '$borderWidthThin',

  '.icon-button': {
    justifySelf: 'start',
  },

  '.text-button-icon': {
    justifySelf: 'center',
    position: 'absolute',
  },

  '&.btn-layout-customizer': {
    display: 'unset !important',

    span: {
      display: 'block',
    },
  },

  variants: {
    medium: {
      primary: {
        backgroundColor: '$brandColorPrimaryPure',
        borderColor: '$brandColorPrimaryPure',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorPrimaryLight',
          borderColor: '$brandColorPrimaryLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
        '&.user-area-button': {
          backgroundColor: '$brandColorSecondaryDark',
          borderColor: '$brandColorSecondaryDark',
          pointerEvents: 'none',
        },
      },
      secondary: {
        backgroundColor: '$neutralColorHighLight',
        borderColor: '$neutralColorHighLight',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      ghost: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM !important',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          border: '$neutralColorHighLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          border: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          border: '$neutralColorHighLight',
          color: '$neutralColorLowLight',
        },
      },
      iconGhost: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          border: '$neutralColorHighLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          border: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          border: '$neutralColorHighLight',
          color: '$neutralColorLowLight',
        },
      },
      primaryHover: {
        backgroundColor: '$brandColorPrimaryLight',
        borderColor: '$brandColorPrimaryLight',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorPrimaryLight',
          borderColor: '$brandColorPrimaryLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      secondaryHover: {
        backgroundColor: '$neutralColorHighLight',
        borderColor: '$neutralColorLowPure',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      ghostHover: {
        backgroundColor: '$neutralColorHighLight',
        borderColor: '$neutralColorHighLight',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          border: '$neutralColorHighLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          border: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
      },
      ghostIconHover: {
        backgroundColor: '$neutralColorHighLight',
        borderColor: '$neutralColorHighLight',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorLowPure',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          border: '$neutralColorHighLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          border: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
      },
      primaryFocus: {
        backgroundColor: '$brandColorPrimaryDark',
        borderColor: '$brandColorPrimaryDark',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$brandColorPrimaryLight',
          borderColor: '$brandColorPrimaryLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      secondaryFocus: {
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorLowPure',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },
      },
      ghostFocus: {
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorLowPure',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          border: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
      },
      ghostIconFocus: {
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorLowPure',
        padding: 0,
        width: 40,
        height: 40,
        fontSize: '$fontSizeSM',
        color: '$neutralColorHighPure',
        lineHeight: '$lineHeightMD',
        textDecoration: 'none !important',

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          border: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
      },
      primaryLoading: {
        backgroundColor: '$brandColorPrimaryPure',
        borderColor: '$brandColorPrimaryPure',
        padding: 0,
        width: 40,
        height: 40,

        '&:hover': {
          backgroundColor: '$brandColorPrimaryLight',
          borderColor: '$brandColorPrimaryLight',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$brandColorPrimaryDark',
          borderColor: '$brandColorPrimaryDark',
          color: '$neutralColorLowPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },

        '& div': {
          width: 20,
          height: 20,
        },
      },
      secondaryLoading: {
        backgroundColor: '$neutralColorHighPure',
        borderColor: '$neutralColorHighMedium',
        padding: 0,
        width: 40,
        height: 40,

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorLowPure',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
          color: '$neutralColorHighPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
          color: '$neutralColorLowLight',
        },

        '& div': {
          width: 20,
          height: 20,
        },
      },
      ghostLoading: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: 0,
        width: 40,
        height: 40,

        '&:hover': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighLight',
        },
        '&:focus': {
          backgroundColor: '$neutralColorLowPure',
          borderColor: '$neutralColorLowPure',
        },
        '&:disabled, &.disabled': {
          backgroundColor: '$neutralColorHighLight',
          borderColor: '$neutralColorHighMedium',
        },

        '& div': {
          width: 20,
          height: 20,
        },
      },
    },
  },
});

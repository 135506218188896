import React, { useState } from 'react'
import Selector from './selector'
import Preview from './preview'
import { Row, Col } from 'react-bootstrap'

const Layout = ({ campaign }) => {
  const [index, setIndex] = useState(0)

  console.log(campaign)

  return (
    <div class='p-4 w-full' style={{ background: '#F5F5F5' }}>
      <Row className='w-full'>
        <Col xs={5}>
          <Selector campaign={campaign} setIndex={setIndex} index={index} />
        </Col>
        <Col xs={7}>
          <Preview campaign={campaign} index={index} />
        </Col>
      </Row>
    </div>
  )
}

export default Layout

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BusinessNegociationPage from "./business-negociation-page";
import moment from "moment";

let location = window.location.pathname.split("/");

const defaultState = {
  breadcrumb: [
    {
      url: "/customers",
      itemName: "Clientes",
    },
    {
      url: `/edit-customer/${location[location.length - 1]}`,
      itemName: "Editar cliente",
    },
    {
      isActive: true,
      itemName: "Negociação comercial",
    },
  ],
  tableHeader: [
    {
      headerTitle: "Quantidade de participantes",
    },
    {
      headerTitle: "Data",
    },
    {
      headerTitle: "Ação",
    },
  ],
  dataSet: [
    {
      shootsQuantity: 12345,
      date: "2022-12-31",
      amount: 10.0,
    },
    {
      shootsQuantity: 12345,
      date: "2022-12-31",
      amount: 10.0,
    },
    {
      shootsQuantity: 12345,
      date: "2022-12-31",
      amount: 10.0,
    },
  ],
};

const BusinessNegociation = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState);

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const showHeader = () => {
    try {
      return ownState.tableHeader.map((e, i) => {
        return <th key={i}>{e.headerTitle}</th>;
      });
    } catch (e) {
      return <th colSpan={ownState.tableHeader.length}>{e.message}</th>;
    }
  };

  const showData = () => {
    try {
      return ownState.dataSet.map((e, i) => {
        return (
          <tr key={i}>
            <td>{e.shootsQuantity}</td>
            <td>{moment(e.date).format("DD/MM/YYYY")}</td>
            <td>{formatter.format(e.amount)}</td>
          </tr>
        );
      });
    } catch (e) {
      return (
        <tr>
          <td colSpan={ownState.dataSet.length}>{e.message}</td>
        </tr>
      );
    }
  };

  const handleSave = () => {
    history.push("/customers");
    history.go("/customers");
  };

  return (
    <BusinessNegociationPage
      styles={styles}
      state={ownState}
      history={history}
      showHeader={showHeader}
      showData={showData}
      // handleSave={handleSave}
    />
  );
};

BusinessNegociation.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default BusinessNegociation;

import { css } from './CreateStitches';

export const accordion = css({
  '&.accordion': {
    '.accordion-item': {
      border: 'none',
      borderRadius: '$borderRadiusSM',
      overflow: 'hidden',
      marginBottom: 40,
      boxShadow: '$shadowLevel1',

      '.accordion-header': {
        '.accordion-button': {
          height: 40,
          padding: '12px $spacingXXS',
          backgroundColor: '$neutralColorHighLight',
          fontFamily: '$fontFamilyHighBaseBold',
          fontSize: '$fontSizeXXS',
          color: '$neutralColorLowMedium',
          textTransform: 'uppercase',

          '&:not(.collapsed)': {
            '&::after': {
              backgroundImage: 'var(--bs-accordion-btn-icon)',
            },
          },

          '&::after': {
            backgroundSize: 12,
            backgroundPosition: 'center',
          },

          '&:focus': {
            boxShadow: 'rgba(102, 102, 102, .2) 0px calc(-1px) 0px inset',
          },
        },
      },

      '.accordion-body': {
        table: {
          borderRadius: 0,

          td: {
            height: 101,

            button: {
              'span[class=""]': {
                display: 'flex',
                alignItems: 'center',
              },
            },

            '&:last-child': {
              position: 'absolute',
              padding: '9px $spacingXXS',
              margin: '$spacingXXS 0',
            },
          },
        },
      },

      '&:last-child': {
        marginBottom: 0,
      },

      '.accordion-collapse': {
        '.accordion-body': {
          padding: 0,
        },
      },
    },
  },
});

import { createStitches } from '@stitches/react';

export const { css } = createStitches({
  theme: {
    colors: {
      brandColorPrimaryPure: '#A500B9',
      brandColorPrimaryLight: '#D778CD',
      brandColorPrimaryMedium: '#9100A5',
      brandColorPrimaryDark: '#5F007D',

      brandColorSecondaryPure: '#FF7503',
      brandColorSecondaryLight: '#1AC42B',
      brandColorSecondaryMedium: '#1DEDD4',
      brandColorSecondaryDark: '#E04B6C',

      brandColorTertiaryPure: '#6300FF',
      brandColorTertiaryLight: '#2F1C59',
      brandColorTertiaryMedium: '#FAE703',
      brandColorTertiaryDark: '#144F1D',

      highlightColorPure: '#1648CD',
      highlightColorLight: '#82A4FF',
      highlightColorMedium: '#1951E5',
      highlightColorDark: '#123BA6',

      neutralColorLowPure: '#000000',
      neutralColorLowLight: '#A3A3A3',
      neutralColorLowMedium: '#666666',
      neutralColorLowDark: '#292929',

      neutralColorHighPure: '#FFFFFF',
      neutralColorHighLight: '#F5F5F5',
      neutralColorHighMedium: '#E0E0E0',
      neutralColorHighDark: '#CCCCCC',

      feedbackColorWarningPure: '#DD0101',
      feedbackColorWarningLight: '#F7638B',
      feedbackColorWarningMedium: '#C40135',
      feedbackColorWarningDark: '#910127',

      feedbackColorHelperPure: '#1648CD',
      feedbackColorHelperLight: '#82A4FF',
      feedbackColorHelperMedium: '#1951E5',
      feedbackColorHelperDark: '#123BA6',

      feedbackColorSuccessPure: '#1B7D2C',
      feedbackColorSuccessLight: '#76E388',
      feedbackColorSuccessMedium: '#38B04C',
      feedbackColorSuccessDark: '#0E4217',
    },
    space: {
      spacingQuarck: '4px',
      spacingNano: '8px',
      spacingXXXS: '16px',
      spacingXXS: '24px',
      spacingXS: '32px',
      spacingSM: '40px',
      spacingMD: '48px',
      spacingLG: '56px',
      spacingXL: '64px',
      spacingXXL: '80px',
      spacingXXXL: '120px',
      spacingHuge: '160px',
      spacingGiant: '200px',
    },
    sizes: {
      quarck: '4px',
      nano: '8px',
      xxxs: '16px',
      xxs: '24px',
      xs: '32px',
      sm: '40px',
      md: '48px',
      lg: '56px',
      xl: '64px',
      xxl: '80px',
      xxxl: '120px',
      huge: '160px',
      giant: '200px',
    },
    fonts: {
      fontFamilyHighlightRegular: 'Montserrat Regular',
      fontFamilyHighlightMedium: 'Montserrat Medium',
      fontFamilyHighlightBold: 'Montserrat Bold',
      fontFamilyHighBaseRegular: 'Source Sans Pro Regular',
      fontFamilyHighBaseMedium: 'Source Sans Pro Medium',
      fontFamilyHighBaseBold: 'Source Sans Pro Bold',
      fontFamilyVariables: 'Fira Code',
      fontFamilyOpenSansRegular: 'Open Sans Regular',
      fontFamilyOpenSansMedium: 'Open Sans Medium',
      fontFamilyOpenSansBold: 'Open Sans Bold',
    },
    fontSizes: {
      fontSizeXXS: '12px',
      fontSizeXS: '14px',
      fontSizeSM: '16px',
      fontSizeMD: '18px',
      fontSizeLG: '20px',
      fontSizeHXS: '24px',
      fontSizeHSM: '32px',
      fontSizeHMD: '40px',
      fontSizeHLG: '48px',
      fontSizeHXL: '64px',
      fontSizeDisplay: '80px',
      fontSizeGiant: '96px',
    },
    lineHeights: {
      lineHeightDefault: '100%',
      lineHeightXS: '115%',
      lineHeightSM: '120%',
      lineHeightMD: '133%',
      lineHeightLG: '150%',
      lineHeightXL: '170%',
      lineHeightXXL: '200%',
    },
    radii: {
      borderRadiusNone: '0px',
      borderRadiusSM: '8px',
      borderRadiusMD: '16px',
      borderRadiusLG: '24px',
      borderRadiusPill: '500px',
      borderRadiusCircular: '50%',
    },
    borderWidths: {
      borderWidthNone: '0px',
      borderWidthHairLine: '1px',
      borderWidthThin: '2px',
      borderWidthThick: '4px',
      borderWidthHeavy: '8px',
      borderQuarck: '4px',
      borderNano: '8px',
      borderXS: '16px',
      borderSM: '24px',
      borderMD: '32px',
      borderLG: '40px',
    },
    shadows: {
      shadowLevel1: '0px 4px 8px rgba(0, 0, 0, 0.08)',
      shadowLevel2: '0px 8px 24px rgba(0, 0, 0, 0.16)',
      shadowLevel3: '0px 16px 32px rgba(0, 0, 0, 0.16)',
      shadowLevel4: '0px 16px 48px rgba(0, 0, 0, 0.24)',
    },
  },
});

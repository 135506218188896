import { css } from './CreateStitches';

export const floatButton = css({
  variants: {
    float: {
      topLeft: {
        position: 'fixed',
        top: 10,
        left: 10,
      },
      topMiddle: {
        position: 'fixed',
        top: 10,
        left: '50%',
      },
      topRight: {
        position: 'fixed',
        top: 10,
        right: 10,
      },
      fullMiddle: {
        top: '50%',
        left: '50%',
      },
      bottomLeft: {
        position: 'fixed',
        bottom: 10,
        left: 10,
      },
      bottomMiddle: {
        position: 'fixed',
        bottom: 10,
        left: '50%',
      },
      bottomRight: {
        position: 'fixed',
        bottom: 10,
        right: 10,
      },
    },
  },
});

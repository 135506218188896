import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input, ButtonComponent } from 'cashin-components';

const RecoveryAccessForm = ({
  styles,
  state,
  handleKeyDown,
  handleChange,
  handleRecoverPassword,
  handleBackToLogin,
}) => {
  return (
    <Fragment>
      <h1 className={styles.tipography({ headings: 'mediumBold' })}>
        Vamos criar sua nova
        <br />
        senha de acesso.
      </h1>
      <p
        className={`
          ${styles.tipography({ paragraphs: 'mediumRegular' })}
          ${styles.colors({ colors: 'neutralColorLowMedium' })}
        `}
      >
        A senha deve conter, no mínimo, 8 dígitos, entre eles: letras,
        <br />
        números e caracteres especiais.
      </p>
      <Input
        id="txtNewPassword"
        inputName="newPasswordValue"
        className={styles.inputs({ input: 'default' })}
        idError="fdbNewPasswordError"
        inputType="password"
        labelName="Sua nova senha"
        placeholder="Digite sua senha"
        errorText={state.newPasswordError}
        isInvalid={state.isInvalidNewPassword}
        styles={styles}
        value={state.newPasswordValue}
        onKeyDown={(e) => handleKeyDown(e.key)}
        onChange={(e) => handleChange(e)}
      />
      <Input
        id="txtConfirmNewPassword"
        inputName="confirmNewPasswordValue"
        className={styles.inputs({ input: 'default' })}
        idError="fdbConfirmNewPasswordError"
        inputType="password"
        labelName="Repita sua nova senha"
        placeholder="Digite sua senha"
        errorText={state.confirmNewPasswordError}
        isInvalid={state.isInvalidConfirmNewPassword}
        styles={styles}
        value={state.confirmNewPasswordValue}
        onKeyDown={(e) => handleKeyDown(e.key)}
        onChange={(e) => handleChange(e)}
      />
      <ButtonComponent
        className={`${styles.buttonStandardLarge({
          large: 'primary',
        })}
        ${
          state.isLoading &&
          styles.buttonStandardLarge({
            large: 'primaryLoading',
          })
        } w-100 mb-3`}
        hasIcon={state.hasIcon}
        isFullWidthIconButton={true}
        iconName=""
        text="Salvar senha"
        isLoading={state.isLoading}
        styles={styles}
        onClick={() => handleRecoverPassword()}
      />
    </Fragment>
  );
};

RecoveryAccessForm.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleRecoverPassword: PropTypes.func,
  handleBackToLogin: PropTypes.func,
};

export default RecoveryAccessForm;

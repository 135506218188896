import React, { Fragment, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ButtonComponent } from 'cashin-components'
import ModalDeleteParticipant from './modal-delete-participant'
import { CampaignContext } from '../../context/campaign-context'
import { Auth } from 'aws-amplify'
import { NegotiationUsageTypeAction } from '../../enums/NegotiationUsageTypeAction'
import useGetParticipants from '../../API/queries/campaign/useGetParticipants'
import CustomPagination from '../../Components/CustomPagination'
import { useQueryClient } from 'react-query'
import api from '../../API/api'
import qs from 'qs'
import { toast } from 'react-toastify'
import { useCompanyUsageBalance } from '../../API/queries/company/useCompanyUsageBalance'

const ParticipantsTableComponent = ({
  styles,
  campaign,
  searchName,
  segmentation,
}) => {
  const [show, setShow] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  const [currentPagination, setCurrentPagination] = useState(1)
  const [usageBalance, setUsageBalance] = useState({})
  const [participantToRemove, setParticipantToRemove] = useState()

  const queryClient = useQueryClient()
  const campaignContext = useContext(CampaignContext)

  const companyId = currentUser?.attributes?.['custom:tenantId']

  const { refetch } = useCompanyUsageBalance(companyId)

  const { data: participantsList } = useGetParticipants(
    campaign?.participantBaseId,
    searchName,
    currentPagination,
    segmentation
  )

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser())
    }

    const usageBalanceAsync = async () => {
      var resonse = await api.get(
        '/company-negotiation-usage/current-usage/company/' + companyId
      )

      setUsageBalance(resonse)
    }

    usageBalanceAsync()

    runAsync()
  }, [companyId, participantsList])

  const handleClose = () => setShow(false)
  const handleShow = (participant) => {
    setShow(true)
    setParticipantToRemove(participant)
  }

  const handleOnPaginationChange = (newPage) => {
    setCurrentPagination(newPage)
  }

  const showHeader = () => {
    return (
      <Fragment>
        <th>Nome</th>
        <th>Celular</th>
        <th>E-mail</th>
        <th>Segmentos</th>
        <th>Ação</th>
      </Fragment>
    )
  }

  const handleDeleteParticipant = async () => {
    var queryString = qs.stringify(
      {
        engagementType: campaignContext.communicationType,
        companyName: campaignContext.companyName,
        campaingName: campaign.name,
        campaignId: campaign.id,
      },
      { skipNulls: true }
    )

    const addCompanyNegotiationBody = {
      companyId: companyId,
      totalUsage: parseInt(1),
      action: 'Participante excluido',
      usageLimit: parseInt(usageBalance.usageLimit),
      negotiationUsageTypeAction: NegotiationUsageTypeAction.RemoveParticipant,
    }

    await api.post(
      `/participant-base/${campaign?.participantBaseId}/remove-participant?${queryString}`,
      participantToRemove
    )

    await api.post('/company-negotiation-usage', addCompanyNegotiationBody)
    queryClient.refetchQueries('participants-list-from-campaing')
    handleClose()
    refetch()
    window.location.reload();


    toast.success('Participante removido.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })
  }

  const showData = () => {
    const applyCellphoneMask = (cellphone) => {
      const hasMask = /^\(\d{2}\) \d{5}-\d{4}$/.test(cellphone)
      if (hasMask) {
        return cellphone
      }

      const maskedCellphone = cellphone
        .replace(/^(\d{2})(\d)/g, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')

      return maskedCellphone
    }

    return (
      <>
        {participantsList?.items?.map((participant) => (
          <tr>
            <td width='30%'>{participant?.name}</td>
            <td width='15%'>{applyCellphoneMask(participant?.cellphone)}</td>
            <td width='25%'>{participant?.email}</td>
            <td width='20%'>{participant?.segmentationName}</td>
            <td width='10%'>
              <ButtonComponent
                className={`
                ${styles.buttonSquareLarge({
                  large: 'primary',
                })}
                  mb-3 bg-delete
                `}
                hasIcon={true}
                iconName='delete_forever'
                onClick={() => handleShow(participant)}
              />
            </td>
          </tr>
        ))}
      </>
    )
  }

  return (
    <Fragment>
      <table
        id='table'
        className={`
          ${styles.table()}
        `}
      >
        <thead>
          <tr>{showHeader()}</tr>
        </thead>
        <tbody>{showData()}</tbody>
      </table>
      <CustomPagination
        totalPages={(participantsList?.totalItems || 0) / 10}
        onPaginationChange={handleOnPaginationChange}
        currentPage={currentPagination}
        setCurrentPage={setCurrentPagination}
      />
      <ModalDeleteParticipant
        styles={styles}
        size='xs'
        show={show}
        onHide={handleClose}
        onDelete={handleDeleteParticipant}
      />
    </Fragment>
  )
}

ParticipantsTableComponent.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  searchName: PropTypes.string,
  refetchList: PropTypes.func,
}

export default ParticipantsTableComponent

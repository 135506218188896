import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { BadgeComponent } from 'cashin-components'
import StepsControls from '../steps-controls'
import LayoutSelector from './layout-selector'
import { CampaignContext } from '../../../context/campaign-context'

const ChooseLayout = ({
  styles,
  state,
  steps,
  handleStepBack,
  handleStepForward,
  handleSaveAndContinue,
  layoutType,
  handleLayoutType,
  layoutSelection,
  handleLayoutSelector,
  show,
  handleClose,
  handleShow,
  handleLayoutChoosen,
  handleSaveLayout,
  wppMessageAttributes,
  setWppMessageAttributes,
  appMessageAttributes,
  setAppMessageAttributes,
}) => {
  const campaignContext = useContext(CampaignContext)
  const isComunicationApp = campaignContext?.communicationType === 'app'

  return (
    <Col xs={10} className='pt-4 px-5'>
      <Row>
        <Col xs={12}>
          <BadgeComponent
            text={`Etapa ${state.activeStep}/${steps.length}`}
            styles={styles}
            className='badge-steps'
          />
        </Col>
        <Col xs={12}>
          <h2
            className={`
              ${styles.tipography({ headings: 'smallBold' })}
              my-2
            `}
          >
            {isComunicationApp
              ? 'Criar notificação push'
              : 'Escolha sua comunicação'}
          </h2>
          {isComunicationApp && (
            <h4
              className={`
              ${styles.tipography({ headings: 'xsmallBold' })}
              my-2
            `}
            >
              Escolha o estilo da sua comunicação e customize.
            </h4>
          )}
          <p
            className={`
              ${styles.tipography({ fontSizes: 'sm' })}
              ${styles.colors({ colors: 'neutralColorLowMedium' })}
            `}
          >
            {isComunicationApp
              ? 'Aqui você pode criar uma notificação personalizada ou pode visualizar as notificações padrão do nosso App.'
              : 'Escolha o estilo da sua campanha e customize.'}
          </p>
          <hr />
          <Row>
            <LayoutSelector
              styles={styles}
              state={state}
              layoutType={layoutType}
              handleLayoutType={handleLayoutType}
              layoutSelection={layoutSelection}
              handleLayoutSelector={handleLayoutSelector}
              show={show}
              handleClose={handleClose}
              handleShow={handleShow}
              handleLayoutChoosen={handleLayoutChoosen}
              handleSaveLayout={handleSaveLayout}
              wppMessageAttributes={wppMessageAttributes}
              setWppMessageAttributes={setWppMessageAttributes}
              appMessageAttributes={appMessageAttributes}
              setAppMessageAttributes={setAppMessageAttributes}
            />
            <StepsControls
              styles={styles}
              state={state}
              handleStepBack={handleStepBack}
              handleStepForward={handleStepForward}
              handleSaveAndContinue={handleSaveAndContinue}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

ChooseLayout.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  steps: PropTypes.array,
  handleStepBack: PropTypes.func,
  handleStepForward: PropTypes.func,
  handleSaveAndContinue: PropTypes.func,
  handleChangeSwitch: PropTypes.func,
  layoutType: PropTypes.array,
  handleLayoutType: PropTypes.func,
  layoutSelection: PropTypes.array,
  handleLayoutSelector: PropTypes.func,
  show: PropTypes.any,
  handleClose: PropTypes.func,
  handleShow: PropTypes.func,
  handleLayoutChoosen: PropTypes.func,
  handleSaveLayout: PropTypes.func,
}

export default ChooseLayout

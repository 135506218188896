import { css } from './CreateStitches';

export const tipography = css({
  variants: {
    headings: {
      giantBold: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeGiant',
        lineHeight: '$lineHeightSM',
      },
      giantMedium: {
        fontFamily: '$fontFamilyHighlightMedium',
        fontSize: '$fontSizeGiant',
        lineHeight: '$lineHeightSM',
      },
      giantRegular: {
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeGiant',
        lineHeight: '$lineHeightSM',
      },
      displayBold: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeDisplay',
        lineHeight: '$lineHeightSM',
      },
      displayMedium: {
        fontFamily: '$fontFamilyHighlightMedium',
        fontSize: '$fontSizeDisplay',
        lineHeight: '$lineHeightSM',
      },
      displayRegular: {
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeDisplay',
        lineHeight: '$lineHeightSM',
      },
      xLargeBold: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHXL',
        lineHeight: '$lineHeightSM',
      },
      xLargeMedium: {
        fontFamily: '$fontFamilyHighlightMedium',
        fontSize: '$fontSizeHXL',
        lineHeight: '$lineHeightSM',
      },
      xLargeRegular: {
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeHXL',
        lineHeight: '$lineHeightSM',
      },
      largeBold: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHLG',
        lineHeight: '$lineHeightDefault',
      },
      largeMedium: {
        fontFamily: '$fontFamilyHighlightMedium',
        fontSize: '$fontSizeHLG',
        lineHeight: '$lineHeightDefault',
      },
      largeRegular: {
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeHLG',
        lineHeight: '$lineHeightDefault',
      },
      mediumBold: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHMD',
        lineHeight: '$lineHeightDefault',
      },
      lightMedium: {
        fontFamily: '$fontFamilyHighlightMedium',
        fontSize: '$fontSizeHMD',
        lineHeight: '$lineHeightDefault',
      },
      lightRegular: {
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeHMD',
        lineHeight: '$lineHeightDefault',
      },
      smallBold: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHSM',
        lineHeight: '$lineHeightDefault',
      },
      smallMedium: {
        fontFamily: '$fontFamilyHighlightMedium',
        fontSize: '$fontSizeHSM',
        lineHeight: '$lineHeightDefault',
      },
      smallRegular: {
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeHSM',
        lineHeight: '$lineHeightDefault',
      },
      xsmallBold: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHXS',
        lineHeight: '$lineHeightDefault',
      },
      xsmallMedium: {
        fontFamily: '$fontFamilyHighlightMedium',
        fontSize: '$fontSizeHXS',
        lineHeight: '$lineHeightDefault',
      },
      xsmallRegular: {
        fontFamily: '$fontFamilyHighlightRegular',
        fontSize: '$fontSizeHXS',
        lineHeight: '$lineHeightDefault',
      },
      dividerLine: {
        width: 80,
        height: '4px !important',
        backgroundColor: '$neutralColorLowPure',
        borderColor: '$neutralColorLowPure',
        opacity: 1,
        marginBottom: 50,
      },
    },
    paragraphs: {
      largeRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeLG',
        lineHeight: '$lineHeightMD',
      },
      largeLinkRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeLG',
        lineHeight: '$lineHeightMD',
        textDecoration: 'underline',
      },
      largeBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeLG',
        lineHeight: '$lineHeightMD',
      },
      largeLinkBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeLG',
        lineHeight: '$lineHeightMD',
        textDecoration: 'underline',
      },
      mediumRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeMD',
        lineHeight: '$lineHeightMD',
      },
      mediumLinkRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeMD',
        lineHeight: '$lineHeightMD',
        textDecoration: 'underline',
      },
      mediumBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeMD',
        lineHeight: '$lineHeightMD',
      },
      mediumLinkBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeMD',
        lineHeight: '$lineHeightMD',
        textDecoration: 'underline',
      },
      smallRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeSM',
        lineHeight: '$lineHeightMD',
      },
      smallLinkRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeSM',
        lineHeight: '$lineHeightMD',
        textDecoration: 'underline',
      },
      smallBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeSM',
        lineHeight: '$lineHeightMD',
      },
      smallLinkBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeSM',
        lineHeight: '$lineHeightMD',
        textDecoration: 'underline',
      },
      xSmallRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeXS',
        lineHeight: '$lineHeightMD',
      },
      xSmallLinkRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeXS',
        lineHeight: '$lineHeightMD',
        textDecoration: 'underline',
      },
      xSmallBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeXS',
        lineHeight: '$lineHeightMD',
      },
      xSmallLinkBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeXS',
        lineHeight: '$lineHeightMD',
        textDecoration: 'underline',
      },
    },
    captions: {
      captionRegular: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeXXS',
        lineHeight: '$lineHeightDefault',
      },
      captionBold: {
        fontFamily: '$fontFamilyHighBaseBold',
        fontSize: '$fontSizeXXS',
        lineHeight: '$lineHeightDefault',
      },
      captionVariables: {
        fontFamily: '$fontFamilyVariables',
        fontSize: '$fontSizeXXS',
        lineHeight: '$lineHeightMD',
        color: '$highlightColorPure',
      },
    },
    margins: {
      marginBottomDefault: {
        marginBottom: '$spacingXXXS',
      },
    },
    fontSizes: {
      xxs: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeXXS',
        lineHeight: '$lineHeightDefault',
      },
      xs: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeXS',
        lineHeight: '$lineHeightDefault',
      },
      sm: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeSM',
        lineHeight: '$lineHeightDefault',
      },
      md: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeMD',
        lineHeight: '$lineHeightDefault',
      },
      lg: {
        fontFamily: '$fontFamilyHighBaseMedium',
        fontSize: '$fontSizeLG',
        lineHeight: '$lineHeightDefault',
      },
      hxs: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHXS',
        lineHeight: '$lineHeightDefault',
      },
      hsm: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHSM',
        lineHeight: '$lineHeightDefault',
      },
      hmd: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHMD',
        lineHeight: '$lineHeightDefault',
      },
      hlg: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHLG',
        lineHeight: '$lineHeightDefault',
      },
      hxl: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeHXL',
        lineHeight: '$lineHeightDefault',
      },
      display: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeDisplay',
        lineHeight: '$lineHeightDefault',
      },
      giant: {
        fontFamily: '$fontFamilyHighlightBold',
        fontSize: '$fontSizeGiant',
        lineHeight: '$lineHeightDefault',
      },
    },
    breadcrumb: {
      regular: {
        fontFamily: '$fontFamilyOpenSansRegular',
        fontSize: '$fontSizeXS',
        lineHeight: '$lineHeightDefault',
      },
      medium: {
        fontFamily: '$fontFamilyOpenSansMedium',
        fontSize: '$fontSizeXS',
        lineHeight: '$lineHeightDefault',
      },
      bold: {
        fontFamily: '$fontFamilyOpenSansBold',
        fontSize: '$fontSizeXS',
        lineHeight: '$lineHeightDefault',

        '& > a': {
          color: '$neutralColorLowPure',
          textDecoration: 'none',
        },
      },
    },
  },
});

import React from "react";
import PropTypes from "prop-types";
import { ButtonComponent, BadgeComponent } from "cashin-components";

const InternalUsersTableComponent = ({
  styles,
  header,
  data,
  handleDeleteUser,
  handleEditUser,
}) => {
  const showHeader = () => {
    try {
      return header.map((e, i) => {
        return <th key={i}>{e.headerTitle}</th>;
      });
    } catch (e) {
      return <th colSpan={header.length}>{e.message}</th>;
    }
  };

  const statusName = {
    0: "Criação pendente",
    1: "Ativo",
    2: "Desativado",
    3: "Deletado",
  };

  const showData = () => {
    try {
      return data?.items?.map((e, i) => {
        return (
          <tr key={i}>
            <td>
              {e.name} {e.surname}
            </td>
            <td>
              <BadgeComponent
                text={statusName[e.status]}
                bgType={e.status}
                isBadgePill={true}
                styles={styles}
              />
            </td>
            <td>
              <div className="d-flex justify-content-center">
                <ButtonComponent
                  className={styles.buttonSquareLarge({
                    large: "secondary",
                  })}
                  hasIcon={true}
                  iconName="delete_outline"
                  onClick={() => handleDeleteUser(e.id)}
                />
                <ButtonComponent
                  className={styles.buttonSquareLarge({
                    large: "secondary",
                  })}
                  hasIcon={true}
                  iconName="drive_file_rename_outline"
                  onClick={() => handleEditUser(e.id)}
                />
              </div>
            </td>
          </tr>
        );
      });
    } catch (e) {
      return (
        <tr>
          <td colSpan={data.length}>{e.message}</td>
        </tr>
      );
    }
  };

  return (
    <table
      id="table"
      className={`
        ${styles.table()}
      `}
    >
      <thead>
        <tr>{showHeader()}</tr>
      </thead>
      <tbody>{showData()}</tbody>
    </table>
  );
};

InternalUsersTableComponent.propTypes = {
  styles: PropTypes.object,
  header: PropTypes.array,
  data: PropTypes.array,
  handleDeleteUser: PropTypes.func,
  handleEditUser: PropTypes.func,
};

export default InternalUsersTableComponent;

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Col, Row, FormGroup } from 'react-bootstrap'
import { Input, Select, ButtonComponent, Check } from 'cashin-components'
import { styles } from 'cashin-design-system/Styles/Styles'
import { CampaignContext } from '../../../context/campaign-context'
import { Typeahead } from 'react-bootstrap-typeahead'

const styleContainer = {
  border: '#E0E0E0 solid 1px',
  borderRadius: 16,
  padding: '1.5rem',
  marginBottom: '2rem',
}

const CampaignVolume = ({ onFieldError }) => {
  const campaignContext = useContext(CampaignContext)

  const [participantDiscountValues, setParticipantDiscountValues] = useState([])

  const [particantDiscountDescription, setParticipantDiscountDescription] =
    useState([])

  const [discountValue, setDiscountValue] = useState(null)
  const [selectedValue, setSelectedValue] = useState(
    campaignContext?.participants?.map(() => 0) ?? []
  )
  const [rawPrizeValue, setRawPrizeValue] = useState('')
  const [participantItemQuantityListBody, setParticipantItemQuantityListBody] =
    useState(
      campaignContext?.participants?.map(() => [
        {
          quantity: '',
          code: '',
          conditionType: 2,
          type: 0,
        },
      ])
    )

  const [discountValueError, setDiscountValueError] = useState(null)
  const [codeError, setCodeError] = useState(null)
  const [discountValueUnchanged, setDiscountValueUnchanged] = useState(false)
  const [rawDiscountValue, setRawDiscountValue] = useState(0.0)
  const [fieldsValid, setFieldsValid] = useState({
    discountValue: true,
  })

  const handleDiscountValueChange = (segmentIndex, value, description) => {
    const updatedDiscountValues = [...participantDiscountValues]
    updatedDiscountValues[segmentIndex] = value
    setParticipantDiscountValues(updatedDiscountValues)

    const updatedDiscountDescription = [...particantDiscountDescription]
    updatedDiscountDescription[segmentIndex] = description
    setParticipantDiscountDescription(updatedDiscountDescription)

    const valid = parseFloat(value) > 0
    setFieldsValid((prevFieldsValid) => ({
      ...prevFieldsValid,
      discountValue: valid,
    }))
  }

  const formatToBRL = (value) => {
    const [integerPart, decimalPart] = String(value).split('.')

    let formattedInteger = integerPart.replace(/\D/g, '')
    formattedInteger = formattedInteger === '' ? '0' : formattedInteger

    let formattedDecimal = '00'
    if (decimalPart && decimalPart.length > 0) {
      formattedDecimal = decimalPart
        .replace(/\D/g, '')
        .padEnd(2, '0')
        .substring(0, 2)
    }

    return `${formattedInteger},${formattedDecimal}`
  }

  const handleOnlyNumbers = (value) => {
    return parseInt(value?.replace(/\D/g, ''))
  }

  const handleItemQuantityListData = (
    segmentIndex,
    itemIndex,
    field,
    value
  ) => {
    setParticipantItemQuantityListBody((prevData) => {
      const updatedParticipants = [...prevData]
      updatedParticipants[segmentIndex][itemIndex] = {
        ...updatedParticipants[segmentIndex][itemIndex],
        [field]: value,
      }
      return updatedParticipants
    })
  }

  const handleAddItem = (segmentIndex) => {
    setParticipantItemQuantityListBody((prevData) => {
      const updatedParticipants = [...prevData]
      updatedParticipants[segmentIndex] = [
        ...updatedParticipants[segmentIndex],
        {
          quantity: '',
          code: '',
          conditionType: 2,
          type: 0,
        },
      ]
      return updatedParticipants
    })
  }

  const handleDeleteItem = (segmentIndex, itemIndex) => {
    setParticipantItemQuantityListBody((prevData) => {
      const updatedParticipants = [...prevData]
      updatedParticipants[segmentIndex].splice(itemIndex, 1)
      return updatedParticipants
    })
  }

  const handleValueChange = (value, setValue) => {
    if (isNaN(value)) {
      return
    }
    if (value === '') {
      setValue(0)
      return
    }
    setValue(parseFloat(value))
  }

  useEffect(() => {
    const ruleAndPrize = campaignContext.segmentTags.map(
      (segment, segmentIndex) => ({
        ruleType: 1,
        segmentation:
          campaignContext.isSegmentation === 'yes' ? segment.label : undefined,
        itemQuantity: participantItemQuantityListBody[segmentIndex]?.map(
          (item) => ({
            quantity: handleOnlyNumbers(item.quantity),
            qtd: item.quantity,
            code: item.code,
            conditionType: item.conditionType,
            type: item.type,
          })
        ),
      })
    )

    const prizes = campaignContext.segmentTags.map((segment, segmentIndex) => {
      return {
        segmentation:
          campaignContext.isSegmentation === 'yes' ? segment.label : undefined,
        prizeType: 0,
        discount: {
          type: particantDiscountDescription[segmentIndex]?.includes('%')
            ? 1
            : 0,
          value: participantDiscountValues[segmentIndex],
        },
      }
    })

    campaignContext.setRuleAndPrize(ruleAndPrize, prizes)
  }, [participantItemQuantityListBody, participantDiscountValues])

  const validateFields = () => {
    const discountValueValid = parseFloat(discountValue) > 0

    setFieldsValid((prevFieldsValid) => ({
      ...prevFieldsValid,
      discountValue: discountValueValid,
    }))
  }

  useEffect(() => {
    validateFields()
  }, [participantItemQuantityListBody])

  useEffect(() => {
    const allFieldsValid = Object.values(fieldsValid).every((valid) => valid)

    onFieldError(allFieldsValid)
  }, [fieldsValid])

  useEffect(() => {
    validateFields()
  }, [])

  return (
    <>
      {campaignContext?.segmentTags?.map((segment, segmentIndex) => (
        <div
          style={
            campaignContext?.isSegmentation === 'yes' ? styleContainer : null
          }
        >
          <Row>
            {campaignContext?.isSegmentation === 'yes' && (
              <Col xs={4}>
                <Input
                  id='txtCampaignSegment'
                  inputName='campaignSegment'
                  className={styles.inputs({ input: 'default' })}
                  idError='fdbCampaignSegmentError'
                  inputType='text'
                  labelName='Segmento:'
                  placeholder='Ex.: Gerente, Coordenador, Vendedor'
                  styles={styles}
                  value={segment.label}
                  disabled
                />
              </Col>
            )}
          </Row>

          {participantItemQuantityListBody[segmentIndex].map((item, index) => {
            const isLastItem =
              participantItemQuantityListBody[segmentIndex].length > 0
                ? index ===
                  participantItemQuantityListBody[segmentIndex].length - 1
                : false
            const disabled = isLastItem

            return (
              <div key={index}>
                <Row>
                  <Col xs={4}>
                    <Input
                      id={`txtCampaignProductSKU${index}`}
                      inputName={`campaignProductSKU${index}`}
                      className={styles.inputs({ input: 'default' })}
                      idError='fdbCampaignProductSKUError'
                      inputType='text'
                      labelName='Produto ou Serviço:'
                      placeholder='Informe um produto ou serviço'
                      errorText={'Campo obrigatório!'}
                      isInvalid={codeError !== null}
                      styles={styles}
                      value={item.code}
                      onChange={(e) =>
                        handleItemQuantityListData(
                          segmentIndex,
                          index,
                          'code',
                          e.target.value
                        )
                      }
                      onBlur={() => {
                        if (!item.code) {
                          setCodeError('Insira um valor.')
                        } else {
                          setCodeError(null)
                        }
                        setDiscountValueUnchanged(!item.code)
                      }}
                    />
                  </Col>
                  <Col xs={3}>
                    <Form.Label
                      className={styles.tipography({ paragraphs: 'smallBold' })}
                      style={{ paddingTop: 1 }}
                    >
                      Quantidade:
                    </Form.Label>
                    <Typeahead
                      labelKey={(option) => option.description}
                      filterBy={['value']}
                      placeholder='Informe uma unidade'
                      options={[
                        {
                          type: 0,
                          value: `${rawPrizeValue} KM`,
                          description: `${rawPrizeValue} KM`,
                        },
                        {
                          type: 1,
                          value: `${rawPrizeValue} metros`,
                          description: `${rawPrizeValue} metros`,
                        },
                        {
                          type: 2,
                          value: `${rawPrizeValue} centímetros`,
                          description: `${rawPrizeValue} centímetros`,
                        },
                        {
                          type: 3,
                          value: `${rawPrizeValue} milímetros`,
                          description: `${rawPrizeValue} milímetros`,
                        },
                        {
                          type: 4,
                          value: `${rawPrizeValue} toneladas`,
                          description: `${rawPrizeValue} toneladas`,
                        },
                        {
                          type: 5,
                          value: `${rawPrizeValue} Kg`,
                          description: `${rawPrizeValue} Kg`,
                        },
                        {
                          type: 6,
                          value: `${rawPrizeValue} gramas`,
                          description: `${rawPrizeValue} gramas`,
                        },
                        {
                          type: 7,
                          value: `${rawPrizeValue} litros`,
                          description: `${rawPrizeValue} litros`,
                        },
                        {
                          type: 8,
                          value: `${rawPrizeValue} milímetros`,
                          description: `${rawPrizeValue} milímetros`,
                        },
                        {
                          type: 9,
                          value: `${rawPrizeValue} unidades`,
                          description: `${rawPrizeValue} unidades`,
                        },
                        {
                          type: 10,
                          value: `${rawPrizeValue} indicações`,
                          description: `${rawPrizeValue} indicações`,
                        },
                      ]}
                      onInputChange={(e) => {
                        handleItemQuantityListData(
                          segmentIndex,
                          index,
                          'quantity',
                          e[0]?.value
                        )
                        handleValueChange(e, setRawPrizeValue)
                      }}
                      label='prizeValue'
                      style={{ height: 44 }}
                      onChange={(e) => {
                        handleItemQuantityListData(
                          segmentIndex,
                          index,
                          'quantity',
                          e[0]?.value
                        )
                      }}
                    />
                  </Col>
                  <Col xs={3}>
                    <Select
                      placeholder='Selecione'
                      id={`selectConditions${index}`}
                      idError={`fdbTookConditionsError${index}`}
                      options={[
                        {
                          value: 0,
                          label: 'e',
                        },
                        {
                          value: 1,
                          label: 'Ou',
                        },
                      ]}
                      isInvalid={
                        !disabled &&
                        selectedValue[segmentIndex] !== 0 &&
                        selectedValue[segmentIndex] !== 1
                      }
                      errorText='Selecione'
                      styles={styles}
                      className='mt-4'
                      onChange={(e) => {
                        const updatedSelectedValue = [...selectedValue]
                        updatedSelectedValue[segmentIndex] = e.value
                        setSelectedValue(updatedSelectedValue)
                        handleItemQuantityListData(
                          segmentIndex,
                          index,
                          'conditionType',
                          e.value
                        )
                      }}
                      disabled={disabled}
                    />
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      marginTop: '0.25rem',
                      gap: '1rem',
                    }}
                  >
                    {participantItemQuantityListBody[segmentIndex].length ===
                      index + 1 && (
                      <ButtonComponent
                        className={`
                      ${styles.buttonSquareLarge({
                        large: 'primary',
                      })}
                      mt-4
                    `}
                        hasIcon={true}
                        iconName='add'
                        onClick={() => handleAddItem(segmentIndex)}
                        disabled={
                          participantItemQuantityListBody[segmentIndex]
                            .length >= 10
                        }
                      />
                    )}

                    {participantItemQuantityListBody[segmentIndex].length >
                      1 && (
                      <ButtonComponent
                        className={`
                      ${styles.buttonSquareLarge({
                        large: 'secondary',
                      })}
                      mt-4
                    `}
                        hasIcon={true}
                        iconName='delete_outline'
                        onClick={() => handleDeleteItem(segmentIndex, index)}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            )
          })}

          <Row>
            <Col xs={4}>
              <Form.Label
                className={styles.tipography({ paragraphs: 'smallBold' })}
                style={{ paddingTop: 1 }}
              >
                Ganhou:
              </Form.Label>
              <FormGroup controlId='txtCampaignPrice'>
                <Typeahead
                  id={`txtCampaignPrice${segmentIndex}`}
                  inputName={`campaignPrice${segmentIndex}`}
                  idError={`fdbCampaignPriceError${segmentIndex}`}
                  errorText={'Campo obrigatório!'}
                  isInvalid={discountValueError !== null}
                  onBlur={() => {
                    if (participantDiscountValues[segmentIndex] !== null) {
                      setDiscountValueError(null)
                    } else {
                      setDiscountValueError('Insira um valor.')
                    }
                    setDiscountValueUnchanged(
                      parseFloat(participantDiscountValues[segmentIndex]) ===
                        null
                    )
                  }}
                  labelKey={(option) => option.description}
                  filterBy={['value']}
                  options={[
                    {
                      id: 2,
                      type: 0,
                      value: rawDiscountValue,
                      description: `R$ ${formatToBRL(rawDiscountValue)}`,
                    },
                    {
                      id: 1,
                      type: 1,
                      value: rawDiscountValue,
                      description: `${formatToBRL(rawDiscountValue)} %`,
                    },
                  ]}
                  onInputChange={(value) => {
                    const formattedValue = parseFloat(value.replace(',', '.'))
                    if (!isNaN(formattedValue)) {
                      setRawDiscountValue(formattedValue)
                    }
                  }}
                  placeholder='0,00'
                  style={{ height: 44 }}
                  onChange={(selected) =>
                    handleDiscountValueChange(
                      segmentIndex,
                      selected[0]?.value,
                      selected[0]?.description
                    )
                  }
                />
                <div style={{ color: '#6300ff' }}>{discountValueError}</div>
              </FormGroup>
            </Col>
          </Row>
        </div>
      ))}

      <Row className='mt-4'>
        <Col xs={3}>
          <Check
            id='addRanking'
            label='Adicionar ranking'
            type='switch'
            onChange={(e) => {
              campaignContext.setIsRanking(e.target.value === 'on')
            }}
            value={campaignContext.isRanking}
            className={`
                ${styles.check({ switch: 'normal' })}
                ps-0
                mb-0
              `}
            hasTooltip={true}
            tooltipText='Com o Ranking você envia para os participantes a posição individual de cada um, ex: 3º Lugar. Para enviar o Ranking basta fazer uma apuração, seja "Parcial" ou no Encerramento da Campanha.'
          />
        </Col>
      </Row>
    </>
  )
}

CampaignVolume.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
}

export default CampaignVolume

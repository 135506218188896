import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FooterPage from './footer-page';

const Footer = ({ styles, profile }) => {
  let date = new Date();

  const returnYear = () => {
    return date.getFullYear();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <FooterPage
      styles={styles}
      returnYear={returnYear}
      profile={profile}
      show={show}
      handleClose={handleClose}
      handleShow={handleShow}
    />
  );
};

Footer.propTypes = {
  styles: PropTypes.object,
  profile: PropTypes.any,
};

export default Footer;

import { body } from './Body';
import { darkBody } from './DarkBody';
import { header } from './Header';
import { headerComponent } from './HeaderComponent';
import { section } from './Section';
import { tipography } from './Tipography';
import { border } from './Border';
import { opacityLevels } from './OpacityLevels';
import { shadow } from './Shadow';
import { spacing } from './Spacing';
import { colors } from './Colors';
import { loading } from './Loading';
import { emoticon } from './Emoticon';
import { logos } from './Logos';
import { buttonStandardLarge } from './ButtonStandardLarge';
import { buttonStandardLargeOnColor } from './ButtonStandardLargeOnColor';
import { buttonStandardMedium } from './ButtonStandardMedium';
import { buttonStandardMediumOnColor } from './ButtonStandardMediumOnColor';
import { buttonStandardSmall } from './ButtonStandardSmall';
import { buttonStandardSmallOnColor } from './ButtonStandardSmallOnColor';
import { buttonSquareLarge } from './ButtonSquareLarge';
import { buttonSquareLargeOnColor } from './ButtonSquareLargeOnColor';
import { buttonSquareMedium } from './ButtonSquareMedium';
import { buttonSquareMediumOnColor } from './ButtonSquareMediumOnColor';
import { buttonSquareSmall } from './ButtonSquareSmall';
import { buttonSquareSmallOnColor } from './ButtonSquareSmallOnColor';
import { floatButton } from './FloatButton';
import { inputs } from './Inputs';
import { select } from './Select';
import { check } from './Check';
import { customIcons } from './CustomIcons';
import { pagination } from './Pagination';
import { tabbar } from './Tabbar';
import { badge } from './Badge';
import { toast } from './Toast';
import { card } from './Card';
import { sortable } from './Sortable';
import { tabs } from './Tabs';
import { breadcrumb } from './Breadcrumb';
import { otpInput } from './OtpInput';
import { login } from './Login';
import { dashboard } from './Dashboard';
import { customerBase } from './CustomerBase';
import { campaigns } from './Campaigns';
import { calendar } from './Calendar';
import { verification } from './Verification';
import { reports } from './Reports';
import { navComponent } from './NavComponent';
import { table } from './Table';
import { steps } from './Steps';
import { modal } from './Modal';
import { progressBar } from './ProgressBar';
import { charts } from './Charts';
import { accordion } from './Accordion';
import { whatsAppMessage } from './WhatsAppMessage';
import { schedulerDashboard } from './SchedulerDashboard';
import { customAccordion } from './CustomAccordion';


export const styles = {
  body: body,
  darkBody: darkBody,
  header: header,
  headerComponent: headerComponent,
  section: section,
  tipography: tipography,
  border: border,
  opacityLevels: opacityLevels,
  shadow: shadow,
  spacing: spacing,
  colors: colors,
  loading: loading,
  emoticon: emoticon,
  logos: logos,
  buttonStandardLarge: buttonStandardLarge,
  buttonStandardLargeOnColor: buttonStandardLargeOnColor,
  buttonStandardMedium: buttonStandardMedium,
  buttonStandardMediumOnColor: buttonStandardMediumOnColor,
  buttonStandardSmall: buttonStandardSmall,
  buttonStandardSmallOnColor: buttonStandardSmallOnColor,
  buttonSquareLarge: buttonSquareLarge,
  buttonSquareLargeOnColor: buttonSquareLargeOnColor,
  buttonSquareMedium: buttonSquareMedium,
  buttonSquareMediumOnColor: buttonSquareMediumOnColor,
  buttonSquareSmall: buttonSquareSmall,
  buttonSquareSmallOnColor: buttonSquareSmallOnColor,
  floatButton: floatButton,
  inputs: inputs,
  select: select,
  check: check,
  customIcons: customIcons,
  pagination: pagination,
  tabbar: tabbar,
  badge: badge,
  card: card,
  toast: toast,
  sortable: sortable,
  tabs: tabs,
  breadcrumb: breadcrumb,
  otpInput: otpInput,
  login: login,
  dashboard: dashboard,
  customerBase: customerBase,
  campaigns: campaigns,
  calendar: calendar,
  verification: verification,
  reports: reports,
  navComponent: navComponent,
  table: table,
  steps: steps,
  modal: modal,
  progressBar: progressBar,
  charts: charts,
  accordion: accordion,
  whatsAppMessage: whatsAppMessage,
  schedulerDashboard: schedulerDashboard,
  customAccordion: customAccordion,
};

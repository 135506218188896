import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Icon from './Icon';

const StepsComponent = ({ styles, state, steps }) => {
  return (
    <Fragment>
      <Col xs={2} className="pt-4 steps-area">
        {steps.map((e, i) => {
          return (
            <Row key={i} className="steps">
              <Col xs={8}>
                <p
                  className={`
                    ${styles.tipography({
                      paragraphs: 'mediumBold',
                    })}
                    ${
                      state.isStepActive[i] || state.isStepDone[i]
                        ? ''
                        : styles.colors({
                            colors: 'neutralColorLowLight',
                          })
                    }
                    text-end m-0
                  `}
                >
                  {e.stepTitle}
                </p>
                <p
                  className={`
                    ${styles.tipography({
                      captions: 'captionRegular',
                    })}
                    ${
                      state.isStepActive[i] || state.isStepDone[i]
                        ? styles.colors({
                            colors: 'neutralColorLowMedium',
                          })
                        : styles.colors({
                            colors: 'neutralColorLowLight',
                          })
                    }
                    text-end m-0
                  `}
                >
                  {e.stepSubTitle}
                </p>
                <p
                  className={`
                    ${styles.tipography({
                      captions: 'captionRegular',
                    })}
                    ${
                      state.isStepActive[i] || state.isStepDone[i]
                        ? styles.colors({
                            colors: 'neutralColorLowMedium',
                          })
                        : styles.colors({
                            colors: 'neutralColorLowLight',
                          })
                    }
                    text-end
                  `}
                >
                  {e.stepSubTitle2}
                </p>
              </Col>
              <Col xs={4} className="d-flex justify-content-center steps-line">
                <div
                  className={`
                    ${styles.buttonSquareMedium({
                      medium: 'primary',
                    })}
                    d-flex align-items-center justify-content-center
                    ${
                      state.isStepActive[i]
                        ? ''
                        : state.isStepDone[i]
                        ? 'done'
                        : 'disabled'
                    }
                    step-badge
                  `}
                  aria-hidden="true"
                >
                  <Icon iconName={e.stepIcon} />
                </div>
              </Col>
            </Row>
          );
        })}
      </Col>
      <Col xs={'auto'} className="pt-4 step-status px-0">
        {steps.map((e, i) => {
          return (
            <Row key={i}>
              <Col
                xs
                className={`step-marker ${
                  state.isStepActive[i]
                    ? 'active'
                    : state.isStepDone[i]
                    ? 'done'
                    : 'disabled'
                }`}
              />
            </Row>
          );
        })}
      </Col>
    </Fragment>
  );
};

StepsComponent.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  steps: PropTypes.array,
};

export default StepsComponent;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { BreadcrumbComponent } from "cashin-components";
import EditCampaignTabs from "./edit-campaign-tabs";
import { useParams } from "react-router";
import api from "../API/api";
import {Box} from "../Components";

const EditCampaignPage = ({ styles, state }) => {
  const [campaign, setCampaign] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const getCampaignById = async () => {
      api.get(`/campaign/${id}`).then(response => setCampaign(response));
    };
    getCampaignById().then();
  }, [id]);

  return (
    <Row className={styles.steps()}>
      <Col xs={12} style={{ padding: 0 }}>
        <Row>
          <Col xs={12} className="pt-4">
            <Box>
              <BreadcrumbComponent
                hasIcon={true}
                styles={styles}
                initialIcon="home-color"
                initialNode="Dashboard"
                breadcrumbData={state.breadcrumb}
              />
            </Box>
          </Col>
          <Col xs={6}>
            <Box>
              <h1 className={`${styles.tipography({ headings: "smallBold" })}   my-3 `}>{campaign?.name}</h1>
            </Box>
          </Col>
          <Col xs={12}>
            <EditCampaignTabs
              styles={styles}
              state={state}
              campaign={campaign}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

EditCampaignPage.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
};

export default EditCampaignPage;

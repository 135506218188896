import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import {
  Check, ButtonComponent, Input,
} from 'cashin-components'
import { toast } from 'react-toastify'
import Accordion from 'react-bootstrap/Accordion'
import { CampaignContext } from '../../../../context/campaign-context'

const style = {
  fileInputTitle: {
    fontWeight: 'bold',
  },
  fileDropArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    padding: '1rem',
    border: '2px dashed #ccc',
    cursor: 'pointer',
    margin: '20px 0',
    position: 'relative',
  },
  fileDropAreaLabel: {
    marginBottom: '10px',
    display: 'contents',
  },
  fileDropAreaFileIcon: {
    fontSize: '30px',
    marginBottom: '10px',
  },
  fileDropAreaP: {
    fontSize: '12px',
    color: '#888',
  },
  labelFile: {
    textDecoration: 'underline',
    color: '#6300FF',
  },
}

const TrainingQuiz = ({ styles, loading, setLoading, setShowTrainingQuiz, setChoicesSavedConentQuiz }) => {

  const campaignContext = useContext(CampaignContext)

  const [thereIsPrize, setThereIsPrize] = useState('no');
  const [cashins, setCashins] = useState('R$ 0,00');
  const [titleQuiz, setTitleQuiz] = useState('');
  const [questionsQuiz, setQuestionsQuiz] = useState([{
    name: '',
    options: [''],
    points: 0,
    answer: ''
  }]);

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const [correctAnswers, setCorrectAnswers] = useState(Array(questionsQuiz.length).fill(''));
  const [isSavedContent, setIsSavedContent] = useState(false);

  const handleCorrectAnswerChange = (index, option) => {
    const newCorrectAnswers = [...correctAnswers];
    newCorrectAnswers[index] = option;
    setCorrectAnswers(newCorrectAnswers);

    const newQuestions = [...questionsQuiz];
    newQuestions[index].answer = option;
    setQuestionsQuiz(newQuestions);
  };

  const handleDeleteQuizModule = () => {
    setShowTrainingQuiz(false);
    setTitleQuiz('');
    setThereIsPrize('no');
    setChoicesSavedConentQuiz('none');
  };

  const formatMoney = (value) => {
    const onlyNumbers = value.replace(/[^\d]/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(onlyNumbers / 100);
    return formattedValue;
  };

  const formatNumber = (value) => {
    const newValue = parseFloat(value.replace('R$', '').replace('.', '').replace(',', '.').trim())
    return newValue
  }

  const handleCashinsChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatMoney(value);
    setCashins(formattedValue);
  };

  const validateFields = () => {
    if (thereIsPrize === 'yes' && (cashins === 'R$ 0,00' || cashins === '')) {
      toast.error('O campo quantidade de cashins é obrigatório')
      return false
    }

    if (!titleQuiz) {
      toast.error('O campo título do quiz é obrigatório')
      return false
    }

    if (questionsQuiz.length < 2) {
      toast.error('É necessário adicionar pelo menos duas perguntas')
      return false
    }

    for (let i = 0; i < questionsQuiz.length; i++) {
      if (!questionsQuiz[i].name) {
        toast.error(`O campo pergunta ${i + 1} é obrigatório`)
        return false
      }

      if (questionsQuiz[i].name === '') {
        toast.error(`O campo pergunta ${i + 1} é obrigatório`)
        return false
      }

      for (let j = 0; j < questionsQuiz[i].options.length; j++) {
        if (!questionsQuiz[i].options[j]) {
          toast.error(`O campo opção ${alphabet[j]} da pergunta ${i + 1} é obrigatório`)
          return false
        }

        if (questionsQuiz[i].options.length < 2) {
          toast.error(`É necessário adicionar pelo menos duas opções na pergunta ${i + 1}`)
          return false
        }

        if (questionsQuiz[i].options[j] === '') {
          toast.error(`O campo opção ${alphabet[j]} da pergunta ${i + 1} é obrigatório`)
          return false
        }

        if (questionsQuiz[i].options.filter((option) => option === questionsQuiz[i].options[j]).length > 1) {
          toast.error(`A opção ${alphabet[j]} da pergunta ${i + 1} está duplicada`)
          return false
        }
      }

      if (!correctAnswers[i]) {
        toast.error(`É necessário marcar a resposta correta da pergunta ${i + 1}`)
        return false
      }
    }

    campaignContext.setTrainingQuizObject({
      name: titleQuiz,
      url: '',
      type: 'quiz',
      quiz: questionsQuiz,
      points: formatNumber(cashins),
    })

    setChoicesSavedConentQuiz('published')
    return true
  }

  return (
    <Accordion className={styles.customAccordion()} defaultActiveKey='0' style={{
      marginBottom: '2rem',
    }}>
      <Accordion.Item
        eventKey={0}
      >
        <Accordion.Header>
          <div className='headerInfos'>
            <div style={{
              alignContent: "center",
            }}>
              <span
                className={styles.tipography({
                  paragraphs: 'mediumBold',
                })}
              >
                Cadastre as perguntas do seu Quiz
              </span>
            </div>
            <div className='endInfos' style={{
              gap: 0
            }}>
              <div className='texts'>
                <ButtonComponent
                  className={`
                  ${styles.buttonStandardMedium({
                    medium: 'secondary',
                  })}
                    mb-3 me-3
                  `}
                  hasIcon={true}
                  iconName='delete'
                  text='Excluir módulo'
                  styles={styles}
                  onClick={handleDeleteQuizModule}
                />
              </div>

              <div className='texts'>
                <ButtonComponent
                  className={`
                              ${styles.buttonStandardMedium({
                    medium: 'primary',
                  })}
                              mb-3
                            `}
                  hasIcon={true}
                  iconName='save'
                  text='Salvar'
                  isSuccessButton={isSavedContent}
                  styles={styles}
                  onClick={() => {
                    if (!validateFields()) {
                      return
                    }
                    setIsSavedContent(true)
                    toast.success('Quiz salvo com sucesso')
                  }}
                />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body style={{
          padding: '20px',
        }}>
          <div>
            <div className='texts'>
              <span
                className={styles.tipography({
                  paragraphs: 'mediumBold',
                })}
              >
                Terá premiação de Cashins neste quiz?
              </span>
            </div>
            <div className=' my-3 d-flex'>
              <Check
                id='yes'
                name='thereIsPrizeQuiz'
                label='Sim'
                type='radio'
                value='yes'
                checked={thereIsPrize === 'yes'}
                disabled={isSavedContent}
                onChange={(e) => {
                  setThereIsPrize(e.target.id)
                }}
                className={`${styles.check({ radio: 'normal' })} me-3`}
              />

              <Check
                id='no'
                name='thereIsPrizeQuiz'
                label='Não'
                type='radio'
                value='no'
                checked={thereIsPrize === 'no'}
                disabled={isSavedContent}
                onChange={(e) => {
                  setThereIsPrize(e.target.id)
                }}
                className={`${styles.check({ radio: 'normal' })} me-3`}
              />
            </div>
            {thereIsPrize === 'yes' && (
              <div className='my-3'>
                <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                  Quantos Cashins você quer premiar neste quiz?
                </span>
                <Input
                  id='txtPrize'
                  label='Quantidade de Cashins'
                  placeholder='Insira a quantidade de cashins'
                  styles={styles}
                  className='mt-3'
                  disabled={isSavedContent}
                  value={cashins}
                  onChange={handleCashinsChange}
                  style={{ marginLeft: 0 }}
                />
              </div>
            )}
            <Col xs={12}>
              <hr className='mt-3 mb-3' />
            </Col>
            <div className='my-3'>
              <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                Título
              </span>
              <Input
                id='txtVideoTitle'
                placeholder='Informe um título para o seu Quiz'
                styles={styles}
                className='mt-3'
                disabled={isSavedContent}
                style={{ marginLeft: 0 }}
                value={titleQuiz}
                onChange={(e) => setTitleQuiz(e.target.value)}
                maxLength={30}
              />
            </div>
            <hr className='mt-3 mb-3' />
            {questionsQuiz.map((question, index) => (
              <div key={index} className='my-3'>
                <span className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                  Pergunta {index + 1}
                </span>
                {index > 1 && !isSavedContent && (
                  <ButtonComponent
                    className={`
                      ${styles.buttonStandardMedium({
                      medium: 'primary',
                    })}
                      mb-3 float-end
                    `}
                    hasIcon={true}
                    iconName='delete'
                    text='Excluir pergunta'
                    styles={styles}
                    disabled={isSavedContent}
                    onClick={() => {
                      const newQuestions = [...questionsQuiz];
                      newQuestions.splice(index, 1);
                      setQuestionsQuiz(newQuestions);
                    }}
                  />
                )}
                <Input
                  id={`txtQuestion${index}`}
                  label={`Pergunta ${index + 1}`}
                  placeholder='Digite a sua pergunta'
                  styles={styles}
                  disabled={isSavedContent}
                  className='mt-3'
                  style={{ marginLeft: 0 }}
                  value={question.name}
                  onChange={(e) => {
                    const newQuestions = [...questionsQuiz];
                    newQuestions[index].name = e.target.value;
                    setQuestionsQuiz(newQuestions);
                  }}
                />
                <hr className='mt-3 mb-3' />
                {question.options.map((option, indexOption) => (
                  <div key={indexOption} className='my-3'>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginRight: indexOption === 0 ? "1rem" : 0,
                    }}
                    >
                      <b style={{
                        marginTop: 5,
                        marginRight: 6
                      }}>{alphabet[indexOption] + '.'}</b>
                      <Input
                        id={`txtOption${indexOption}`}
                        label={`Opção ${indexOption + 1}`}
                        placeholder='Digite a opção'
                        styles={styles}
                        disabled={isSavedContent}
                        className='w-80'
                        value={option}
                        onChange={(e) => {
                          const newQuestions = [...questionsQuiz];
                          newQuestions[index].options[indexOption] = e.target.value;
                          setQuestionsQuiz(newQuestions);
                        }}
                      />
                      <div
                        style={{ marginTop: 5, width: 240, marginLeft: "1rem" }}>
                        <Check
                          label='Marca resposta correta'
                          type='checkbox'
                          checked={correctAnswers[index] === option}
                          disabled={isSavedContent}
                          onChange={() => {
                            handleCorrectAnswerChange(index, option);
                          }}
                          className={`${styles.check({ radio: 'normal' })}`}
                        />
                      </div>

                      {indexOption > 0 && !isSavedContent && (
                        <svg
                          width='24'
                          height='26'
                          viewBox='0 0 13 15'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          onClick={() => {
                            const newQuestions = [...questionsQuiz];
                            newQuestions[index].options.splice(indexOption, 1);
                            setQuestionsQuiz(newQuestions);
                          }}
                          style={{
                            cursor: 'pointer', marginTop: 5,
                          }}
                        >
                          <path
                            d='M1.75004 13.0417C1.75004 13.9125 2.46254 14.625 3.33337 14.625H9.66671C10.5375 14.625 11.25 13.9125 11.25 13.0417V5.125C11.25 4.25417 10.5375 3.54167 9.66671 3.54167H3.33337C2.46254 3.54167 1.75004 4.25417 1.75004 5.125V13.0417ZM11.25 1.16667H9.27087L8.70879 0.604583C8.56629 0.462083 8.36046 0.375 8.15462 0.375H4.84546C4.63962 0.375 4.43379 0.462083 4.29129 0.604583L3.72921 1.16667H1.75004C1.31462 1.16667 0.958374 1.52292 0.958374 1.95833C0.958374 2.39375 1.31462 2.75 1.75004 2.75H11.25C11.6855 2.75 12.0417 2.39375 12.0417 1.95833C12.0417 1.52292 11.6855 1.16667 11.25 1.16667Z'
                            fill='#A3A3A3'
                          />
                        </svg>
                      )}
                    </div>
                    <hr className='mt-3 mb-3' />
                  </div>
                ))}
                <Check
                  id={`addOption${index}`}
                  name={`addOption${index}`}
                  type='radio'
                  checked={isSavedContent}
                  className={`${styles.check({ radio: 'normal' })} me-3`}
                  label='Adicionar opção'
                  onChange={() => {
                    const newQuestions = [...questionsQuiz];
                    newQuestions[index].options.push('');
                    setQuestionsQuiz(newQuestions);
                  }}
                />
                <hr className='mt-3 mb-3' />
              </div>
            ))}

            <div className='d-flex my-3 align-items-center gap-3'>
              <ButtonComponent
                className={`
                ${styles.buttonStandardMedium({
                  medium: 'primary',
                })}
                  mb-3 mt-3
                `}
                hasIcon={true}
                iconName='add'
                text=''
                disabled={isSavedContent}
                style={{
                  width: 10,
                  borderRadius: 14
                }}
                styles={styles}
                onClick={() => {
                  const newQuestions = [...questionsQuiz];
                  newQuestions.push({
                    name: '',
                    options: [''],
                    points: 0,
                    answer: ''
                  });
                  setQuestionsQuiz(newQuestions);
                }}
              />
              <h2 className={`${styles.tipography({ paragraphs: 'mediumBold' })}`}>
                Adicionar pergunta
              </h2>
            </div>

          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

TrainingQuiz.propTypes = {
  styles: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setShowTrainingVideo: PropTypes.func,
}

export default TrainingQuiz
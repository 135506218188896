import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditInternalUserPage from './edit-internal-user-page';

const defaultState = {
  valueStatusOptions: [
    {
      value: 1,
      label: 'Todos',
    },
  ],
  breadcrumb: [
    {
      url: '/internal-users',
      itemName: 'Usuários internos',
    },
    {
      isActive: true,
      itemName: 'Editar usuário',
    },
  ],
};

const EditInternalUser = ({ styles, history }) => {
  const [ownState, setOwnState] = useState(defaultState);

  const handleSaveInternalUser = () => {
    history.push('/internal-users');
    history.go('/internal-users');
  };

  return (
    <EditInternalUserPage
      styles={styles}
      state={ownState}
      handleSaveInternalUser={handleSaveInternalUser}
    />
  );
};

EditInternalUser.propTypes = {
  styles: PropTypes.object,
  history: PropTypes.object,
};

export default EditInternalUser;

import PropTypes from "prop-types";
import parse from "html-react-parser";
import { useContext, useEffect } from "react";
import { CampaignContext } from "../../../../context/campaign-context";
import { Auth } from "aws-amplify";
import { useState } from "react";
import useIdentificationInfo from "../../../../API/queries/company/useIdentificationInfo";

const BannerObrigado = `${process.env.REACT_APP_BLOBSTORAGE_URL}/BannerObrigado.png`;

const FolderObrigado = ({ layoutInfo, layoutTitleChoosen, render }) => {

  const [currentUser, setCurrentUser] = useState({});  
  const { data: indentificantionData } = useIdentificationInfo();
  const companyName = indentificantionData?.find((x) => x.id === currentUser?.attributes?.["custom:tenantId"])?.description;  

  useEffect(() => {
    const runAsync = async () => {
      setCurrentUser(await Auth.currentAuthenticatedUser());
    }; 
    runAsync();
  }, []); 

  const html = `
  <!DOCTYPE html>
  <html lang="en">
  
  <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
  </head>
  
  <body topmargin="0" rightmargin="0" bottommargin="0" leftmargin="0" bgcolor="#e1e1e1">
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td align="center">
          <table border="0" cellpadding="0" cellspacing="0" width="600" bgcolor="#ffffff">
            <tr>
              <td align="center" valign="middle" height="88" bgcolor=${
                layoutTitleChoosen !== "Divertido"
                  ? layoutInfo.baseColor
                  : "#ffffff"
              }>
                <img src=${
                  layoutInfo.logoHeader
                } alt="" style="max-width: 600px">
              </td>
            </tr>
            <tr>
              <td align="left" valign="middle" style="padding: 0 40px;">
                <br>
                <font face="Arial" style="font-size: 30px; font-weight: 700;" color=${
                  layoutInfo.titleColor
                }>
                  "Acabou! Obrigada por participar<br>com a gente"
                </font>
                <br>
                <br>
                <font face="Arial" style="font-size: 16px; font-weight: 400;" color=${
                  layoutInfo.textColor
                }>
                  Você participou da <b>*Campanha ${
                    layoutInfo.campaignName
                  }*</b> <i>_${companyName}_</i> no<br>período de ${
    layoutInfo.startDate
  } a ${layoutInfo.endDate}.
                </font>
                <br>
                <br>
                <br>
              </td>
            </tr>
            <tr>
              <td align="center" valign="middle" height="40">
                <font face="Arial" style="font-size: 24px; font-weight: 700;" color=${
                  layoutInfo.textColor
                }>
                  Aproveite!
                </font>
                <br>
                <br>
                <font face="Arial" style="font-size: 16px; font-weight: 400;" color=${
                  layoutInfo.textColor
                }>
                  <i>Chegou a hora de colher os resultados da sua performance</i>
                </font>
                <br>
                <br>
                <font face="Arial" style="font-size: 20px; font-weight: 400;" color=${
                  layoutInfo.textColor
                }>
                  Seu prêmio na mão, de forma 100% digital, com muita segurança<br>e liberdade.
                </font>
                <br>
                <br>
                <font face="Arial" style="font-size: 16px; font-weight: 400;" color=${
                  layoutInfo.textColor
                }>
                  Consulte sua premiação em: <a href="https://app.cashin.com.br/" target="_blank" style="color: ${
                    layoutInfo.textColor
                  }">https://app.cashin.com.br/</a>
                </font>
              </td>
            </tr>
            <tr>
              <td align="center" valign="middle">&nbsp;</td>
            </tr>
            <tr>
              <td align="center" valign="middle" height="40">
                <img src=${BannerObrigado} alt="" style="width: 311px">
              </td>
            </tr>
            <tr>
              <td align="center" valign="middle">&nbsp;</td>
            </tr>
            ${
              layoutTitleChoosen !== "Divertido" &&
              layoutTitleChoosen !== "Executivo"
                ? `
              <tr>
                <td align="center" valign="middle" style="padding: 0 40px;">
                  <hr style="width: 520px; border: solid 1px #000000; margin: 0;">
                </td>
              </tr>
            `
                : ""
            }
            <tr>
              <td align="center" valign="middle" height="240" bgcolor=${
                layoutInfo.footerColor
              }>
                <table border="0" cellpadding="0" cellspacing="0" width="504">
                  <tr>
                    <td colspan="2" align="center" valign="middle">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="middle">
                      <font face="Arial" style="font-size: 15px; font-weight: 400;" color=${
                        layoutInfo.footerTextColor
                      }>
                        Se você é usuário Cashin e precisa de ajuda o atendimento<br>é feito somente por e-mail:
                      </font>
                      <br>
                      <a href="mailto:suporte.multicampanhas@cashin.com.br" style="color: ${
                        layoutInfo.footerTextColor
                      };">
                        <font face="Arial" style="font-size: 15px; font-weight: 400;" color=${
                          layoutInfo.footerTextColor
                        }>
                          suporte.multicampanhas@cashin.com.br
                        </font>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="center" valign="middle" style="border-right: solid 1px ${
                      layoutInfo.footerTextColor
                    }">
                      <a href=${
                        layoutInfo.campaignWebSite
                      } target="_blank" style="color: ${
    layoutInfo.footerTextColor
  };">
                        <font face="Arial" style="font-size: 15px; font-weight: 400;" color=${
                          layoutInfo.footerTextColor
                        }>
                          Acesse o site da<br>campanha clicando aqui
                        </font>
                      </a>
                    </td>
                    <td align="center" valign="middle">
                      <a href=${
                        layoutInfo.campaignTerms
                      } target="_blank" style="color: ${
    layoutInfo.footerTextColor
  };">
                        <font face="Arial" style="font-size: 15px; font-weight: 400;" color=${
                          layoutInfo.footerTextColor
                        }>
                          Acesse o regulamento da<br>campanha clicando aqui
                        </font>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="middle">
                      <img src=${
                        layoutInfo.logoHeader
                      } alt="" style="max-width: 600px">
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="center" valign="middle">&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    </td>
    </tr>
    </table>
  </body>
  
  </html>
`;

  const campaignContext = useContext(CampaignContext);

  useEffect(() => {
    campaignContext.addLayout(html, 13);
  }, [html, campaignContext]);

  if (render) return parse(html);
  else return <></>;
};

FolderObrigado.propTypes = {
  layoutInfo: PropTypes.object,
  layoutTitleChoosen: PropTypes.string,
};

export default FolderObrigado;

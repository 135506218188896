import React from "react";
import PropTypes from "prop-types";

const Icon = ({ styles, className, iconName, onClick, ...rest }) => {
  return (
    <span
      className={`${styles} material-icons ${className}`}
      aria-hidden="true"
      onClick={onClick}
      {...rest}
    >
      {iconName}
    </span>
  );
};

Icon.propTypes = {
  styles: PropTypes.object,
  className: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;

import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { BadgeComponent, RuleTypeSelector } from "cashin-components";
import { CampaignContext } from '../../../context/campaign-context'
import CampaignRulesForm from './campaign-rules-form'
import CampaignHitTook from "./campaign-hit-took";
import CampaignVolume from "./campaign-volume";
import CampaignHybrid from "./campaign-hybrid";
import StepsControls from "../steps-controls";

const CampaignRules = ({
  styles,
  state,
  steps,
  campaignRules,
  startDateValue,
  endDateValue,
  onChangeStartDate,
  onChangeEndDate,
  handleKeyDown,
  handleChange,
  handleUploadChange,
  handleRuleType,
  handleChangeSwitch,
  handleStepBack,
  handleStepForward,
  handleSaveAndContinue,
}) => {
  const campaignContext = useContext(CampaignContext)
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false)

  const [canProceedToNextStepByRule, setCanProceedToNextStepByRule] =
    useState(false);
  const [selectedRule, setSelectedRule] = useState(0);

  return (
    <Col xs={10} className="pt-4 px-5">
      <Row>
        <Col xs={12}>
          <BadgeComponent
            text={`Etapa ${state.activeStep}/${steps.length}`}
            styles={styles}
            className="badge-steps"
          />
        </Col>
        <Col xs={12}>
          <h2
            className={`
              ${styles.tipography({ headings: "smallBold" })}
              pt-3 pb-3 mb-3
            `}
          >
            Selecione o tipo de regra:
          </h2>

          <hr />
          <RuleTypeSelector onRuleChange={(rule) => {
            campaignContext.setCampaignRuleSelected(rule)
            setSelectedRule(rule)
          }} />

          {campaignContext.isSegmentation === 'yes' && (<hr />)}

          {selectedRule === 0 && (
            <CampaignHitTook onFieldError={setCanProceedToNextStepByRule} />
          )}
          {selectedRule === 1 && (
            <CampaignVolume onFieldError={setCanProceedToNextStepByRule} />
          )}
          {selectedRule === 2 && (
            <CampaignHybrid onFieldError={setCanProceedToNextStepByRule} />
          )}
        </Col>

        <StepsControls
          styles={styles}
          state={state}
          handleStepBack={handleStepBack}
          handleStepForward={handleStepForward}
          handleSaveAndContinue={handleSaveAndContinue}
          canProceedToNextStep={
            canProceedToNextStepByRule
          }
        />
      </Row>
    </Col>
  );
};

CampaignRules.propTypes = {
  styles: PropTypes.object,
  state: PropTypes.object,
  steps: PropTypes.array,
  campaignRules: PropTypes.array,
  startDateValue: PropTypes.any,
  endDateValue: PropTypes.any,
  onChangeStartDate: PropTypes.any,
  onChangeEndDate: PropTypes.any,
  handleKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleUploadChange: PropTypes.func,
  handleRuleType: PropTypes.func,
  handleChangeSwitch: PropTypes.func,
  handleStepBack: PropTypes.func,
  handleStepForward: PropTypes.func,
  handleSaveAndContinue: PropTypes.func,
};

export default CampaignRules;
import { css } from './CreateStitches';

export const whatsAppMessage = css({
  '.wp-area': {
    position: 'relative',
    width: 350,
    padding: 10,
    textAlign: 'center',
    backgroundColor: '#e4ffd4',
    borderRadius: 7.5,
    borderTopRightRadius: 0,
    boxShadow: '0 1px .5px rgba(11, 20, 26, .13)',

    '.wp-ballon-arrow': {
      position: 'absolute',
      top: 0,
      right: '-8px',
      display: 'block',
      width: 8,
      height: 13,
      color: '#e4ffd4',

      svg: {
        display: 'block',
        pointerEvents: 'none',
      },
    },

    img: {
      width: '100%',

      '&.img-attachment': {
        borderRadius: 7.5,
      },
    },
  },
});
